import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import green from "@material-ui/core/colors/green";
import classNames from "classnames";
//import AutoSelect from 'react-select';
import { connect } from "react-redux";
import { searchOperation } from "../../reducers/search";

import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100
  },
  formControl: {
    margin: theme.spacing.unit,
    //minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  button: {
    margin: theme.spacing.unit
  },
  padding5px: {
    display: "flex",
    flexWrap: "wrap",
    padding: "5px",
    margin: "10px"
  },
  cssGreen: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  titlePanel: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500])
  },
  selectStyles: {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    })
  }
});

class RouteSelect extends Component {
  state = {
    value:''
  };
  componentDidMount(){
    console.log('componentDidMount search_operation: local',window.localStorage.getItem('company'));
    console.log('componentDidMount search_operation:',this.props.search_operation);
    if(localStorage.getItem('company')=="SUPPLIER")
      this.props.searchOperation({...this.props.search_operation,routeType:'sequence' });
    else if(!this.props.search_operation.routeType && this.props.search_operation.routeType=='')
      this.props.searchOperation({...this.props.search_operation,routeType:'all' });
  }
  renderMenuItem(){
    let company = localStorage.getItem('company');
    console.log('#renderMenuItem company',company);
    let  routeList = [
      {id:'all',name:'All'},
      {id:'milkrun',name:'Milkrun'},
      {id:'sequence',name:'Seq. route'}
    ];
    if(routeList !== undefined){
      var menuItem = routeList;
    //if(this.props.location_group !== undefined){
    //  var menuItem = this.props.location_group;
      return menuItem.map(function(data, index) {
        return (
          <MenuItem key={index} value={data.id} disabled={company=="SUPPLIER" ? true : false}>
            {data.name}
          </MenuItem>
        );
      });
    }
    else 
      return null; 
  }
  handleChange = (event) =>{
    this.setState({ value: event.target.value });
    this.props.searchOperation({...this.props.search_operation,routeType:event.target.value });
  }
  render() {
    const { classes } = this.props;
    return (
        <FormControl  className={classes.textField}>
            <InputLabel shrink htmlFor="routeSelect-required">
              ROUTE TYPE  
            </InputLabel>
            <Select
              name="routeSelection"
              width={100}
              inputProps={{
                  id: "routeSelect-required"
              }}
              value={this.props.search_operation.routeType ? this.props.search_operation.routeType : (localStorage.getItem('company')=="SUPPLIER" ? 'sequence' : 'all')}
              onChange={this.handleChange}
            >
            
            {this.renderMenuItem()}

            </Select>
        </FormControl>
    );
  }
}
const mapStateToProps = function(state,ownProps) {
  
  return {
    ...ownProps,
    search_operation : state.search.search_operation
  };
};
const mapActionsToProps = {
  searchOperation
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(RouteSelect));
