
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import {
    Field,
} from "redux-form";
import { required } from "../../../libs/validation";
import { selectCategory } from "../../../reducers/ui/selectList";
import { searchHazardRoute } from "../../../reducers/search";
const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
});
const renderSelect = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    value, 
    defaultValue,
    ...props
  }) => (
    <Select
      error={!!(touched && error)}
      helperText={touched && error}
      defaultValue={defaultValue}
      value={value}
      {...inputProps}
      {...props}
    />
  );
class RunSeqSelect extends Component {
    constructor(props){
        super(props);
        this.menuItem = [
            {value:"share",icon :"🚫 ", text : "Share Information"},
            {value:"warning",icon :"❗",  text : "Warning"},
            {value:"prohibit",icon :"⚠️ ", text : "Prohibit Area"}
        ]
        if(props.isSearch !== undefined && 
            props.isSearch === true){
            this.menuItem = [
                {value:"03",icon : '',   text :"03"},
                
            ];
        }
    }
    handleChange = (event) =>{
        this.props.searchHazardRoute({
             ...this.props.search_hazard_route,
             runSeq:event.target.value
        });
    }
    renderMenuItem() {
        if(this.props.run_seqs.runSeq){
          var menuItem = this.props.run_seqs.runSeq;
          return menuItem.map(function(data) {
            return (
              <MenuItem key={data} value={data}>
                {data}
              </MenuItem>
            );
          });
        }
        else 
          return null;
    }
    render(){
        return (
            <FormControl required fullWidth>
                <InputLabel shrink htmlFor="runSeq-field">
                Run Seq.
                </InputLabel>
                <Field
                    defaultValue="03"
                    value={'03'}
                    onChange={this.handleChange}
                    name="runSeq"
                    component={renderSelect}
                    label="CATEGORY"
                    InputLabelProps={{
                        shrink: true,
                        id: "runSeq-field"
                    }}
                    margin="dense"
                    className={styles.textField}
                    validate={[required]}
                    fullWidth
                    >
                    {this.renderMenuItem()}
                </Field>
            </FormControl>
        )
    }
}
const mapStateToProps = function(state,ownProps) {
    return {
        category : state.selection.category,
        search_hazard_route : state.search.search_hazard_route,
        run_seqs : state.map.run_seqs,
        ...ownProps
    };
  };
  const mapActionsToProps = {
    searchHazardRoute : searchHazardRoute,
    selectCategory
  };
  
  export default connect(
    mapStateToProps,
    mapActionsToProps
  )(withStyles(styles)(RunSeqSelect));