import React, { Component } from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import compose from "recompose/compose";
import DatePicker from 'react-datepicker';
import DialogActions from "@material-ui/core/DialogActions";
import 'react-datepicker/dist/react-datepicker.css';
//import renderDatePicker from '../Ui/Renderer/DatePickerRenderer';
import {
  propTypes,
  reduxForm,
  Form,
  Field,
  formValueSelector
} from "redux-form";
//import AutoSelect from 'react-select';
//Lib
import { required, number } from "../../libs/validation";
//Component
import CategorySelect from './RouteForm/CategorySelect';
import Impacts from './Impacts';
//Reducer
import {
  apiGetCallRequest,
  apiGetCallSuccess,
  resetAPIResult,
  apiCallRequest
} from "../../reducers/api";
import { addLocation, addGroupLocation } from "../../reducers/ui/mapForm";
import { connect } from "react-redux";
import { uiPanelDialogOpen, uiSwitchForm } from "../../reducers/ui";
import { selectImpactHazard } from "../../reducers/ui/selectList";
import {
  mapDrawingMode,
  mapResetMap,
  mapChangeMarker,
  mapDrawingOptions,
  mapSetSuggestionPolylines,
  SET_MARKER_ORIGIN_DESC
} from "../../reducers/map";

import { getLocationGroup,getLogisticPoint,GET_IMPACT_HAZARDS } from "../../reducers/map/api";
import { searchHazardImpact } from "../../reducers/search";
//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);
class HazardForm extends Component {
  state = {
    actionsLocationPointGroup: false,
    actionsLocationGroup: false,
  };

  componentDidMount() {
    this.props.RESET_MAP(true);
    this.props.DRAWING_OPTION(this.state.drawingOptions);
    let defaultValue = {
      category: "all"
    };
    if (this.props.search_hazard_impact.category) {
      defaultValue = this.props.search_hazard_impact;
    }
    this.props.initialize({...defaultValue});
    this.props.searchHazardImpact({...defaultValue})
  }
  componentWillMount() {
  }
  componentWillReceiveProps(nextProps, props) {
  }
  handleChange = event => {
    if (event.target.name === "category") {
      this.setState({
        [event.target.name]: event.target.value,
        categoryText: event.currentTarget.innerText
      });
      this.props.searchHazardImpact({
        ...this.props.search_hazard_impact,
        category:event.target.value
      });
    } else {
      this.props.searchHazardImpact({
        ...this.props.search_hazard_impact,
        [event.target.name] :event.target.value
      });
    }
  };
  handleSearch = form => {
    
    this.props.mapSetSuggestionPolylines([])
    this.props.RESET_MAP(true);

    let filter = this.props.search_hazard_impact;
    if(filter.category && filter.category=='all') delete(filter.category);
    this.props.GET_IMPACT_HAZARDS({
      url: Configs[env].BACKEND_HOST + "/impactHazardCond",
      // url: "https://map.leafte.ch/impactHazardHistoryCond",
      filter: filter
    });
  };
  handleSelectedHazard = () =>{
      this.props.selectImpactHazard({id:5})
  }
  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div style={{ padding: "10px" }}>
        <Card className={styles.card}>
          <CardContent>
            <Form
              autoComplete={"off"}
              autoCorrect={"off"}
              spellCheck={"off"}
              onSubmit={handleSubmit(this.handleSearch.bind(this))}
            > 
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <CategorySelect 
                    isSearch={true}
                    formImpact="impact"/>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="location"
                    label="LOCATION"
                    component={renderInput}
                    className={styles.textField}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="detail"
                    label="DETAIL"
                    component={renderInput}
                    className={styles.textField}
                    onChange={this.handleChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12}>
                <DialogActions>
                {/* <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    aria-label="Save"
                    className={styles.button}
                    onClick={this.handleSelectedHazard}
                  >
                    SELCTED HAZARD 5
                  </Button> */}
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    aria-label="Save"
                    className={styles.button}
                    type="submit"
                  >
                    SEARCH
                  </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
            <Typography variant="subtitle1">Impact Detail : </Typography>
            <Impacts />
          </CardContent>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = function(state) {
  return {
    apiFetchResult: state.api.result,
    dialogOpen: state.ui.dialogOpen,
    formManage: state.ui.formManage,
    manageForm: state.mapForm.manageForm,
    search_hazard_impact : state.search.search_hazard_impact
  };
};
const mapActionsToProps = {
  DRAWING_OPTION: mapDrawingOptions,
  OPEN_DIALOG: uiPanelDialogOpen,
  SWITCH_FORM: uiSwitchForm,
  RESET_MAP: mapResetMap,
  GET_IMPACT_HAZARDS,
  searchHazardImpact,
  selectImpactHazard,
  SET_MARKER_ORIGIN_DESC,
  mapSetSuggestionPolylines
};
const enhance = compose(
  reduxForm({
    form: "searchHazardForm",
    initialValues: {
      category: "0"
    }
    // validate
  }),
  connect(
    mapStateToProps,
    mapActionsToProps
  ),
  withStyles(styles)
);
export default enhance(HazardForm);
