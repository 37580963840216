import React, { Component } from "react";
import {
  Marker,
  InfoWindow
} from "react-google-maps";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
// import {
//   MARKER_SELECTING,
// } from "../../../reducers/map";

class DataLoadingBox extends Component {
  componentWillUpdate(nextProps, nextState) {}

  render() {
    return (
      <div style={{ }}>
          <div>
            {/* <center>Please wait...</center> */}
            <CircularProgress size={'30px'} />
          </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    data_loading: state.map.data_loading
    // marker_selecting: state.map.marker_selecting
  };
};

const mapActionsToProps = {
//   MARKER_SELECTING : MARKER_SELECTING,
//   MARKER_MOVE : mapMoveMarker
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(DataLoadingBox);
