import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { connect } from "react-redux";
import moment from "moment";
import compose from "recompose/compose";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

//Component
import TollRenderer from "../Ui/Renderer/TollRenderer";
import AddBtnRenderer from "./Renderer/AddBtnRenderer";
import SecToTimeRenderer from "../Ui/Renderer/SecToTimeRenderer";
import DistanceRenderer from "../Ui/Renderer/DistanceRenderer";
//Reducer
import {
  apiCallRequest,
  apiCallSuccess,
  apiCallFail
} from '../../reducers/api';
import { getCandidateRoutes } from "../../reducers/map/api";
import { MAP_SELECT_CANDIDATE
  ,mapResetMap ,
  SELECT_MASTER_OR_CANDIDATE,
  SET_MARKER_ORIGIN_DESC} from "../../reducers/map";

//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    margin:0,
    //marginLeft: theme.spacing.unit,
    //marginRight: theme.spacing.unit,
    width: 200,
  },
});
class CandidateRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toll : 0,
      columnDefs: [
        { 
          headerName: "ADD", 
          field: "Vehicle" ,
          cellRenderer: "addBtnRenderer",
          width: 35
        },{ 
          headerName: "ROUTE", 
          field: "Vehicle" ,
          width: 80,
          autoHeight: true
        },{ 
          headerName: "DISTANCE", 
          field: "TotalDistance" ,
          cellRenderer : "distanceRenderer",
          width: 75,
          autoHeight: true
        },{ 
          headerName: "DURATION", 
          field: "TotalTime" ,
          cellRenderer : "secToTimeRenderer",
          width: 120,
          autoHeight: true
        },
        { 
          headerName: "TOLL", 
          field: "TollUsage" ,
          width: 50,
          autoHeight: true,
          cellRenderer: "tollRenderer",
        }
        
      ],
      rowData: null,
      context: { componentParent: this },
      suppressRowClickSelection: true,
      rowSelection: "multiple",
      frameworkComponents: {
        tollRenderer : TollRenderer,
        addBtnRenderer: AddBtnRenderer,
        secToTimeRenderer : SecToTimeRenderer,
        distanceRenderer : DistanceRenderer,
      },
    };
  }
  componentDidMount() {
      
  }
  componentWillUnmount() {
    
  }
  componentDidUpdate () {
    this.props.SET_MARKER_ORIGIN_DESC(
      { 
          marker_origin : {
                lat : this.props.logisticRouteForm.originLat , 
                lng : this.props.logisticRouteForm.originLng
              },
          marker_desc : {
                lat : this.props.logisticRouteForm.destinationLat , 
                lng : this.props.logisticRouteForm.destinationLng
              }
        }
    )
    this.autoSizeAll();
  }
  //Grid
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  }
  autoSizeAll = () => {
    // var allColumnIds = [];
    // if(this.gridColumnApi){

    //   this.gridColumnApi.getAllColumns().forEach(function(column,index) {
    //     if(index!=0)allColumnIds.push(column.colId);
    //   });
    //   this.gridColumnApi.autoSizeColumns(allColumnIds);
    // }
  }

  componentWillReceiveProps(nextProps) {
    this.focusRow(nextProps.candidate_selecting)
  }
  
  focusRow = (candidate_selecting) => {
    let gridApi = this.gridApi
    if(gridApi){
      gridApi.deselectAll()
      this.gridApi.forEachNode(function(node) {
        if (node.data.Vehicle === candidate_selecting) {

          node.setSelected(true,true);
          gridApi.ensureIndexVisible(node.rowIndex, { position: 'top' }); 

          // and ensureNodeVisible(event.node, { position: 'top' });
        }

      });


    }
  }

  getCandidateRoutes = () =>{

    let self = this;
    let paths_neth = [];
    paths_neth.push(new window.google.maps.LatLng(this.props.logisticRouteForm.originLat, this.props.logisticRouteForm.originLng));
    paths_neth.push(new window.google.maps.LatLng(this.props.logisticRouteForm.destinationLat, this.props.logisticRouteForm.destinationLng));
    let encodedURI = (window.google.maps.geometry.encoding.encodePath(paths_neth));    

    let filter = {
      // st_lat: this.props.logisticRouteForm.originLat,
      // st_lng : this.props.logisticRouteForm.originLng,
      // en_lat : this.props.logisticRouteForm.destinationLat,
      // en_lng : this.props.logisticRouteForm.destinationLng,
      // toll : self.state.toll,
      // date_time : moment().format("YYYY-MM-DD[T]HH:mm:ss"),
      toll : 1,
      service_time: 0,
      vehicle_type: 8,
      user : 'leaftech',
      date_time: '2018-11-22T08:00:00',
      polyline: encodedURI
    };

    this.props.RESET_MAP(true);
    this.props.GET_CANDIDATE({
      url: Configs[env].REROUTE_HOST,
      filter: filter
      // url:Configs[env].CANDIDATE_HOST,
      // filter: filter
    });
  }
  renderData = () => {
    if (Array.isArray(this.props.candidate_routes)) {

      return this.props.candidate_routes;
    } else return [];
  }
  handleSelectionChanged = node => {
    var selectedRows = node.api.getSelectedRows();


    this.props.SELECT_MASTER_OR_CANDIDATE(2)
    this.props.MAP_SELECT_CANDIDATE(selectedRows[0].Vehicle);
  }
  handleChangeToll = () =>{
    this.setState({toll:this.state.toll==1 ? 0 : 1});
  }
  render() {
    return (
      <div style={{ width: "100%", height: "180px" ,padding:'5px'}}>
        <div
          className="ag-theme-balham"
          style={{
            height: "150px",
            width: "100%",
          }}>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <DialogActions>
                <Typography variant="subheading">CANDIDATE ROUTES : </Typography>
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.toll==1 ? 'checked' : ''}
                      value="toll"
                      onChange={this.handleChangeToll}
                      color="primary"
                    />
                  }
                  label="Toll"
                /> */}
                <Button 
                    variant="contained" size="small"  color="primary" 
                    aria-label="Add" className={classNames(styles.button)} 
                    onClick={this.getCandidateRoutes}>
                    GET
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
          <AgGridReact
            enableSorting={true}
            singleClickEdit={true}
            suppressMovableColumns={true}
            enableColResize={true}
            columnDefs={this.state.columnDefs}
            rowData={this.renderData()}
            rowSelection="single"
            onGridReady={this.onGridReady}
            onRowClicked={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function(state,ownProps) {
  return {
    isLoading : state.api.fetching,
    candidate_routes: state.map.candidate_routes,
    logisticRouteForm : state.mapForm.logisticRouteForm,
    candidate_selecting : state.map.candidate_selecting,
  }  
};
const mapActionsToProps =  {
  getRequest : apiCallRequest,
  success : apiCallSuccess,
  RESET_MAP: mapResetMap,
  GET_CANDIDATE : getCandidateRoutes,
  SELECT_MASTER_OR_CANDIDATE : SELECT_MASTER_OR_CANDIDATE,
  MAP_SELECT_CANDIDATE,
  SET_MARKER_ORIGIN_DESC
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapActionsToProps,
    //mapDispatchToProps
  ),
  withStyles(styles)
)(CandidateRoutes);
