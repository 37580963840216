import React, { Component } from "react";
import { connect } from "react-redux";
import uuidv4  from 'uuid/v4';

//Component
import MarkerPlace from './MarkerPlace';

//Reducer
import {
    mapCenterChanged,
    mapZoomChanged,
    mapSetMarkerPlace,
    mapSelectedMarkerSearch,
    CLEAR_SELECTING
} from "../../reducers/map";
const {SearchBox} = require("react-google-maps/lib/components/places/SearchBox");
  
class SearchPlace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placename : true,
    };
  }
  componentWillUpdate(nextProps, nextState) {}

  onPlacesChanged = () => {
    const places = this.searchbox.getPlaces();
    const bounds = new window.google.maps.LatLngBounds();

    if(places.length>0){
      // console.log("KAI places",places)
      let name = '';
      places.forEach(place => {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
        name = place.name;
      });
      const nextMarkers = places.map(place => ({
        position: place.geometry.location
      }));
      if(nextMarkers){
        // console.log('#onPlacesChanged nextMarkers',nextMarkers);
        this.props.mapSetMarkerPlace({
          ...this.props.markerPlace,
          name : name ,
          position : nextMarkers[0].position});
        this.props.SET_CENTER(nextMarkers[0].position);
        this.props.SET_ZOOM(15);
        this.props.CLEAR_SELECTING()
        this.props.mapSelectedMarkerSearch('place_id');
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <SearchBox
            ref={input => (this.searchbox = input)}
            // bounds={this.props.bounds}
            controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
            onPlacesChanged={this.onPlacesChanged}>
            <input
                type="text"
                placeholder="Search Places"
                style={{
                    boxSizing: "border-box",
                    border: "1px solid transparent",
                    width: "300px",
                    height: "32px",
                    marginTop: "10px",
                    marginRight: "40px",
                    padding: "0 12px",
                    borderRadius: "3px",
                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                    fontSize: "14px",
                    outline: "none",
                    textOverflow: "ellipses"
                }}
            />
        </SearchBox>
        {this.props.markerPlace && 
          (
             <MarkerPlace
                 key="place_id"
                 id="place_id"
                 name={this.props.markerPlace.name}
                 position={this.props.markerPlace.position}
                 />
      
        )}
     </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    bounds : state.map.bounds,
    markerPlace : state.map.markerPlace
  };
};

const mapActionsToProps = {
    SET_CENTER : mapCenterChanged,
    SET_ZOOM : mapZoomChanged,
    mapSetMarkerPlace,
    mapSelectedMarkerSearch,
    CLEAR_SELECTING
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(SearchPlace);
