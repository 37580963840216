import React, { Component } from "react";
import { render } from "react-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline
} from "react-google-maps";


import {
    MAP_SELECT_SUGGESSION,
  } from "../../../reducers/map";

import { compose, withProps, withStateHandlers } from "recompose";
import { connect } from "react-redux";

// {{ lat: -34.397, lng: 150.644 }}

class IhPolyline extends Component {
  // selectInfo = arg => {
  //   this.props.candidate_selecting(this.props.id);


  // };

  // onDragEnd = e => {
  //   this.props.position.lat = e.latLng.lat();
  //   this.props.position.lng = e.latLng.lng();
  //   this.props.MARKER_MOVE(this.props);
  // };

  onClick = e => {
    // this.props.MAP_SELECT_SUGGESSION(this.props.id)

  };

  onMouseOver = e => {

    // this.props.PATH_MOUSEOVER(this.props.id);

  };
  onMouseOut = e => {
    // this.props.PATH_MOUSEOUT(this.props.id);

  };

  render() {
    const id = this.props.id;
    let selected = false;
    let isMoving = false;

    // if (this.props.candidate_selecting === id) {
    //   selected = true;
    // }
    // if (this.props.marker_selecting === id) {
    //   selected = true;
    // }
    if (this.props.suggestion_selecting === id) {
      selected = true;
    }
    if (
      this.props.marker_MovingAdding === id ||
      this.props.marker_MovingAdding === id + 1
    ) {
      isMoving = true;
    }

    let strokeWeight = 4;
    if (this.props.path_onMouseOver === id) {
      strokeWeight = 7;
    }

    const arrowSymbol = {
      strokeColor: "#2868FC",
      strokeOpacity: 0.8,
      strokeWeight: strokeWeight,
      icons: [
        {
          icon: {
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
          },
          offset: "50%"
          // repeat: "20px"
        }
      ]
    };

    // strokeColor: "#2868FC",
    const normalSymbol = {
      zIndex: 0,
      strokeColor: "#808080",
      strokeOpacity: 0.8,
      strokeWeight: strokeWeight,
      icons: []
    };

    // strokeColor: "red",
    const higlightSymbol = {
      zIndex: 1,
      strokeColor: "#1E90FF",
      strokeOpacity: 1,
      strokeWeight: strokeWeight,
      icons: []
    };

    const dashlineSymbol = {
      strokeColor: "#ff2527",
      strokeOpacity: 0,
      strokeWeight: strokeWeight,
      icons: [
        {
          icon: {
            path: "M 0,-0.5 0,0.5",
            strokeOpacity: 1,
            scale: 4
          },
          offset: "0",
          repeat: "15px"
        }
      ]
    };

    // let icon = "http://maps.google.com/mapfiles/ms/icons/green.png";
    // let icon = new window.google.maps.MarkerImage(
    //   "http://maps.google.com/mapfiles/ms/icons/green.png",
    //   null /* size is determined at runtime */,
    //   null /* origin is 0,0 */,
    //   null /* anchor is bottom center of the scaled image */,
    //   new window.google.maps.Size(32, 32)
    // );
    return (
      <React.Fragment>
        <Polyline
          {...this.props}
          onClick={this.onClick}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          options={selected ? higlightSymbol : normalSymbol}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    candidate_selecting: state.map.candidate_selecting,
    marker_selecting: state.map.candidate_selecting,
    suggestion_selecting: state.map.suggestion_selecting,
    // marker_MovingAdding: state.marker_MovingAdding,
    // paths: state.paths,
    // path_onMouseOver: state.path_onMouseOver
  };
};

const mapActionsToProps = {
    MAP_SELECT_SUGGESSION : MAP_SELECT_SUGGESSION,
  // POLYGON_SELECTING : mapSelectedPolygon ,
  // POLYGON_CHANGE : mapChangePolygon ,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(IhPolyline);
