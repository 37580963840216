import React, { Component } from "react";
import { setSidebarVisibility,uiSwitchForm } from "../../reducers/ui";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import SplitPane from 'react-split-pane';
import { Pane } from 'react-sortable-pane';
//import Pane from 'react-split-pane/lib/Pane';
//Component
import BaseMap from "../Map/BaseMap";
import SearchForm from "./SearchForm";
import ManageForm from "./ManageForm";
import LocationGroupForm from "./LocationGroupForm";
//Reducer
import { selectReset } from '../../reducers/ui/selectList';
import { mapResetMap,mapSetLogisticPoint } from '../../reducers/map';


const env = process.env.NODE_ENV;
const MIN_HEIGHT = 25;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  scrollHeight:{

  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  gridForm : {
    padding : '0px'
  },
  subheader: {
    width: '100%',
  },
});

class LocationMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  componentWillMount() {
    //console.log('#componentWillMount...');
    //this.props.selectReset(true);
    this.props.mapSetLogisticPoint([]);
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;

    setSidebarVisibility(false);
  }
  autosizeHeaders(event) {
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        "#locationMap .ag-header-cell-label"
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach(cell => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }
  renderManageForm(){
    switch(this.props.formManage.page){
      case 'manage' : 
        return (<ManageForm/>);
      case 'group' : 
        return (<LocationGroupForm
          
          type={this.props.formManage.mode}
          /*logistic={{ 
            value : this.state.logistic,
            text : this.state.logisticText
          }}
          location={{
            detail : this.state.locationDetail,
            value : this.state.location,
            text : this.state.locationText,
          }}*/
          />);
      default : 
        return (<SearchForm/>);
    }
  }
  methodFromParent(cell) {

  }
  render() {
    return (
      //height: 'calc(100vh-60px)' 
      <div style={{ display: 'flex', height:'calc(100vh - 52px)' }}>
       
          <React.Fragment> 
            <SplitPane split="vertical" minSize={50} defaultSize={100}>
                <Pane initialSize="400px" minSize="200px" key={"key_sklanlksadlar"} >
                     <div style={{height:'calc(100vh - 40px)',overflow:'auto'}}>
                        {this.renderManageForm()}
                     </div>
                  </Pane>
                  <Pane key={"key_sajkgdjk92b9r"}  >
                    <div style={{ height:'calc(100vh - 52px)' }}>
                          <BaseMap isReset={this.props.isReset}/>
                    </div>
                  </Pane>
            </SplitPane>
          </React.Fragment>  
      </div>
    );
  }
}
const mapStateToProps = state => ({
  isReset : state.map.isReset,
  formManage : state.ui.formManage,
});

const mapDispatchToProps = dispatch => {
  return {
    selectReset: payload => dispatch(selectReset(payload)),
    setSidebarVisibility: status => dispatch(setSidebarVisibility(status)),
    mapSetLogisticPoint :  payload => dispatch(mapSetLogisticPoint(payload)),
    dispatch
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(LocationMaster);
