import React, {Component} from "react";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

// import EditIcon from '@material-ui/icons/Create';
//Component
import { uiSwitchForm } from '../../../reducers/ui';
//Reducer
import {
    apiCallRequest,
} from '../../../reducers/api';
import { getMasterRoutes } from "../../../reducers/map/api";
//Config
import Configs from "../../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
    gridButton: {
        padding: '0px 0px',
        minWidth: '50px',
        fontSize: '0.8em',
        minHeight: '20px'
    },
    iconStyle :{
        padding:0,
    },
    center:{
      position: 'relative',
      left: '20px'
    }
})

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class AddBtnRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open:false,
            name : this.props.data.name
        }
        this.invokeParentMethod = this.invokeParentMethod.bind(this);
    }

    invokeParentMethod() {

        this.setState({open : true});
        //this.props.context.componentParent.methodFromParent(`Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`)
        //this.props.SWITCH_FORM({page:"manage",mode:"edit",editID:this.props.data.id});
    }
    handleConfirm = () =>{

        var id = this.props.data.id;
        let params = {
            logisticsRouteId: this.props.logisticRouteForm.logisticsRouteId,
            routes: this.props.data,
            name : this.props.data.Vehicle
        };

        this.props.API_REQUEST({
            url: Configs[env].BACKEND_HOST + "/route",
            method : 'POST',
            form: params,
            callback : this.handleReloadMaster
        });

        this.setState({ open: false });
        
        //setTimeout(function(){this.handleReloadMaster.bind(this);},1500)
        //this.handleReloadMaster();
    }
    handleReloadMaster = () => {

        this.props.GET_MASTER_ROUTE({
            url:Configs[env].BACKEND_HOST + "/routeCond",
            filter: {
              logisticsRouteId: this.props.logisticRouteForm.logisticsRouteId,
            }
        });
        this.handleCancel();
    }
    handleCancel = () => {
        this.setState({ open: false });
    };
    render() {
        const { classes } = this.props;

        return (
            <span>
                <IconButton
                    className={classes.iconStyle}
                    onClick={this.invokeParentMethod}>
                    <AddIcon/>
                </IconButton>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    >
                    <DialogTitle id="alert-dialog-slide-title">
                        Add Candidate route into master route
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                        Confirm to add { this.state.name }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirm.bind(this)} disabled={this.props.isLoading} color="primary">
                        {this.props.isLoading && <CircularProgress className={classes.center} size={25} thickness={2} />}
                    Confirm
                        </Button>
                        <Button onClick={this.handleCancel.bind(this)} disabled={this.props.isLoading} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        );
    }
};

const mapStateToProps = function(state,ownProps) {
    return {
        ...ownProps,
        isLoading: state.ui.isLoading,
        logisticRouteForm : state.mapForm.logisticRouteForm,
    }
};

const mapActionsToProps =  {
    API_REQUEST : apiCallRequest,
    SWITCH_FORM : uiSwitchForm,
    GET_MASTER_ROUTE : getMasterRoutes,

};
const enhance = compose(
    connect(
        mapStateToProps,
        mapActionsToProps
    ),
    withStyles(styles)
);  

export default enhance(AddBtnRenderer)
