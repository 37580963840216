import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Marker } from "react-google-maps";

//Component 
import EditBtnRenderer from "../Ui/Renderer/EditBtnRenderer";
import DeleteBtnRenderer from "../Ui/Renderer/DeleteBtnRenderer";
import CategoryIconRenderer from "../HazardPoints/Renderer/CategoryIconRenderer";
//Reducer
import { apiGetCallRequest } from "../../reducers/api";
import { mapSetLogisticPoint, mapSelectedMarker,
  MAP_LOG,SET_HAZARD_PHOTO,ADD_HAZARD_VDO,
  MAP_SELECT_SUGGESSION,
  mapSetSuggestionPolylines,
  mapSetBounds,
  SET_MARKER_ORIGIN_DESC } from "../../reducers/map";
//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    margin: 0,
    //marginLeft: theme.spacing.unit,
    //marginRight: theme.spacing.unit,
    width: 200
  }
});
class HazardSelected extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pointList: this.props.hazard_seleciton_list,
      url_endpoint: {
        response: false,
        endpoint: Configs[env].BACKEND_HOST
      },
      columnDefs: [{
        headerName: "Plan Arrival Time",
        field: "planArrivalTime",
        width: 60
      },{
        headerName: "Route",
        field: "routeCode",
        width: 60
      },{
        headerName: "Run Seq.",
        field: "runSeq",
        width: 50
      },{
        headerName: "ETA",
        field: "eta",
        width: 60
      },{
        headerName: "Part Usage Time",
        field: "partUsageTime",
        width: 80
      },{
        headerName: "Status",
        field: "status",
        width: 100
      }
    ],
      rowData: null,
      context: { componentParent: this },

      frameworkComponents: {
        categoryIconRender : CategoryIconRenderer,
      }
    };
  }
  componentDidMount() {
    this.autoSizeAll();
  }
  componentWillUnmount() {}
  componentDidUpdate(){
    // this.autoSizeAll();
  }
  componentWillReceiveProps(nextProps, props) {
    this.focusRow(nextProps.marker_selecting)
    /*if(props.hazard_seleciton_list!=nextProps.hazard_seleciton_list){
      this.setHazardDetail(nextProps.hazard_seleciton_list[0].job);
    }*/
}
  focusRow = (marker_selecting) => {
    let gridApi = this.gridApi
    if(gridApi){
      gridApi.deselectAll()
      this.gridApi.forEachNode(function(node) {
        if (node.data.latitude === marker_selecting) {

          node.setSelected(true,true);
          gridApi.ensureIndexVisible(node.rowIndex, { position: 'top' }); 
        }
      });
    }
  }
  componentDidUpdate () {
    // this.autoSizeAll();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  }
  autoSizeAll = () => {
    var allColumnIds = [];
    if(this.gridColumnApi){

      this.gridColumnApi.getAllColumns().forEach(function(column,index) {
        if(index!=0)allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  }
  renderRow() {
    // console.log('#renderRow hazard_seleciton_list',this.props.hazard_seleciton_list);
    if (this.props.hazard_seleciton_list.length>0){
      if (this.props.hazard_seleciton_list[0].job && Array.isArray(this.props.hazard_seleciton_list[0].job)) {
        
        return this.props.hazard_seleciton_list[0].job;
      } else return [];
    } else return [];
  }

  handleSelectionChanged = node => {
    try{
      
        let selectedRows = node.api.getSelectedRows();

        // -------- Set polyline --------
        this.props.SET_MARKER_ORIGIN_DESC({ 
            marker_origin : {},
            marker_desc : {}
        })
        this.props.mapSetSuggestionPolylines([])
        let bounds = [];
        let paths = [];

        let routes = selectedRows[0].routes
        if(selectedRows[0].routes) {
          let polygonList = [];
          let polygon = {}; 
          var decodePath = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(routes));
              
              decodePath.forEach(function(p) {
                paths.push({ lat: p.lat(), lng: p.lng() });
                bounds.push({ lat: p.lat(), lng: p.lng() });
              });
              polygon = {
                id : 1,
                path : decodePath,
                // info : {
                //   name : point.location,
                //   description : point.detail,
                //   logisticsPoint : point.category ,
                // },
                isGroup : false,
                editable : false
              };
              polygonList.push(polygon);
          // this.props.MAP_SELECT_SUGGESSION(1)
          this.props.mapSetSuggestionPolylines(polygonList)
          this.props.SET_MARKER_ORIGIN_DESC(
            { 
              marker_origin : {
                    lat : paths[0].lat , 
                    lng : paths[0].lng
                  },
              marker_desc : {
                    lat : paths[paths.length-1].lat , 
                    lng : paths[paths.length-1].lng
                  }
            }
          )
         
          this.props.MAP_SELECT_SUGGESSION(1)
          this.props.mapSetBounds(bounds)

        }

        
      // -------- Set polyline --------

    } catch (e) {

    }

  };
  render() {
    return (
      <div style={{ width: "100%", height: 'calc(100vh - 500px)'  }}>
        <div
          className="ag-theme-balham"
          style={{
            height: 'calc(100vh - 500px)' ,
            width: "100%",
            marginTop: "10px"
          }}
        >
          <AgGridReact
            enableSorting={true}
            singleClickEdit={true}
            suppressMovableColumns={true}
            enableColResize={true}
            columnDefs={this.state.columnDefs}
            rowData={this.renderRow()}
            rowSelection="single"
            onRowClicked={this.handleSelectionChanged}
            onSelectionChanged={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady.bind(this)}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function(state, ownProps) {
  return {
    isLoading: state.api.fetching,
    logistic_point: state.map.logistic_point,
    textSearchLocation: state.search.search_location,
    marker_selecting: state.map.marker_selecting,
    hazard_seleciton_list : state.map.hazard_seleciton_list,
  };
};
const mapActionsToProps = {
  getHazardSelected: apiGetCallRequest,
  setLogisticPoint: mapSetLogisticPoint,
  onSelectedMarker: mapSelectedMarker,
  SELECT_MARKER: mapSelectedMarker,
  SET_MARKER_ORIGIN_DESC: SET_MARKER_ORIGIN_DESC,
  MAP_SELECT_SUGGESSION,
  mapSetSuggestionPolylines,
  mapSetBounds,
  
  MAP_LOG:MAP_LOG,
  SET_HAZARD_PHOTO,
  ADD_HAZARD_VDO
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapActionsToProps
    //mapDispatchToProps
  ),
  withStyles(styles)
)(HazardSelected);
