import React, { Component } from "react";
import { render } from "react-dom";
import {
  Circle,
  InfoWindow
} from "react-google-maps";
import { connect } from "react-redux";
import {
  mapSelectedCircle,
  mapChangeCircle
} from "../../reducers/map";

class ImCircle extends Component {
  constructor(props) {
    super(props);
  }

  selectInfo = arg => {
    this.props.CIRCLE_SELECTING(this.props.id);


  };

  onDragEnd = e => {
    this.props.position.lat = e.latLng.lat();
    this.props.position.lng = e.latLng.lng();
    this.props.MARKER_MOVE(this.props);
  };

  toggleEdit = e => {
    this.props.CIRCLE_TOGGLE_EDIT(this.props.id);

  };

  getCenter = e => {

  };

  onCenterChanged = () => {

      const center = this.circle.getCenter();
      const radius = this.circle.getRadius();
      let obj = { ...this.props, radius: radius ,center : {lat: center.lat() , lng : center.lng()}  };
      this.props.CIRCLE_CHANGE(obj);
  }

  render() {
    const id = this.props.id;
    let selected = false;



    if (this.props.circle_selecting === id) {
      selected = true;
    }

    return (
      <Circle
        key={this.props.id}
        id={this.props.id}
        center={this.props.center}
        editable={this.props.editable}
        color="blue"
        radius={this.props.radius}
        //onClick={this.toggleEdit}
        onClick={this.selectInfo}
        onCenterChanged={this.onCenterChanged}
        onRadiusChanged={this.onCenterChanged}
        ref={input => (this.circle = input)}
      >
        {selected ? (
          <InfoWindow>
            <div>
              {this.props.info.logisticsPoint ? 'Logistic Point : '+this.props.info.logisticsPoint : ''}
              <br />
              Name: <br />
              {this.props.info.name}
              Description: <br />
              {this.props.info.description}
              <br />
              {this.props.isGroup}
            </div>
          </InfoWindow>
        ) : null}
      </Circle>
    );
  }
}

const mapStateToProps = state => {
  return {
    circle_selecting: state.map.circle_selecting
  };
};

const mapActionsToProps = {
  CIRCLE_SELECTING : mapSelectedCircle ,
  CIRCLE_CHANGE : mapChangeCircle ,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(ImCircle);
