


import { takeLatest,select, call, put,takeEvery } from 'redux-saga/effects';
import axios from "axios";
import { 
    getManagement,
    getOperation,
    getFrame,
    getRecovery,
} from "../reducers/dashboard/api";
import { 
    dashboardManagement,
    dashboardOperation,
    dashboardFrame,
    dashboardPlant,
    dashboardYard,
    dashboardRoute,
    dashboardRecovery,
} from "../reducers/dashboard";

import {
    forceLogout
  } from '../reducers/auth';

import { SET_SNACKBAR } from "../reducers/ui";

import Configs from "../config/config"
const env = process.env.NODE_ENV;
// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(getManagement, dashboardWorkerSaga);
  yield takeLatest(getOperation, dashboardWorkerSaga);
  yield takeLatest(getFrame, dashboardWorkerSaga);
  yield takeLatest(getRecovery, dashboardWorkerSaga);
}

function paramsUrl(data) {
    return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
}

function fetchDataGet(params) {  

    //http://mapssh.leafte.ch/managementTest
    let url = params.payload.url+'?'+paramsUrl(params.payload.filter);
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache': "no-cache",
        'Authorization': Configs[env].AUTHORIZATION,
        'token':localStorage.getItem('token'),
    };

    return axios
    .get(url , { headers: headers , timeout: 60000})
    .then(resp => {
        return resp
    })
    .catch(error => {      
        return { catchError : error};
    });
}

function* dashboardWorkerSaga(action) {
    try {
        const resp = yield call(fetchDataGet, action);
        const response = yield checkError(resp,action)

        if(response !== false) {
            if(getManagement==action.type){
                
                yield put({ type: dashboardPlant, payload:response.data.filteredBy.plant});
                yield put({ type: dashboardYard, payload:response.data.filteredBy.filter_yard});
                yield put({ type: dashboardManagement, payload:response.data.result});
            } else if(getOperation==action.type){
                yield put({ type: dashboardPlant, payload:response.data.filter.plant});
                yield put({ type: dashboardYard, payload:response.data.filter.yard});
                yield put({ type: dashboardOperation, payload:response.data.result});
            } else if(getFrame==action.type){
                yield put({ type: dashboardPlant, payload:response.data.filter.plant});
                yield put({ type: dashboardRoute, payload:response.data.filter.route});
                yield put({ type: dashboardFrame, payload:response.data.result});
            } else if(getRecovery==action.type){
                yield put({ type: dashboardRecovery, payload:response.data.result});
            } 
        }
        
    } catch (error) {
        yield put({ 
            type: SET_SNACKBAR, 
            payload: {
                snackbarOpen: true,
                snackbarVariant: "error",
                snackbarMessage: error.toString(),
                snackbarDuration: 20000
            }
        });
    }
}

//===============================
function* checkError(response,action) {
    let params = action
    let callback = params.payload.callback;
    let callback_error = params.payload.callback_error;

    if(response.hasOwnProperty("catchError")){
        if(callback_error) callback_error(response.catchError.toString());
        yield put({ 
            type: SET_SNACKBAR, 
            payload: {
                snackbarOpen: true,
                snackbarVariant: "error",
                snackbarMessage: response.catchError.toString(),
                snackbarDuration: 20000
            }
        });
        return false
    } 

    if(parseInt(response.status) < 200 ||  parseInt(response.status) > 200 ){
        if(callback_error) callback_error("HTTP ERROR : status code = " + response.status);
        yield put({ 
            type: SET_SNACKBAR, 
            payload: {
                snackbarOpen: true,
                snackbarVariant: "error",
                snackbarMessage: "HTTP ERROR : status code = " + response.status,
                snackbarDuration: 20000
            }
        });
        return false
    }
    if(response.data.hasOwnProperty("errorList")) {
        if(response.data.errorList.length > 0){
            if(callback_error) callback_error(errorShow)

            let errorShow = ""
            errorShow = response.data.errorList[0].errorMessage
            console.info("errorDetail",response.data.errorList[0].errorDetail)
            
            if(parseInt(response.data.errorList[0].errorCode) === 2101){
                yield put({ 
                  type: SET_SNACKBAR, 
                    payload: {
                        snackbarOpen: true,
                        snackbarVariant: "error",
                        snackbarMessage: errorShow,
                        snackbarDuration: 3000
                    }
                });
                window.location.href = "/operation_dash";
                return false
              }

            if(parseInt(response.data.errorList[0].errorCode) === 2102 ||
                parseInt(response.data.errorList[0].errorCode) === 2103 ){
                console.log("#kai forceLogout")
                yield put({ 
                    type: SET_SNACKBAR, 
                    payload: {
                        snackbarOpen: true,
                        snackbarVariant: "error",
                        snackbarMessage: errorShow,
                        snackbarDuration: 3000,
                        forceLogout: true
                    }
                });
                return false
            }

            yield put({ 
                type: SET_SNACKBAR, 
                payload: {
                    snackbarOpen: true,
                    snackbarVariant: "error",
                    snackbarMessage: errorShow,
                    snackbarDuration: 20000
                }
            });
            return false
        }
    }


    if(response.data.hasOwnProperty("list") || response.data.hasOwnProperty("result")){
        if(callback) callback(response.data);
        // yield put({ 
        //     type: SET_SNACKBAR, 
        //     payload: {
        //         snackbarOpen: true,
        //         snackbarVariant: "success",
        //         snackbarMessage: "Manual reload data success",
        //         snackbarDuration: 2000
        //     }
        // });

        return response
    }
    

    yield put({ 
        type: SET_SNACKBAR, 
        payload: {
            snackbarOpen: true,
            snackbarVariant: "error",
            snackbarMessage: "API error",
            snackbarDuration: 20000
        }
    });

    return false
}
