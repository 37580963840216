import React ,{Component}from 'react';
import {
  Legend,
  PieChart,
  Pie,
  Cell,
  ReferenceArea,
  Tooltip,
  Sector,
  ResponsiveContainer,
  Surface, Symbols,
} from 'recharts';
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";

const pieData = [];
for(let i=0 ; i<20 ; i++){
    pieData['action'+i] = 1;
}
const COLORS = [];

           
const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  legend : {
  }
});
class ETADelayChart extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        activeIndex: 0,
        pieData : null,
        opacity: {
            uv: 1,
            pv: 1,
        },
      }
    }
    componentDidMount() {
        this.addColor();
        this.addColor();
    }
    onPieEnter = (data, index) =>{
        this.setState({
        activeIndex: index,
        });
    }
    addColor = () =>{
        const colors = [
            '#2196F3', '#4CAF50', '#E91E63','#FFD600','#AB47BC','#00BCD4','#D4E157',
            '#8D6E63','#651FFF','#009688','#EF9A9A','#607D8B','#64DD17','#EF5350',
            '#5C6BC0', '#FF8F00','#90CAF9','#CE93D8','#827717','#E65100'
        ];
        for(let c=0;c<colors.length;c++){
            COLORS.push(colors[c]);
        }
    }
    renderData(){
        let data = [];
        if(this.props.dashboard_management){
            // console.log(this.props.dashboard_management);
            let pieData = this.props.dashboard_management.pieChart;
            for (const key in pieData) {    
                data.push({name : key , value : pieData[key] });
            }
        }
        return data;
    }

   renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
       
        const RADIAN = Math.PI / 180;    
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy  + radius * Math.sin(-midAngle * RADIAN);

        

        let data = [];
        if(this.props.dashboard_management){
            let pieData = this.props.dashboard_management.pieChart;
            for (const key in pieData) {    
                data.push({name : key , value : pieData[key] });
            }
        }
        if(data[index]){

            //let txtValue = this.wrapText(data[index].name+"("+data[index].value+")",10);
            let txtValue = data[index].value;
            return (
                <text scaleToFit='true' width={2} style={{fontSize: '16px'}} x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {txtValue}
                    {/* sss saasas {`${(percent * 100).toFixed(0)}%`} */}
                </text>
            );
        } else {
            return null
        }
    }
    renderLegend = (props) => {
        const { payload } = props;
        console.log('#renderLegend payload',payload);
        // sort by value
        payload.sort(function (a, b) {
            return b.payload.value - a.payload.value;
        });
        return (
            <div className="customized-legend">
            {
              payload.map((entry, index) => {
                const { dataKey, color } = entry
                let style = { backgroundColor: color , color: '#fff'};
                if(index<3){
                    return (
                        <div className="legend-item">
                        <Surface width={10} height={10} viewBox="0 0 10 10" >
                            <Symbols cx={5} cy={5} type="circle" size={50} fill={color}  />
                        </Surface>
                        <span> {entry.payload.name} : {entry.payload.value}</span>
                        </div>
                    );
                } else {
                    return null
                }
              })
            }
          </div>
        );
      }
    wrapText = (str, limit) => {
        const words = str.split(" ");
        let aux = []
        let concat = []
    
        for (let i = 0; i < words.length; i++) {
          concat.push(words[i])
          let join = concat.join(' ')
          if (join.length > limit) {
            aux.push(join)
            concat = []
          }
        }
    
        if (concat.length) {
          aux.push(concat.join(' ').trim())
        }
    
        return aux
    }
    renderToolTip = ({ active, payload, label }) =>{
        console.log('#renderToolTip payload',payload);
        console.log('#renderToolTip label',label);
        if(payload[0]){
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${payload[0].name} ( ${payload[0].value} )`}</p>
                </div>
            );
        } 
        return '';
    }
    renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
            fill, payload, percent, value } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
        
        return (
            <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={payload.color}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={payload.color}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={payload.color} fill="none"/>
            <circle cx={ex} cy={ey} r={2} fill={payload.color} stroke="none"/>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name}`} : {`${payload.value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {` ( ${(percent * 100).toFixed(2)}%)`}
            </text>
            </g>
        );
    }
    handleMouseEnter = (o) => {
        const { dataKey } = o;
        const { opacity } = this.state;
        console.log('#handleMouseEnter dataKey',dataKey);
        this.setState({
          opacity: { ...opacity, [dataKey]: 0.5 },
        });
    }
    
    handleMouseLeave = (o) => {
        const { dataKey } = o;
        const { opacity } = this.state;
        console.log('#handleMouseEnter dataKey',dataKey);
        this.setState({
          opacity: { ...opacity, [dataKey]: 1 },
        });
    }    
    render() {
        const {classes} = this.props;
        return (
            <ResponsiveContainer width={'100%'} height={220}>
                <PieChart >
                    <Pie tick={{fontSize: 10}}
                        // activeIndex={this.state.activeIndex}
                        // activeShape={this.renderActiveShape}
                        labelLine={false}
                        label={this.renderCustomizedLabel} 
                        data={this.renderData()} 
                        innerRadius={2}
                        outerRadius={70} 
                        fill="#8884d8"
                        isAnimationActive={false}
                        // fill="#8884d8"
                        // onMouseEnter={this.onPieEnter}
                    >
                    {
                            this.renderData().map((entry, index) => <Cell fill={COLORS[index % COLORS.length]}/>)
                        }
                    </Pie>
                    <Tooltip content={this.renderToolTip} />
                    {/*<Legend onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} />*/}
                    <Legend iconSize={10} content={this.renderLegend}  className={classes.legend}  layout="vertical" verticalAlign="bottom"  textAlign="left"  />
                </PieChart>
            </ResponsiveContainer>
        );
    }
};
const mapStateToProps = function(state, ownProps) {
  return {
    dashboard_management : state.dashboard.dashboard_management,
  };
};
const mapActionsToProps = {
};

export default compose(
    connect(
      mapStateToProps,
      mapActionsToProps
      //mapDispatchToProps
    ),
    withStyles(styles)
  )(ETADelayChart);
  