import React, { Component } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
// import {socketio} from "./libs/socketio";

// import SnackbarMe from '@material-ui/core/Snackbar';
import SnackbarMe from './Components/Ui/SnackbarMe';
import UserAuthen from './Components/User/UserAuthen';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'

import moment from 'moment';
// import io from "socket.io-client"

import {
  Dashboard,
  PieChart,
  ViewList,
  Directions,
  Place,
  EditLocation,
  Warning,
  Person,
  Settings,
  Ballot,
  NewReleases,
  Build,
  History,
  Map
} from '@material-ui/icons';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import MenuListItem from './Layout/MenuListItem';
import ContentRoutes from './routes';
import List from '@material-ui/core/List';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from 'react-router-dom';
//Component
import NewPasswordDialog from "./Components/Ui/NewPasswordDialog";
import Alert from './Components/Ui/AlertDialogSlide';
//Api
import {
  isAuthenticated,
  authLoginRequest,
  authLoginResponse,
  authLoginError,
  getAuthResponse,
  authLogoutRequest
} from './reducers/auth';
import {uiAlertOpen, SET_SNACKBAR} from './reducers/ui';
import { setActiveMenuListItem,uiSwitchForm } from './reducers/ui';
import { selectReset } from './reducers/ui/selectList';
import { searchReset,searchOperation } from './reducers/search';
import Configs from "./config/config";
const env = process.env.NODE_ENV;


const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    height: 'calc(100vh - 0px)'
  },

  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    //padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works

    [theme.breakpoints.up('xs')]: {
      marginTop: '2em'
    }
  },
  overlay : {
    width: '100%',
    height : '100%',
    display : "contents",
    backgroundColor : "#55555555",
    zIndex : 1000000000000,
  },
  toolbar: theme.mixins.toolbar,
  center: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginTop: '-50px',
    marginLeft: '-50px'
  }
});

let endpoint = "http://mapssh.leafte.ch:8000";
let socket
class App extends Component {

  constructor() {
    super();
    
    let privilege = JSON.parse(localStorage.getItem('privilege'));
    let isMaster = false;
    let isDashboard = false;
    if(privilege && !privilege.locationMaster.visible
      && !privilege.routeMaster.visible
      && !privilege.inputHazard.visible
      && !privilege.impactHazard.visible ){
        isMaster= true;
    }
    if(privilege && !privilege.managementDash.visible
      && !privilege.operationDash.visible
      && !privilege.sequenceDash.visible ){
        isDashboard= true;
    }
    console.log('privilege',privilege);
    this.state = {
      snackbarOpen: false,
      snackbarVariant: "success",
      snackbarMessage: "Success",
      snackbarDuration: 5000,
      newlogin: null, 
      response: false,
      isLogout : false,
      endpoint: "http://mapssh.leafte.ch:8000",
      current_id : null,
      dashboardListItems : {
        itemName: 'Dashboard',
        itemIcon: Dashboard,
        itemLink: null,
        disabled : isDashboard,
        nestedItems: [
          {
            itemName: 'Management Dashboard',
            itemIcon: PieChart,
            disabled : privilege && privilege.hasOwnProperty("managementDash") ? !privilege.managementDash.visible : false,
            itemLink: '/management_dash'
          },
          {
            itemName: 'Operation Dashboard',
            itemIcon: ViewList,
            disabled : privilege && privilege.hasOwnProperty("operationDash") ? !privilege.operationDash.visible : false,
            itemLink: '/operation_dash'
          },
          {
            itemName: 'Sequence Dashboard',
            itemIcon: ViewList,
            disabled : privilege && privilege.hasOwnProperty("sequenceDash") ? !privilege.sequenceDash.visible : false,
            itemLink: '/frame_dash'
          },
          // {
          //   itemName: 'Route Suggestion',
          //   itemIcon: Directions,
          //   disabled : privilege && privilege.hasOwnProperty("routeSuggestion") ? !privilege.routeSuggestion.visible : false,
          //   itemLink: '/route_suggestion'
          // }
        ]
      },
      maintenanceListItems : {
        itemName: 'Maintenance',
        itemIcon: EditLocation,
        itemLink: null,
        disabled : isMaster,
        nestedItems: [
          {
            itemName: 'Location Master',
            itemIcon: Place,
            disabled : privilege && privilege.hasOwnProperty("locationMaster") ? !privilege.locationMaster.visible : false,
            itemLink: '/location_master'
          },
          {
            itemName: 'Route Master',
            itemIcon: Directions,
            disabled : privilege && privilege.hasOwnProperty("routeMaster") ? !privilege.routeMaster.visible : false,
            itemLink: '/route_master'
          },
          {
            itemName: 'Hazard Points',
            itemIcon: Warning,
            disabled : privilege && privilege.hasOwnProperty("inputHazard") ? !privilege.inputHazard.visible : false,
            itemLink: '/hazard_point'
          },
          {
            itemName: 'Impact Hazard',
            itemIcon: NewReleases,
            disabled : privilege && privilege.hasOwnProperty("impactHazard") ? !privilege.impactHazard.visible : false,
            itemLink: '/impact_hazard'
          }
        ]
      },
      distanceMatrixMasterListItems: {
        itemName: 'DistanceMatrixMaster',
        itemIcon: Map,
        itemLink: '/distance_matrix_master',
        disabled : isMaster,
        nestedItems: undefined
      },
      configurationListItems : {
        itemName: 'Configuration',
        itemIcon: Settings,
        itemLink: null,
        disabled : privilege && privilege.hasOwnProperty("configuration") ? !privilege.configuration.visible : false,
        nestedItems: [
          {
            itemName: 'Users',
            itemIcon: Person,
            disabled : privilege && privilege.hasOwnProperty("configuration") ? !privilege.configuration.visible : false,
            itemLink: '/users'
          },
          {
            itemName: 'System Configuration',
            itemIcon: Build,
            itemLink: '/settings',
            disabled : privilege && privilege.hasOwnProperty("configuration") ? !privilege.configuration.visible : false,
          }
          ,
          {
            itemName: 'System Master',
            itemIcon: Ballot,
            itemLink: '/system_master',
            disabled : privilege && privilege.hasOwnProperty("configuration") ? !privilege.configuration.visible : false,
          }
          ,
          {
            itemName: 'Event Logs',
            itemIcon: History,
            itemLink: '/event_logs',
            disabled : privilege && privilege.hasOwnProperty("configuration") ? !privilege.configuration.visible : false,
          }
        ]
      }
    };

  }
  componentWillReceiveProps(nextProps){
    // if(this.state.newlogin !== null) alert(this.state.newlogin)

    if(nextProps.location !== this.props.location){
      this.setHeader(nextProps);
    }
    if(nextProps.someValue !== this.props.someValue){
      //Perform some operation
      // this.setState({someState: someValue });
      // this.classMethod();
    }
  }
  setHeader(nextProps){
      // console.log('#setHeader...');
      let menuName = "";
      for(let item of this.state.dashboardListItems.nestedItems){
        if(nextProps.location.pathname==item.itemLink) 
          menuName = item.itemName;
      }
      for(let item of this.state.maintenanceListItems.nestedItems){
        if(nextProps.location.pathname==item.itemLink) 
          menuName = item.itemName;
      }
      for(let item of this.state.configurationListItems.nestedItems){
        if(nextProps.location.pathname==item.itemLink) 
          menuName = item.itemName;
      }
      if (
        nextProps.location.pathname ==
        this.state.distanceMatrixMasterListItems.itemLink
      )
        menuName = this.state.distanceMatrixMasterListItems.itemName;
      this.props.selectReset(true);
      this.props.searchReset(true);
      this.props.uiSwitchForm({ page : "list"});
      this.props.dispatch(
        this.props.setActiveMenuListItem({
          ...this.props.activeMenuListItem,
          menuName: menuName
        })
      );
  }

  componentWillMount(){


    // !this.props.privilege.managementDash.visible
    
  }
  componentWillUnmount() {

  }

  updateDimensions = () =>{
    var w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;


        // this.props.SET_BODY_SIZE({
        //   width: width,
        //   height: height
        // });
        // this.setState({width: width, height: height});
        // // if you are using ES2015 I'm pretty sure you can do this: this.setState({width, height});
    }
  componentDidMount() {
    this.setState({ current_id: parseInt(localStorage.getItem('id')) });

    this.updateDimensions()
    // window.addEventListener("resize", this.updateDimensions);
    //window.addEventListener("visibilitychange", this.updateDimensions);
    

    this.handleNewLogin();

    this.props.setActiveMenuStart({
      ...this.props.activeMenuListItem,
      subLink: this.props.location.pathname
    });
    this.setHeader(this.props);
    //this.props.dispatch(isAuthenticated());
    // document.body.style.zoom = .9
    
    // this.props.SET_SNACKBAR({ 
    //   snackbarOpen: true,
    //   snackbarVariant: "error",
    //   snackbarMessage: "test",
    //   snackbarDuration: 5000
    // })
  }
  componentDidUpdate(prevProps, prevState) {
    if(prevState.newlogin !== this.state.newlogin){

    }

    
  } 
  
  handleNewLogin = () => {
    this.props.SET_SNACKBAR({ 
      snackbarOpen: false,
      snackbarVariant: "success",
      snackbarMessage: "test",
      snackbarDuration: 1
    })
    this.props.searchOperation({...this.props.search_operation,routeType:'all' });

  }
  handleLogout = () => {

    this.props.authLogoutRequest({
      callback : this.setLogout
    });
    this.props.setActiveMenuStart({
      ...this.props.activeMenuListItem,
      subLink: this.props.location.pathname
    });
  };
  setLogout = () => {
    this.setState({ auth: false });
    window.localStorage.clear();
    //this.props.searchOperation({...this.props.search_operation,routeType:'' });
    this.props.reset();
    // this.setState({isLogout:false});
  }

  renderMenuItem = (links) =>{

  }
  render() {
    
    let user_authen = JSON.parse(localStorage.getItem('user_authen'));
    // console.log("Timer : App.js (now)",moment().format('YYYY/MM/DD HH:mm:ss'))

    if(user_authen) {
        // console.log("Timer : App.js (if true)",moment().format('YYYY/MM/DD HH:mm:ss'))
        let stamp_datetime = moment(user_authen.stamp_datetime,'YYYY/MM/DD HH:mm:ss');
        // let stamp_datetime = moment("2019/01/24 02:08:38",'YYYY/MM/DD HH:mm:ss');
        var diff = moment.duration(moment().diff(stamp_datetime));
        var d = moment.duration(diff);
        var time_diffs = parseInt(d.format("ss",{useGrouping: false}));

        // console.log("Timer : App.js (now) - ",moment().format('YYYY/MM/DD HH:mm:ss'))
        // console.log("Timer : App.js (current_user_authen) = ",user_authen.stamp_datetime) 
        // console.log("Timer : App.js (time_diffs)",time_diffs)

        // console.log("Timer : App.js (time_diffs)",time_diffs)
        if(time_diffs > 60 || time_diffs < 1){

          // console.log("Timer : App.js (>60 <1)",time_diffs)
          this.setState({ auth: false });
          window.localStorage.clear();
        }
    } else {
      // console.log("Timer : App.js (if else)",moment().format('YYYY/MM/DD HH:mm:ss'))
      this.setState({ auth: false });
      window.localStorage.clear();
    }

    const { classes, isLoading, auth, dispatch} = this.props;

    // let isAuth = localStorage.getItem('auth');
    let authResult = localStorage.getItem('authResult');
    if (!auth) {
      return <CircularProgress className={classes.center} size={'100px'} />;
      
    } else if (auth && !authResult) { // ) || this.props.force_logout

      return <Redirect to='/login' />;
    } else {
      return (
        <div className={classes.root}>
          <Header />
          <Sidebar>
            <List>
              <MenuListItem 
                name='Dashboard' listItems={this.state.dashboardListItems} />
              <MenuListItem
                name='Maintenance'
                listItems={this.state.maintenanceListItems}
              />
              <MenuListItem
                name='Distance Matrix Master'
                listItems={this.state.distanceMatrixMasterListItems}
              />
              <MenuListItem
                name='Configuration'
                listItems={this.state.configurationListItems}
              />
            </List>
          </Sidebar>
          <main className={classes.content}>
            
            {<LoadingOverlay
              active={this.props.loadingDialog.active}
              className={classes.overlay}
              spinner={<BounceLoader />}
              text={this.props.loadingDialog.msg}
              >
            </LoadingOverlay>}
            <Alert/>
            <NewPasswordDialog title="CHANGE PASSWORD"/>
            <ContentRoutes dispatch={dispatch}/>
          </main>

          <SnackbarMe/>
          <UserAuthen/>
          
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  isLoading: state.auth.fetching,
  force_logout: state.auth.force_logout,
  // bodySize: state.ui.bodySize,
  activeMenuListItem : state.ui.activeMenuListItem,
  loadingDialog : state.ui.loadingDialog,
  // snackbar: state.ui.snackbar,
  auth: getAuthResponse(state),
  // privilege: state.auth.privilege
});

const mapDispatchToProps = dispatch => {
  return {
    selectReset: payload => dispatch(selectReset(payload)),
    searchReset: payload => dispatch(searchReset(payload)),
    uiSwitchForm: payload => dispatch(uiSwitchForm(payload)),
    userLogin: auth => dispatch(authLoginRequest(auth)),
    setActiveMenuStart: location => dispatch(setActiveMenuListItem(location)),
    SET_SNACKBAR: payload => dispatch(SET_SNACKBAR(payload)),
    setActiveMenuListItem :payload => dispatch(setActiveMenuListItem(payload)),
    searchOperation:payload => dispatch(searchOperation(payload)),
    authLogoutRequest,
    uiAlertOpen,
    dispatch
  };
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
);

export default enhance(App);
