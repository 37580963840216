import React, { Component } from "react";
import moment from "moment";
import {
  propTypes,
  reduxForm,
  Form,
  Field,
  formValueSelector
} from "redux-form";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import compose from "recompose/compose";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import FormControl from "@material-ui/core/FormControl";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
//import momentLocaliser from 'react-widgets/lib/localizers/moment'
import "react-widgets/dist/css/react-widgets.css";
//Component
import Loading from "../../Ui/Loading";
//Lib
import { required, number,email,maxLength200 } from "../../../libs/validation";
//Reducer
import { apiCallRequest } from "../../../reducers/api";
import { getOperation } from "../../../reducers/dashboard/api";
import { formOperation } from "../../../reducers/ui/mapForm";
import { dashboardOpenActionDialog } from "../../../reducers/dashboard";
import { STATUS_LOADING } from "../../../reducers/ui/";

//Config
import Configs from "../../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    maxWidth : '200px'
  },
  formControl: {
    margin: theme.spacing.unit
  }
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);
const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) =>
  <TextField
    hintText={label}
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    {...custom}
  />
function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ActionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open : true,
      actionId : "",
      isRadio : false,
      countTxt : '0/200',
      isDisable : false,
    };
  }
  componentWillReceiveProps(nextProps) {

    
    if (nextProps.operationForm && this.props.operationForm!=nextProps.operationForm) {
      this.setState({ actionId : nextProps.operationForm.action.value },this.checkRadio);
      this.renderActionCheckbox();
      this.props.initialize({...nextProps.operationForm,actionId : nextProps.operationForm ? nextProps.operationForm.action.value : ''});
    }
  }
  renderActionItem() {
    
    if (this.props.operationForm && this.props.operationForm.action) {
      

      var menuItem = this.props.operationForm.action.radio;
      return menuItem.map(function(data, index) {
        return (
          <MenuItem key={index} selected={data.checked=1 ? true : false } value={data.text} detail={data.text}>
            {data.text}
          </MenuItem>
        );
      });
    } else return null;
  }
  handleChangeCheckbox = (event) =>{
    if(this.state.actionId==event.target.value)
      this.setState({actionId : '',isRadio:false});
    else 
      this.setState({actionId : event.target.value ,isRadio:false});

  }
  checkRadio = () =>{  
    let isRadio = true;     
    let self = this;

    if (this.props.operationForm && this.props.operationForm.action) {


      var menuItem = this.props.operationForm.action.radio;
      menuItem.forEach(function(data) {
        if(data.text==self.state.actionId)
          isRadio = false;
      });
      if(isRadio) {
        this.props.change('detail',self.state.actionId);
        this.setState({actionId : ''});
      }
      this.setState({isRadio : isRadio});
    }
  }
  renderActionCheckbox = () =>{
    let self = this;
    if (this.props.operationForm && this.props.operationForm.action) {
      

      var menuItem = this.props.operationForm.action.radio;
      return menuItem.map(function(data, index) {
        return (
          <FormControlLabel
              key={data.text}
              control={
                <Checkbox
                  checked={data.text==self.state.actionId}
                  onChange={self.handleChangeCheckbox}
                  name={data.text}
                  value={data.text}
                />
              }
              label={data.text}
          />
        );
      });
    } else return null;
  }
  handleSave = (form) =>{
    //debugger
    //debugger
    this.props.STATUS_LOADING(true);
    this.setState({isDisable:true});
    this.props.dashboardOpenActionDialog(false);
   
    let filter = {};
    filter.jobId = this.props.operationForm.jobDashboardId.value;
    //let ETA = this.props.operationForm.ETA.value;
    //let partUsageTime = this.props.operationForm.partUsageTime.value;
    if(this.props.operationForm.colDef.field=="action.value"){
      if(this.state.actionId!='')
        filter.action = this.state.actionId;
      else 
        filter.action = form.detail ? form.detail : '';
    } 
    filter.version  = this.props.operationForm.version+1;
    if(this.props.operationForm.planAccessSeq && this.props.operationForm.planAccessSeq!=""){
        filter.planAccessSeq = this.props.operationForm.planAccessSeq;
    }
    if(this.props.operationForm.shiftSeq.value && this.props.operationForm.shiftSeq.value!=""){
        filter.shiftSeq = this.props.operationForm.shiftSeq.value.toString();
    }
    if (filter.jobId) {
      this.props.API_REQUEST({
        url: Configs[env].BACKEND_HOST + "/operationDashboard",
        objData: filter,
        method : "POST",
        callback: this.handleCancel,
        callback_error: this.handleError
      });
    }
  }
  handleError = () =>{
    this.setState({isDisable:false});
    this.props.dashboardOpenActionDialog(true);
    this.props.STATUS_LOADING(false);
  }
  handleChange = event => {
    if(event.target.name=="actionId"){
      this.setState({ [event.target.name]: event.target.value });
    } else if(event.target.name=="detail"){
      this.setState({ countTxt: event.target.value.length+'/200' });
    }
  };
  handleCancel = () => {
    let filter = this.props.search_operation;
      filter.isPriority  = filter.isPriority ? 1 : 0 ;
      filter.isHistory   = filter.isHistory ? 1 : 0 ;
      if(filter.etaType=='all') delete(filter.etaType);
      this.props.getOperation({
        url: Configs[env].BACKEND_HOST + "/operationDashboard",
        filter: filter
      });
    this.setState({actionId : '',isRadio:false,isDisable:false});
    this.props.reset();
    this.props.formOperation(null);
    this.props.dashboardOpenActionDialog(false);
    this.props.STATUS_LOADING(false);
  }
  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div style={{ padding: "10px" }}>
        {this.props.isLoading && <Loading />}
        
          <Dialog
            open={this.props.dashboard_action_dialog ? this.props.dashboard_action_dialog : false}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleCancel}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle id="alert-dialog-slide-title">
            {this.props.operationForm && this.props.operationForm.colDef ? this.props.operationForm.colDef.headerName : ''}
            </DialogTitle>
            <DialogContent>
            <Form 
                  ////style={{ overflowY:"scroll"}}
                  autoComplete={"off"}
                  autoCorrect={"off"}
                  spellCheck={"off"}
                  onSubmit={handleSubmit(this.handleSave.bind(this))}
              >
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                      <Field
                          name="detail"
                          fullWidth
                          multiline={true}
                          rows={2}
                          rowsMax={2}
                          label="Key In"
                          disabled={this.state.actionId!='' && !this.state.isRadio ? true : false}
                          component={renderInput}
                          InputLabelProps={{
                            shrink: true,
                            id: "detail-field"
                          }}
                          onChange={this.handleChange}
                          helperText={this.state.countTxt}
                          margin="dense"
                          className={styles.textField}
                          validate={[maxLength200]}
                        >
                      </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth className={styles.formControl}>
                      {this.renderActionCheckbox()}
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <DialogActions>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        aria-label="Save"
                        disabled={this.state.isDisable}
                        className={styles.button}
                        type="submit">
                        SAVE
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        aria-label="Close"
                        className={classNames(styles.button, styles.cssGreen)}
                        onClick={this.handleCancel}
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
            
          </Dialog>
        
      </div>
    );
  }
}
const mapStateToProps = function(state, ownProps) {
  return {
    dashboard_action_dialog : state.dashboard.dashboard_action_dialog,
    operationForm: state.mapForm.operationForm,
    search_operation : state.search.search_operation
    
  };
};
const mapActionsToProps = {
  API_REQUEST: apiCallRequest,
  dashboardOpenActionDialog,
  STATUS_LOADING,
  getOperation,
  formOperation
};

const enhance = compose(
  reduxForm({
    form: "operationActionForm",
  }),
  connect(
    mapStateToProps,
    mapActionsToProps
  ),
  withStyles(styles)
);
export default enhance(ActionDialog);