import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BaseMap from "../Map/BaseMap";
import MenuTabs from "./MenuTabs";
import uuidv4  from 'uuid/v4';
import SplitPane from 'react-split-pane';
import { Pane } from 'react-sortable-pane';

//Reducer
import { setActiveMenuListItem } from '../../reducers/ui';
import { setSidebarVisibility,uiSwitchForm } from "../../reducers/ui";
import { searchHazardRoute,searchReset } from "../../reducers/search";
import { mapSetImpactHazard, mapSetRunSeq } from '../../reducers/map';

const env = process.env.NODE_ENV;
const MIN_HEIGHT = 25;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  gridForm : {
    padding : '0px'
  },
  subheader: {
    width: '100%',
  },
});

class ImpactHazard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    };
  }
  componentWillMount() {
    //this.props.searchReset(true);
    this.props.mapSetImpactHazard([]);
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;
    const { routeCode } = this.props.match.params;
    const { runSeq } = this.props.match.params;
    if(routeCode && mapSetRunSeq && this.props.search_hazard_route){
      this.props.searchHazardRoute({
        ...this.props.search_hazard_route,
        routeCode :routeCode,
        runSeq :runSeq,
      });
    }

    //this.props.selectJobDashboardId(jobDashboardId);
    //this.props.setActiveMenuListItem({...this.props.activeMenuListItem,subLink:'/route_suggestion'});
    setSidebarVisibility(false);

  }
  autosizeHeaders(event) {
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        "#locationMap .ag-header-cell-label"
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach(cell => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }
  
  methodFromParent(cell) {

  }
  render() {
    const { match } = this.props
    const { routeCode } = this.props.match.params;
    const { runSeq } = this.props.match.params;
    //console.log('#render routeCode',routeCode);
    //console.log('#render runSeq',runSeq);
    return (
      // <div style={{ width: "100%", height:'calc(100vh - 52px)' }}>
        <div
          id="locationMap"
          style={{
            boxSizing: "border-box",
            height:'calc(100vh - 52px)',
            width: "100%"
          }}
          className="ag-theme-balham"
        >
         
              <SplitPane split="vertical" minSize={50} defaultSize={400}>
                  <Pane size={{width:500}} key={"key_sklanlksadlaz"} >
                    <MenuTabs firstOpen={ routeCode && runSeq ? 1 : 0}/>
                  </Pane>
                  <Pane key={"key_sajkgdjk92b9c"}  >
                    <div style={{ height:'calc(100vh - 52px)' }}>
                    <BaseMap isReset={this.props.isReset}/>
                    </div>
                  </Pane>
              </SplitPane>  

            {/* <GridList 
              cols={12}>
                <GridListTile key={uuidv4()} cols={4} className={styles.gridForm}>
                  <MenuTabs/>
                </GridListTile>
                <GridListTile key={uuidv4()} cols={8}>
                  <BaseMap isReset={this.props.isReset}/>
                </GridListTile>
            </GridList> */}
 
        </div>
      // </div>
    );
  }
}
ImpactHazard.propTypes = {
  match: PropTypes.object,
};

const mapStateToProps = state => ({
  isReset : state.map.isReset,
  formManage : state.ui.formManage,
  search_hazard_route : state.search.search_hazard_route
});

const mapDispatchToProps = dispatch => {
  return {
    setActiveMenuListItem: payload => dispatch(setActiveMenuListItem(payload)),
    setSidebarVisibility: status => dispatch(setSidebarVisibility(status)),
    searchHazardRoute:  payload => dispatch(searchHazardRoute(payload)),
    //searchReset: payload => dispatch(searchReset(payload)),
    mapSetImpactHazard :  payload => dispatch(mapSetImpactHazard(payload)),
    dispatch
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(withRouter(ImpactHazard));