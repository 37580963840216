import React from "react";
import { Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import compose from "recompose/compose";
import Foo from "./Components/Foo";
import ManagementDashboard from "./Components/ManagementDashboard";
import Test from "./Components/Test";
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './config/configureStore'

import Users from "./Components/User";
import Setting from "./Components/Configuration/Setting";
import EventLogs from "./Components/Configuration/EventLogs"
import SystemMaster from "./Components/SystemMaster";
import OperationDashboard from "./Components/OperationDashboard";
import FrameDashboard from "./Components/FrameDashboard";
import LocationMaster from "./Components/LocationMaster";
import HazardPoints from "./Components/HazardPoints";
import ImpactHazard from "./Components/ImpactHazard";
import RouteMaster from "./Components/RouteMaster";
import RouteSuggestion from "./Components/RouteSuggestion";
import Tracking from "./Components/Tracking";
import DistanceMatrixMaster from "./Components/DistanceMatrixMaster";
import uuidv4 from "uuid/v4";
import { setSidebarVisibility,uiSwitchForm } from './reducers/ui';

const ContentRoutes = (props) => {
  const {dispatch} = props;
  return(
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/management_dash" component={() => <ManagementDashboard name="ManagementDashboard" />} />
        <Route
          path="/operation_dash"
          component={() => {
            // Force closing sidebar to have more main content space for Operation view
            dispatch(setSidebarVisibility(false));
            return <OperationDashboard name="OperationDashboard" />;
          }}
        />
        <Route
          path="/frame_dash"
          component={() => {
            // Force closing sidebar to have more main content space for Frame view
            dispatch(setSidebarVisibility(false));
            return <FrameDashboard name="FrameDashboard" />;
          }}
        />
        <Route
          path="/route_suggestion/:jobDashboardId"
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <RouteSuggestion name="Route Suggestion" />;
          }}
        />
        <Route
          path="/tracking/:jobDashboardId"
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <Tracking name="Route Suggestion" />;
          }}
        />
        <Route
          path="/location_master"
          component={() => {
            //dispatch(uiSwitchForm({ page : "list"}));
            dispatch(setSidebarVisibility(false));
            return <LocationMaster name="Location Master" />;
          }}
        />
        <Route
          path="/route_master"
          component={() => {
            //dispatch(uiSwitchForm({ page : "list"}));
            dispatch(setSidebarVisibility(false));
            return <RouteMaster name="Route Master" />;
          }}
        />
        <Route
          path="/hazard_point"
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <HazardPoints name="Hazard Points" />;
          }}
        />
        <Route
          path="/impact_hazard/:routeCode?/:runSeq?"
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <ImpactHazard name="Impact Hazard" />;
          }}
        />
        <Route
          path="/distance_matrix_master"
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <DistanceMatrixMaster name="Distance Matrix Master" />;
          }}
        />
        <Route 
          path="/users" 
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <Users name="Users" />
          }} />
        <Route 
          path="/settings" 
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <Setting name="System Configuration" />
          }} />
        <Route 
          path="/system_master" 
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <SystemMaster name="System Master" />
          }} />
        <Route 
          path="/event_logs" 
          component={() => {
            dispatch(setSidebarVisibility(false));
            return <EventLogs name="EventLogs" />
          }} />
      </Switch>
    </ConnectedRouter>

)}
export default withRouter(ContentRoutes);
