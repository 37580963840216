import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from '@material-ui/core/styles';
//Reducer
import {
  apiCallRequest,
  apiCallSuccess,
  apiCallFail
} from '../../reducers/api';

import {
  SET_MARKER_ORIGIN_DESC,
  mapResetMap,
  SET_ROUTESVIEW,
  MAP_SELECT_CANDIDATE
} from "../../reducers/map";

import { getMasterRoutes } from "../../reducers/map/api";

//Component
import AmountRenderer from "../Ui/Renderer/AmountRenderer";
import ManageBtnRenderer from "./Renderer/ManageBtnRenderer";
//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    margin:0,
    //marginLeft: theme.spacing.unit,
    //marginRight: theme.spacing.unit,
    width: 200,
  },
});
class LogisticRoutes extends Component {
  constructor(props) {
    super(props);
    // var header_template = '<span class="text-danger" style="height:30px;">Some Value </span>';
    this.state = {
      columnDefs: [
        { 
          headerName: "", 
          field: "id" ,
          cellRenderer: "manageBtnRenderer",
          width: 65
        },{ 
          headerName: "AMOUNT", 
          field: "amount" ,
          cellRenderer : "amountRenderer",
          width: 35,
          headerComponentParams: {menuIcon: 'fa-cog'},
          autoHeight: true
        },{ 
          headerName: "NAME", 
          field: "name" ,
          width: 100,
          autoHeight: true,
          suppressSizeToFit: true
        },{ 
          headerName: "ORIGIN", 
          field: "originName" ,
          width: 100,
          autoHeight: true
        },{ 
          headerName: "DESTINATION", 
          field: "destinationName" ,
          width: 100,
          autoHeight: true
        }
      ],
      rowData: null,
      suppressRowClickSelection: true,
      context: { componentParent: this },
      frameworkComponents: {
        manageBtnRenderer: ManageBtnRenderer,
        amountRenderer : AmountRenderer,
      },
      
    };
  }
  componentDidMount() {
  }
  componentWillUnmount() {
    
  }
  componentDidUpdate () {
    this.autoSizeAll();
  }
  //Grid
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  }
  autoSizeAll = () => {
    var allColumnIds = [];
    if(this.gridColumnApi){
      this.gridColumnApi.getAllColumns().forEach(function(column,index) {
        if(index!==0 && index!==1 )allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  }
  renderData = () => {

    if (Array.isArray(this.props.logistic_routes)) {

      return this.props.logistic_routes;
    } else return [];
  }
  handleSelectionChanged = node => {
    var selectedRows = node.api.getSelectedRows();

  };

  handleRowClicked = row => {
    this.props.SET_ROUTESVIEW([])
    this.props.RESET_MAP(true);
    this.props.MAP_SELECT_CANDIDATE(-1);
    // this.props.SELECT_MASTER_OR_CANDIDATE(1)

    var selectedRows = row.api.getSelectedRows();
    

    this.props.SET_MARKER_ORIGIN_DESC(
        { 
          marker_origin : {
                lat : selectedRows[0].originLat , 
                lng : selectedRows[0].originLng
              },
          marker_desc : {
                lat : selectedRows[0].destinationLat , 
                lng : selectedRows[0].destinationLng
              }
        }
    )
    
    if(selectedRows[0].id){
      this.props.GET_MASTER_ROUTE({
        url:Configs[env].BACKEND_HOST + "/routeCond",
        filter: {
          logisticsRouteId: selectedRows[0].id,
        },
        callback : this.getSuccess
      });
    }

  };

  getSuccess = (response) => {
    if(response.hasOwnProperty("list")&& response.list.length > 0){
      this.props.SET_ROUTESVIEW(response.list)
    }
    else {
      this.props.SET_ROUTESVIEW([])
    }
  }
  
  render() {
    return (
      <div style={{ width: "100%", height: "250px" }}>
        <div
          className="ag-theme-balham"
          style={{
            height: "250px",
            width: "100%",
            marginTop: "10px"
          }}>
          <AgGridReact
            enableSorting={true}
            singleClickEdit={true}
            suppressMovableColumns={true}
            enableColResize={true}
            columnDefs={this.state.columnDefs}
            rowData={this.renderData()}
            rowSelection="multiple"
            onGridReady={this.onGridReady}
            onRowClicked={this.handleRowClicked}
            onSelectionChanged={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function(state,ownProps) {
  return {
    isLoading : state.api.fetching,
    logistic_routes: state.map.logistic_routes,
    
  }  
};
const mapActionsToProps =  {
  getRequest : apiCallRequest,
  success : apiCallSuccess,
  RESET_MAP: mapResetMap,
  GET_MASTER_ROUTE : getMasterRoutes,
  SET_MARKER_ORIGIN_DESC : SET_MARKER_ORIGIN_DESC,
  SET_ROUTESVIEW : SET_ROUTESVIEW,
  MAP_SELECT_CANDIDATE : MAP_SELECT_CANDIDATE
};



export default compose(
  connect(
    mapStateToProps,
    mapActionsToProps,
    //mapDispatchToProps
  ),
  withStyles(styles)
)(LogisticRoutes);
