import React,{Component} from 'react';
import { connect } from "react-redux";
import moment from "moment";
import debounce from 'lodash/debounce'
import { propTypes, reduxForm,Form, Field } from 'redux-form';
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from "@material-ui/core/Typography";
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import UpdateIcon from '@material-ui/icons/Update';
import SyncIcon from '@material-ui/icons/Sync';
import ScheduleIcon from '@material-ui/icons/Schedule';
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import ArchiveIcon from '@material-ui/icons/Archive';
import TimerMachine from 'react-timer-machine';
import momentDurationFormatSetup from "moment-duration-format";
import Tooltip from '@material-ui/core/Tooltip';
//Component
import OperationsGrid from './OperationsGrid';
import PlantSelect from './PlantSelect';
import YardSelect from './YardSelect';
import ETAMultiSelect from './ETAMultiSelect';
import RouteSelect from './RouteSelect';
//Reducer
import { getDataOperation } from '../../reducers/dashboard/operation';
import { getOperation,exportOperation } from '../../reducers/dashboard/api';
import { setSidebarVisibility,uiSwitchForm,SET_SNACKBAR,STATUS_LOADING } from "../../reducers/ui";
import { searchOperation,searchOperationTxt } from "../../reducers/search";

import io from "socket.io-client"

//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  card: {
    margin: theme.spacing.unit,
  },
  media: {
    height: 140,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop:  '3px',
    marginBottom : 0,
    padding : 0,
    width: 150
  },
  txtBlack : {
    color : "#000000",
    fontSize : 12,
    justifyContent: 'center', 
    alignItems: 'center',
    textAlign: 'right',
  },
  iconStyle :{
    padding:0,
  },
  buttonReload : {
    textAlign : 'center',
    padding : '8px',
  },
  checkBox : {
    padding : '5px'
  }
});


momentDurationFormatSetup(moment);

let endpoint = "http://mapssh.leafte.ch:8000";
let socket

const WAIT_INTERVAL = 1000;

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      etaSelect:'',
      plantSelect : '',
      yardSelect : '',
      radioValue : 'priority',
      last_updated : '',
      operationUpdate : null,
      blinkColor : 0,
      started : true,
      timeStart : 180,
      fetching : false,
      isGrouped : false,
      isPriority : false,
      isHistory : false,
      manualReload: false,
      isLoading : false,
    };
    this.onFilterTextBoxChanged = debounce(this.onFilterTextBoxChanged, 500);
  }
  componentDidMount() {
    var intervalId = setInterval(this.timer, 1000);
    const { setSidebarVisibility } = this.props;
    // console.log('#componentDidMount search_operation',this.props.search_operation);
    setSidebarVisibility(false);
    let search = this.props.search_operation;
    this.getData(search);
    
    // Configs[env].BACKEND_HOST
    // endpoint
    socket = io.connect(Configs[env].SOCKETIO_URL)

    socket.on("digitalMapEvent", (data) => {
      console.info("Realtime Updated by socket.io msg: ",data)
      if(data === "update") {
        this.setState({ operationUpdate: new Date().toISOString() })
      }
    });
    //this.props.initialize({...this.props.search_operation});
  }
  componentDidUpdate(prevProps, prevState) {
    // console.log('#componentDidUpdate search_operation',this.props.search_operation);
    if(prevState.operationUpdate !== this.state.operationUpdate){
      this.getData(this.props.search_operation);

    }    
  }
  componentWillUnmount() {
    clearInterval(this.state.blinkColor);
    socket.disconnect()
  }

  
  componentWillReceiveProps(nextProps){
    // console.log('#componentWillReceiveProps search_operation',nextProps.search_operation);
    if(nextProps.search_operation!==this.props.search_operation){

      this.getData(nextProps.search_operation);
    }
  }

  handleChangeRadio = (event) => {

    this.setState({ radioValue: event.target.value },this.setSearchOperation);
  }
  handleChangeCheckbox = (event) => {
    console.log('#handleChangeCheckbox event',event.target.value);
    console.log('#handleChangeCheckbox isGrouped',this.state.isGrouped);
    console.log('#handleChangeCheckbox isPriority',this.state.isPriority);
    console.log('#handleChangeCheckbox isHistory',this.state.isHistory);
    let self = this;
    if(event.target.value=='history'){
      if(!this.state.isHistory) {
        this.setState({ 
          isGrouped:false,
          isHistory:true,
          isPriority:false
        },this.setSearchOperation);
      } else {
        this.setState({ 
          isHistory:!this.state.isHistory
        },this.setSearchOperation);
      }
       
    } else if(event.target.value=='priority'){
      if(!this.state.isPriority) {
        this.setState({ 
          isGrouped:false,
          isHistory:false,
          isPriority:true
        },this.setSearchOperation);
      } else {
        this.setState({ 
          isPriority:!this.state.isPriority
        },this.setSearchOperation);
      }
    } else if(event.target.value=='group'){
      if(!this.state.isGrouped) {
        this.setState({ 
          isGrouped:true ,
          isHistory:false,
          isPriority:false
        },this.setSearchOperation);
      } else {
        this.setState({ 
          isGrouped:!this.state.isGrouped
        },this.setSearchOperation);
      }
    }
    //setTimeout(function(){self.setSearchOperation();},1000);
  }
  timer = () => {
    this.setState({blinkColor:this.state.blinkColor!=1 ? 1 : 0})
  }
  manualReload(search_operation){
    this.getData(search_operation);
    this.setState({manualReload : true})
  }
  handleInputChange = e => {
    //console.log('#handleInputChange e',e.target.value);
    this.onFilterTextBoxChanged(e.target.value);
  }
  onFilterTextBoxChanged = (value) => {
    //console.log('#onFilterTextBoxChanged searchTxt',value);
    this.props.searchOperationTxt(value);
    //this.props.dashboardSelected(null);
    //this.props.filterResult({key:'LOCATION_GROUP_LIST',filter:event.target.value});
    //this.state.api.setQuickFilter(document.getElementById('location-search').value);
  }
  setSearchOperation = () => {
    console.log('#setSearchOperation isGrouped',this.state.isGrouped);
    console.log('#setSearchOperation isPriority',this.state.isPriority);
    console.log('#setSearchOperation isHistory',this.state.isHistory);
    this.props.searchOperation({
      ...this.props.search_operation,
      isGrouped  : this.state.isGrouped ? 1 : 0 ,
      isPriority  : this.state.isPriority ? 1 : 0 ,
      isHistory   : this.state.isHistory ? 1 : 0 ,
    });
    //this.getData();
  }
  handleTimerOnComplete = (search_operation) =>{
    this.getData(this.props.search_operation)
  }
  getData = (search) =>{
    this.setState({fetching : true,started : false,isLoading : true })
    this.props.STATUS_LOADING(true);
    let filter = search;
    //if(this.props.search_operation.plant) filter.plant = this.props.search_operation.plant.join(',')
    //if(this.props.search_operation.yard) filter.yard = this.props.search_operation.yard.join(',');
    let isGrouped  = search.isGrouped ? 1 : 0 ;
    let isPriority  = search.isPriority ? 1 : 0 ;
    let isHistory   = search.isHistory ? 1 : 0 ;

    console.log('#getData isGrouped',isGrouped);
    console.log('#getData isPriority',isPriority);
    console.log('#getData isHistory',isHistory);

    if(filter.etaType=='all') delete(filter.etaType);
    if(filter.routeType=='all') delete(filter.routeType);
    this.props.getOperation({
      url: Configs[env].BACKEND_HOST + "/operationDashboard",
      method : "GET",
      filter: {...filter,isGrouped,isPriority,isHistory },
      callback : this.updateTime,
      callback_error : this.updateTime_error,
    });
  }
  updateTime = () => {
    console.log('#updateTime.....');
    this.setState({ last_updated: moment().format('YYYY/MM/DD HH:mm:ss') });
    this.setState({started : true,fetching : false,isLoading : false })
    this.props.STATUS_LOADING(false);
    if(this.state.manualReload) {
        this.props.SET_SNACKBAR({ 
          snackbarOpen: true,
          snackbarVariant: "success",
          snackbarMessage: "Manual reload data success",
          snackbarDuration: 2000,
          forceLogout: false
        })
    }
    this.setState({manualReload : false})
    console.log('#updateTime.....End');
  }

  updateTime_error = (error) => {
    console.log('#updateTime_error.....');
    this.props.STATUS_LOADING(false);
    this.setState({started : true,fetching : false,isLoading : false,manualReload : false })
  }

  handleExport = () => {
    //const category = formValueSelector('category');

    this.props.exportOperation({
      url: Configs[env].BACKEND_HOST + "/operationDashboard/report",
      filter: this.props.search_operation
    });
  };
  render (){
    const { handleSubmit, submitting,classes,invalid,error } = this.props;
    return (
        <div style={{ padding: "10px",display: 'flex', flexDirection: 'column', height:'calc(100vh - 80px)' }}  testing="div-operation-dashboard">
          <div>
            <Grid container spacing={16}>
              <Grid container item xs={4} spacing={16}>
                  <Grid item xs={5}><PlantSelect/></Grid>
                  <Grid item xs={5}><YardSelect/></Grid>
                  <Grid item xs={2} className={classes.buttonReload}>
                    <Tooltip title="Update Data" aria-label="Update Data">
                      <Button disabled={this.props.isLoading} variant="contained" size="small" color="primary" size="small"  
                            onClick={() => this.manualReload(this.props.search_operation)}>
                        <TimerMachine
                            timeStart={this.state.timeStart * 1000} // start at 10 seconds
                            timeEnd={0} // end at 20 seconds
                            started={this.state.started}
                            paused={false}
                            countdown={true} // use as stopwatch
                            interval={1000} // tick every 1 second
                            formatTimer={(time, ms) =>
                              moment.duration(ms, "milliseconds").format("h:mm:ss")
                            }
                            onComplete={time => {
                              this.handleTimerOnComplete(this.props.search_operation)
                              }
                            }
                        /></Button>
                      </Tooltip>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Grid container spacing={16} item xs={12}>
                    <Grid item xs={12}>
                    <FormControl required  noValidate autoComplete="on">
                      <TextField
                        id="operationdash-search"
                        label="SEARCH"
                        type="search"
                        InputLabelProps={{
                          shrink: true
                        }}
                        //value={this.props.textSearchOperation} 
                        margin="dense"
                        className={classes.textField}
                        onChange={this.handleInputChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment variant="filled" position="end">
                              <IconButton>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </FormControl>
                  
                    <RouteSelect/>
                    <ETAMultiSelect/>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="checkBox"
                            checked={this.props.search_operation.isGrouped && this.props.search_operation.isGrouped==1 ? true : false}
                            onChange={this.handleChangeCheckbox}
                            value="group"
                            name="group"
                            color="primary"
                          />
                        }
                        label="GROUP"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="checkBox"
                            checked={this.props.search_operation.isPriority && this.props.search_operation.isPriority==1 ? true : false}
                            onChange={this.handleChangeCheckbox}
                            value="priority"
                            name="priority"
                            color="primary"
                          />
                        }
                        label="PRIORITY"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className="checkBox"
                            checked={this.props.search_operation.isHistory && this.props.search_operation.isHistory==1 ? true : false}
                            onChange={this.handleChangeCheckbox}
                            value="history"
                            color="primary"
                            name="history"
                          />
                        }
                        label="HISTORY"
                      />
                      <Tooltip title="Download File" aria-label="Download File">
                        <IconButton
                            color="secondary"
                            className={classes.iconStyle}
                            onClick={this.handleExport}>
                            <ArchiveIcon/>
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div style={{ flex: '1' ,display: 'flex', flexDirection: 'column' ,marginTop: '1em'}}>
              {/* <Grid item xs={12}> */}
                <Card className={styles.card} style={{height : "100%" }}>
                  <CardContent style={{height : "100%" }}>
                    <OperationsGrid blinkColor={this.state.blinkColor}/>
                  </CardContent>
                </Card>
            {/* </Grid> */}
          </div>
      </div>
      );
    };
  }
const mapStateToProps = state => ({
  search_operation : state.search.search_operation,
  textSearchOperation : state.search.search_operation_txt,
  isLoading : state.ui.isLoading
});
const mapDispatchToProps = dispatch => {
  return {
    setSidebarVisibility: status => dispatch(setSidebarVisibility(status)),
    getOperation : payload => dispatch(getOperation(payload)),
    searchOperation : payload => dispatch(searchOperation(payload)),
    searchOperationTxt : payload => dispatch(searchOperationTxt(payload)),
    exportOperation : payload => dispatch(exportOperation(payload)),
    SET_SNACKBAR: payload => dispatch(SET_SNACKBAR(payload)),
    STATUS_LOADING: payload => dispatch(STATUS_LOADING(payload)),
    dispatch
  };
};
/*const enhance = compose(
  reduxForm({
    //form: "operationDashboardForm",
    // validate
    initialValues: { min: '1', max: '10' }
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
);

export default enhance(Main);*/
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Main);
/**
 * <FormControlLabel
                    value="priority"
                    label="PRIORITY"
                    name="type"
                    control={
                      <Radio 
                      onChange={this.handleChangeRadio}
                      //checked={this.state.priority}
                      checked={this.state.radioValue=="priority" ? 'checked' : ''}
                      color="primary"
                      classes={{
                        root: styles.root,
                        checked: styles.checked,
                      }} />
                    }
                  />
                  <FormControlLabel
                    value="history"
                    label="HISTORY"
                    name="type"
                    control={
                      <Radio 
                      onChange={this.handleChangeRadio}
                      //checked={this.state.history}
                      checked={this.state.radioValue=="history" ? 'checked' : ''}
                      color="primary"
                      classes={{
                        root: styles.root,
                        checked: styles.checked,
                      }} />
                    }
                  />
 */