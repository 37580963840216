import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
// import EditIcon from '@material-ui/icons/Create';
import {
    apiCallRequest,
} from '../../../reducers/api';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { mapPropsStream } from "recompose";
import { red } from "@material-ui/core/colors";

const styles = theme => ({
    gridButton: {
        padding: '0px 0px',
        minWidth: '50px',
        fontSize: '0.8em',
        minHeight: '20px'
    },
    iconStyle :{
        padding:0,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200
      },
})
class SelectRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    
    renderMenuItem(){
        let  etaList = [{value:'YYY',name:'YYY'},{value:'AAA',name:'AAA'},{value:'BBB',name:'BBB'}]
        if(etaList !== undefined){
            var menuItem = etaList;
            //if(this.props.location_group !== undefined){
            //  var menuItem = this.props.location_group;
            return menuItem.map(function(data, index) {
                return (
                <MenuItem key={index} value={data.value}>
                    {data.name}
                </MenuItem>
                );
            });
        }
        else 
        return null;
    }
    render() {
        const { classes } = this.props;
        
        // return (

        //     // <Select
        //     //   name="etaSelection"
        //     //   width={300}
        //     //   inputProps={{
        //     //       id: "etaSelect-required"
        //     //   }}
        //     //   className={styles.textField}
        //     // >
            
        //     // {this.renderMenuItem()}

        //     // </Select>
        // );
        return (
            <span>
                <div>{this.props.value}</div>
            </span>
        );
    }
};

const mapStateToProps = function(state,ownProps) {
    return {
        ...ownProps
    }
};

const mapActionsToProps =  {

};
const enhance = compose(
    connect(
        mapStateToProps,
        mapActionsToProps
    ),
    withStyles(styles)
);  

export default enhance(SelectRenderer)
