import { handleActions } from 'redux-actions';
// ACTION CREATORS
import { createAction  } from 'redux-actions';
export const addLocation = createAction('ADD_LOCATION');
export const addGroupLocation = createAction('ADD_GROUP_LOCATION');
export const formLogisticRoute = createAction('FORM_LOGISTIC_ROUTE');
export const formUpdaateLogisticRouteId = createAction('FORM_UPDATE_LOGISTIC_ROUTEID');
export const formMasterRoute = createAction('FORM_MASTER_ROUTE');
export const formHazardPoint = createAction('FORM_HAZARD_POINT');
export const formWarningType = createAction('FORM_WARNING_TYPE');
export const formUser = createAction('FORM_USER');
export const formLevel = createAction('FORM_LEVEL');
export const formGroup = createAction('FORM_GROUPCOMP');
export const formCompany = createAction('FORM_COMPANY');
export const formProvider = createAction('FORM_PROVIDER');
export const formProblem = createAction('FORM_PROBLEM');
export const formAction = createAction('FORM_ACTION');
export const formOperation = createAction('FORM_OPERATION');

const initialState = {
    locationForm :{},
    groupForm : {},
    hazardForm : {},
    warningForm : {},
    masterRouteForm : {},
    logisticRouteForm : {},
    userForm : {},
    levelForm : {},
    companyForm : {},
    groupcompanyForm : {},
    providerForm : {},
    problemForm : {},
    actionForm : {},
    operationForm : {},
};

export default handleActions(
    {
      [addLocation](state, { payload }) {
        return { ...state, locationForm: payload };
      },
      [addGroupLocation](state, { payload }) {
        return { ...state, groupForm: payload };
      },
      [formHazardPoint](state, { payload }) {
        return { ...state, hazardForm: payload };
      },
      [formWarningType](state, { payload }) {
        return { ...state, warningForm: payload };
      },
      [formMasterRoute](state, { payload }) {
        return { ...state, masterRouteForm: payload };
      },
      [formLogisticRoute](state, { payload }) {
        return { ...state, logisticRouteForm: payload };
      },
      [formUpdaateLogisticRouteId](state, { payload }) {
        // console.log('#formUpdaateLogisticRouteId logisticRouteForm',state.logisticRouteForm);
        return { ...state, logisticRouteForm: {...state.logisticRouteForm,logisticsRouteId:payload} };
      },
      [formUser](state, { payload }) {
        return { ...state, userForm: payload };
      },
      [formLevel](state, { payload }) {
        return { ...state, levelForm: payload };
      },
      [formGroup](state, { payload }) {
        return { ...state, groupcompanyForm: payload };
      },
      [formCompany](state, { payload }) {
        return { ...state, companyForm: payload };
      },
      [formProvider](state, { payload }) {
        return { ...state, providerForm: payload };
      },
      [formProblem](state, { payload }) {
        return { ...state, problemForm: payload };
      },
      [formAction](state, { payload }) {
        return { ...state, actionForm: payload };
      },
      [formOperation](state, { payload }) {
        return { ...state, operationForm: payload };
      }
    },
    initialState
);