import React, { Component } from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import compose from "recompose/compose";
import DatePicker from 'react-datepicker';
import DialogActions from "@material-ui/core/DialogActions";
import 'react-datepicker/dist/react-datepicker.css';
//import renderDatePicker from '../Ui/Renderer/DatePickerRenderer';
import {
  propTypes,
  reduxForm,
  Form,
  Field,
  formValueSelector
} from "redux-form";
//import AutoSelect from 'react-select';
//Lib
import { required, number } from "../../libs/validation";
//Component
import CategorySelect from './RouteForm/CategorySelect';
import HazardSelected from './HazardSelected';
//Reducer
import {
  apiGetCallRequest,
  apiGetCallSuccess,
  resetAPIResult,
  apiCallRequest
} from "../../reducers/api";
import { addLocation, addGroupLocation } from "../../reducers/ui/mapForm";
import { connect } from "react-redux";
import { uiPanelDialogOpen, uiSwitchForm } from "../../reducers/ui";
import {
  mapDrawingMode,
  mapResetMap,
  mapChangeMarker,
  mapDrawingOptions,
  mapSetSuggestionPolylines
} from "../../reducers/map";

import { selectImpactHazard } from "../../reducers/ui/selectList";
import { getLocationGroup,getLogisticPoint,GET_SELECTED_HAZARDS } from "../../reducers/map/api";
import { searchHazardImpact } from "../../reducers/search";
//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
});

class SelectedHazardForm extends Component {
  state = {
    detail : '',
    actionsLocationPointGroup: false,
    actionsLocationGroup: false,
  };

  componentDidMount() {
    this.props.RESET_MAP(true);
    this.props.DRAWING_OPTION(this.state.drawingOptions);
    if(this.props.selected_impact){
      this.handleLoadSelected();
    }
  }
  componentWillMount() {
  }
  componentWillReceiveProps(nextProps, props) {
    //console.log('#componentWillReceiveProps hazard_seleciton_list',this.props.hazard_seleciton_list);
      if(this.props.hazard_seleciton_list!=nextProps.hazard_seleciton_list && nextProps.hazard_seleciton_list.length>0){
        this.setHazardDetail(nextProps.hazard_seleciton_list[0].hazard[0]);
      }
  }

  handleLoadSelected = () =>{
      this.props.RESET_MAP(true);
      this.props.GET_SELECTED_HAZARDS({
        url: Configs[env].BACKEND_HOST + "/impactHazard/"+this.props.selected_impact.id,
        filter:{}
      });
  }
  handleBacktoHazardForm = () =>{
    this.props.RESET_MAP(true);
    this.props.selectImpactHazard(null)
  }
  setHazardDetail = (hazard) =>{
    //console.log('#setHazardDetail',hazard);
    let detail = (<div>
      <Typography component="p">
          Category : {hazard.category}
      </Typography>
      <Typography component="p">
          Location : {hazard.location}
      </Typography>
      <Typography component="p">
          Detail : {hazard.detail}
      </Typography>
    </div>);
    this.setState({detail : detail});
  }
  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div style={{ padding: "10px" }}>
        <Card className={styles.card}>
          <CardContent>
            
            <Typography variant="subtitle1">Filtered By Selected Hazard : </Typography>
            {this.state.detail}
            <HazardSelected />
            <DialogActions>
              <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  aria-label="REFRESH"
                  onClick={this.handleLoadSelected}
                  className={styles.button}
                >
                  REFRESH
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  aria-label="BACK"
                  onClick={this.handleBacktoHazardForm}
                  className={styles.button}
                >
                  BACK
                </Button>
            </DialogActions>
          </CardContent>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = function(state) {
  return {
    apiFetchResult: state.api.result,
    dialogOpen: state.ui.dialogOpen,
    formManage: state.ui.formManage,
    manageForm: state.mapForm.manageForm,
    selected_impact : state.selection.selected_impact,
    hazard_seleciton_list : state.map.hazard_seleciton_list,
    search_hazard_impact : state.search.search_hazard_impact
  };
};
const mapActionsToProps = {
  DRAWING_OPTION: mapDrawingOptions,
  RESET_MAP: mapResetMap,
  GET_SELECTED_HAZARDS,
  selectImpactHazard
};
const enhance = compose(
  connect(
    mapStateToProps,
    mapActionsToProps
  ),
  withStyles(styles)
);
export default enhance(SelectedHazardForm);
