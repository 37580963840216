import React, { Component } from "react";
import { render } from "react-dom";
import { connect } from "react-redux";
import ImCircle from "../ImCircle";
import ImPolygon from "../ImPolygon";
import { selectImpactHazard } from "../../../reducers/ui/selectList";
// import MarkerLocation from "../MarkerLocation";
import IhMarker from "./IhMarker";
import IhPolyline from "./IhPolyline";
import {
    Circle,
    Polygon,
    Marker
  } from "react-google-maps";


class HazardPoints extends Component {
    rightClick = (e,id) => {
        // handleSelectedHazard = () =>{
        // let x = id
        // console.log(id)
        this.props.selectImpactHazard({id:id})
        // }
    }
  render() {
    return (
        <div>
            {this.props.markerList.length>0 && this.props.markerList.map((marker, index) => {
                return (
                    <IhMarker
                        onRightClick={(e) => this.rightClick(e,marker.hid)}
                        {...marker}
                        key={marker.id}
                        id={marker.id}
                        position={marker.position}
                        info={marker.info}
                        editable={marker.editable}
                        isGroup={marker.isGroup}
                        category={marker.category}
                        />
                );
            })}
            {this.props.circleList && this.props.circleList.length>0 && this.props.circleList.map((circle, index) => {
                return (
                    <Circle
                    key={circle.id}
                    id={circle.id}
                    center={circle.center}
                    color="blue"
                    radius={circle.radius}
                    options={{
                        zIndex: 10,
                        strokeColor: 'red',
                        fillColor: 'red',
                        strokeOpacity: 1,
                        strokeWeight: 2,
                        fillOpacity: 0.3
                    }}
                    />
                );
            })}
            {this.props.polygonList && this.props.polygonList.length>0 && this.props.polygonList.map((polygon, index) => {
                return (
                    <Polygon
                    key={polygon.id}
                    id={polygon.id}
                    path={polygon.path}
                    options={{
                        zIndex: 10,
                        strokeColor: 'red',
                        fillColor: 'red',
                        strokeOpacity: 1,
                        strokeWeight: 2,
                        fillOpacity: 0.3
                    }}
                    />
                );
            })}

            {this.props.suggestionPolylineList.map((polyline, index) => {
                  return (
                      <IhPolyline
                      key={polyline.index}
                      id={polyline.id}
                      path={polyline.path}
                      editable={polyline.editable}
                    //   info={polyline.info}
                      />
                  );
              })}
            
            {//------ Origin - Desc Marker ------//
            }
            <div>
                <Marker
                    position={this.props.marker_origin}
                    icon={{
                        url: "http://www.google.com/mapfiles/dd-start.png",
                        labelOrigin: new window.google.maps.Point(19, 14),
                        scaledSize: new window.google.maps.Size(22, 36),
                        size: new window.google.maps.Size(22, 36)
                    }}
                />
                <Marker
                    position={this.props.marker_desc}
                    icon={{ 
                        url: "http://www.google.com/mapfiles/dd-end.png",
                        labelOrigin: new window.google.maps.Point(19, 14),
                        scaledSize: new window.google.maps.Size(22, 36),
                        size: new window.google.maps.Size(22, 36)
                    }}
                />
            </div>
        </div>
    )
  }
}
const mapStateToProps = function(state) {
    return {
        markerGroup: state.map.markerGroup,
        markerList: state.map.markerList,
        circleList: state.map.circleList,
        polygonList: state.map.polygonList,
        marker_origin: state.map.marker_origin,
        marker_desc: state.map.marker_desc,
        suggestionPolylineList: state.map.suggestionPolylineList,
    };
  };
  
  const mapActionsToProps = {
    selectImpactHazard,
  };
  
  export default connect(
    mapStateToProps,
    mapActionsToProps
  )(HazardPoints);
  