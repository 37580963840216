import React, { Component } from "react";
import { render } from "react-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import { compose, withProps, withStateHandlers } from "recompose";
import { connect } from "react-redux";

import {
  MARKER_MOVE,
  MARKER_DRAGGING,
  MARKER_SELECTING,
  DEL_MARKER,
  SET_DATA_FOR_EDIT_ROUTE
} from "../../../reducers/map";
import { getEditRoute } from "../../../reducers/map/api";

import Configs from "../../../config/config";
const env = process.env.NODE_ENV;


class RsWaypointMarker extends Component {
  selectInfo = arg => {
    this.props.MARKER_SELECTING(this.props.all_idx);


  };

  onDragStart = arg => {
    this.closeInfo()
    this.props.MARKER_DRAGGING(this.props.id);


  };

    onDragEnd = e => {
        let data_1_ForEditRoute = this.props.dataForEditRoute[this.props.route_idx]
        
        var copyWP = data_1_ForEditRoute.Legs[this.props.leg_idx].waypoints.slice();
        copyWP[this.props.subleg_idx] = {lat: e.latLng.lat(), lng: e.latLng.lng()}

        data_1_ForEditRoute.Legs[this.props.leg_idx].waypoints = copyWP
        this.reloadRoute(data_1_ForEditRoute)
    };

    closeInfo = () => {
        this.props.MARKER_SELECTING(-1)
    }

    delWaypoint = e => {
        
        let data_1_ForEditRoute = this.props.dataForEditRoute[this.props.route_idx]

        if(data_1_ForEditRoute.Legs[this.props.leg_idx].waypoints.length > 3 ) {
            var copyWP = data_1_ForEditRoute.Legs[this.props.leg_idx].waypoints.slice();
            copyWP.splice(this.props.subleg_idx, 1);
            data_1_ForEditRoute.Legs[this.props.leg_idx].waypoints = copyWP
            this.reloadRoute(data_1_ForEditRoute)
        }

        this.props.MARKER_SELECTING(-1)
    }

    reloadRoute = (data_1_ForEditRoute) => {
        //=================

        let tmp_paths = [];
        data_1_ForEditRoute.Legs[this.props.leg_idx].waypoints.map((point, i) => {
            tmp_paths.push(new window.google.maps.LatLng(point.lat, point.lng))
        });

        let encodedURI = window.google.maps.geometry.encoding.encodePath(tmp_paths);
        // console.log("Kai encodedURI",encodedURI);

        data_1_ForEditRoute.Legs[this.props.leg_idx].polyline = encodedURI
        data_1_ForEditRoute.Legs[this.props.leg_idx].isChangeRoute = true
        // console.log("Kai data_1_ForEditRoute", data_1_ForEditRoute)

        let post_data = JSON.parse(JSON.stringify(data_1_ForEditRoute));

        post_data.Legs.map((leg,idx) => {
            delete(post_data.Legs[idx].waypoints)
        })

        if(this.props.shiftSeq !== null && this.props.shiftSeq !== '')
            post_data.shiftNo = this.props.shiftSeq
        else
            post_data.shiftNo = null

        console.log("KAI 2",post_data)

        this.props.getEditRoute({
            url: Configs[env].BACKEND_HOST + "/editRoute",
            objData : post_data,
            route_id : this.props.route_idx,
            method : "POST",
            callback: this.editR
        });

    
    
    };

    editR = (response) =>{
        // console.log("Kai Marker editR", response)

        let dataForEditRoute = JSON.parse(JSON.stringify(this.props.dataForEditRoute));

        response.list[0].routes[this.props.route_idx].Legs.map((leg,idx)=>{
            dataForEditRoute[this.props.route_idx].Legs[idx].routeId = leg.routeId 
        })

        this.props.SET_DATA_FOR_EDIT_ROUTE(dataForEditRoute)
    }

  render() {
    const id = this.props.id;
    let selected = false;
    if (this.props.marker_selecting === this.props.all_idx) {
      selected = true;
    }

    let icon_url = "http://maps.google.com/mapfiles/ms/micons/red.png"
    let label = id.toString();
    let size = new window.google.maps.Size(40, 40);


    // let icon = new window.google.maps.MarkerImage(
    //     "http://maps.google.com/mapfiles/ms/icons/green.png",
    //     null /* size is determined at runtime */,
    //     null /* origin is 0,0 */,
    //     null /* anchor is bottom center of the scaled image */,
    //     new window.google.maps.Size(43, 43)
    //   );
      
    // //https://sites.google.com/site/gmapicons/home/
    // if (this.props.isFirst) {
    //   icon_url = "http://www.google.com/mapfiles/dd-start.png";
    //   size = new window.google.maps.Size(22, 36);
    // } else if (this.props.isLast) {
    //   icon_url = "http://www.google.com/mapfiles/dd-end.png";
    //   size = new window.google.maps.Size(22, 36);
    // } else if (this.props.marker_MovingAdding === id) {
    //   icon_url = "http://maps.google.com/mapfiles/ms/icons/red.png";
    //   label = id.toString();
    // } else {
    //   icon_url = "http://maps.google.com/mapfiles/ms/icons/blue.png";
    //   label = id.toString();
    // }

    const _this = this;
    return (
      <div>
        <Marker
          position={this.props.position}
          label={label}
          icon={{
            url: icon_url,
            labelOrigin: new window.google.maps.Point(19, 14),
            scaledSize: size,
            size: size
          }}
          key={this.props.key}
          defaultDraggable={this.props.defaultDraggable}
          onClick={this.selectInfo}
          onDragStart={this.onDragStart}
          // onMouseOver={this.onDragStart}
          // onMouseOut={this.props.mapClearInfo}
          // mapClearInfo={this.props.mapClearInfo}
          onDragEnd={this.onDragEnd}>
          {selected ? (
            <div>
              <InfoWindow onCloseClick={this.closeInfo}>
                <div>
                  {/* <div>
                    <div>Distance : {this.props.infoDistance}</div>
                    <div>ETA : {this.props.infoETA}</div>
                  </div> */}
                  {/* <div> */}
                    {!(this.props.isFirst || this.props.isLast) && (
                      <button onClick={() => this.delWaypoint(id)}>
                        Delete
                      </button>
                    )}
                  {/* </div> */}
                </div>
              </InfoWindow>
            </div>
          ) : null}
        </Marker>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    marker_selecting: state.map.marker_selecting,
    marker_MovingAdding: state.map.marker_MovingAdding,
    marker_Dragging: state.map.marker_MovingAdding,
    dataForEditRoute: state.map.dataForEditRoute,
    shiftSeq : state.selection.shiftSeq
  };
};

const mapActionsToProps = {
  // CIRCLE_SELECTING : mapSelectedCircle ,
  // CIRCLE_CHANGE : mapChangeCircle ,
  MARKER_MOVE : MARKER_MOVE,
  MARKER_DRAGGING : MARKER_DRAGGING,
  MARKER_SELECTING: MARKER_SELECTING,
  DEL_MARKER : DEL_MARKER,
  getEditRoute,
  SET_DATA_FOR_EDIT_ROUTE
};

// const mapDispatchToProps = dispatch => {
//   return {
//     DEL_MARKER: value => dispatch({ type: "DEL_MARKER", value: value }),
//     MARKER_SELECTING: id => dispatch({ type: "MARKER_SELECTING", value: id }),
//     MARKER_DRAGGING: id => dispatch({ type: "MARKER_DRAGGING", value: id }),
//     MARKER_MOVE: id => dispatch({ type: "MARKER_MOVE", value: id })
//   };
// };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(RsWaypointMarker);
