import { createAction, handleActions } from 'redux-actions';


export const getGroupCompanies = createAction("API_GET_GROUPCOMP");
export const getCompanies = createAction("API_GET_COMP");
export const getLevel = createAction("API_GET_LEVEL");
export const getUsers = createAction("API_GET_USERS");
export const getConfigs = createAction("API_GET_SETTINGS");
export const getProvider = createAction("API_GET_PROVIDER");
export const getProblem = createAction("API_GET_PROBLEM");
export const getAction = createAction("API_GET_ACTION");


export const exportEventLogs = createAction("API_EXPORT_EVENTLOGS");


const initialState = {
    fetching: false,
};

// REDUCERS
export default handleActions(
{
    [getGroupCompanies](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    [getCompanies](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    [getLevel](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    [getUsers](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    [getConfigs](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    [getProvider](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    [getProblem](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    [getAction](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    [exportEventLogs](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    
    
},initialState);