import React, { Component } from "react";
import { render } from "react-dom";
import { connect } from "react-redux";
import ImCircle from "./ImCircle";
import ImPolygon from "./ImPolygon";
import MarkerLocation from "./MarkerLocation";
import HpMarker from "./HazardPoints/HpMarker";


class HazardPoints extends Component {
  render() {
    return (
        <div>
            {this.props.markerList.length>0 && this.props.markerList.map((marker, index) => {
                return (
                    <HpMarker
                        key={marker.id}
                        id={marker.id}
                        position={marker.position}
                        info={marker.info}
                        editable={marker.editable}
                        isGroup={marker.isGroup}
                        />
                );
            })}
            {this.props.circleList && this.props.circleList.length>0 && this.props.circleList.map((circle, index) => {
                return (
                    <ImCircle
                    key={circle.id}
                    id={circle.id}
                    center={circle.center}
                    color="blue"
                    info={circle.info}
                    radius={circle.radius}
                    editable={circle.editable}
                    />
                );
            })}
            {this.props.polygonList && this.props.polygonList.length>0 && this.props.polygonList.map((polygon, index) => {
                return (
                    <ImPolygon
                    key={polygon.id}
                    id={polygon.id}
                    path={polygon.path}
                    editable={polygon.editable}
                    info={polygon.info}
                    />
                );
            })}
            
        </div>
    )
  }
}
const mapStateToProps = function(state) {
    return {
        markerGroup: state.map.markerGroup,
        markerList: state.map.markerList,
        circleList: state.map.circleList,
        polygonList: state.map.polygonList,
    };
  };
  
  const mapActionsToProps = {
  };
  
  export default connect(
    mapStateToProps,
    mapActionsToProps
  )(HazardPoints);
  