import { handleActions } from 'redux-actions';
// ACTION CREATORS
import { createAction  } from 'redux-actions';
 
export const uiAppbarToggle = createAction('UI_APPBAR_TOGGLE');
export const setSidebarVisibility = createAction('SET_SIDEBAR_VISIBILITY');
export const setActiveMenuListItem = createAction('UI_MENU_LISTITEM_ACTIVE');
export const uiPanelDialogOpen = createAction("UI_PANEL_DIALOG_OPEN");
export const uiSwitchForm = createAction("UI_FORM_MANAGE");
export const uiSwitchFormReset = createAction("UI_FORM_MANAGE_RESET");

export const uiSwitchPage = createAction("UI_PAGE_MANAGE");
export const uiAlertOpen = createAction("UI_ALERT_OPEN");
export const uiAlertReset = createAction("UI_ALERT_RESET");

export const uiActiveTabImpact = createAction("UI_ACTIVETAB_IMPACT");
export const uiActiveTabSystemMaster = createAction("UI_ACTIVETAB_SYSTEMMASTER");

export const SET_BODY_SIZE = createAction("SET_BODY_SIZE");
export const SET_SNACKBAR = createAction("SET_SNACKBAR");
export const SET_LOADING = createAction("SET_LOADING");
export const STATUS_LOADING = createAction("STATUS_LOADING");

const initialState = {
  sidebarOpen: true,
  activeMenuListItem: {
    name: '',
    idx: null,
    subLink:'',
  },
  alert:{ open: false, message : "", title : ""},
  dialogOpen : false,
  formManage : {
    page:'list',
    mode : '',
  },
  pageManage : {
    page : 'location_master'
  },
  bodySize : {
    width : 1024,
    height : 768
  },
  snackbar : {
    snackbarOpen: false,
    snackbarVariant: "success",
    snackbarMessage: "Success",
    snackbarDuration: 5000,
    forceLogout: false,
  },
  loadingDialog : {
    msg : 'Loading...',
    active : false,
  },
  isLoading : false,
  activeTabImpact : 0 ,
  activeTabSystemMaster : 0 ,
};

// REDUCERS
export default handleActions(
  {
    [STATUS_LOADING](state,{ payload }) {
      return { ...state, isLoading: payload }
    },
    [SET_LOADING](state,{ payload }) {
      return { ...state, loadingDialog: { ...state.loadingDialog, ...payload } }
    },
    [SET_SNACKBAR](state,{ payload }) {
      if(!payload.forceLogout) payload.forceLogout = false
      return { ...state, snackbar: { ...state.snackbar, ...payload } }
    },
    [SET_BODY_SIZE](state,{ payload }) {

      return { ...state, bodySize: payload };
    },
    [uiAppbarToggle](state) {
      return { ...state, sidebarOpen: !state.sidebarOpen };
    },
    [setSidebarVisibility](state, { payload }) {

      return { ...state, sidebarOpen: payload };
    },
    [setActiveMenuListItem](state, { payload }) {
      return { ...state, activeMenuListItem: payload, formManage: { page:'list' }};
    },
    [uiPanelDialogOpen](state,{ payload }){
      return {...state, dialogOpen: payload};
    },   
    [uiSwitchForm](state,{ payload }){

      return {...state, formManage: payload};
      //return {...state, formManage:payload, ...payload };
    },
    [uiSwitchFormReset](state,{ payload }){
      return {...state, formManage:{ page: payload || 'list'} }
    },
    [uiSwitchPage](state,{ payload }){

      return {...state, pageManage: payload};
    },
    [uiAlertOpen](state,{payload}){
      return {...state, alert: { ...payload }}
    },
    [uiAlertReset](state){
      return {...state, alert: { open: false, message : "", title : ""}}
    }  ,
    [uiActiveTabImpact](state,{payload}){
      return {...state, activeTabImpact:payload}
    }, 
    [uiActiveTabSystemMaster](state,{payload}){
      return {...state, activeTabSystemMaster:payload}
    },
  },
  initialState
);

// SELECTORS
export const getSidebarOpen = state => state.ui.sidebarOpen;
export const getActiveMenuListItem = state => state.ui.activeMenuListItem;
