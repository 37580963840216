import React, { Component } from "react";
import moment from "moment";
import momentLocalizer from 'react-widgets-moment';
import {
  propTypes,
  reduxForm,
  Form,
  Field,
  formValueSelector,
} from "redux-form";

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import PlaceIcon from "@material-ui/icons/Place";
import PanoramaFishEyeIcon from "@material-ui/icons/PanoramaFishEye";
import CropDinIcon from "@material-ui/icons/CropDin";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import PanoramaHorizontalIcon from "@material-ui/icons/PanoramaHorizontal";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Loading from "../Ui/Loading";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import compose from "recompose/compose";
import CircularProgress from '@material-ui/core/CircularProgress';

//import 'date-fns';
//import {DateFnsUtils} from '@date-io/date-fns';
//import { MuiPickersUtilsProvider, TimePicker, DatePicker } from 'material-ui-pickers';
import {DateFormatInput, TimeFormatInput} from 'material-ui-next-pickers'

//Component
import PanelDialog from "../Ui/PanelDialog";
import LogisticPointSelect from './LogisticPointSelect';
import LocationGroupSelect from './LocationGroupSelect';
import DateComponent from '../Ui/DateComponent';
//Lib
import { required, number,logisticLength,maxLength200,maxLength100 } from "../../libs/validation";
import { logisticPointFormat } from "../../libs/normalize";
//Reducer
import { apiCallRequest } from "../../reducers/api";
import { apiGetCallRequest } from "../../reducers/api";
import { uiPanelDialogOpen, uiSwitchForm ,SET_SNACKBAR } from "../../reducers/ui";
import {
  mapDrawingMode,
  mapResetMap,
  mapAreaType,
  mapDrawingOptions
} from "../../reducers/map";
import { getLogisticDetail } from "../../reducers/map/api";
import { addLocation } from "../../reducers/ui/mapForm";

import 'react-widgets/dist/css/react-widgets.css'
//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

moment.locale('th')
momentLocalizer()

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  formControl: {
    margin: theme.spacing.unit
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  cssGreen: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  titlePanel: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500])
  },
  center:{
    position: 'relative',
    left: '20px'
  },
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);
const renderDateTimePicker = ({ input: { onChange, value },label, showTime }) =>
  <DateFormatInput
    onChange={onChange}
    dateFormat="yyyy/MM/dd"
    label={label}
    //time={showTime}
    value={!value ? new Date() : new Date(value)}
  />
class ManageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawingMode: null,
      marker: null,
      date: undefined,
      drawingOptions: {
        drawingControl: false,
        editable: true,
        circleOptions: {
          fillColor: "#000000",
          fillOpacity: 0.5,
          strokeWeight: 1,
          stokColor: "#000000",
          strokeOpacity: 0.5
        },
        polygonOptions: {
          fillColor: "#000000",
          fillOpacity: 0.5,
          strokeWeight: 1,
          stokColor: "#000000",
          strokeOpacity: 0.5
        }
      }
    };
  }

  componentWillMount() {

    //debugger
    if (this.props.mode === "EDIT") {

      this.props.GET_LOGISTIC_DETAIL({
        url: Configs[env].BACKEND_HOST + "/location/"+this.props.pointDeatailID,
        filter: {
        }
      });
    } else {
      // mode ADD
      
      if (this.props.locationForm) {
        this.props.initialize({...this.props.locationForm});
        this.props.change("tcFrom",moment().format('YYYY-MM-DD'))

      }
    }
  }
  componentDidMount() {
    this.props.DRAWING_MODE(null);
    this.props.RESET_MAP(true);
    this.props.DRAWING_OPTION(this.state.drawingOptions);
  }

  componentWillReceiveProps(nextProps, props) {


    if(nextProps.locationForm  && nextProps.locationForm !== this.props.locationForm){


      //this.props.ADD_LOCATIONS(...logisticPoint);
      this.props.initialize({
        ...nextProps.locationForm
      });
      this.props.change("tcFrom",moment(nextProps.locationForm.tcFrom).format('YYYY-MM-DD'))
      this.props.change({
        ...nextProps.locationForm
      });
    }

    //SET MARKER 
    if (nextProps.markerList && nextProps.markerList.length>0) {
      this.props.change("latitude", parseFloat(nextProps.markerList[0].position.lat.toFixed(6)) );
      this.props.change("longitude", parseFloat(nextProps.markerList[0].position.lng.toFixed(6)) );
    }
  }
  getType = () => {
    if(this.props.polygonList[0]) return "polygon";
    else if(this.props.circleList[0]) return "circle";
    else return "";
  }  
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSave(form) {
    //debugger
    let detail = JSON.parse(this.props.locationGroup.value);
    //let tcFrom = form.tcFrom; delete(form.tcFrom);
    if((this.getType()=='circle' && this.props.circleList[0]) || 
      (this.getType()=='polygon' && this.props.polygonList[0])
    ){
        let formData = {...form,
          logisticsPointGroup: this.props.logisticPoint.value.trim(),
          //logisticsPointGroup: this.props.logisticPoint.text,
          locationGroupId: detail.id,
          //location_group: this.props.locationGroup.text,
          radius: this.props.circleList[0] ? parseFloat(this.props.circleList[0].radius.toFixed(3)) : null,
          circleLatitude: this.props.circleList[0] ? parseFloat(this.props.circleList[0].center.lat.toFixed(6)): null,
          circleLongitude: this.props.circleList[0] ? parseFloat(this.props.circleList[0].center.lng.toFixed(6)): null,
          polygon: this.props.polygonList[0] ? window.google.maps.geometry.encoding.encodePath(this.props.polygonList[0].path) : "",
          type : this.getType(),
          tcFrom : moment(form.tcFrom).format('YYYY-MM-DD')
        };

        if (this.props.mode.toUpperCase() === "EDIT") {
          let id = formData.id;delete(formData.id);
          
          this.props.API_REQUEST({
            url: Configs[env].BACKEND_HOST + "/location/"+id,
            form: {...formData },
            method : "PUT",
            callback: this.handleReset.bind(this)
          });
        } else {
          this.props.API_REQUEST({
            url: Configs[env].BACKEND_HOST + "/location",
            form: { ...formData},
            method : "POST",
            callback: this.handleReset.bind(this)
          });
        }
      } else {
        this.props.SET_SNACKBAR({
          snackbarOpen: true,
          snackbarVariant: "error",
          snackbarMessage: "Require area Circle or Polygon",
          snackbarDuration: 5000
        });
    }
  }
  
  handleClick(event) {
    this.props.OPEN_DIALOG(true);
  }
  handleClickBack(event) {
    this.handleReset();
  }
  handleReset() {
    // console.log("#handleReset...")
    this.props.reset();
    this.props.SWITCH_FORM({ page: "list" });
    this.props.RESET_MAP(true);
    this.props.ADD_LOCATIONS({});
    
  }

  handleDrawingMode(mode, event) {

    //this.props.RESET_MAP(false);
    this.setState({ drawingMode: mode }, this.setMode);
  }
  setMode() {
    if (this.state.drawingMode != "marker")
      this.props.CHANGE_MAP_TYPE(this.state.drawingMode);
    this.props.DRAWING_MODE(this.state.drawingMode);
  }
  handleChangeDate = (form) =>{
  }
  renderGroupButton() {
    return (
      <Grid item xs={12}>
        <Button
          variant="fab"
          mini
          color={this.props.drawingMode=="circle" ? "secondary" : "primary"}
          aria-label="Create Circle"
          className={styles.button}
          onClick={this.handleDrawingMode.bind(this, "circle")}
        >
          <PanoramaFishEyeIcon className={styles.rightIcon} />
        </Button>
        <Button
          variant="fab"
          mini
          color={this.props.drawingMode=="polygon" ? "secondary" : "primary"}
          aria-label="Create Polygon"
          className={styles.button}
          onClick={this.handleDrawingMode.bind(this, "polygon")}
        >
          <PanoramaHorizontalIcon className={styles.rightIcon} />
        </Button>
      </Grid>
    );
  }
  onChangeDate = (date) => {
    // console.log('Date = ' + date)
    this.setState({date})
  }
  render() {
    const { handleSubmit, submitting,classes } = this.props;
    const {date, min, max, time} = this.state
    return (
      <div style={{ padding: "10px" }}>
        {//this.props.isLoading && <Loading />
        }
        <Toolbar variant="dense">
          <Typography variant="h6" gutterBottom className={styles.titlePanel}>
            {this.props.mode} LOCATION MASTER
          </Typography>
        </Toolbar>
        <Card className={styles.card}>
          <CardContent>
            <Form
              testing="form-add_edit-logistics-point"
              autoComplete={"off"}
              autoCorrect={"off"}
              spellCheck={"off"}
              onSubmit={handleSubmit(this.handleSave.bind(this))}
              
            >
              <Grid container spacing={16}>
                <Grid item xs={12}>
                  <LogisticPointSelect/>
                </Grid>
                <Grid item xs={7}>
                  <LocationGroupSelect/>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    testing="field-logistics-point"
                    label="LOGISTIC POINT"
                    name="logisticsPoint"
                    component={renderInput}
                    className={styles.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    margin="dense"
                    validate={[required,logisticLength]}
                    normalize={logisticPointFormat}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    testing="field-logistics-point-name"
                    label="NAME"
                    name="name"
                    fullWidth
                    component={renderInput}
                    className={styles.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="dense"
                    validate={[required,maxLength100]}
                  />
                </Grid>
                <Grid item xs={12}>
                  {<Field
                    name="tcFrom"
                    label="TC FROM"
                    type="date"
                    //component={renderDateTimePicker}
                    //onChange={this.onChangeDate}
                    //value={date}
                    component={renderInput}
                    className={styles.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="dense"
                    validate={[required]}
                  />}
                  {/*<FormControl className={classes.formControl}>
                    <InputLabel htmlFor="tcFrom" className={classes.bootstrapFormLabel}>TC FROM</InputLabel>
                    <Field
                      name="tcFrom"
                      id="tcFrom"
                      label="TC FROM"
                      showTime={false}
                      InputLabelProps={{
                        shrink: true
                      }}
                      classes={{
                        root: classes.bootstrapRoot,
                        input: classes.bootstrapInput,
                      }}
                      placeholder="TC FROM"
                      onChange={this.handleChangeDate}
                      component={renderDateTimePicker}
                      className={classes.textField}
                    />
                    </FormControl>*/}
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Field name="created_on" component={DateComponent} floatingLabelText="Created Date"/>
                  </MuiPickersUtilsProvider>*/}
                  {/*<Field
                    name="birthday"
                    inputValueFormat="YYYY-MM-DD"
                    dateFormat="L"
                    dateFormatCalendar="dddd"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className={classes.textField}
                    normalize={value => (value ? moment(value).format('YYYY-MM-DD') : null)}
                    component={DateComponent}
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="dense"
                    validate={[required]}
                  />*/}
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="detail"
                    label="DETAIL"
                    component={renderInput}
                    className={styles.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    margin="dense"
                    validate={[maxLength200]}
                  />
                </Grid>

                <Grid item xs={2}>
                  <IconButton
                    color={this.props.drawingMode=="marker" ? "secondary" : "primary"}
                    aria-label="Create Marker"
                    className={styles.button}
                    onClick={this.handleDrawingMode.bind(this, "marker")}
                  >
                    <PlaceIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    testing="field-logistics-point-lat"
                    disabled
                    name="latitude"
                    label="Latitude"
                    component={renderInput}
                    className={styles.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="dense"
                    validate={[required, number]}
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                  <Field
                    testing="field-logistics-point-lng"
                    disabled
                    name="longitude"
                    label="lng"
                    component={renderInput}
                    className={styles.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="dense"
                    validate={[required, number]}
                  />
                </Grid>

                {this.renderGroupButton()}
                
              </Grid>
              <Grid item xs={12}>
                <DialogActions>
                  <Button
                    testing="button-save-logistics-point"
                    variant="contained"
                    size="small"
                    color="primary"
                    aria-label="Save"
                    disabled={this.props.isLoading}
                    className={styles.button}
                    type="submit"
                  >
                    {this.props.isLoading && <CircularProgress className={classes.center} size={25} thickness={2} />}
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    aria-label="Add"
                    disabled={this.props.isLoading}
                    className={classNames(styles.button, styles.cssGreen)}
                    onClick={this.handleClickBack.bind(this)}
                  >
                    BACK
                  </Button>
                </DialogActions>
              </Grid>
            </Form>
          </CardContent>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = function(state, ownProps) {


  return {
    //isLoading: state.api.fetching,
    isLoading: state.ui.isLoading,
    //successSave : state.api.result,
    apiFetchResult: state.api.result,
    dialogOpen: state.ui.uiPanelDialogOpen,
    drawingMode: state.map.drawingMode,
    areaType: state.map.areaType,
    locationForm: state.mapForm.locationForm,
    markerList: state.map.markerList,
    circleList: state.map.circleList,
    polygonList: state.map.polygonList,
    logistic_point: state.map.logistic_point,

    mode: state.ui.formManage.mode || ownProps.mode === "edit" ? "EDIT" : "ADD",
    pointDeatailID: state.ui.formManage.editID || "",

    logisticPoint : state.selection.logisticPoint,
    locationGroup: state.selection.locationGroup,

  };
};
const mapActionsToProps = {
  API_REQUEST: apiCallRequest,
  OPEN_DIALOG: uiPanelDialogOpen,
  SWITCH_FORM: uiSwitchForm,
  DRAWING_MODE : mapDrawingMode,
  CHANGE_MAP_TYPE: mapAreaType,
  RESET_MAP: mapResetMap,
  GET_POINT_DETAIL: apiGetCallRequest,
  DRAWING_OPTION: mapDrawingOptions,
  GET_LOGISTIC_DETAIL : getLogisticDetail,
  ADD_LOCATIONS : addLocation,
  SET_SNACKBAR
};

const enhance = compose(
  reduxForm({
    form: "manageForm",
    // validate
    initialValues: { min: '1', max: '10' }
  }),
  connect(
    mapStateToProps,
    mapActionsToProps
  ),
  withStyles(styles)
);
export default enhance(ManageForm);
