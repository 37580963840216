import React, { Component } from "react";
import {
  Marker,
  InfoWindow
} from "react-google-maps";
import { connect } from "react-redux";
import {
  mapSelectedMarker,
  mapMoveMarker,
  DEL_HAZARD_PHOTO,
  DEL_HAZARD_VIDEO,
  SET_AS_FIRST
} from "../../../reducers/map";

import compose from 'recompose/compose';

import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

import VideocamIcon from '@material-ui/icons/Videocam';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import CancelIcon from '@material-ui/icons/Cancel';
import AspectRatio from 'react-aspect-ratio';


import { uiSwitchForm } from '../../../reducers/ui';

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';

import Configs from "../../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
});

class HpMarker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_photo : true,
      selecting_photo : 0,
      is_selectingPhoto : true,
      photoIndex: 0,
      isOpen: false,
    }
  }
  componentWillUpdate(nextProps, nextState) {}
  selectInfo = arg => {
    this.props.MARKER_SELECTING(this.props.id);


    
  };

  onDragEnd = e => {

    this.props.position.lat = e.latLng.lat();
    this.props.position.lng = e.latLng.lng();
    this.props.MARKER_MOVE(this.props);
  };

  onPhotoClick = (e,index) => {
    this.setState({
      selecting_photo : index
    })
  }
  deletePhotoVDO = () => {
    if(this.state.is_selectingPhoto){
      let selecting_photo = this.state.selecting_photo
      this.setState({show_photo: null})
      setTimeout(()=> {
        this.props.DEL_HAZARD_PHOTO(selecting_photo)
        this.setState({show_photo: true, selecting_photo : 0})
      }, 100)
    }

    if(!this.state.is_selectingPhoto){
      this.props.DEL_HAZARD_VIDEO()
      this.setState({show_photo: true, is_selectingPhoto : true,  selecting_photo : 0})

    }
  }

  setAsFirst = () => {
    let selecting_photo = this.state.selecting_photo
    this.setState({show_photo: null})
    setTimeout(()=> {
      this.props.SET_AS_FIRST(selecting_photo)
      this.setState({show_photo: true, selecting_photo : 0})
    }, 100)
  }

  closeInfo = () => {
    this.props.MARKER_SELECTING(-1)
  }

  renderFullscreenButton = (onClick, isFullscreen) => {
    return (
      <button
        type='button'
        className={
          `image-gallery-fullscreen-button${isFullscreen ? ' active' : ''}`}
        onClick={() => this.setState({ isOpen: true })}
      />
    );
  }

  slideToIndex = () => {
    this.gallery.slideToIndex(this.state.selecting_photo)
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    let imageX = []

    let images = []
    let vdo = null
    if(this.props.hazard_photos.length > 0)
      this.props.hazard_photos.map((photo_path,index) => {
        imageX.push(
          Configs[env].BACKEND_HOST + "/getMedia?path=" + photo_path
        )

        images.push(
          {
            original: Configs[env].BACKEND_HOST + "/getMedia?path=" + photo_path,
            thumbnail: Configs[env].BACKEND_HOST + "/getMedia?path=" + photo_path,
            sizes: '(max-height: 50px)',
          }
        )
      })

    if(this.props.hazard_vdo !== null)
      vdo = Configs[env].BACKEND_HOST + "/getMedia?path=" +this.props.hazard_vdo

    const id = this.props.id;
    let selected = false;


    if (this.props.marker_selecting === id) {
      selected = true;
    }

    let icon = new window.google.maps.MarkerImage(
        "http://maps.google.com/mapfiles/ms/icons/blue.png",
        null /* size is determined at runtime */,
        null /* origin is 0,0 */,
        null /* anchor is bottom center of the scaled image */,
        new window.google.maps.Size(32, 32)
      );

    return (
        
      <Marker 
        position={this.props.position}
        icon={icon}
        key={this.props.key}
        id={this.props.id}
        onClick={this.selectInfo}
        markerCilck={this.selectInfo}
        mapClearInfo={this.props.mapClearInfo}
        onDragEnd={this.onDragEnd}
        draggable={this.props.editable}
        zIndex={this.props.zIndex}
        >
        {selected ? (
            <div>
                <div>
                  {isOpen && (
                    <Lightbox
                      mainSrc={imageX[this.state.selecting_photo]}
                      nextSrc={imageX[(this.state.selecting_photo + 1) % imageX.length]}
                      prevSrc={imageX[(this.state.selecting_photo + imageX.length - 1) % imageX.length]}
                      onCloseRequest={() => this.setState({ isOpen: false })}
                      onMovePrevRequest={() => {
                          this.setState({
                            selecting_photo: (this.state.selecting_photo + imageX.length - 1) % imageX.length,
                          })
                          setTimeout(()=> this.slideToIndex(), 100)
                          
                        }
                      }
                      onMoveNextRequest={() => {
                          this.setState({
                            selecting_photo: (this.state.selecting_photo + 1) % imageX.length,
                          })
                          setTimeout(()=> this.slideToIndex(), 100)
                        }
                      }
                    />
                  )}
              </div>
                <InfoWindow onCloseClick={this.closeInfo}>
                <table border={0}>
                    <tr valign="top">
                        { (images.length > 0 || vdo !== null) &&
                            <td rowspan="2">
                            <AspectRatio ratio="3/4" style={{maxWidth: '300px'}}>
                                {this.state.show_photo === true && 
                                    <ImageGallery
                                    showIndex={this.state.selecting_photo+1}
                                    lazyLoad={false}
                                    showPlayButton={false}
                                    items={images}
                                    showNav={false}
                                    onThumbnailClick={this.onPhotoClick}
                                    renderFullscreenButton={this.renderFullscreenButton}
                                    ref={input => (this.gallery = input)}
                                    />
                                  }
                                  {this.state.show_photo === false && 
                                    <Video autoPlay loop muted
                                        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
                                        poster="http://sourceposter.jpg"
                                        onCanPlayThrough={() => {
                                            // Do stuff
                                        }}>
                                        <source src={vdo} type="video/mp4" />
                                        <track label="English" kind="subtitles" srcLang="en" src="http://source.vtt" default />
                                    </Video>
                                  }
                                  { this.props.page!='list' &&  ( (this.state.is_selectingPhoto && this.props.hazard_photos.length > 0)
                                    ||
                                    (!this.state.is_selectingPhoto && this.props.hazard_vdo !== null) )
                                  &&
                                        <div style={{position:'absolute', left:'-6px', top:'-6px'}}>
                                            <IconButton 
                                            color="secondary"
                                            className={styles.button} aria-label="a"
                                            onClick={this.deletePhotoVDO}
                                            >
                                            <CancelIcon />
                                          </IconButton>
                                        </div>
                                  }
                                  {this.props.page!='list' && this.props.hazard_photos.length > 0 && this.state.selecting_photo > 0 &&
                                            <div style={{position: "absolute" ,left: "30px" , top: "8px"}}>
                                                <button onClick={this.setAsFirst}>Set as first</button> 
                                            </div>
                                  }
                                </AspectRatio>
                            </td>
                        }
                        <td>
                            <div style={{ width: "100px" }}>
                                <b>Location :</b><br/>
                                {this.props.info.name}<br/><br/>
                                <b>Detail :</b><br/>
                                {this.props.info.description}
                            </div>
                        </td>
                    </tr>
                    <tr align="center" height="50" valign="center">
                        <td>
                            {/* {!this.state.show_photo && this.props.hazard_photos.length > 0 && */}
                            <IconButton 
                              disabled={this.props.hazard_photos.length > 0 ? false : true}
                              color={this.state.show_photo ? "secondary" : "primary"} 
                              className={styles.button} aria-label="a"
                              onClick={()=>this.setState({ show_photo : true , selecting_photo : 0, is_selectingPhoto : true})}
                              >
                              <PhotoLibraryIcon />
                            </IconButton>

                            |
                            {/* // <img height='50' src='/img/photo.png' onClick={()=>this.setState({ show_photo : true , selecting_photo : 0, is_selectingPhoto : true})}/>
                            // } */}
                            {/* {this.state.show_photo && this.props.hazard_vdo !== null && */}
                            <IconButton 
                              disabled={this.props.hazard_vdo !== null ? false : true}
                              color={!this.state.show_photo ? "secondary" : "primary"} 
                              className={styles.button} aria-label="a"
                              onClick={()=>this.setState({ show_photo : false, selecting_photo : 0, is_selectingPhoto : false})}
                              >
                              <VideocamIcon />
                           </IconButton>
                            {/* // <button onClick={()=>this.setState({ show_photo : false, selecting_photo : 0, is_selectingPhoto : false})}>Show Video</button>
                            // <img onClick={()=>this.setState({ show_photo : false, selecting_photo : 0, is_selectingPhoto : false})} height='50' src='/img/vdo.png' />
                            } */}
                        </td>
                    </tr>
                </table>
                {/* <Button variant="fab" mini aria-label="Delete" 
                color="secondary"
                className={this.props.classes.button} 
                style={{position:'absolute',marginLeft:'-35px',marginTop:'-15px'}}
                onClick={()=>{this.onDelete(index)}}
                >
                <DeleteIcon/> 
                </Button>*/}
            
            </InfoWindow>
          </div>
        ) : null}
      </Marker>
    );
  }
}

const mapStateToProps = state => {
  return {
    marker_selecting: state.map.marker_selecting,
    hazard_photos : state.map.hazard_photos,
    hazard_vdo : state.map.hazard_vdo,
    page : state.ui.formManage.page
  };
};

const mapActionsToProps = {
  MARKER_SELECTING : mapSelectedMarker,
  MARKER_MOVE : mapMoveMarker,
  uiSwitchForm : uiSwitchForm,
  DEL_HAZARD_PHOTO : DEL_HAZARD_PHOTO,
  DEL_HAZARD_VIDEO ,
  SET_AS_FIRST
};

const enhance = compose(
  connect(
      mapStateToProps,
      mapActionsToProps
  ),
  withStyles(styles)
);  
export default enhance(HpMarker);
