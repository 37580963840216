import React, { Component } from "react";
import { setSidebarVisibility,uiSwitchForm } from "../../reducers/ui";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import uuidv4  from 'uuid/v4';
import SplitPane from 'react-split-pane';
import { Pane } from 'react-sortable-pane';
//Component
import BaseMap from "../Map/BaseMap";
import SearchForm from "./SearchForm";
import ManageForm from "./ManageForm";
import MasterRouteForm from "./MasterRouteForm";

//Reducer
import { searchReset } from '../../reducers/search';
import { mapSetLogisticRoutes } from '../../reducers/map';

const env = process.env.NODE_ENV;
const MIN_HEIGHT = 25;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  gridForm : {
    padding : '10px'
  },
  subheader: {
    width: '100%',
  },
});

class RouteMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  componentWillMount() {
    //this.props.searchReset(true);
    this.props.mapSetLogisticRoutes([]);
    //console.log('#componentWillMount search_logistics_route',this.props.search_logistics_route);
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;   
    console.log('#componentDidMount search_logistics_route',this.props.search_logistics_route);
    setSidebarVisibility(false);
  }
  autosizeHeaders(event) {
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        "#locationMap .ag-header-cell-label"
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach(cell => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }
  renderManageForm(){
    switch(this.props.formManage.page){
      case 'manage' :
        return (<ManageForm/>);
      case 'master_route' :
        return (<MasterRouteForm/>);
      default : 
      return (<SearchForm/>);
    }
  }
  methodFromParent(cell) {
    alert("Parent Component Method from " + cell + "!");
  }
  
  render() {
    return (
      <div style={{ display: 'flex', height:'calc(100vh - 52px)' }}>
        {/* <div
          id="routeMaster"
          style={{
            boxSizing: "border-box",
            height: "98%",
            width: "100%"
          }}
          className="ag-theme-balham"
        > */}
          <React.Fragment> 
            <SplitPane split="vertical" minSize={50} defaultSize={100}>
                  <Pane initialSize="400px" minSize="200px" key={"key_sklanlksadlas"} >
                     <div style={{height:'calc(100vh - 40px)',overflow:'auto'}}>
                        {this.renderManageForm()}
                     </div>
                  </Pane>
                  <Pane key={"key_sajkgdjk92b9c"}  >
                    <div style={{ height:'calc(100vh - 52px)' }}>
                          <BaseMap isReset={this.props.isReset}/>
                    </div>
                  </Pane>
              </SplitPane>
          </React.Fragment>  
        {/* </div> */}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  formManage : state.ui.formManage,
  search_logistics_route : state.search.search_logistics_route,
});

const mapDispatchToProps = dispatch => {
  return {
    setSidebarVisibility: status => dispatch(setSidebarVisibility(status)),
    searchReset: payload => dispatch(searchReset(payload)),
    mapSetLogisticRoutes :  payload => dispatch(mapSetLogisticRoutes(payload)),
    dispatch
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(RouteMaster);
