export default {
  development: {
    SOCKETIO_newlogin: "newlogin" ,
    SOCKETIO_loggedin: "loggedin",
    // SOCKETIO_newlogin: "newlogin_uat" ,
    // SOCKETIO_loggedin: "newlogin_uat",
    IDLE_TIMEOUT: 2 * 60 * 60 * 1000, // 2Hr
    LEAFTECH_HOST: "https://maps.leafte.ch",
    // BACKEND_HOST: "https://digital.map.api.dev.rtic-thai.info/api/v1", // TDEM
    // BACKEND_HOST: "https://digital.map.api.rtic-thai.info/api/v1",  // NETH
    //BACKEND_HOST: "https://digital.map.api.dev.rtic-thai.info/api/v1", // NETH
    // BACKEND_HOST: "https://digital.map.lsp.web.api.nexty.rtic-thai.info/api/v1",
    BACKEND_HOST: "http://localhost:6001/api/v1",
    //SOCKETIO_URL: "https://digital.map.api.dev.rtic-thai.info",
    SOCKETIO_URL: "https://digital.map.lsp.web.api.nexty.rtic-thai.info", 
    // BACKEND_HOST : "http://dm_dev1.leafte.ch:8888/https://digital.map.api.rtic-thai.info/api/v1", 
    // BACKEND_HOST: "http://digital.map.api.dev.rtic-thai.info:6001/api/v1",
    //BACKEND_HOST: "http://digital.map.api.dev.rtic-thai.info:5000/api/v1",
    CANDIDATE_HOST : "http://digital.map.route.rtic-thai.info/odRouting",
    // REROUTE_HOST : "http://digital.map.route.rtic-thai.info/sfvrp",
    REROUTE_HOST : "https://waypoint.digital.map.lsp.web.api.rtic-thai.info/v0.1/waypoint-routing",
    CANDIDATE_HOST : "https://waypoint.digital.map.lsp.web.api.rtic-thai.info/v0.1/waypoint-routing",
    //CANDIDATE_HOST : "https://tsq2.leafte.ch/odRouting",
    //CANDIDATE_HOST : "http://beta.tsq.api.rtic-thai.info/odRouting",
    //CANDIDATE_HOST : "http://digital.map.route.rtic-thai.info/odRouting",
    AUTHORIZATION : "1234567890-12345-12345678",
    // MAP_KEY : "AIzaSyAR8tDtV_F2TuYSqZHAkdAzG7g8Hif8Cz8"
    MAP_KEY : "AIzaSyCUCNt2DVRZWWB_1ep1KOb0W9Bdr_eiQMY"
  },
  production: {
    IDLE_TIMEOUT: 2 * 60 * 60 * 1000, // 2Hr
    // BACKEND_HOST: "https://digital.map.lsp.web.api.nexty.rtic-thai.info/api/v1", //Nexty dev
    BACKEND_HOST: "https://digital.map.lsp.api.web.rtic-thai.info/api/v1",  //Prod
    AUTHORIZATION : "1234567890-12345-12345678",
    // SOCKETIO_URL: "https://digital.map.lsp.web.api.nexty.rtic-thai.info", //Nexty dev
    SOCKETIO_URL: "https://digital.map.lsp.api.web.rtic-thai.info",  //Prod
    // CANDIDATE_HOST : "https://6awkolijvc.execute-api.ap-southeast-1.amazonaws.com/prod/odRouting",
    // REROUTE_HOST : "https://6awkolijvc.execute-api.ap-southeast-1.amazonaws.com/prod/sfvrp",
    CANDIDATE_HOST : "https://waypoint.digital.map.lsp.web.api.rtic-thai.info/v0.1/waypoint-routing",
    REROUTE_HOST : "https://waypoint.digital.map.lsp.web.api.rtic-thai.info/v0.1/waypoint-routing",
    MAP_KEY : "AIzaSyAR8tDtV_F2TuYSqZHAkdAzG7g8Hif8Cz8"
  }
};
