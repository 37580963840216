import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Polyline, Marker, InfoWindow } from "react-google-maps";

import { SET_MAP_LOADING } from "../../reducers/map";
import { markerPinNumberSvgIcon } from "./DistanceMatrix/Marker";

import { polygonColor } from "../../libs/color";

function DistanceMatrixMap(props) {
  const [selectedMarker, setSelectedMarker] = useState(null);

  const higlightSymbol = {
    zIndex: 1,
    strokeColor: "#2868FC",
    strokeOpacity: 1,
    strokeWeight: 4,
    icons: [],
  };

  useEffect(() => {
    if (props.locations.length > 0) {
      const notNullLocations = props.locations.filter(
        (location) => location && location.lat && location.lng
      );
      if (notNullLocations.length > 0) {
        props.setBound(notNullLocations);
      }
    }
  }, [props.locations]);

  return (
    <>
      {props.distanceMatrix.map((item, index) => (
        <div key={index}>
          {item.polyline && (
            <>
              <Polyline
                {...props}
                path={window.google.maps.geometry.encoding.decodePath(
                  decodeURIComponent(item.polyline)
                )}
                onClick={() => console.log("hi")}
                options={{
                  ...higlightSymbol,
                  strokeColor: polygonColor[index],
                }}
              />
            </>
          )}
        </div>
      ))}
      {props.locations.map(
        (item, index) =>
          item !== null && (
            <>
              <Marker
                key={index}
                position={{
                  lat: item.lat,
                  lng: item.lng,
                }}
                icon={markerPinNumberSvgIcon(
                  (index + 1).toString(),
                  30,
                  40,
                  polygonColor[index],
                  10
                )}
                onClick={() => setSelectedMarker(item.id)}
                zIndex={1}
              >
                {selectedMarker == item.id && (
                  <InfoWindow onCloseClick={() => setSelectedMarker(null)}>
                    <div>
                      <b>Information: </b>
                      <br />
                      {item.logisticsPoint
                        ? "Logistics Point : " + item.logisticsPoint
                        : ""}
                      <br />
                      <b>Name: </b>
                      <br />
                      {item.logisticsPoint}
                      <br />
                      <b>Description: </b>
                      <br />
                      {item.detail ? item.detail : ""}
                      <br />
                      <br />
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            </>
          )
      )}
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    distanceMatrix: state.map.distance_matrix,
    locations: state.map.distance_matrix_locations,
  };
};

const mapActionsToProps = {
  SET_MAP_LOADING,
};

export default connect(mapStateToProps, mapActionsToProps)(DistanceMatrixMap);
