import React, { Component } from "react";
import {
  Marker,
  InfoWindow
} from "react-google-maps";
import { connect } from "react-redux";
import {
  mapSelectedMarkerSearch,
  mapMoveMarker,
  mapSetMarkerPlace
} from "../../reducers/map";

import { uiSwitchForm } from '../../reducers/ui';

class MarkerPlace extends Component {
  componentWillUpdate(nextProps, nextState) {}

  selectInfo = arg => {
    // console.log('#selectInfo',this.props.id);
    this.props.mapSelectedMarkerSearch(this.props.id);
  };
  closeInfo = () => {
    this.props.mapSelectedMarkerSearch("")
  }
  handleClearSearchMarker = () => {
    // console.log('#handleClearSearchMarker');
    this.props.mapSetMarkerPlace(null)
  }
  render() {
    const id = this.props.id;
    let selected = false;

    let icon = new window.google.maps.MarkerImage(
      "/img/placesearch_marker.png",
      null /* size is determined at runtime */,
      null /* origin is 0,0 */,
      null /* anchor is bottom center of the scaled image */,
      new window.google.maps.Size(30, 40)
    );


    if (this.props.car_selecting === id) {
      selected = true;
    }

    return (
      <Marker 
        {...this.props}
        position={this.props.position}
        icon={icon}
        key={this.props.key}
        id={this.props.id}
        title={this.props.name || ''}
        zIndex={this.props.zIndex}
        onClick={this.selectInfo}
        markerCilck={this.selectInfo}
        >
        {(this.props.marker_search_selecting=='place_id' || selected) ? (
          <InfoWindow onCloseClick={this.closeInfo}>
            <div>
              {this.props.name || ''} <button onClick={this.handleClearSearchMarker}>Remove</button>
            </div>
          </InfoWindow>
        ) : ''}

      </Marker>
    );
  }
}

const mapStateToProps = state => {
  return {
    marker_search_selecting: state.map.marker_search_selecting
  };
};

const mapActionsToProps = {
  mapSelectedMarkerSearch,
  mapSetMarkerPlace,
  MARKER_MOVE : mapMoveMarker,
  uiSwitchForm : uiSwitchForm
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MarkerPlace);


// import React, { Component } from "react";
// import { render } from "react-dom";
// import { connect } from "react-redux";
// import CarMarker from "./CarMarker";

// class MarkerPlace extends Component {
//   render() {
//     let icon = new window.google.maps.MarkerImage(
//       "/img/placesearch_marker.png",
//       null /* size is determined at runtime */,
//       null /* origin is 0,0 */,
//       null /* anchor is bottom center of the scaled image */,
//       new window.google.maps.Size(30, 40)
//     );

//     return <CarMarker selected={false} {...this.props} icon={icon} zIndex={1} />;

//   }
// }

// export default MarkerPlace;
