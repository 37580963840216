import { select,takeLatest, call, put } from "redux-saga/effects";
import { 
  MAP_GET_LOGISTIC_ROUTES,
  getCandidateRoutes,
  getMasterRoutes,
  getMasterRouteDetail } from "../reducers/map/api";
import { 
  mapSetLogisticRoutes,
  mapSetCandidateRoutes,
  mapSetMasterRoutes,
  mapSetCandidatePolylines,
  mapSetMasterPolylines,
  mapSetMasterLegs,
  mapSetBounds,
  MAP_LOG,
  ADD_WAYPOINT,
  LOAD_MODIFIED_ROUTE_SUCCESS,
  MARKER_MOVE,
  DEL_MARKER,
  SET_MAP_LOADING,
  SET_MODIFYING_ROUTE_LOADING,
  SELECT_MASTER_OR_CANDIDATE,
  mapResetMap,
  MAP_RESET_MASTER_CANDIDATE,
  SET_USE_TOLL
} from "../reducers/map";

import axios from "axios";

import { formMasterRoute } from "../reducers/ui/mapForm";
import Configs from "../config/config";
const env = process.env.NODE_ENV;

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {

  yield takeLatest(MAP_GET_LOGISTIC_ROUTES, logisticRoutesWorkerSaga);
  yield takeLatest(getCandidateRoutes, candidateRoutesWorkerSaga);
  yield takeLatest(getMasterRoutes, masterRoutesWorkerSaga);
  //yield takeLatest(getMasterRouteDetail, masterRouteDetailWorkerSaga);
  yield takeLatest(formMasterRoute,masterRouteDetailWorkerSaga);

  //----- Route Modifying
  yield takeLatest(ADD_WAYPOINT, loadNewPaths);
  yield takeLatest(MARKER_MOVE, loadNewPaths);
  yield takeLatest(DEL_MARKER, loadNewPaths);
  yield takeLatest(SET_USE_TOLL, loadNewPaths);
  
  
}

function fetchPaths(state) {


  let paths_neth = [];
  state.waypoints.map((point, i) => {

    // paths_neth.push({lat : point.position.lat, lng : point.position.lng});
    paths_neth.push(new window.google.maps.LatLng(point.position.lat, point.position.lng))
  });



  let encodedURI = encodeURIComponent(window.google.maps.geometry.encoding.encodePath(paths_neth));




  
  const URL = Configs[env].REROUTE_HOST;
  const QUERY =
    "?date_time=2018-11-22T08:00:00&polyline=" +
    encodedURI +
    "&service_time=0&toll=" + state.use_toll + "&vehicle_type=8&user=leaftech";

  return axios
    .get(URL + QUERY, { timeout: 60000})
    .then(resp => {
      

      //------------
      if(resp.data.hasOwnProperty("errorList")){

        alert(resp.data.errorList[0].errorMessage)
        return null
      } else {
        if(!resp.data.hasOwnProperty("list")){

          alert(resp.data)
          return null
        } else {


          // let legs = resp.data[1].Plan[0].Legs;
          let modified_route = resp.data.list[0];

          return modified_route
        }
      }
    })
    .catch(error => {
      return state.map.legs;
      // })
    });
}

function* loadNewPaths() {
  yield put({ type: SET_MAP_LOADING, payload: true });

  const state = yield select();

  // yield put({ type: SET_MODIFYING_ROUTE_LOADING, payload: true });
  try {

    const modified_route = yield call(fetchPaths, state.map);

    if(modified_route !== null) {
      yield put({ type: MAP_LOG, payload: modified_route })

      let legs = setLegs(modified_route.Legs,false);
      let TollUsage = modified_route.TollUsage
      yield put({ type: LOAD_MODIFIED_ROUTE_SUCCESS, payload: { legs : legs, modified_route : modified_route , TollUsage : TollUsage } });
    }
    yield put({ type: SET_MAP_LOADING, payload: false });

  } catch (error) {
    yield put({ type: SET_MAP_LOADING, payload: false });
    // yield put({ type: SET_MODIFYING_ROUTE_LOADING, payload: false });
  }
}

// function that makes the api request and returns a Promise for response
function fetchData(params) {  

  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache': "no-cache",
    'Authorization': Configs[env].AUTHORIZATION,
    'token':localStorage.getItem('token'),
  };
  let callback = params.payload.callback;
  return fetch(params.payload.url, {
    method: "POST",
    body: JSON.stringify({
      ...params.payload.filter
    }),
    headers: headers,
  })
  .then(resp=> {
    // Here you get the data to modify as you please

    return resp.json()
  })
  .then(data=>{

    if(callback) callback(data);
    return data;
  })
  .catch(function(error) {
    // If there is any error you will catch them here
    return error
  }); 
}
function fetchDataGetNoHeader(params) {  

  let url = params.payload.url+'?'+paramsUrl(params.payload.filter);
  let callback = params.payload.callback;
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache': "no-cache",
    'Authorization': Configs[env].AUTHORIZATION,
    'token':localStorage.getItem('token'),
  };
  return axios
    .get(url , { timeout: 60000})
    .then(resp => {
      if(resp.data.hasOwnProperty("errorList")){
        alert(resp.data.errorList[0].errorMessage)
        return null
      } else {
        if(!resp.data.hasOwnProperty("list")){
          alert(resp.data)
          return null
        } else {
          if(callback) callback(resp.data);
          return resp.data
        }
      }
    })
    .catch(error => {
      alert(error)
      return null;
    });
}
function fetchDataGet(params) {  
  let url = params.payload.url+'?'+paramsUrl(params.payload.filter);
  let callback = params.payload.callback;
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache': "no-cache",
    'Authorization': Configs[env].AUTHORIZATION,
    'token':localStorage.getItem('token'),
  };
  return axios
    .get(url , { headers: headers , timeout: 60000})
    .then(resp => {
      if(resp.data.hasOwnProperty("errorList")){
        alert(resp.data.errorList[0].errorMessage)
        return null
      } else {
        if(!resp.data.hasOwnProperty("list")){
          alert(resp.data)
          return null
        } else {
          if(callback) callback(resp.data);
          return resp.data
        }
      }
    })
    .catch(error => {
      alert(error)
      return null;
    });
  // return fetch(url, {
  //   method: "GET",
  //   //mode : 'no-cors',
  //   headers: headers,
  // })
  // .then(resp=> {
  //   // Here you get the data to modify as you please

  //   return resp.json()
  // })
  // .then(data=>{

  //   if(callback) callback(data);
  //   return data;
  // })
  // .catch(function(error) {
  //   // If there is any error you will catch them here
  //   return error
  // }); 
}
function paramsUrl(data) {
  return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
}
// worker saga: makes the api call when watcher saga sees the action
function* locationPointWorkerSaga(action) {
  try {
    const state = yield select();
    const response = yield call(fetchData, action);

    // dispatch a response action
    //yield put({ type: mapSetLogisticPoint, ...response });
  } catch (error) {
    // dispatch a failure action to the store with the error
    //yield put({ type: mapAction.authLoginError, error });


  }
}

// worker saga: makes the api call when watcher saga sees the action
function* logisticRoutesWorkerSaga(action) {
  yield put({ type: MAP_LOG, payload: "before loading" })
  yield put({ type: SET_MAP_LOADING, payload: true })
  yield put({ type: mapResetMap, payload: true })
  yield put({ type: MAP_RESET_MASTER_CANDIDATE, payload: true })
  yield put({ type: MAP_LOG, payload: "after loading" })

  try {

    const state = yield select();
    const response = yield call(fetchDataGet, action);

    if(response !== null) {

      yield put({ type: mapSetLogisticRoutes, payload:response.list });
    }
    yield put({ type: SET_MAP_LOADING, payload: false });

  } catch (error) {
    // dispatch a failure action to the store with the error
    //yield put({ type: mapAction.authLoginError, error });

    yield put({ type: SET_MAP_LOADING, payload: false });

  }
}

// worker saga: makes the api call when watcher saga sees the action
function* candidateRoutesWorkerSaga(action) {
  try {

    yield put({ type: SET_MAP_LOADING, payload: true });
    const state = yield select();
    const response = yield call(fetchDataGetNoHeader, action);
    if(response !== null) {


      //Set polyline
      let polylines = setPolylines(response.list,false);


      let bounds = setBounds(response.list);

      yield put({ type: mapSetCandidateRoutes, payload:response.list });
      yield put({ type: mapSetCandidatePolylines, payload:polylines });
      yield put({ type: mapSetBounds, payload:bounds });

      yield put({ type: SELECT_MASTER_OR_CANDIDATE, payload:2 });
    }

    yield put({ type: SET_MAP_LOADING, payload: false });
  } catch (error) {
    // dispatch a failure action to the store with the error
    //yield put({ type: mapAction.authLoginError, error });


    yield put({ type: SET_MAP_LOADING, payload: false });

  }
}

// worker saga: makes the api call when watcher saga sees the action
function* masterRoutesWorkerSaga(action) {
  try {
    yield put({ type: SET_MAP_LOADING, payload: true });

    const state = yield select();
    const response = yield call(fetchDataGet, action);
  

    if(response !== null) {

      //Set polyline
      let polylines = setPolylines(response.list,false);


      let bounds = setBounds(response.list);


      yield put({ type: mapSetMasterRoutes, payload:response.list });
      yield put({ type: mapSetMasterPolylines, payload:polylines });
      yield put({ type: mapSetBounds, payload:bounds });

      yield put({ type: SELECT_MASTER_OR_CANDIDATE, payload:1 });
    }
    yield put({ type: SET_MAP_LOADING, payload: false });

  } catch (error) {
    // dispatch a failure action to the store with the error
    //yield put({ type: mapAction.authLoginError, error });
    yield put({ type: SET_MAP_LOADING, payload: false });

    

  }
}
// for Add/Edit Waypoints
function* masterRouteDetailWorkerSaga(action) {
  try {
    yield put({ type: SET_MAP_LOADING, payload: true });

    const state = yield select();
    //const response = yield call(fetchDataGet, action);
    
    let response = state.mapForm.masterRouteForm;
    let legs = setLegs(response.routes.Legs,false);

    let waypoints = []
    legs.map((leg,index) => {
      waypoints.push({ position : { lat : leg.path[0].lat() , lng : leg.path[0].lng() } })
    })
    
    let last_leg_idx = legs.length - 1
    let last_path_idx = legs[last_leg_idx].path.length - 1

    waypoints.push(
      { position : 
        { 
            lat : legs[last_leg_idx].path[last_path_idx].lat() , 
            lng : legs[last_leg_idx].path[last_path_idx].lng() 
          } 
      })


    // yield put({ type: mapSetCandidateRoutes, payload: {} });
    // yield put({ type: mapSetCandidatePolylines, payload: {} });

    yield put({ type: mapSetMasterLegs, payload: { legs : legs, waypoints : waypoints } });
    let bounds = setBoundsWaypoints(response.routes.Legs)
  

    yield put({ type: mapSetMasterPolylines, payload:waypoints });
    yield put({ type: mapSetBounds, payload:bounds });

    yield put({ type: SET_MAP_LOADING, payload: false });

  } catch (error) {
    // dispatch a failure action to the store with the error
    //yield put({ type: mapAction.authLoginError, error });
    yield put({ type: SET_MAP_LOADING, payload: false });


  }
}

/////// POLYLINE /////////
function setPolylines(data,editable){

  let candidatePolylineList = [];
  let polyline = {};
  if(data){
      data.forEach((item, i) => {
        let id = 0;
        if(item.id) id = item.id
        else id = item.Vehicle; 

        if(item.routes) item = item.routes;

        //if(item.type=='polyline'){
          var decodePath = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(item.MergeRoute ? item.MergeRoute : item.Route));
          var paths = [];

          decodePath.forEach(function(p) {
            paths.push({ lat: p.lat(), lng: p.lng()});
          });

          polyline = {
            id : id,
            path : decodePath,
            info : {
              name : item.name,
              description : item.detail,
              logisticsPoint : item.logisticsPoint ,
            },
            isGroup : false,
            editable : editable
          };
          //candidatePolylineList.push(polyline);
          candidatePolylineList.push(polyline);
        //}
      });
      return candidatePolylineList;
  }
}

function setLegs(data,clickable){

  let candidatePolylineList = [];
  let polyline = {};
  if(data){
      data.forEach((item, i) => {
        //if(item.type=='polyline'){
          var decodePath = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(item.Route));
          var paths = [];
          decodePath.forEach(function(p) {
            paths.push({ lat: p.lat(), lng: p.lng()} );
          });
          polyline = {
            id : item.Seq,
            path : decodePath,
            info : {
              name : item.Seq,
              description : '',
              logisticsPoint : '' ,
              clickable : clickable
            }
          };
          //candidatePolylineList.push(polyline);
          candidatePolylineList.push(polyline);
        //}
      });
      return candidatePolylineList;
  }
}

function setBounds(data){
  let bounds = [];
  if(data){
      data.forEach((item, i) => {
          if(item.routes) {
            item = item.routes;
          }
          var decodePath = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(item.MergeRoute ? item.MergeRoute : item.Route));

          decodePath.forEach(function(p) {
            bounds.push({ lat: p.lat(), lng: p.lng()} );
          });
      });
  }
  return bounds;
}


function setBoundsWaypoints(Legs){
  let bounds = [];
  if(Legs){
      Legs.forEach((item, i) => {
          var decodePath = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(item.Route));

          decodePath.forEach(function(p) {
            bounds.push({ lat: p.lat(), lng: p.lng()} );
          });
      });
  }
  return bounds;
}

