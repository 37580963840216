
import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import { Field } from 'redux-form';
import axios from 'axios';

import {
  //uploadFile,
  putImageFiles,
  putVideoFiles,
  resetImageFiles,
  resetVideoFiles,
  uploadFile,
} from '../../reducers/upload';
import { uiAlertOpen,SET_SNACKBAR } from '../../reducers/ui/index'
import { 
  SET_MAP_LOADING,
  ADD_HAZARD_PHOTO,
  ADD_HAZARD_VDO,
  mapSelectedMarker,
} from "../../reducers/map";

import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 5,
    marginRight: 5,
    width: 80,
    height: 80,
    padding: 4,
    boxSizing: 'border-box'
  };
  
  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  }
  
  const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
  };
  
  const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    extendedIcon: {
      marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
  });

  const renderHiddenField = (field) => {
    return (
    <div className="input-row">
      <input {...field.input} type="hidden" value={field.value}/>
    </div>
  )}
  class UploadFileInput extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        maxFiles : this.props.maxFiles || 4,
        files: [],
        fileType : this.props.fileType === "image" ? "image/*" : "video/*"
      };
      this.filePreview =[];
    }
    componentWillMount(){

    }
    componentWillReceiveProps(nextProps){

    }
    // onDrop(files) {

    // }
    onDrop = (files)  => {

      //---- count photo
      let count_new_photos = 0
      
      files.map((file)=>{
          if(file.type === "video/mp4"){
              
          } else {
            count_new_photos++
          }
      })

      if(this.props.hazard_photos.length + count_new_photos > 4) {
        this.props.SET_SNACKBAR({ 
          snackbarOpen: true,
          snackbarVariant: "error",
          snackbarMessage: "Uploading photos reach the maximum number of 4",
          snackbarDuration: 20000
        })
      } else {
          this.props.SET_MAP_LOADING(true)
          const uploaders = files.map(file => {

            let self = this
            // Initial FormData
            const formData = new FormData();
            formData.append("files", file);
            // formData.append("tags", `codeinfuse, medium, gist`);
            // formData.append("upload_preset", "pvhilzh7"); // Replace the preset name with your own
            // formData.append("api_key", "1234567"); // Replace API key with your own Cloudinary key
            // formData.append("timestamp", (Date.now() / 1000) | 0);

            let url = Configs[env].BACKEND_HOST +"/uploadImage";

            let fileType = file.type;

            if(fileType === "video/mp4"){
              //url = Configs[env].BACKEND_HOST+"/api/v1/uploadVideo";
              url = Configs[env].BACKEND_HOST +"/uploadVdo";
            }
            // Make an AJAX upload request using Axios (replace Cloudinary URL below with your own)
            return axios.post(url, formData, {
              headers: {
                // 'content-type': 'multipart/form-data',
                'Content-Type': 'application/x-www-form-urlencoded',
                //'Content-Type': 'application/json',
                // "X-Requested-With": "XMLHttpRequest" ,
                // 'Cache': "no-cache",
                'Authorization': Configs[env].AUTHORIZATION,
                // Configs[env].AUTHORIZATION,
                'token':localStorage.getItem('token'),
                //'Content-Length': fileSizeInBytes
              },
            }).then(response => {
              const data = response.data;

              if(data.hasOwnProperty("errorList")){
                // alert(data.errorList[0].errorMessage)
                this.props.SET_SNACKBAR({ 
                  snackbarOpen: true,
                  snackbarVariant: "error",
                  snackbarMessage: data.errorList[0].errorMessage,
                  snackbarDuration: 20000
                })
              } else {
          
                if(fileType === "video/mp4")
                  self.props.ADD_HAZARD_VDO(data.list[0])
                else
                  self.props.ADD_HAZARD_PHOTO(data.list[0])
                  this.props.MARKER_SELECTING(this.props.markerList[0].id)
              } 
            }).catch(error => {
              // alert(error)
              this.props.SET_SNACKBAR({ 
                snackbarOpen: true,
                snackbarVariant: "error",
                snackbarMessage: error,
                snackbarDuration: 20000
              })
              this.props.SET_MAP_LOADING(false)
            })

          });
        
          // Once all the files are uploaded 
          axios.all(uploaders).then(() => {

            this.props.SET_MAP_LOADING(false)
            // ... perform after upload is successful operation
          });
        }
      // files.map((file,index)=>{
      //     if(this.filePreview.length < this.state.maxFiles){
            // this.props.uploadFile({
            //   file: files,
            // })
              // fileType : this.state.fileType,
              // callback:(result)=>{
              //   //debugger
              //   if(result.success){
              //     this.filePreview.push({
              //       preview : result.result
              //     })
              //     this.setState({files : this.filePreview});
              //   }
              //   else{
              //     this.props.uiAlertOpen({
              //       open: true, 
              //       message : result.error.errorMessage || "Something wrong... can't upload", 
              //       title : "Error "+result.error.errorCode || "", 
              //       type: "warning",
              //     })
              //   }
              // }}

          // }
          // else{
          //     this.props.uiAlertOpen({
          //         open: true, 
          //         message : "Max files input is "+this.state.maxFiles+" please remove", 
          //         title : "Max file length", 
          //         type: "confirm"
          //     })
          // }
 
      // })
    }
    onDelete(id){
        this.filePreview.splice(id,1);
        this.setState({
            files: this.filePreview
        })
    }
    onHandleUploadFiles(){
        if(this.props.onHandleUploadFiles !== undefined){
            this.props.onHandleUploadFiles();
        }
        else{

        }
    }
    componentWillUnmount() {
      // // Make sure to revoke the data uris to avoid memory leaks
      // const {files} = this.state;
      // for (let i = files.length-1; i >= 0; i--) {
      //   const file = files[i][0];
      //   URL.revokeObjectURL(file.preview);
      // }

      // if(this.props.fileType === "image")
      //   this.props.resetImageFiles();
      // else
      //   this.props.resetVideoFiles();
    }
  
    render() {
      const {files} = this.state;
    
    //   const listName = files.map((file,index) => (
    //     <li key={index}>{file[0].name}</li>
    //   ));

      //this input for redux submit
      const inputs = files.map((file,index) => {
        return(
        <div id="genInputForm" key={index}>
          
          {
            
            this.props.fileType === "image"
            ? <Field name={"fileImageName_"+index} props={{value:file.preview}} component={renderHiddenField}/>
              : 
              <Field name={"fileVideoName_"+index} props={{value:file.preview}} component={renderHiddenField}/>
          }
        </div>
      )});
      const thumbs = files.map((file,index) => (
        <div key={index}>
            {
                this.props.fileType === "image" ? 
                  <div style={thumb} >
                    <div style={thumbInner}>
                        <img
                      src={file.preview}
                      style={img}
                      />
                    </div>
                  </div>
                :
                <video width="220" height="140" controls>
                  <source src={file.preview} type="video/mp4" />
                </video>
              }
            
            <Button variant="fab" mini aria-label="Delete" 
                color="secondary"
                className={this.props.classes.button} 
                style={{position:'absolute',marginLeft:'1px',marginTop:'1px'}}
                onClick={()=>{this.onDelete(index)}}
                >
                <DeleteIcon/>
            </Button>
        </div>
      ));
  
      return (
        <div>
          
          {/* { inputs } */}
          
            <div className="dropzone">
                {this.props.markerList.length > 0 && !this.props.markerList[0].id &&
                  <div style={{position: 'relative',width: '180px', height: '40px' ,borderWidth: '2px', borderColor: 'rgb(102, 102, 102)', borderStyle: 'dashed',borderRadius:'5px'}}>
                    <Button size="small" color="primary" aria-label="Save" disabled={true} >
                              Please pin the point first
                              <CloudUploadIcon className={this.props.classes.rightIcon} />
                          </Button>
                  </div>
                }
                {this.props.markerList.length > 0 && this.props.markerList[0].id &&
                <Dropzone
                  disable={true}
                  maxLength={this.state.maxFiles}
                  multiple
                  // accept={this.state.fileType}
                  accept="image/jpeg, video/mp4"
                  onDrop={this.onDrop.bind(this)}
                  style={{position: 'relative',width: '180px', height: '40px' ,borderWidth: '2px', borderColor: 'rgb(102, 102, 102)', borderStyle: 'dashed',borderRadius:'5px'}}
                >
                      <Button size="small" color="primary" aria-label="Save" >
                          Click To Upload Photos/VDO
                          <CloudUploadIcon className={this.props.classes.rightIcon} />
                      </Button>
                </Dropzone>
                }
                <aside style={thumbsContainer}>
                    {thumbs}
                </aside>

                {/* <div>
                    {listName}
                </div> */}
            </div>
          
        </div>
      );
    }
  }
  UploadFileInput.propTypes = {
    maxFiles: PropTypes.number,
    fileType : PropTypes.string,
    onHandleUploadFiles : PropTypes.func,

  };

const mapStateToProps = function(state,ownProps) {

    return {
      markerList : state.map.markerList,
      hazard_photos : state.map.hazard_photos,
    }
}
const mapActionsToProps =  {
  putImageFiles,
  putVideoFiles,
  resetImageFiles,
  resetVideoFiles,
  uiAlertOpen,
  uploadFile,
  SET_MAP_LOADING,
  ADD_HAZARD_PHOTO,
  ADD_HAZARD_VDO,
  SET_SNACKBAR,
  MARKER_SELECTING : mapSelectedMarker,
};

const enhance = compose(
    connect(
        mapStateToProps,
        mapActionsToProps
    ),
    withStyles(styles)
);  
export default enhance(UploadFileInput);