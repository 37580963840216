import React, { Component } from "react";
import moment from "moment";
import {
  propTypes,
  reduxForm,
  Form,
  Field,
  formValueSelector
} from "redux-form";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import compose from "recompose/compose";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
//import momentLocaliser from 'react-widgets/lib/localizers/moment'
import "react-widgets/dist/css/react-widgets.css";
import CircularProgress from '@material-ui/core/CircularProgress';
//Component
import Loading from "../../Ui/Loading";
//Lib
import { required, number,email,match } from "../../../libs/validation";
//Reducer
import { apiCallRequest } from "../../../reducers/api";
import { getAction,getProblem } from "../../../reducers/setting/api";
import { formAction } from "../../../reducers/ui/mapForm";
import { settingOpenAction,settingActionSelect } from "../../../reducers/setting";

//Config
import Configs from "../../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth : '200px'
  },
  formControl: {
    margin: theme.spacing.unit
  },
  center:{
    position: 'relative',
    left: '20px'
  }
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);
const renderSelect = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <Select
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ActionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open : true
    };
  }

  componentWillMount() {
    
    
  }
  componentWillReceiveProps(nextProps) {

    this.renderProblemItem();
    if (this.props.actionForm!=nextProps.actionForm) {
      // console.log('#componentWillReceiveProps actionForm',nextProps.actionForm);
      this.props.initialize({...nextProps.actionForm,problemId:nextProps.problem_selecting.id});
    }
  }
  //Group Action
  getProblemItem() {

    this.props.getProblem({
      url: Configs[env].BACKEND_HOST + "/actionGroup",
      method : "GET",
      filter: {  },
    });
  }
  renderProblemItem() {
    if (this.props.setting_problem) {

      var menuItem = this.props.setting_problem;
      return menuItem.map(function(data, index) {
        return (
          <MenuItem key={index} value={data.id} detail={data.name}>
            {data.name}
          </MenuItem>
        );
      });
    } else return null;
  }
  handleSave = (form) =>{
    //debugger
    this.props.settingOpenAction(false);
    let formData = {...form};
    
   
    if (formData.id) {
      delete(formData.password);
      let id = formData.id;delete(formData.id);
      this.props.API_REQUEST({
        url: Configs[env].BACKEND_HOST + "/action/"+id,
        form: {...formData },
        method : "PUT",
        callback: this.handleCancel
      });
    } else {
      this.props.API_REQUEST({
        url: Configs[env].BACKEND_HOST + "/action",
        form: { ...formData},
        method : "POST",
        callback: this.handleCancel
      });
    }
    
  }
  handleChange = event => {
    if(event.target.name=="problemId"){
      this.setState({ [event.target.name]: event.target.value });
    }
  };
  handleCancel = () => {
    // console.log('#handleCancel problemId',this.props.problem_selecting)
    if(this.props.problem_selecting.id){
      this.props.getAction({
        url: Configs[env].BACKEND_HOST + "/actionCond",
        filter: {
          problemId :  this.props.problem_selecting.id
        }
      });
    }
    this.props.reset();
    this.props.formAction(null);
    //this.props.settingActionSelect(null);
    this.props.settingOpenAction(false);
  }
  render() {
    const { handleSubmit, submitting,classes } = this.props;

    return (
      <div style={{ padding: "10px" }}>
        {/*this.props.isLoading && <Loading />*/}
        
          <Dialog
            open={this.props.action_dialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleCancel}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle id="alert-dialog-slide-title">
                {this.props.actionForm && this.props.actionForm.id ? "EDIT ACTION" : "ADD ACTION"}
            </DialogTitle>
            <DialogContent>
            <Form 
                  //style={{ overflowY:"scroll"}}
                  autoComplete={"off"}
                  autoCorrect={"off"}
                  spellCheck={"off"}
                  onSubmit={handleSubmit(this.handleSave.bind(this))}
              >
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <FormControl fullWidth className={styles.formControl}>
                    <InputLabel shrink htmlFor="problemaction-field">
                    Problem
                    </InputLabel>
                    <Field
                        onChange={this.handleChange}
                        name="problemId"
                        fullWidth
                        component={renderSelect}
                        label="Problem"
                        InputLabelProps={{
                          shrink: true,
                          id: "problemaction-field"
                        }}
                        disabled={true}
                        margin="dense"
                        className={styles.textField}
                      >
                    {this.renderProblemItem()}
                    </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name='name'
                      component={renderInput}
                      className={styles.textField}
                      label='Action'
                      validate={[required]}
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <DialogActions>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        aria-label="Save"
                        className={styles.button}
                        disabled={this.props.isLoading}
                        type="submit">
                        {this.props.isLoading && <CircularProgress className={classes.center} size={25} thickness={2} />}
                        SAVE
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        aria-label="Close"
                        disabled={this.props.isLoading}
                        className={classNames(styles.button, styles.cssGreen)}
                        onClick={this.handleCancel}
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
            
          </Dialog>
        
      </div>
    );
  }
}
const mapStateToProps = function(state, ownProps) {
  return {
    isLoading: state.ui.isLoading,
    action_selecting : state.setting.action_selecting,
    action_dialog : state.setting.action_dialog,
    actionForm: state.mapForm.actionForm,
    setting_problem : state.setting.setting_problem,
    problem_selecting: state.setting.problem_selecting,
    
  };
};
const mapActionsToProps = {
  API_REQUEST: apiCallRequest,
  settingOpenAction,settingActionSelect,
  getAction,getProblem,
  formAction
};

const enhance = compose(
  reduxForm({
    form: "actionForm",
  }),
  connect(
    mapStateToProps,
    mapActionsToProps
  ),
  withStyles(styles)
);
export default enhance(ActionDialog);
