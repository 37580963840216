import React, {Component} from "react";
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { uiSwitchForm } from '../../../reducers/ui';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

//Reducer
import { 
    dashboardOpenProblemDialog,
    dashboardOpenActionDialog 
} from "../../../reducers/dashboard";
import { formOperation } from "../../../reducers/ui/mapForm";

const styles = theme => ({
    gridButton: {
        padding: '0px 0px',
        minWidth: '50px',
        fontSize: '0.8em',
        minHeight: '20px'
    },
    iconStyle :{
        padding:0,
    }
})

class ProbActionFieldRenderer extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            value: props.value,
            title : 'Test',
            isYard: props.data.isYard.value
        }
    }
    openProblemActionDialog = (event) => {
        
        if(this.props.colDef.field=="problem.value"){

            this.props.dashboardOpenProblemDialog(true);
            this.props.formOperation({...this.props.data,colDef : this.props.colDef});
        }
        if(this.props.data.problem.value!="" && this.props.colDef.field=="action.value"){

            this.props.dashboardOpenActionDialog(true);
            this.props.formOperation({...this.props.data,colDef : this.props.colDef});
        }
    }
    render() {
        let canEdit = false

        if(this.props.colDef.field === "problem.value" && this.props.data.problem.editable === 1) 
            canEdit = true
        
        if(this.props.colDef.field === "action.value" 
            && this.props.data.action.editable === 1 
            && this.props.data.problem.value!="") 
            canEdit = true

        return (
            <div class="something" onClick={canEdit ? this.openProblemActionDialog : null} styles={{width:'100%',height:'100%'}}>
                {canEdit && <div class="arrow-right"></div>}
                <span>{this.state.value ? this.state.value : '-' }</span>
            </div>
        );
    }
};
const mapStateToProps = function(state,ownProps) {
    return {
        ...ownProps,
        dashboard_time_dialog : state.dashboard.dashboard_time_dialog
    }
};

const mapActionsToProps =  {
    dashboardOpenProblemDialog,
    dashboardOpenActionDialog,
    formOperation
};
const enhance = compose(
    connect(
        mapStateToProps,
        mapActionsToProps
    ),
    withStyles(styles)
);  

export default enhance(ProbActionFieldRenderer)
