import React, { Component } from "react";
import { render } from "react-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline
} from "react-google-maps";


import {
    MAP_SELECT_SUGGESSION,
    MARKER_SELECTING,
    SET_DATA_FOR_EDIT_ROUTE,
    SET_LEG_EDITING
  } from "../../../reducers/map";

import { getEditRoute } from "../../../reducers/map/api";

import { compose, withProps, withStateHandlers } from "recompose";
import { connect } from "react-redux";

//Config
import Configs from "../../../config/config";
const env = process.env.NODE_ENV;

// {{ lat: -34.397, lng: 150.644 }}

class RsPolyline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mouseOver : false,
    };
  }

  // selectInfo = arg => {
  //   this.props.candidate_selecting(this.props.id);


  // };

  // onDragEnd = e => {
  //   this.props.position.lat = e.latLng.lat();
  //   this.props.position.lng = e.latLng.lng();
  //   this.props.MARKER_MOVE(this.props);
  // };

  onMouseOver = e => {
    if(this.props.suggestion_editing === this.props.route_id) {
      this.setState({mouseOver : true})
      console.log(`KAI r=${this.props.route_id} l=${this.props.leg_id} s=${this.props.sub_idx}`)
    }
  };
  onMouseOut = e => {
    this.setState({mouseOver : false})
  };

  addWaypoint = e => {
    if(this.props.suggestion_editing === this.props.route_id) {
       this.props.MARKER_SELECTING(-1)
        // let new_waypoint = {
        //   id: this.props.id,
        //   position: { lat: e.latLng.lat(), lng: e.latLng.lng() }
        // };
        // // this.props.ADD_WAYPOINT(new_waypoint);
        // // let route_info = {
        // //     routeId : this.props.routeId,
        // //     route_id : this.props.route_id,
        // //     leg_id : this.props.leg_id,
        // //     seq_id : this.props.seq_id
        // // }
        // // console.log("Kai addWaypoint",e);
        // // console.log("Kai addWaypoint",new_waypoint);
        // // console.log("Kai this.props",this.props);

        let data_1_ForEditRoute = this.props.dataForEditRoute[this.props.route_id]
        
        // console.log("KAI idx",this.props.sub_idx)
        let sub_idx = this.props.sub_idx;
        let position = { lat: e.latLng.lat(), lng: e.latLng.lng() };

        var copyWP = data_1_ForEditRoute.Legs[this.props.leg_id].waypoints.slice();

        // if(copyWP.length> 2)
        if(copyWP.length < 3 || this.props.leg_editing === this.props.route_id + "_" + this.props.leg_id) {
            copyWP.splice(sub_idx + 1, 0, position);
        }
        console.log(`KAI this.props.leg_editing ${this.props.leg_editing}`)
        this.props.SET_LEG_EDITING(this.props.route_id + "_" + this.props.leg_id)

        // console.log("Kai waypoints",data_1_ForEditRoute.Legs[this.props.leg_id].waypoints);

        data_1_ForEditRoute.Legs[this.props.leg_id].waypoints = copyWP

        data_1_ForEditRoute.Legs.map((val,idx)=>{
          data_1_ForEditRoute.Legs[idx].isChangeRoute = false
        })

        // console.log("Kai addWaypoint id",id);
        // console.log("Kai addWaypoint",data_1_ForEditRoute);

        // console.log("Kai copyWP",copyWP);
        

        //=================
        
        let tmp_paths = [];
        copyWP.map((point, i) => {
          tmp_paths.push(new window.google.maps.LatLng(point.lat, point.lng))
        });

        let encodedURI = window.google.maps.geometry.encoding.encodePath(tmp_paths);
        // console.log("Kai encodedURI",encodedURI);

        data_1_ForEditRoute.Legs[this.props.leg_id].polyline = encodedURI
        data_1_ForEditRoute.Legs[this.props.leg_id].isChangeRoute = true
        console.log("Kai data_1_ForEditRoute", data_1_ForEditRoute)
        console.log("Kai addWaypoint waypoints",data_1_ForEditRoute.Legs[this.props.leg_id].waypoints)

        let post_data = JSON.parse(JSON.stringify(data_1_ForEditRoute));

        post_data.Legs.map((leg,idx) => {
          delete(post_data.Legs[idx].waypoints)
        })

        console.log("KAI 1",this.props.shiftSeq)

        if(this.props.shiftSeq !== null && this.props.shiftSeq !== '')
            post_data.shiftNo = this.props.shiftSeq
        else
            post_data.shiftNo = null

        console.log("KAI 1",post_data)
        
        this.props.getEditRoute({
          url: Configs[env].BACKEND_HOST + "/editRoute",
          objData : post_data,
          route_id : this.props.route_id,
          method : "POST",
          callback: this.addR
        });
    }
};

addR = (response) =>{
  // console.log("Kai addR", response)

  let dataForEditRoute = JSON.parse(JSON.stringify(this.props.dataForEditRoute));

  response.list[0].routes[this.props.route_id].Legs.map((leg,idx)=>{
      dataForEditRoute[this.props.route_id].Legs[idx].routeId = leg.routeId 
  })

  this.props.SET_DATA_FOR_EDIT_ROUTE(dataForEditRoute)
}

  render() {
    const id = this.props.id;
    let selected = false;
    let isMoving = false;

    if (this.props.candidate_selecting === id) {
      selected = true;
    }
    if (this.props.marker_selecting === id) {
      selected = true;
    }
    if (this.props.suggestion_selecting === id) {
      selected = true;
    }
    if (
      this.props.marker_MovingAdding === id ||
      this.props.marker_MovingAdding === id + 1
    ) {
      isMoving = true;
    }

    let strokeWeight = 4;
    if (this.props.path_onMouseOver === id) {
      strokeWeight = 7;
    }

    const arrowSymbol = {
      strokeColor: "#2868FC",
      strokeOpacity: 0.8,
      strokeWeight: strokeWeight,
      icons: [
        {
          icon: {
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
          },
          offset: "50%"
          // repeat: "20px"
        }
      ]
    };

    // strokeColor: "#2868FC",
    const normalSymbol = {
      zIndex: 0,
      strokeColor: "#808080",
      strokeOpacity: 0.8,
      strokeWeight: strokeWeight,
      icons: []
    };

    // strokeColor: "red",
    const higlightSymbol = {
      zIndex: 1,
      strokeColor: "#1E90FF",
      strokeOpacity: 1,
      strokeWeight: strokeWeight,
      icons: []
    };

    const dashlineSymbol = {
      strokeColor: "#ff2527",
      strokeOpacity: 0,
      strokeWeight: strokeWeight,
      icons: [
        {
          icon: {
            path: "M 0,-0.5 0,0.5",
            strokeOpacity: 1,
            scale: 4
          },
          offset: "0",
          repeat: "15px"
        }
      ]
    };

    // let icon = "http://maps.google.com/mapfiles/ms/icons/green.png";
    // let icon = new window.google.maps.MarkerImage(
    //   "http://maps.google.com/mapfiles/ms/icons/green.png",
    //   null /* size is determined at runtime */,
    //   null /* origin is 0,0 */,
    //   null /* anchor is bottom center of the scaled image */,
    //   new window.google.maps.Size(32, 32)
    // );

    var option = normalSymbol
    if(selected) {
      option = higlightSymbol
    }
    if(this.state.mouseOver) {
      option = dashlineSymbol
    }

    return (
      <React.Fragment>
        {(this.props.suggestion_editing === -1 || 
         this.props.suggestion_editing === this.props.id) && 
        <Polyline
          {...this.props}
          // onClick={this.onClick}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          options={option}
          onClick={this.addWaypoint}
        />}
      </React.Fragment>
        
    );
  }
}

const mapStateToProps = state => {
  return {
    candidate_selecting: state.map.candidate_selecting,
    marker_selecting: state.map.candidate_selecting,
    suggestion_selecting: state.map.suggestion_selecting,
    suggestion_editing: state.map.suggestion_editing,
    dataForEditRoute: state.map.dataForEditRoute,
    leg_editing: state.map.leg_editing,
    shiftSeq : state.selection.shiftSeq
    // marker_MovingAdding: state.marker_MovingAdding,
    // paths: state.paths,
    // path_onMouseOver: state.path_onMouseOver
  };
};

const mapActionsToProps = {
    MAP_SELECT_SUGGESSION : MAP_SELECT_SUGGESSION,
    getEditRoute,
    MARKER_SELECTING: MARKER_SELECTING,
    SET_DATA_FOR_EDIT_ROUTE,
    SET_LEG_EDITING,
  // POLYGON_SELECTING : mapSelectedPolygon ,
  // POLYGON_CHANGE : mapChangePolygon ,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(RsPolyline);
