function pinPathCubicBezier(width, height) {
  const deltaX = width * Math.sqrt(3);
  const deltaY = (height * 4) / 3;
  return `M 0,0 C ${-deltaX},${-deltaY}
      ${deltaX},${-deltaY} 0,0 z`;
}

export const markerPinNumberSvgIcon = (
  text,
  width,
  height,
  background,
  border
) => {
  const path = pinPathCubicBezier(width, height);
  let radius = Math.min(width, height) / 2;

  let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("width", width);
  svg.setAttribute("height", height);

  let group1 = document.createElementNS("http://www.w3.org/2000/svg", "g");
  group1.setAttribute("transform", `translate(${radius},${height})`);
  svg.appendChild(group1);

  let pathElement = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path"
  );
  pathElement.setAttribute("d", path);
  pathElement.style.stroke = border;
  pathElement.style.fill = background;
  group1.appendChild(pathElement);

  let group2 = document.createElementNS("http://www.w3.org/2000/svg", "g");
  group2.setAttribute("transform", `translate(${radius},${radius})`);
  svg.appendChild(group2);

  let circleElement = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "circle"
  );
  circleElement.setAttribute("cx", 0);
  circleElement.setAttribute("cy", 0);
  circleElement.setAttribute("r", radius - 4);
  circleElement.setAttribute("fill", "#ffffff");
  group2.appendChild(circleElement);

  let textElement = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "text"
  );
  textElement.setAttribute("dy", "3px");
  textElement.style.textAnchor = "middle";
  textElement.setAttribute("class", "inner-circle");
  textElement.setAttribute("font-size", "12px");
  textElement.setAttribute("font-family", "sans-serif");
  textElement.setAttribute("font-weight", "bold");
  textElement.setAttribute("fill", "black");
  textElement.textContent = text;
  group2.appendChild(textElement);

  let svgString = new XMLSerializer().serializeToString(svg);

  let svgUrl = `data:image/svg+xml,` + encodeURIComponent(svgString);

  return svgUrl;
};
