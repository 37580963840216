import React, {Component} from "react";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { uiSwitchForm } from '../../../reducers/ui';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import CircularProgress from '@material-ui/core/CircularProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/DeleteForever';
// import EditIcon from '@material-ui/icons/Create';
import {
    apiCallRequest,
} from '../../../reducers/api';
import { getLocationGroup,getLogisticPoint } from "../../../reducers/map/api";
//Config
import Configs from "../../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
    gridButton: {
        padding: '0px 0px',
        minWidth: '50px',
        fontSize: '0.8em',
        minHeight: '20px'
    },
    iconStyle :{
        padding:0,
    },
    center:{
      position: 'relative',
      left: '20px'
    }
})
function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class ManageBtnRenderer extends Component {
    constructor(props) {
        super(props);

        this.invokeParentMethodDelete = this.invokeParentMethodDelete.bind(this);
        this.invokeParentMethodEdit = this.invokeParentMethodEdit.bind(this);
        this.state = {
            open:false,
            name : this.props.data.name
        }
    }
    handleConfirm(){
        var id = this.props.data.id;
        this.props.API_REQUEST({
            url: Configs[env].BACKEND_HOST + "/location/"+id,
            method : 'DELETE',
            filter: {},
            callback : this.handleDeleteComplete
        })
        
    }
    handleDeleteComplete = () =>{
        let detail = JSON.parse(this.props.locationGroup.value);
        this.props.GET_LOGISTIC_POINTS({
            url: Configs[env].BACKEND_HOST + "/locationCond",
            filter: {
              logisticsPointGroup: this.props.logisticPoint.value,
              locationGroupId: this.props.data.locationGroupId
            }
          });
        this.handleCancel();
    }
    handleCancel = () => {
        this.setState({ open: false });
    };
    invokeParentMethodDelete() {

        let privilege = JSON.parse(localStorage.getItem('privilege'));
        let controllable = privilege && privilege.hasOwnProperty("locationMaster") ? privilege.locationMaster.controllable : false
        // let toDisable = !controllable

        if(controllable)
            this.setState({open : true});
    }
    invokeParentMethodEdit() {
        let privilege = JSON.parse(localStorage.getItem('privilege'));
        let controllable = privilege && privilege.hasOwnProperty("locationMaster") ? privilege.locationMaster.controllable : false
        // let toDisable = !controllable
        //this.props.context.componentParent.methodFromParent(`Row: ${this.props.node.rowIndex}, Col: ${this.props.colDef.headerName}`)
        if(controllable)
            this.props.SWITCH_FORM({page:"manage",mode:"edit",editID:this.props.data.id});
    }

    render() {
        const { classes } = this.props;

        return (
            <span>
                {/* <Button variant="outlined" size="small" color="primary" className={classes.gridButton} onClick={this.invokeParentMethod} >
                    EDIT
                </Button> */}
                <IconButton
                    className={classes.iconStyle}
                    onClick={this.invokeParentMethodEdit}
                >
                    <EditIcon/>
                </IconButton>
                <IconButton
                    className={classes.iconStyle}
                    onClick={this.invokeParentMethodDelete}
                >
                    <DeleteIcon/>
                </IconButton>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    >
                    <DialogTitle id="alert-dialog-slide-title">
                        Delete location point
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                        Confirm to delete { this.state.name }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirm.bind(this)} disabled={this.props.isLoading} color="primary">
                        {this.props.isLoading && <CircularProgress className={classes.center} size={25} thickness={2} />}
                            Confirm
                        </Button>
                        <Button onClick={this.handleCancel.bind(this)} disabled={this.props.isLoading} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        );
    }
};

const mapStateToProps = function(state,ownProps) {
    return {
        ...ownProps,
        isLoading: state.ui.isLoading,
        locationGroup : state.selection.locationGroup,
        logisticPoint : state.selection.logisticPoint
    }
};

const mapActionsToProps =  {
    SWITCH_FORM : uiSwitchForm,
    API_REQUEST: apiCallRequest,
    GET_LOGISTIC_POINTS : getLogisticPoint,

};
const enhance = compose(
    connect(
        mapStateToProps,
        mapActionsToProps
    ),
    withStyles(styles)
);  

export default enhance(ManageBtnRenderer)
