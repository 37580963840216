import { handleActions } from 'redux-actions';
// ACTION CREATORS
import { createAction  } from 'redux-actions';

export const uploadFile = createAction('UPLOAD_FILE_REQUEST');
export const uploadFileSuccess = createAction('UPLOAD_FILE_SUCCESS');


export const putImageFiles = createAction('PUT_IMAGE_FILES');
export const putVideoFiles = createAction('PUT_VIDEO_FILES');
export const resetImageFiles = createAction('RESET_IMAGE_FILES');
export const resetVideoFiles = createAction('RESET_VIDEO_FILES');

const initialState = {
    url : "",
    upload : false,
    imageFiles : [],
    videoFiles : [],
}

export default handleActions(
    {
      [uploadFile](state) {
        return { ...state };
      },
      [uploadFileSuccess](state,{result}){
        return { ...state , url : result, upload : true };
      },
      
      [putImageFiles](state,{payload}){
        return {...state, imageFiles : payload};
      },
      [putVideoFiles](state,{payload}){
        return {...state, videoFiles : payload};
      },
      [resetImageFiles](state){
        return { ...state, imageFiles: [] };
      },
      [resetVideoFiles](state){
        return { ...state, videoFiles: [] };
      },
    },
    initialState
);