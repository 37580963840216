import React, { Component } from "react";
import {
  Marker,
  InfoWindow
} from "react-google-maps";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
// import {
//   MARKER_SELECTING,
// } from "../../../reducers/map";

class MapLoadingBox extends Component {
  componentWillUpdate(nextProps, nextState) {}

  render() {
    return (
      <div style={{ background: `white`, border: `1px solid #ccc`, padding: 5, position: "absolute" ,left: "10px" , top: "55px"}}>
          <div>
            {/* <center>Please wait...</center> */}
            <CircularProgress size={'100px'} />
          </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    map_loading: state.map.map_loading
    // marker_selecting: state.map.marker_selecting
  };
};

const mapActionsToProps = {
//   MARKER_SELECTING : MARKER_SELECTING,
//   MARKER_MOVE : mapMoveMarker
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MapLoadingBox);
