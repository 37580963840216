import React, { Component } from "react";
import {
  Marker,
  InfoWindow
} from "react-google-maps";
import { connect } from "react-redux";
import {
  MARKER_SELECTING,
  SET_USE_TOLL
} from "../../../reducers/map";

class WPFloatingBox extends Component {
  componentWillUpdate(nextProps, nextState) {}

  handleChange = (e) => {
    if(e.target.checked) {
      this.props.SET_USE_TOLL(0)
    } else {
      this.props.SET_USE_TOLL(1)
    }
  }

  render() {
    let checked = false;
    if(this.props.use_toll === 0) {
      checked = true;
    } 
    let totalDistance = 0;
    let totalETA = 0;
    let infoDistance = 0;
    let infoETA;

    return (
      <div style={{ background: `white`, border: `1px solid #ccc`, padding: 5, position: "absolute" ,left: "10px" , top: "55px"}}>
         
         { (!this.props.map_loading) &&
          <center><b>Waypoints</b></center>
         }

         {/* { (this.props.modifying_route_loading) &&
          <div>
            <center>Please wait...</center>
            <img src="/img/station-loading.gif" width="70"></img>
          </div>
         } */}

          { (this.props.waypoints && !this.props.map_loading)  &&
            this.props.waypoints.map((leg, index) => {

              let divStyle = {
                cursor: "pointer"
              };
                  if (
                    this.props.marker_selecting === index ||
                    this.props.marker_MovingAdding === index
                  )
                    divStyle = { 
                      color: "red", 
                      cursor: "pointer"
                    };

                if (index > 0) {
                  try {
                    totalDistance =
                      totalDistance + Number(this.props.modified_route.Legs[index - 1].Distance);
                    totalETA = totalETA + Number(this.props.modified_route.Legs[index - 1].ETA);

                    infoDistance = (totalDistance / 1000).toFixed(2) + " KM";
                    infoETA = totalETA + " min";
                  } catch (e) {
                    infoDistance = (totalDistance / 1000).toFixed(2) + " KM";
                    infoETA = totalETA + " min";
                  }
                } else {
                  infoDistance = "0 KM";
                  infoETA = "0 min";
                }

                return (
                  <div
                    style={divStyle}
                    key={index}
                    onClick={() => this.props.MARKER_SELECTING(index)}>
                    {index}# {infoDistance}: {infoETA}
                    {/* {this.props.marker_MovingAdding === index &&
                      this.props.marker_Dragging !== index && (
                        <img
                          src="https://www.zadig-et-voltaire.com/skin/frontend/enterprise/zadig_v2/images/loading.gif"
                          width="15px"
                        />
                      )} */}
                  </div>
                );
          })
        }

      {this.props.TollUsage == 1 &&  
        <div>
          {/* TollUsage: {this.props.TollUsage} */}
          <center><img src="/img/toll_use.png" height="25"></img></center>
        </div> 
      }
        <div valign="center">
          <input type="checkbox" defaultChecked={checked} name="toll" value={this.props.use_toll} ref="complete" onChange={this.handleChange}/> Avoid Tolls 
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    modified_route: state.map.modified_route,
    marker_selecting: state.map.marker_selecting,
    marker_MovingAdding: state.map.marker_MovingAdding,
    marker_Dragging: state.map.marker_MovingAdding,
    modifying_route_loading: state.map.modifying_route_loading,
    map_loading: state.map.map_loading,
    waypoints: state.map.waypoints,
    TollUsage : state.map.TollUsage,
    use_toll : state.map.use_toll
    // marker_selecting: state.map.marker_selecting
  };
};

const mapActionsToProps = {
  MARKER_SELECTING : MARKER_SELECTING,
  SET_USE_TOLL : SET_USE_TOLL
//   MARKER_MOVE : mapMoveMarker
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(WPFloatingBox);
