import React, { Component } from "react";
import { render } from "react-dom";
import {
  Marker,
  InfoWindow
} from "react-google-maps";
import { connect } from "react-redux";
import {
  mapSetMarkers,
  mapSetCircles,
  mapSetPolygons,
  mapClearMarker,
  mapClearCircle,
  mapClearPolygon,
  mapDrawingMode
} from "../../reducers/map";
import uuidv4  from 'uuid/v4';
import {DrawingManager} from "react-google-maps/lib/components/drawing/DrawingManager";

class DrawingMap extends Component {
  componentWillUpdate(nextProps, nextState) {}

  onMarkerComplete = marker => {

      marker.setMap(null);
      this.props.DRAWING_MODE(null);
      
      let markerList = [];
      let data = {
        id : uuidv4(),
        position : {lat : marker.position.lat() , lng : marker.position.lng()},
        info : {
          name : marker.position.lat()+','+ marker.position.lng(),
          description : '',
          logisticsPoint : '',
        },
        isGroup : false,
        editable : true
      };
      markerList.push(data);
      this.props.SET_MARKERS(markerList);
  }
  onCircleComplete = circle => {

      circle.setMap(null);
      this.props.DRAWING_MODE(null);
      this.props.CLEAR_POLYGON(null);
      let circleList = [];
      let data = {
          id : uuidv4(),
          center : { lat: circle.getCenter().lat() , lng : circle.getCenter().lng()},
          radius : circle.getRadius(),
          info : {
            name : circle.getCenter().lat()+','+circle.getCenter().lng(),
            description : '',
            logisticsPoint : '',
          },
          isGroup : true,
          editable : true
      };
      circleList.push(data);
      this.props.SET_CIRCLES(circleList);
  }
  onPolygonComplete = polygon => {

      polygon.setMap(null);
      this.props.DRAWING_MODE(null);
      this.props.CLEAR_CIRCLE(null);
      let polygonList = [];
      //var decodePath = window.google.maps.geometry.encoding.decodePath(polygon.polygon);
      //var paths = [];
      //decodePath.forEach(function(p) {
        //paths.push({ lat: p.lat(), lng: p.lng() });
      //});
      let data = {
        id : uuidv4(),
        path : polygon.getPath(),
        info : {
          name : '',
          description : '',
          logisticsPoint : '',
        },
        isGroup : true,
        editable : true
      };
      polygonList.push(data);

      this.props.SET_POLYGON(polygonList);
  }
  render() {
    return (
        <DrawingManager
          options={this.props.drawingOptions}
          drawingMode={this.props.drawingMode}
          onOverlayComplete={this.onOverlayComplete}
          onMarkerComplete={this.onMarkerComplete}
          onCircleComplete={this.onCircleComplete}
          onPolygonComplete={this.onPolygonComplete}
          onPolylineComplete={this.onPolylineComplete}
          onRectangleComplete={this.onRectangleComplete}/>
    );
  }
}

const mapStateToProps = state => {
  return {
    markerList: state.map.markerList,
    circleList: state.map.circleList,
    polygonList: state.map.polygonList,
    drawingMode : state.map.drawingMode
  };
};

const mapActionsToProps = {
  SET_MARKERS : mapSetMarkers,
  SET_CIRCLES : mapSetCircles,
  SET_POLYGON : mapSetPolygons,
  DRAWING_MODE : mapDrawingMode,
  CLEAR_POLYGON : mapClearPolygon,
  CLEAR_CIRCLE : mapClearCircle,
  CLEAR_MARKER : mapClearMarker
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(DrawingMap);
