import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

import { withStyles } from "@material-ui/core/styles";
import SplitPane from 'react-split-pane';
import { Pane } from 'react-sortable-pane';

import SearchFrom from "./SearchForm";
import BaseMap from "../Map/BaseMap";
import { selectReset } from '../../reducers/ui/selectList';
import { setSidebarVisibility } from "../../reducers/ui";
import { mapSetLogisticPoint } from '../../reducers/map';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  scrollHeight:{

  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  gridForm : {
    padding : '0px'
  },
  subheader: {
    width: '100%',
  },
});

function DistanceMatrixMaster(props) {
  return (
    <div style={{ display: "flex", height: "calc(100vh - 52px)" }}>
      <SplitPane split="vertical" minSize={50} defaultSize={100}>
        <Pane initialSize="400px" minSize="200px" key={"key_sklanlksadlar"}>
          <div style={{ height: "calc(100vh - 40px)", overflow: "auto" }}>
            <SearchFrom />
          </div>
        </Pane>
        <Pane key={"key_sajkgdjk92b9r"}>
          <div style={{ height: "calc(100vh - 52px)" }}>
            <BaseMap />
          </div>
        </Pane>
      </SplitPane>
    </div>
  );
}
const mapStateToProps = state => ({
  isReset : state.map.isReset,
  formManage : state.ui.formManage,
});

const mapDispatchToProps = dispatch => {
  return {
    selectReset: payload => dispatch(selectReset(payload)),
    setSidebarVisibility: status => dispatch(setSidebarVisibility(status)),
    mapSetLogisticPoint :  payload => dispatch(mapSetLogisticPoint(payload)),
    dispatch
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(DistanceMatrixMaster);
