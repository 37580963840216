import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { uiSwitchForm } from '../../../reducers/ui';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import moment from "moment";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
// import EditIcon from '@material-ui/icons/Create';
import {
    propTypes,
    reduxForm,
    Form,
    Field,
    formValueSelector
  } from "redux-form";

//Lib
import { required, number,email,match,maxLength200 } from "../../../libs/validation";
//Comonent
//Reducer
import {
    apiCallRequest,
} from '../../../reducers/api';
import { mapResetMap } from "../../../reducers/map";
import { getManagement } from "../../../reducers/dashboard/api";
import { searchManage } from "../../../reducers/search";
//Config
import Configs from "../../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
    gridButton: {
        padding: '0px 0px',
        minWidth: '50px',
        fontSize: '0.8em',
        minHeight: '20px'
    },
    iconStyle :{
        padding:0,
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    dense: {
        marginTop: 16,
    },
})


const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
  }) => (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
    />
  );
  const renderSelect = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
  }) => (
    <Select
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
    />
  );

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class EditBtnRenderer extends Component {
    constructor(props) {
        super(props);
        this.invokeParentMethodEdit = this.invokeParentMethodEdit.bind(this);
        this.state = {
            title : "EDIT JOB",
            open:false,
            name : this.props.data.name,
            countTxt : '0/200',
            isDisable : false,
        }
    }
    handleChange = event => {
          this.setState({ countTxt: event.target.value.length+'/200' });
    };
    handleSave = (form) =>{
        this.setState({open:false,isDisable:true});
        //debugger
        var id = this.props.data.jobDashboardId;
        let formData = {...form,jobId : id,version:this.props.data.version+1};
        if(!formData.detailExplanation)formData.detailExplanation='';
        this.props.API_REQUEST({
            url: Configs[env].BACKEND_HOST + "/managementDashboard",
            objData: formData,
            method : "POST",
            callback: this.handleComplete,
            callback_error: this.handleError
        });
    }
    handleComplete = () =>{
        this.handleCancel();
    }
    handleError = () =>{
        this.setState({open:true,isDisable:false});
    }
    handleCancel = () => {
        this.props.reset();
        this.setState({ isDisable:false,open: false });
        let filter = {};
        if(this.props.search_manage.plant) filter.plant = this.props.search_manage.plant.join(',')
        if(this.props.search_manage.yard) filter.yard = this.props.search_manage.yard.join(',');

        this.props.getManagement({
            url: Configs[env].BACKEND_HOST + "/managementDashboard",
            method : "GET",
            filter: filter,
        });
    };
    invokeParentMethodEdit() {


        let privilege = JSON.parse(localStorage.getItem('privilege'));
        let controllable = privilege && privilege.hasOwnProperty("managementDash") ? privilege.managementDash.controllable : false
        // let toDisable = !controllable

        if(controllable){
            this.setState({open : true});
            this.props.change('detailExplanation',this.props.data.detailExplanation);
        }
    }

    render() {
        const { classes } = this.props;
        const { handleSubmit, submitting } = this.props;
        return (
            <span>
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    aria-label="EDIT"
                    className={classes.gridButton}
                    onClick={this.invokeParentMethodEdit}>
                    EDIT
                </Button>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleCancel}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    >
                    <DialogTitle id="alert-dialog-slide-title">
                        Update Detail Explanation
                    </DialogTitle>
                    <DialogContent>
                    <Form 
                        //style={{ overflowY:"scroll"}}
                        autoComplete={"off"}
                        autoCorrect={"off"}
                        spellCheck={"off"}
                        onSubmit={handleSubmit(this.handleSave.bind(this))}
                    >
                        <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <Field
                            fullWidth
                            name='detailExplanation'
                            fullWidth   
                            multiline={true}
                            rows={2}
                            rowsMax={2}
                            label="Key In"
                            component={renderInput}
                            InputLabelProps={{
                                shrink: true,
                                id: "detail-field"
                            }}
                            onChange={this.handleChange}
                            helperText={this.state.countTxt}
                            margin="dense"
                            className={styles.textField}
                            validate={[maxLength200]}
                            />
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <DialogActions>
                            <Button
                                variant="contained"
                                size="small"
                                disabled={this.state.isDisable}
                                color="primary"
                                aria-label="Save"
                                className={styles.button}
                                type="submit">
                                SAVE
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                color="secondary"
                                aria-label="Close"
                                className={classNames(styles.button, styles.cssGreen)}
                                onClick={this.handleCancel}
                            >
                                Close
                            </Button>
                            </DialogActions>
                        </Grid>
                        </Grid>
                    </Form>
                    </DialogContent>
                    
                </Dialog>
            </span>
        );
    }
};

const mapStateToProps = function(state,ownProps) {
    return {
        ...ownProps,
        search_manage : state.search.search_manage,
    }
};

const mapActionsToProps =  {
    SWITCH_FORM : uiSwitchForm,
    API_REQUEST: apiCallRequest,
    getManagement,
    searchManage

};
const enhance = compose(
    reduxForm({
        form: "criticalForm",
    }),
    connect(
        mapStateToProps,
        mapActionsToProps
    ),
    withStyles(styles)
);  

export default enhance(EditBtnRenderer)
