import React, {Component} from "react";
import moment from 'moment';

export default class SecToTimeRenderer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        }
    }

    formatDateTime(value) {
        
        var seconds = parseInt(value) //because moment js dont know to handle number in string format
        var hours = Math.floor(moment.duration(seconds,'minutes').asHours())!=0 ? Math.floor(moment.duration(seconds,'minutes').asHours()) + ' Hour ' : '';
        var mins = moment.duration(seconds,'minutes').minutes() + ' Mins ' ;
        return   hours+' '+mins;
    }

    render() {
        return (
            <span>{this.formatDateTime(this.state.value)}</span>
        );
    }
};

