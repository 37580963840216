import React, { Component } from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DialogActions from "@material-ui/core/DialogActions";
import compose from "recompose/compose";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import renderDatePicker from '../Ui/Renderer/DatePickerRenderer';
import {
  propTypes,
  reduxForm,
  Form,
  Field,
  formValueSelector
} from "redux-form";
//import AutoSelect from 'react-select';
//Lib
import { required, number } from "../../libs/validation";
//Component
import CategorySelect from './RouteForm/CategorySelect';
import RouteSelect from './RouteForm/RouteSelect';
import RunSeqSelect from './RouteForm/RunSeqSelect';

import Routes from './Routes';
//Reducer
import { connect } from "react-redux";
import { uiPanelDialogOpen, uiSwitchForm } from "../../reducers/ui";
import {
  mapDrawingMode,
  mapChangeMarker,
  mapDrawingOptions,
  mapSetLogisticPoint, mapSelectedMarker,
  MAP_LOG,SET_HAZARD_PHOTO,ADD_HAZARD_VDO,
  MAP_SELECT_SUGGESSION,
  mapSetSuggestionPolylines,
  mapSetBounds,
  SET_MARKER_ORIGIN_DESC,
  
  mapResetMap 
} from "../../reducers/map";
import { getLocationGroup,getLogisticPoint,GET_IMPACT_ROUTES,getRouteCode } from "../../reducers/map/api";
import { searchHazardRoute } from "../../reducers/search";
//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    dateFormat="YYYY/MM/DD" 
    {...inputProps}
    {...props}
  />
);
const renderDatePicker = ({
  input, 
  placeholder, 
  defaultValue, 
  meta: {touched, error} }) => (
  <div>
        <DatePicker {...input} 
        dateFormat="YYYY/MM/DD" 
        selected={input.value ? moment(input.value) : null} />
        {touched && error && <span>{error}</span>}
  </div>
);
class RouteForm extends Component {
  state = {
    actionsLocationPointGroup: false,
    actionsLocationGroup: false,
  };

  componentDidMount() {
    this.props.RESET_MAP(true);
    this.props.DRAWING_OPTION(this.state.drawingOptions);
    let defaultValue = {
        runSeq: "03",
        routeCode: "SD05",  
    };
    if (this.props.search_hazard_route) {
      defaultValue = this.props.search_hazard_route;
    }
    this.props.initialize({...defaultValue});
    this.props.searchHazardRoute({...defaultValue})

    this.getRouteCode();

    // console.log('#componentDidMount props',this.props);
    if(this.props.firstOpen){
      setTimeout(()=>this.handleSearch(), 1000);
    }

  }
  getRouteCode = () =>{
    this.props.getRouteCode({
      url: Configs[env].BACKEND_HOST + "/impactHazardFindRoute",
      filter: {}
    });
  }
  componentWillMount() {
  }
  componentWillReceiveProps(nextProps, props) {
    if(this.props.activeTabImpact!=nextProps.activeTabImpact && nextProps.activeTabImpact==1){
      this.handleSearch();
    }
  }
  handleChange = event => {
      this.props.searchHazardRoute({
        ...this.props.search_hazard_route,
        [event.target.name] :event.target.value
      });
  };
  handleSearch = () => {
    this.props.RESET_MAP(true);
    console.log('#handleSearch search_hazard_route',this.props.search_hazard_route);
    let filter = this.props.search_hazard_route;
    this.props.GET_IMPACT_ROUTES({
      url: Configs[env].BACKEND_HOST + "/impactHazardRoute",
      filter: filter,
      callback : this.drawRoute,
      // callback_error : this.updateTime_error,
    });
  };
  drawRoute = (response) => {

        this.props.mapSetSuggestionPolylines([])
        this.props.SET_MARKER_ORIGIN_DESC(
          { 
            marker_origin : {},
            marker_desc : {}
          }
        )
        // -------- Set polyline --------
        let bounds = [];
        let paths = [];

        
        if(response.list.length > 0  
            && response.list[0].hasOwnProperty("routes")
            && response.list[0].routes){
          
            let routes = response.list[0].routes
            let polygonList = [];
            let polygon = {}; 
            var decodePath = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(routes));
                
                decodePath.forEach(function(p) {
                  paths.push({ lat: p.lat(), lng: p.lng() });
                  bounds.push({ lat: p.lat(), lng: p.lng() });
                });
                polygon = {
                  id : 1,
                  path : decodePath,
                  // info : {
                  //   name : point.location,
                  //   description : point.detail,
                  //   logisticsPoint : point.category ,
                  // },
                  isGroup : false,
                  editable : false
                };
                polygonList.push(polygon);
            // this.props.MAP_SELECT_SUGGESSION(1)
            this.props.mapSetSuggestionPolylines(polygonList)
            this.props.SET_MARKER_ORIGIN_DESC(
              { 
                marker_origin : {
                      lat : paths[0].lat , 
                      lng : paths[0].lng
                    },
                marker_desc : {
                      lat : paths[paths.length-1].lat , 
                      lng : paths[paths.length-1].lng
                    }
              }
            )
          
            this.props.MAP_SELECT_SUGGESSION(1)
            this.props.mapSetBounds(bounds)

        }

        
      // -------- Set polyline --------
  }
  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div style={{ padding: "10px" }}>
        <Card className={styles.card}>
          <CardContent>
            <Form
              autoComplete={"off"}
              autoCorrect={"off"}
              spellCheck={"off"}
              onSubmit={handleSubmit(this.handleSearch.bind(this))}
            > 
              <Grid container spacing={16}>
                <Grid item xs={6}>
                  <RouteSelect isSearch={true}/>
                </Grid>
                <Grid item xs={6}>
                  <RunSeqSelect isSearch={true}/>
                </Grid>
                <Grid item xs={12}>
                <DialogActions>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    aria-label="Save"
                    className={styles.button}
                    type="submit"
                  >
                    SEARCH
                  </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
            <Typography variant="subtitle1">Impact Detail : </Typography>
            <Routes />
          </CardContent>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = function(state) {
  return {
    apiFetchResult: state.api.result,
    dialogOpen: state.ui.dialogOpen,
    formManage: state.ui.formManage,
    manageForm: state.mapForm.manageForm,
    activeTabImpact : state.ui.activeTabImpact,
    search_hazard_route : state.search.search_hazard_route,
  };
};
const mapActionsToProps = {
  DRAWING_OPTION: mapDrawingOptions,
  OPEN_DIALOG: uiPanelDialogOpen,
  SWITCH_FORM: uiSwitchForm,
  CHANGE_MAPMODE: mapDrawingMode,
  RESET_MAP: mapResetMap,
  GET_IMPACT_ROUTES,
  mapSetSuggestionPolylines,
  SET_MARKER_ORIGIN_DESC,
  mapSetBounds,
  mapResetMap,
  MAP_SELECT_SUGGESSION,
  searchHazardRoute,getRouteCode
};
const enhance = compose(
  reduxForm({
    form: "searchRouteForm",
    // validate
  }),
  connect(
    mapStateToProps,
    mapActionsToProps
  ),
  withStyles(styles)
);
export default enhance(RouteForm);
