import React, { Component } from "react";
import { render } from "react-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline
} from "react-google-maps";
import { compose, withProps, withStateHandlers } from "recompose";
import { connect } from "react-redux";

import {
  ADD_WAYPOINT,
  CLEAR_SELECTING
} from "../../../reducers/map";


// {{ lat: -34.397, lng: 150.644 }}

class WpPolyline extends Component {
 
  state = {
    isMouseOver: false
  };

  addWaypoint = e => {
    let new_waypoint = {
      id: this.props.id,
      position: { lat: e.latLng.lat(), lng: e.latLng.lng() }
    };
    // this.props.CLEAR_SELECTING();
    this.props.ADD_WAYPOINT(new_waypoint);

  };



  onMouseOver = e => {
    this.setState({ isMouseOver : true })

  };

  onMouseOut = e => {
    this.setState({ isMouseOver : false })

  };


  render() {
    const id = this.props.id;
    let selected = false;
    let isMoving = false;

    if (this.props.marker_selecting === id) {
      selected = true;
    }
    if (
      this.props.marker_MovingAdding === id ||
      this.props.marker_MovingAdding === id + 1
    ) {
      isMoving = true;
    }

    let strokeWeight = 4;
    if (this.state.isMouseOver == true) {
      strokeWeight = 7;

    } else {
      strokeWeight = 4;

    }

    const arrowSymbol = {
      strokeColor: "#2868FC",
      strokeOpacity: 0.8,
      strokeWeight: strokeWeight,
      icons: [
        {
          icon: {
            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
          },
          offset: "50%"
          // repeat: "20px"
        }
      ]
    };

    const normalSymbol = {
      strokeColor: "#1E90FF",
      strokeOpacity: 1,
      strokeWeight: strokeWeight,
      icons: []
    };

    const higlightSymbol = {
      strokeColor: "red",
      strokeOpacity: 0.8,
      strokeWeight: strokeWeight,
      icons: []
    };

    const dashlineSymbol = {
      strokeColor: "#ff2527",
      strokeOpacity: 0,
      strokeWeight: strokeWeight,
      icons: [
        {
          icon: {
            path: "M 0,-0.5 0,0.5",
            strokeOpacity: 1,
            scale: 4
          },
          offset: "0",
          repeat: "15px"
        }
      ]
    };
    return (
      <React.Fragment>
        <Polyline
          {...this.props}
          onClick={this.addWaypoint}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          options={isMoving ? higlightSymbol : normalSymbol}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    marker_selecting: state.map.marker_selecting,
    marker_MovingAdding: state.map.marker_MovingAdding,
    // paths: state.paths
  };
};

const mapActionsToProps = {
  // CIRCLE_SELECTING : mapSelectedCircle ,
  // CIRCLE_CHANGE : mapChangeCircle ,
  ADD_WAYPOINT : ADD_WAYPOINT,
  CLEAR_SELECTING: CLEAR_SELECTING
};

// const mapDispatchToProps = dispatch => {
//   return {
//     MARKER_SELECTING: id => dispatch({ type: "MARKER_SELECTING", value: id }),
//     MARKER_MOVE: id => dispatch({ type: "MARKER_MOVE", value: id }),
//     ADD_WAYPOINT: w => dispatch({ type: "ADD_WAYPOINT", value: w })
//   };
// };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(WpPolyline);
