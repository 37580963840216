import React, { Component } from "react";
import { render } from "react-dom";
import { connect } from "react-redux";
import CarMarker from "../CarMarker";

class TruckMarker extends Component {
  render() {
    let icon = new window.google.maps.MarkerImage(
      "/img/truck_marker.png",
      null /* size is determined at runtime */,
      null /* origin is 0,0 */,
      null /* anchor is bottom center of the scaled image */,
      new window.google.maps.Size(35, 40)
    );
    return <CarMarker selected={false} {...this.props} icon={icon} zIndex={1} />;
  }
}

export default TruckMarker;
