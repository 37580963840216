
export const upper = value => value && value.toUpperCase()

export const lower = value => value && value.toLowerCase()

export const lessThan = otherField => (value, previousValue, allValues) =>
  parseFloat(value) < parseFloat(allValues[otherField]) ? value : previousValue

export const greaterThan = otherField => (value, previousValue, allValues) =>
  parseFloat(value) > parseFloat(allValues[otherField]) ? value : previousValue
  

export const logisticPointFormat = value => {
    if (!value) {
      return value
    }
    const txtFormat = value.replace(/[^a-zA-Z0-9 ]/g, '')
    if (txtFormat.length <= 4) {
      return txtFormat.toUpperCase()
    }
    if (txtFormat.length <= 5) {
      return `${txtFormat.slice(0, 4).toUpperCase()}-${txtFormat.slice(4,5).toUpperCase()}`
    }
    return `${txtFormat.slice(0, 4).toUpperCase()}-${txtFormat.slice(4,5).toUpperCase()}-${txtFormat.slice(5,8).toUpperCase()}`
}

export const jobDashboardFormat = (value) => {
   if (!value) {
     return value;
   }
   const txtFormat = value.replace(/[^a-zA-Z0-9 ]/g, "");
   if (txtFormat.length <= 8) {
     return txtFormat.replace(/[^0-9]/g, "").toUpperCase();
   }
   if (txtFormat.length <= 12) {
     return `${txtFormat
       .slice(0, 8)
       .replace(/[^0-9]/g, "")
       .toUpperCase()}-${txtFormat.slice(8, 12).toUpperCase()}`;
   }
   if (txtFormat.length <= 14) {
     return `${txtFormat
       .slice(0, 8)
       .replace(/[^0-9]/g, "")
       .toUpperCase()}-${txtFormat.slice(8, 12).toUpperCase()}-${txtFormat
       .slice(12, 14)
       .toUpperCase()}`;
   }
   return `${txtFormat
     .slice(0, 8)
     .replace(/[^0-9]/g, "")
     .toUpperCase()}-${txtFormat.slice(8, 12).toUpperCase()}-${txtFormat
     .slice(12, 14)
     .toUpperCase()}-${txtFormat.slice(14, 17).toUpperCase()}`;
 };
