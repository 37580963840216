import React,{Component} from 'react';
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import DialogActions from "@material-ui/core/DialogActions";
import classNames from "classnames";
import Tooltip from '@material-ui/core/Tooltip';

//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  card: {
    maxWidth: 345,
    margin: theme.spacing.unit,
  },
  media: {
    height: 140,
  },
  margin: {
    margin: theme.spacing.unit,
  },
});

class ActionBoxes extends Component {
  constructor(props) {
    super(props);

    this.state = {

    };
  }
  componentDidMount() {

  }
  
  render () {
    return (
        
          <div style={{ width: 'calc(100vw - 75px)' ,marginTop: '0.6em'}}>
          <Grid container spacing={16}>
              <Grid item xs={3}>
                <Card className={styles.card} >
                  <CardContent style={{backgroundColor:'#B71C1C',padding: "3px", flex: 1, flexDirection: 'row', display: 'flex'}}>   
                    <Card style={{flex: 1, backgroundColor:'white'}} >
                          <CardContent  style={{padding: "0px", flex: 1, flexDirection: 'row', display: 'flex'}}>
                          <Card style={{flex: 2, backgroundColor:'white'}} >
                            <CardContent  style={{padding: "5px"}}>
                              <Typography variant="subtitle1" component="subtitle1" align="center" style={{lineHeight:2.3}} vertical-align >
                                ETA Delay
                              </Typography>
                            </CardContent>
                          </Card> 
                          <Card style={{flex: 1, display: 'flex',flexDirection: 'row', backgroundColor:'#B71C1C', alignItems: 'center'}} >
                              <CardContent  style={{flex: 1,flexDirection: 'row',padding: "5px"}}>
                              <Typography variant="h4" component="h4" align="center" style={{color:'white'}}>
                              {this.props.summary.total}
                            </Typography>
                            </CardContent>
                          </Card> 


                        </CardContent>
                    </Card> 
                  </CardContent>
                </Card>
              </Grid>
            
              <Grid item xs={3}>
                <Card className={styles.card} >
                    <CardContent style={{backgroundColor:'#01579B',padding: "3px", flex: 1, flexDirection: 'row', display: 'flex'}}>   
                      <Card style={{flex: 1, backgroundColor:'white'}} >
                            <CardContent  style={{padding: "0px", flex: 1, flexDirection: 'row', display: 'flex'}}>
                            <Card style={{flex: 2, backgroundColor:'white'}} >
                              <CardContent  style={{padding: "5px"}}>
                                <Typography variant="subtitle1" component="subtitle1" align="center" style={{lineHeight:2.3}}>
                                Already Action
                                </Typography>
                              </CardContent>
                            </Card> 
                            <Card style={{flex: 1, display: 'flex',flexDirection: 'row', backgroundColor:'#01579B', alignItems: 'center'}} >
                              <CardContent  style={{flex: 1,flexDirection: 'row',padding: "5px"}}>
                              <Typography variant="h4" component="h4" align="center" style={{color:'white'}}>
                              {/* <div style={{flex: 1,verticalAlign : "middle",align: 'center'}} > */}
                                {this.props.summary.alreadyTakeAction}
                              {/* </div> */}
                              </Typography>
                              </CardContent>
                            </Card> 


                          </CardContent>
                      </Card> 
                    </CardContent>
                  </Card>

              </Grid>
              
              <Grid item xs={3}>
                <Card className={styles.card} >
                    <CardContent style={{backgroundColor:'#E65100',padding: "3px", flex: 1, flexDirection: 'row', display: 'flex'}}>   
                      <Card style={{flex: 1, backgroundColor:'white'}} >
                            <CardContent  style={{padding: "0px", flex: 1, flexDirection: 'row', display: 'flex'}}>
                            <Card style={{flex: 2, backgroundColor:'white'}} >
                              <CardContent  style={{padding: "5px"}}>
                                <Typography variant="subtitle1" component="subtitle1" align="center" style={{lineHeight:2.3}}>
                                Need Action
                                </Typography>
                              </CardContent>
                            </Card> 
                            <Card style={{flex: 1, display: 'flex',flexDirection: 'row', backgroundColor:'#E65100', alignItems: 'center'}} >
                              <CardContent  style={{flex: 1,flexDirection: 'row',padding: "5px"}}>
                              <Typography variant="h4" component="h4" align="center" style={{color:'white'}}>
                                {this.props.summary.needAction}
                              </Typography>
                              </CardContent>
                            </Card> 


                          </CardContent>
                      </Card> 
                    </CardContent>
                  </Card>
  
              </Grid>
              <Grid item xs={3}>
                <Tooltip title={this.props.tooltip_action} placement="bottom-end">
                  <Card className={styles.card} >
                      <CardContent style={{backgroundColor:'#263238',padding: "3px", flex: 1, flexDirection: 'row', display: 'flex'}}>   
                        <Card style={{flex: 1, backgroundColor:'#263238'}} >
                              <CardContent  style={{padding: "0px", flex: 1, flexDirection: 'row', display: 'flex'}}>
                              <Card style={{flex: 2, backgroundColor:'#263238'}} >
                                <CardContent  style={{padding: "5px"}}>
                                  <Typography variant="subtitle1" component="subtitle1" align="center" style={{color:'white',lineHeight:2.3}}>
                                  ACTION NOW !
                                  </Typography>
                                </CardContent>
                              </Card> 
                              <Card style={{flex: 1, display: 'flex',flexDirection: 'row', backgroundColor:'white', alignItems: 'center'}} >
                              <CardContent  style={{flex: 1,flexDirection: 'row',padding: "5px"}}>
                              <Typography variant="h4" component="h4" align="center" style={{color:'red'}}>
                                  {this.props.summary.actionNow}
                                </Typography>
                                </CardContent>
                              </Card> 
                            </CardContent>
                        </Card> 
                      </CardContent>
                    </Card>
                  </Tooltip>
              </Grid>
              </Grid>
          </div>

    );
  };
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(ActionBoxes);