
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import Select from "@material-ui/core/Select";
import {
    Field,
} from "redux-form";
import { required } from "../../../libs/validation";
import { selectCategory } from "../../../reducers/ui/selectList";
import { searchHazardRoute } from "../../../reducers/search";
import { mapSetRunSeq } from "../../../reducers/map";
import { getRouteCode , getRunSeq } from "../../../reducers/map/api";
//Config
import Configs from "../../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
});
const renderSelect = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    value, 
    defaultValue,
    ...props
  }) => (
    <Select
      error={!!(touched && error)}
      helperText={touched && error}
      defaultValue={defaultValue}
      value={value}
      {...inputProps}
      {...props}
    />
  );
class RouteSelect extends Component {
    constructor(props){
        super(props);
        this.menuItem = [
            {value:"share",icon :"🚫 ", text : "Share Information"},
            {value:"warning",icon :"❗",  text : "Warning"},
            {value:"prohibit",icon :"⚠️ ", text : "Prohibit Area"}
        ]
        if(props.isSearch !== undefined && 
            props.isSearch === true){
            this.menuItem = [
                {value:"SD05",icon : '',   text :"SD05"},
                // {value:"share",icon :"🚫 ", text : "Share Information"},
                // {value:"warning",icon :"❗",  text : "Warning"},
                // {value:"prohibit",icon :"⚠️ ", text : "Prohibit Area"}
                
            ];
        }
    }
    componentDidMount() {
        if(this.props.search_hazard_route.routeCode){
            this.getRunSeq(this.props.search_hazard_route.routeCode);
        }
    }
    handleChange = (event) =>{
        //this.props.selectCategory({text:"",value:event.target.value})
        this.props.searchHazardRoute({
            ...this.props.search_hazard_route,
            routeCode:event.target.value
        });
        this.props.mapSetRunSeq({});
        this.getRunSeq(event.target.value);
        
    }
    getRunSeq = (routeCode) =>{
        this.props.getRunSeq({
          url: Configs[env].BACKEND_HOST + "/impactHazardFindRunSeq",
          filter: {routeCode : routeCode}
        });
    }
    renderMenuItem() {
        if(this.props.route_codes.routeCode){
          var menuItem = this.props.route_codes.routeCode;
          return menuItem.map(function(data) {
            return (
              <MenuItem key={data} value={data}>
                {data}
              </MenuItem>
            );
          });
        }
        else 
          return null;
    }
    handleReload = () =>{
        //Load Route Code
      this.props.getRouteCode({
        url: Configs[env].BACKEND_HOST + "/impactHazardFindRoute",
        filter: {}
      });
    }
    render(){
        return (
            <FormControl required fullWidth>
                <InputLabel shrink htmlFor="routecode-field">
                Route
                </InputLabel>
                <Field
                    defaultValue="SD05"
                    value={this.props.search_hazard_route.routeCode ? this.props.search_hazard_route.routeCode : 'SD05'}
                    onChange={this.handleChange}
                    onClick={this.handleReload}
                    name="routeCode"
                    component={renderSelect}
                    label="CATEGORY"
                    InputLabelProps={{
                        shrink: true,
                        id: "routecode-field"
                    }}
                    margin="dense"
                    className={styles.textField}
                    validate={[required]}
                    fullWidth
                    >
                    {this.renderMenuItem()}
                </Field>
            </FormControl>
        )
    }
}
const mapStateToProps = function(state,ownProps) {
    return {
        search_hazard_route : state.search.search_hazard_route,
        route_codes : state.map.route_codes,
        ...ownProps
    };
  };
  const mapActionsToProps = {
    searchHazardRoute,
    selectCategory,
    mapSetRunSeq,
    getRouteCode,getRunSeq
  };
  
  export default connect(
    mapStateToProps,
    mapActionsToProps
  )(withStyles(styles)(RouteSelect));