import React,{Component} from 'react';
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import classNames from 'classnames';
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
//Component
import ActionDialog from "./Dialog/ActionDialog";
import ActionBtnRenderer from "./Renderer/ActionBtnRenderer";
//Reducer
import { getAction,getProblem, } from "../../reducers/setting/api";
import { searchAction } from "../../reducers/search";
import { settingOpenAction } from "../../reducers/setting";
import { formAction } from "../../reducers/ui/mapForm";

import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
});
class Action extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title : "ADD ACTION",
      columnDefs: [
        { headerName: "Action", field: "name" },
        { headerName: "Last Update", field: "lastUpdate" },
        {
          cellRenderer: "actionBtnRenderer",
            width: 80
        }
      ],
      rowData: null,
      frameworkComponents: {
        actionBtnRenderer: ActionBtnRenderer,
      }
    };
  }
  componentDidMount(){
    //this.getData();
  }
  componentDidUpdate () {
    this.autoSizeAll();
  }
  componentWillReceiveProps(nextProps) {
  }
  renderData = () => {
    if (Array.isArray(this.props.setting_action)) {


      //this.props.setLogisticPoint(this.props.setting_action);
      //filter text in location point
      if (this.props.textSearchAction === "")
        return this.props.setting_action;
      else {
        let result = [];
        let findText = this.props.textSearchAction;
        this.props.setting_action.forEach((point, i) => {
            if (
              point.name.toUpperCase().search(findText.toUpperCase()) > -1 
            )
              result.push(point);
        });
        
        return result;
      }
    } else return [];
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  }
  autoSizeAll = () => {
    var allColumnIds = [];
    if(this.gridColumnApi){

      let columns = this.gridColumnApi.getAllColumns();
      columns.forEach(function(column,index) {
        if(index!=(columns.length-1))allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  }
  getData = () => {
    if(this.props.problem_selecting && this.props.problem_selecting.hasOwnProperty("id")){
      this.props.getAction({
        url: Configs[env].BACKEND_HOST + "/actionCond",
        filter : {
          problemId : this.props.problem_selecting.id
        }
      });
    }
  }
  
  handleAdd = () =>{
    if(this.props.problem_selecting){
      this.props.formAction({
        problemId : this.props.problem_selecting.id
      });
      //this.props.settingUserSelect(this.props.userForm.id);
      this.props.settingOpenAction(true);
    }
  }
  onFilterTextBoxChanged = event => {

    this.props.searchAction(event.target.value);
    //this.props.filterResult({key:'LOCATION_ACTION_LIST',filter:event.target.value});
    //this.state.api.setQuickFilter(document.getElementById('location-search').value);
  };
  render() {
    return (
      <div style={{ width: "100%", height: '400px' }}>
        <div
          className="ag-theme-balham"
          style={{
            height: "98%",
            width: "100%"
          }}
        >
        
        <Typography variant="subtitle1" gutterBottom className={styles.titlePanel}>
            Action : {this.props.problem_selecting ? this.props.problem_selecting.name : ""}
          </Typography>
        <Grid container spacing={16}>
            <Grid item xs={6}>
              <FormControl required fullWidth noValidate autoComplete="on">
                <TextField
                  id="action-search"
                  label="SEARCH ACTION"
                  type="search"
                  InputLabelProps={{
                    shrink: true
                  }}
                  margin="dense"
                  className={styles.textField}
                  onChange={this.onFilterTextBoxChanged.bind(this)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment variant="filled" position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <DialogActions>
                <Button 
                    variant="contained" size="small"  color="primary" 
                    aria-label="Add" className={classNames(styles.button)} 
                    onClick={this.getData}
                    disabled={this.props.problem_selecting ? false : true }>
                    REFRESH
                </Button>
                <Button 
                    variant="contained" size="small"  color="primary" 
                    aria-label="Add" className={classNames(styles.button)} 
                    onClick={this.handleAdd}
                    disabled={this.props.problem_selecting ? false : true }>
                    ADD ACTION
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
          
          <AgGridReact
            enableSorting={true}
            singleClickEdit={true}
            suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            enableColResize={true}
            rowData={this.renderData()}
            rowSelection="multiple"
            //onRowClicked={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady.bind(this)}
          />
          <ActionDialog title={this.state.title}/>
        </div>
      </div>
    );
  }
}
const mapStateToProps = function(state, ownProps) {
  return {
    setting_action: state.setting.setting_action,
    setting_action: state.setting.setting_action,
    textSearchAction: state.search.search_action,
    problem_selecting : state.setting.problem_selecting,
  };
};
const mapActionsToProps = {
  searchAction,
  getAction,
  settingOpenAction,
  formAction,
  getProblem,
};

export default compose(
  connect(
    mapStateToProps,
    mapActionsToProps
    //mapDispatchToProps
  ),
  withStyles(styles)
)(Action);
