import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import moment from 'moment';

import {
  forceLogout
} from '../../reducers/auth';

const styles = theme => ({
});

class UserAuthen extends Component {
  constructor(props) {
    super(props);

    this.state = {
        intervalId : null,
        currentCount: 0,
        stamp_datetime : null
    };
  }
  componentDidMount() {
    var intervalId = setInterval(this.timer, 10000);
    // store intervalId in the state so it can be accessed later:
    this.setState({intervalId: intervalId});


    // let user_authen = JSON.parse(localStorage.getItem('user_authen'));
    // if(user_authen) {

    // }
    // localStorage.setItem('privilege', JSON.stringify(privilege));

    // var time1 = moment('2018/01/31 01:01:00','YYYY/MM/DD HH:mm:ss');
    // var time2 = moment('2019/01/31 05:00:00','YYYY/MM/DD HH:mm:ss');

    // var diff = moment.duration(time2.diff(time1));
    // var d = moment.duration(diff);
    // var s = d.format("ss");

    // setTimeout(() => {
    //   this.props.forceLogout(true) 
    // },10000)
 }


 componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
 }
 
 timer = () => {

    // let user_authen = JSON.parse(localStorage.getItem('user_authen'));
    // if(user_authen) {
    //     let stamp_datetime = moment(user_authen.stamp_datetime,'YYYY/MM/DD HH:mm:ss');
    //     var diff = moment.duration(moment().diff(stamp_datetime));
    //     var d = moment.duration(diff);
    //     var s = d.format("ss");
    // }

    let current_user_authen = JSON.parse(localStorage.getItem('user_authen'));
    // console.log("Timer : UserAuthen.js (now)",moment().format('YYYY/MM/DD HH:mm:ss'))

    if(current_user_authen) {
        // console.log("Timer : UserAuthen.js (if true)",moment().format('YYYY/MM/DD HH:mm:ss'))
        let stamp_datetime = moment(current_user_authen.stamp_datetime,'YYYY/MM/DD HH:mm:ss');
        var diff = moment.duration(moment().diff(stamp_datetime));
        var d = moment.duration(diff);
        var time_diffs = parseInt(d.format("ss",{useGrouping: false}));

        // console.log("Timer : UserAuthen.js (now) - ",moment().format('YYYY/MM/DD HH:mm:ss'))
        // console.log("Timer : UserAuthen.js (current_user_authen) = ",current_user_authen.stamp_datetime) 
        // console.log("Timer : UserAuthen.js (time_diffs)",time_diffs)
        if(time_diffs > 60 || time_diffs < 1){
          // console.log("Timer : UserAuthen.js (>60 <1)",time_diffs)
          this.props.forceLogout(true) 
          return false
        }
    } else {
      // console.log("Timer : UserAuthen.js (if else)",moment().format('YYYY/MM/DD HH:mm:ss'))
      this.props.forceLogout(true) 
      return false
    }
    
    let user_authen = {
        stamp_datetime : moment(moment().subtract(5, 'seconds')).format('YYYY/MM/DD HH:mm:ss')
    }
    localStorage.setItem('user_authen', JSON.stringify(user_authen));
    
    let user_authen_localStorage = JSON.parse(localStorage.getItem('user_authen'));
    // console.log("Timer : timer = ()",user_authen_localStorage.stamp_datetime)

 }


  autosizeHeaders(event) {
    
  }
  
  methodFromParent(cell) {

  }
  render() {
    return (
        <div>

        </div>
    );
  }
}
const mapStateToProps = state => ({
//   isReset : state.map.isReset,
//   formManage : state.ui.formManage
});

const mapDispatchToProps = dispatch => {
  return {
    forceLogout: payload => dispatch(forceLogout(payload)),
    dispatch
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(UserAuthen);
