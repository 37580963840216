import React, { Component } from "react";
import moment from "moment";
import {
  propTypes,
  reduxForm,
  Form,
  Field,
  formValueSelector
} from "redux-form";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import compose from "recompose/compose";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
//import momentLocaliser from 'react-widgets/lib/localizers/moment'
import "react-widgets/dist/css/react-widgets.css";
import CircularProgress from '@material-ui/core/CircularProgress';
//Component
import Loading from "../../Ui/Loading";
//Lib
import { required, number,email,match } from "../../../libs/validation";
//Reducer
import { apiCallRequest } from "../../../reducers/api";
import { getGroupCompanies } from "../../../reducers/setting/api";
import { formGroup } from "../../../reducers/ui/mapForm";
import { settingOpenGroup,settingGroupSelect } from "../../../reducers/setting";

//Config
import Configs from "../../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    minWidth : '200px'
  },
  formControl: {
    margin: theme.spacing.unit
  },
  center:{
    position: 'relative',
    left: '20px'
  }
});

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);
const renderSelect = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <Select
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class GroupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open : true
    };
  }

  componentWillMount() {
    
    
  }
  componentWillReceiveProps(nextProps) {

    if (this.props.groupcompanyForm!=nextProps.groupcompanyForm) {
      this.props.initialize({...nextProps.groupcompanyForm});
    }
  }
  handleSave = (form) =>{
    //debugger
    this.props.settingOpenGroup(false);
    let formData = {...form};

   
    if (formData.id) {
      delete(formData.password);
      let id = formData.id;delete(formData.id);
      this.props.API_REQUEST({
        url: Configs[env].BACKEND_HOST + "/companyGroup/"+id,
        form: {...formData },
        method : "PUT",
        callback: this.handleCancel
      });
    } else {
      this.props.API_REQUEST({
        url: Configs[env].BACKEND_HOST + "/companyGroup",
        form: { ...formData},
        method : "POST",
        callback: this.handleCancel
      });
    }
    
  }
  handleCancel = () => {
    //if(data.errorList.length==0){
      this.props.reset();
      this.props.getGroupCompanies({
        url: Configs[env].BACKEND_HOST + "/companyGroup",
        filter: {}
      });
      this.props.formGroup(null);
      //this.props.settingGroupSelect(null);
      this.props.settingOpenGroup(false);
    //}
  }
  render() {
    const { handleSubmit, submitting,classes } = this.props;

    return (
      <div style={{ padding: "10px" }}>
        {/*this.props.isLoading && <Loading />*/}
        
          <Dialog
            open={this.props.group_dialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleCancel}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle id="alert-dialog-slide-title">
                {this.props.groupcompanyForm && this.props.groupcompanyForm.id ? "EDIT GROUP" : "ADD GROUP"}
            </DialogTitle>
            <DialogContent>
            <Form 
                  //style={{ overflowY:"scroll"}}
                  autoComplete={"off"}
                  autoCorrect={"off"}
                  spellCheck={"off"}
                  onSubmit={handleSubmit(this.handleSave.bind(this))}
              >
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      name='name'
                      component={renderInput}
                      className={styles.textField}
                      label='Group'
                      validate={[required]}
                    />
                  </Grid>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                    <DialogActions>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        aria-label="Save"
                        disabled={this.props.isLoading}
                        className={styles.button}
                        type="submit">
                        {this.props.isLoading && <CircularProgress className={classes.center} size={25} thickness={2} />}
                        SAVE
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        aria-label="Close"
                        disabled={this.props.isLoading}
                        className={classNames(styles.button, styles.cssGreen)}
                        onClick={this.handleCancel}
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
            
          </Dialog>
        
      </div>
    );
  }
}
const mapStateToProps = function(state, ownProps) {
  return {
    isLoading: state.ui.isLoading,
    group_selecting : state.setting.group_selecting,
    group_dialog : state.setting.group_dialog,
    groupcompanyForm: state.mapForm.groupcompanyForm,
  };
};
const mapActionsToProps = {
  API_REQUEST: apiCallRequest,
  settingOpenGroup,settingGroupSelect,
  getGroupCompanies,formGroup
};

const enhance = compose(
  reduxForm({
    form: "groupcompanyForm",
  }),
  connect(
    mapStateToProps,
    mapActionsToProps
  ),
  withStyles(styles)
);
export default enhance(GroupDialog);
