import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";
import debounce from "lodash/debounce";

import Select from "react-select";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import DialogActions from "@material-ui/core/DialogActions"; 
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from "@material-ui/core/styles";

import { polygonColor } from "../../libs/color";
import { jobDashboardFormat } from "../../libs/normalize";
import {
  getSearchLocation,
  getDistanceMatrix,
  getDistanceMatrixReport,
  getDistanceMatrixReportDetail,
  getSearchJob,
} from "../../reducers/map/api";

import {
  mapSetDistanceMatrixLocations,
  SET_MAP_LOADING,
  mapSetSearchJob,
} from "../../reducers/map";
import { markerPinNumberSvgIcon } from "../Map/DistanceMatrix/Marker";
import Configs from "../../config/config";
const env = process.env.NODE_ENV;
const MAX_LOCATIONS = 20;
const SEARCH_TRIGGER_RANGE = 13;

const styles = (theme) => ({
  input: {
    display: "flex",
    minHeight: 46,
    padding: 0,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  button: {
    margin: theme.spacing.unit,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
});

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.selectProps.isLoadingOption ? (
        <div style={{ textAlign: "center", paddingTop: 15, paddingBottom: 15 }}>
          Loading...
        </div>
      ) : (
        <>{props.children}</>
      )}
    </Paper>
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        height: "fit-content",
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function SearchForm({
  classes,
  GET_SEARCH_LOCATION,
  GET_SEARCH_JOB,
  GET_DISTANCE_MATRIX,
  GET_REPORT_DETAIL,
  GET_REPORT,
  SET_LOCATIONS,
  SET_SEARCH_JOB,
  searchLocation,
  totalDistance,
  reportDetail,
  distanceMatrix,
  searchJob,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [locations, setLocations] = useState([null, null]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectorInput, setSelectorInput] = useState("");

  const onAddLocation = () => {
    setLocations((prev) => [...prev, null]);
  };

  const reorderLocation = (list, startIndex, endIndex) => {
    const newList = list;
    const [removed] = newList.splice(startIndex, 1);
    newList.splice(endIndex, 0, removed);

    return newList;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newOrder = reorderLocation(
      locations,
      result.source.index,
      result.destination.index
    );
    setLocations([...newOrder]);
  };

  const onSelectLocation = (value, index) => {
    const newList = locations;
    newList[index] = value;
    setLocations([...newList]);
  };

  const onDelLocation = (index) => {
    const newList = locations;
    newList.splice(index, 1);
    setLocations([...newList]);
  };

  const onDownloadReport = () => {
    setIsLoadingReport(true);
    GET_REPORT({
      url: Configs[env].BACKEND_HOST + "/distance_matrix/download_report",
      path: reportDetail[0],
      callback: async (response) => {
        const url = URL.createObjectURL(
          new Blob([`\ufeff${response}`], { type: "text/csv;charset=utf-8" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "distance_matrix_report.csv");
        document.body.appendChild(link);
        link.click();
        setIsLoadingReport(false);
      },
      callback_error: () => setIsLoadingReport(false)
    });
  };

  const onSearchJobs = (value) => {
    if (value.length >= SEARCH_TRIGGER_RANGE) {
      GET_SEARCH_JOB({
        url: Configs[env].BACKEND_HOST + "/distance_matrix/search_jobs",
        key: value,
        callback: () => {
          setIsLoading(false);
        },
      });
    } else {
      setIsLoading(false);
    }
  };
  const debounceSearchJobs = useCallback(debounce(onSearchJobs, 1000), []);

  const onSelectJob = (value, { action }) => {
    if (action === "clear") {
      setSelectedJob(null);
    } else {
      setSelectedJob(value);
      setLocations([...value.data]);
    }
  };

  useEffect(() => {
    SET_LOCATIONS(locations);
    GET_DISTANCE_MATRIX({
      url: Configs[env].BACKEND_HOST + "/distance_matrix",
      locations,
      callback: () => {
        setIsLoading(false);
      },
    });
    if (selectedJob) {
      if (selectedJob.data.length == locations.length) {
        if (
          locations.some((ele) => ele == null) ||
          selectedJob.data.some(
            (_, index) =>
              selectedJob.data[index].label != locations[index].label
          )
        ) {
          setSelectedJob(null);
        }
      } else {
        setSelectedJob(null);
      }
    }
  }, [locations]);

  useEffect(() => {
    if (!selectedJob) SET_SEARCH_JOB([])
  }, [selectedJob])

  useEffect(() => {
    GET_SEARCH_LOCATION({
      url: Configs[env].BACKEND_HOST + "/distance_matrix/location",
      callback: () => setIsLoading(false),
    });
    setIsLoadingReport(true);
    GET_REPORT_DETAIL({
      url: Configs[env].BACKEND_HOST + "/distance_matrix/report",
      callback: () => setIsLoadingReport(false),
    });
  }, []);

  return (
    <div style={{ padding: "10px" }} testing="div-location-master">
      <DialogActions>
        <Typography variant="body1" gutterBottom>
          <div>Latest:</div>
        </Typography>
        <Typography variant="body1" gutterBottom>
          {reportDetail
            ? moment(reportDetail[1]).format("DD/MM/YYYY")
            : "NOT AVAILABLE"}
        </Typography>
        <Button
          variant="contained"
          size="small"
          color="primary"
          aria-label="Add"
          className={classNames(styles.button, styles.cssGreen)}
          disabled={!reportDetail || isLoadingReport}
          onClick={onDownloadReport}
        >
          {isLoadingReport && (
            <CircularProgress
              className={classes.center}
              size={20}
              thickness={2}
              style={{ marginRight: 10 }}
            />
          )}
          Download Report
        </Button>
      </DialogActions>

      <Card className={styles.card}>
        <CardContent
          style={{ height: "calc(100vh - 200px)", overflow: "auto" }}
        >
          <div style={{ marginBottom: 20 }}>
            <Select
              classes={classes}
              options={searchJob}
              components={{ Control, Menu, Option }}
              loadOptions={debounceSearchJobs}
              isLoadingOption={isLoading}
              onInputChange={(value) => {
                const normalizeValue = jobDashboardFormat(value);
                setSelectorInput(normalizeValue);
                if (normalizeValue.length >= SEARCH_TRIGGER_RANGE)
                  setIsLoading(true);
                debounceSearchJobs(normalizeValue);
              }}
              inputValue={selectorInput}
              value={selectedJob}
              onChange={onSelectJob}
              placeholder="YYYYMMDD-XXXX-XX-XXX"
              isClearable
            />
          </div>

          <Grid container alignItems="flex-end" justify="space-between">
            <Grid item>
              <Typography variant="subheading" gutterBottom>
                Total Distance: {totalDistance.toFixed(1)} km
              </Typography>
            </Grid>
          </Grid>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  style={{ minHeight: 68 * locations.length }}
                  ref={provided.innerRef}
                >
                  {locations.map((item, index) => (
                    <Draggable
                      key={"drag-id-" + index}
                      draggableId={"drag-id-" + index}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Grid
                            style={{ paddingTop: 10, paddingBottom: 10 }}
                            container
                            alignItems="center"
                          >
                            <Grid item xs={1}>
                              <img
                                src={markerPinNumberSvgIcon(
                                  (index + 1).toString(),
                                  25,
                                  35,
                                  polygonColor[index],
                                  5
                                )}
                                alt={(index + 1).toString()}
                                width={20}
                                height={30}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              {index > 0 && (
                                <Typography
                                  style={{
                                    marginTop: 5,
                                    textAlign: "right",
                                    paddingRight: 10,
                                  }}
                                  variant="body1"
                                  gutterBottom
                                >
                                  {distanceMatrix[index - 1]
                                    ? distanceMatrix[
                                        index - 1
                                      ].distance.toFixed(1)
                                    : "0.0"}{" "}
                                  km
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={7}>
                              <Select
                                classes={classes}
                                options={searchLocation}
                                components={{ Control, Menu, Option }}
                                value={item}
                                isLoadingOption={isLoading}
                                onChange={(value) =>
                                  onSelectLocation(value, index)
                                }
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <div
                                style={{
                                  padding: 6,
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingTop: 7,
                                }}
                                {...provided.dragHandleProps}
                              >
                                <DragIndicatorIcon
                                  color="gray"
                                  fontSize="small"
                                />
                              </div>
                            </Grid>
                            <Grid item xs={1}>
                              {locations.length > 2 && (
                                <IconButton
                                  style={{ padding: 6 }}
                                  aria-label="Delete"
                                  onClick={() => onDelLocation(index)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <DialogActions style={{ paddingBottom: 300 }}>
            {locations.length < MAX_LOCATIONS && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                onClick={onAddLocation}
              >
                <AddIcon fontSize="small" />
                <div style={{ paddingTop: 2, marginLeft: 4 }}>ADD</div>
              </Button>
            )}
          </DialogActions>
        </CardContent>
      </Card>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    searchLocation: state.map.search_location,
    searchJob: state.map.search_job,
    totalDistance: state.map.total_distance,
    distanceMatrix: state.map.distance_matrix,
    reportDetail: state.map.report_detail,
    locations: state.map.distance_matrix_locations
  };
};
const mapActionsToProps = {
  GET_SEARCH_JOB: getSearchJob,
  GET_SEARCH_LOCATION: getSearchLocation,
  GET_DISTANCE_MATRIX: getDistanceMatrix,
  GET_REPORT_DETAIL: getDistanceMatrixReportDetail,
  GET_REPORT: getDistanceMatrixReport,
  SET_LOCATIONS: mapSetDistanceMatrixLocations,
  SET_SEARCH_JOB: mapSetSearchJob,
  SET_MAP_LOADING
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(SearchForm));
