import React, { Component } from "react";
import { render } from "react-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import { compose, withProps, withStateHandlers } from "recompose";
import { connect } from "react-redux";

import {
  MARKER_MOVE,
  MARKER_DRAGGING,
  MARKER_SELECTING,
  DEL_MARKER
} from "../../../reducers/map";

class WpMarker extends Component {
  selectInfo = arg => {
    this.props.MARKER_SELECTING(this.props.id);


  };

  onDragStart = arg => {
    this.closeInfo()
    this.props.MARKER_DRAGGING(this.props.id);


  };

  onDragEnd = e => {
    this.props.position.lat = e.latLng.lat();
    this.props.position.lng = e.latLng.lng();
    // let newPosition = {
    //   position: {
    //     lat: e.latLng.lat(),
    //     lng: e.latLng.lng()
    //   }
    // };
    this.props.MARKER_MOVE(this.props);
  };

  closeInfo = () => {
    this.props.MARKER_SELECTING(-1)
  }

  render() {
    const id = this.props.id;
    let selected = false;
    if (this.props.marker_selecting === id) {
      selected = true;
    }

    let icon_url;
    let label = "";
    let size = new window.google.maps.Size(40, 40);

    //https://sites.google.com/site/gmapicons/home/
    if (this.props.isFirst) {
      icon_url = "http://www.google.com/mapfiles/dd-start.png";
      size = new window.google.maps.Size(22, 36);
    } else if (this.props.isLast) {
      icon_url = "http://www.google.com/mapfiles/dd-end.png";
      size = new window.google.maps.Size(22, 36);
    } else if (this.props.marker_MovingAdding === id) {
      icon_url = "http://maps.google.com/mapfiles/ms/icons/red.png";
      label = id.toString();
    } else {
      icon_url = "http://maps.google.com/mapfiles/ms/icons/blue.png";
      label = id.toString();
    }

    const _this = this;
    return (
      <div>
        <Marker
          position={this.props.position}
          label={label}
          icon={{
            url: icon_url,
            labelOrigin: new window.google.maps.Point(19, 14),
            scaledSize: size,
            size: size
          }}
          key={this.props.key}
          defaultDraggable={this.props.defaultDraggable}
          onClick={this.selectInfo}
          onDragStart={this.onDragStart}
          // onMouseOver={this.onDragStart}
          // onMouseOut={this.props.mapClearInfo}
          // mapClearInfo={this.props.mapClearInfo}
          onDragEnd={this.onDragEnd}>
          {selected ? (
            <div>
              <InfoWindow onCloseClick={this.closeInfo}>
                <div>
                  <div>
                    <div>Distance : {this.props.infoDistance}</div>
                    <div>ETA : {this.props.infoETA}</div>
                  </div>
                  <div>
                    {!(this.props.isFirst || this.props.isLast) && (
                      <button onClick={() => this.props.DEL_MARKER(id)}>
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              </InfoWindow>
            </div>
          ) : null}
        </Marker>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    marker_selecting: state.map.marker_selecting,
    marker_MovingAdding: state.map.marker_MovingAdding,
    marker_Dragging: state.map.marker_MovingAdding
  };
};

const mapActionsToProps = {
  // CIRCLE_SELECTING : mapSelectedCircle ,
  // CIRCLE_CHANGE : mapChangeCircle ,
  MARKER_MOVE : MARKER_MOVE,
  MARKER_DRAGGING : MARKER_DRAGGING,
  MARKER_SELECTING: MARKER_SELECTING,
  DEL_MARKER : DEL_MARKER
};

// const mapDispatchToProps = dispatch => {
//   return {
//     DEL_MARKER: value => dispatch({ type: "DEL_MARKER", value: value }),
//     MARKER_SELECTING: id => dispatch({ type: "MARKER_SELECTING", value: id }),
//     MARKER_DRAGGING: id => dispatch({ type: "MARKER_DRAGGING", value: id }),
//     MARKER_MOVE: id => dispatch({ type: "MARKER_MOVE", value: id })
//   };
// };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(WpMarker);
