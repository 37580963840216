import { select,takeLatest, call, put } from "redux-saga/effects";
import { getSuggestionRoutes,
  getTruckLocation,
  getEditRoute,
  postConfirmRoute} from "../reducers/map/api";
import { 
  mapSetSuggestionRoutes,
  mapSetTruckLocation,
  mapSetSuggestionPolylines,
  mapSetCandidateRoutes,
  mapSetCandidatePolylines,
  mapSetBounds,
  SET_MAP_LOADING,
  mapSetMarkers,
  SET_MARKER_ORIGIN_DESC,
  mapSetCircles,
  mapSetPolygons,
  mapSetDropPoint,
  mapSetSubLegs
} from "../reducers/map";
import axios from 'axios';

import { SET_SNACKBAR } from "../reducers/ui";

//import { formSuggestionRoute } from "../reducers/ui/mapForm";
import Configs from "../config/config";
const env = process.env.NODE_ENV;


// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {

  yield takeLatest(getSuggestionRoutes, suggestionRoutesWorkerSaga);
  yield takeLatest(getTruckLocation, suggestionRoutesWorkerSaga);
  yield takeLatest(getEditRoute, suggestionRoutesWorkerSaga);
  yield takeLatest(postConfirmRoute, suggestionRoutesWorkerSaga); 
}

function fetchForm(params) {
  //debugger
  //onsole.log('formSaga #fetchForm params',params);
  let url = params.payload.url;
  let form = params.payload.form;
  let listData = params.payload.listData;
  let objData = params.payload.objData;

  let inputParams = {
    list: listData ? listData : [{...form}]
  };
  if(objData) inputParams = objData;
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache': "no-cache",
    'Authorization': Configs[env].AUTHORIZATION,
    'token':localStorage.getItem('token'),
  };

  let callback = params.payload.callback;
  return new Promise((resolve, reject) => {
    // const data = {
    //   Name: name,
    //   ID: id,
    // } 
    axios({
      method: params.payload.method ? params.payload.method : "POST",
      url: url,
      data: JSON.stringify(inputParams),
      headers: headers,
      timeout: 60000
    })
    .then(function (resp) {
        resolve(resp)
    })
    .catch(function (error) {
      resolve({ catchError : error})
    });
  })

}

function fetchDataGet(params) {  

  //http://mapssh.leafte.ch/managementTest
  
  let url = params.payload.url+'?'+paramsUrl(params.payload.filter);
  //let url = "https://digital.map.api.rtic-thai.info/api/v1/routeSuggestion/20190114-SC05-07-040"
  // let url = "http://mapssh.leafte.ch/routeSuggestionSample"
  
  // let callback = params.payload.callback;
  // let callback_error = params.payload.callback_error;
  // let callback_errorList = params.payload.callback_errorList;
  let headers = {
      'Accept': 'application/json',
      // 'Accept-Encoding' : 'gzip',
      'Content-Type': 'application/json',
      'Cache': "no-cache",
      'Authorization': Configs[env].AUTHORIZATION,
      'token':localStorage.getItem('token'),
  };

  return axios
  .get(url , { headers: headers , timeout: 60000})
  .then(resp => {
      return resp
  })
  .catch(error => {
      return { catchError : error};
  });
}


function paramsUrl(data) {
  return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
}


/////// POLYLINE /////////

function setDropPoint_array(data,editable){

  let markerList = [];
  let marker ={};
  if(data){
      data.routes[0].Legs.forEach((item, i) => {

          marker = {
            ...item,
            route_data : data,
            id : i,
            position : {lat: item.lat , lng: item.lng},
            // info : {
            //   name : point.location,
            //   description : point.detail,
            //   logisticsPoint : point.category ,
            // },
            isGroup : false,
            // category : point.category,
            editable : editable
          };
          markerList.push(marker);
      });
      
  }
  return markerList;
}

function setSubLegs(data,editable){
  let subLegs = [];
  let polylineList = [];
  let polyline = {};
  // let waypoint = []
  let count_wp = 0;
  let count_sub_idx = 0;
  if(data){
      data.routes.forEach((route, route_idx) => {
        route.Legs.forEach((leg, leg_idx) => {
            if(leg.hasOwnProperty("subLegs")){
              leg.subLegs.forEach((subLeg, subleg_idx) => {
                // Seq: 1
                // subRoute:
                var decodePath = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(subLeg.subRoute));
                var paths = [];
                decodePath.forEach(function(p) {
                  paths.push({ lat: p.lat(), lng: p.lng() });
                });

                let tmp = {
                  position : paths[0],
                  route_idx : route_idx,
                  leg_idx : leg_idx,
                  subleg_idx : subleg_idx,
                  all_idx : route_idx + "_" + leg_idx + "_" + subleg_idx
                }
                if(subleg_idx > 0)
                  subLegs.push(tmp);

                // polyline = {
                //   // ...subLeg,
                //   route_id : route_id,
                //   leg_id : leg_id,
                //   seq_id : subLeg.Seq,
                //   subleg_seq : subLeg.Seq,
                //   id : route_id,
                //   sub_idx : count_sub_idx,
                //   path : decodePath,
                //   editable : editable
                // };
                // polylineList.push(polyline);
                // // waypoint[count_wp+1].push(paths[0]);
                // // count_wp++
                // count_sub_idx++

                
              })
            } 
        });
      })

      return subLegs;
  }
}

function setPolylines(data,editable){

  let polylineList = [];
  let polyline = {};
  // let waypoint = []
  let count_wp = 0;
  
  if(data){
      data.routes.forEach((route, route_id) => {
        route.Legs.forEach((leg, leg_id) => {
            let count_sub_idx = 0;  
            if(leg.hasOwnProperty("subLegs")){
              leg.subLegs.forEach((subLeg, subLeg_id) => {
                // Seq: 1
                // subRoute:
                var decodePath = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(subLeg.subRoute));
                var paths = [];
                decodePath.forEach(function(p) {
                  paths.push({ lat: p.lat(), lng: p.lng() });
                });
                polyline = {
                  // ...subLeg,
                  route_id : route_id,
                  leg_id : leg_id,
                  seq_id : subLeg.Seq,
                  subleg_seq : subLeg.Seq,
                  id : route_id,
                  sub_idx : count_sub_idx,
                  path : decodePath,
                  editable : editable
                };
                polylineList.push(polyline);
                // waypoint[count_wp+1].push(paths[0]);
                // count_wp++
                count_sub_idx++

              })
            } else {
              var decodePath = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(leg.Route));
              var paths = [];
              decodePath.forEach(function(p) {
                paths.push({ lat: p.lat(), lng: p.lng() });
              });
              polyline = {
                ...leg,
                route_id : route_id,
                leg_id : leg_id,
                seq_id : leg.Seq,
                id : route_id,
                sub_idx : 0,
                path : decodePath,
                editable : editable
              };
              polylineList.push(polyline);
              // waypoint[0].push(paths[0]);
              // waypoint[count_wp].push(paths[paths.length-1]);
              
            }
        });
      })

      return polylineList;
  }
}

function setBounds(data){
  let bounds = [];
  if(data){
      data.forEach((point, i) => {
          var decodePath = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(point.polyline));
          decodePath.forEach(function(p) {
            bounds.push({ lat: p.lat(), lng: p.lng() });
          });
      });
  }
  return bounds;
}

function setBoundsWaypoints(polylines){
  let bounds = [];
  if(polylines){
    polylines.forEach((item, i) => {
          var decodePath = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(item.Route));

          decodePath.forEach(function(p) {
            bounds.push({ lat: p.lat(), lng: p.lng()} );
          });
      });
  }
  return bounds;
}

function setHazardMarkers_array(data,editable){
  let markerList = [];
  let marker ={};

  data.routes.forEach((route, i) => {
    if(route.hazard){
      route.hazard.forEach((point, i) => {
          //Marker
          marker = {
            ...point,
            id : point.latitude,
            position : {lat: point.latitude , lng: point.longitude},
            info : {
              name : point.location,
              description : point.detail,
              logisticsPoint : point.category ,
            },
            isGroup : false,
            category : point.category,
            editable : editable
          };
          //markerList.push(marker);
          markerList.push(marker);
        });
    }
  })
  return markerList;
}

function setCircles_array(data,editable){
  let circleList = [];
  let circle = {};
  data.routes.forEach((route, i) => {
    if(route.hazard){
      route.hazard.forEach((point, i) => {
        //Circle
        if(point.type=='circle'){

          circle = {
            id : point.id,
            center : {lat: point.circleLatitude , lng: point.circleLongitude},
            radius : point.radius,
            info : {
              name : point.location,
              description : point.detail,
              logisticsPoint : point.category ,
            },
            isGroup : false,
            editable : editable
          };
          circleList.push(circle);
        }
      });
  }
})

return circleList;
}

function setPolygons_array(data,editable){
  let polygonList = [];
  let polygon = {};
  data.routes.forEach((route, i) => {
    if(route.hazard){
      route.hazard.forEach((point, i) => {
          var decodePath = window.google.maps.geometry.encoding.decodePath(point.polygon);
          var paths = [];
          decodePath.forEach(function(p) {
            paths.push({ lat: p.lat(), lng: p.lng() });
          });
          polygon = {
            id : point.routeCode,
            path : decodePath,
            // info : {
            //   name : point.location,
            //   description : point.detail,
            //   logisticsPoint : point.category ,
            // },
            isGroup : false,
            editable : editable
          };
          //polygonList.push(polygon);
          polygonList.push(polygon);
        })
      }
    })
    return polygonList;
}
//////////////////////////////////////////////////

function* suggestionRoutesWorkerSaga(action) {
  try {

      let response

      // if(getTruckLocation!==action.type){
        yield put({ type: SET_MAP_LOADING, payload: true });
      // }
      

      if(getEditRoute==action.type){
        const state = yield select();
        
        let tmp = yield call(fetchForm, action);
        response = JSON.parse(JSON.stringify(tmp));

        let suggestion_routes = state.map.suggestion_routes
        response.data.list[0] = JSON.parse(JSON.stringify(suggestion_routes));
        // "planDepartureTime": "2019-01-28 07:10:00", 
        // tmp.data.list[0].Legs[0].planDepartureTime = "2019-01-28 :10:00"
        response.data.list[0].routes[action.payload.route_id] = JSON.parse(JSON.stringify(tmp.data.list[0]));
        // response.data.list[0].routes[0].Legs[0].planDepartureTime = "2019-01-28 10:10:00"
      } else if(postConfirmRoute==action.type){ 
        response = yield call(fetchForm, action); 
      } else {
        response = yield call(fetchDataGet, action);
      }
      
      let params = action
      let callback = params.payload.callback;
      let callback_error = params.payload.callback_error;

      // yield put({ type: SET_MAP_LOADING, payload: false });

      if(response.hasOwnProperty("catchError")){
          if(callback_error) callback_error(response.catchError.toString());
          yield put({ 
              type: SET_SNACKBAR, 
              payload: {
                  snackbarOpen: true,
                  snackbarVariant: "error",
                  snackbarMessage: response.catchError.toString(),
                  snackbarDuration: 20000
              }
          });
          return false
      } 

      if(parseInt(response.status) < 200 ||  parseInt(response.status) > 200 ){
          if(callback_error) callback_error("HTTP ERROR : status code = " + response.status);
          yield put({ 
              type: SET_SNACKBAR, 
              payload: {
                  snackbarOpen: true,
                  snackbarVariant: "error",
                  snackbarMessage: "HTTP ERROR : status code = " + response.status,
                  snackbarDuration: 20000
              }
          });
          return false
      }
      
      if(response.data.hasOwnProperty("errorList")) {
        if(response.data.errorList.length > 0){
            if(callback_error) callback_error(errorShow)

            let errorShow = ""
            errorShow = response.data.errorList[0].errorMessage
            console.info("errorDetail",response.data.errorList[0].errorDetail)
            if(parseInt(response.data.errorList[0].errorCode) === 2101){
              yield put({ 
                type: SET_SNACKBAR, 
                  payload: {
                      snackbarOpen: true,
                      snackbarVariant: "error",
                      snackbarMessage: errorShow,
                      snackbarDuration: 3000
                  }
              });
              window.location.href = "/operation_dash";
              return false
            }

            if(parseInt(response.data.errorList[0].errorCode) === 2102 ||
                parseInt(response.data.errorList[0].errorCode) === 2103 ){
                yield put({ 
                    type: SET_SNACKBAR, 
                    payload: {
                        snackbarOpen: true,
                        snackbarVariant: "error",
                        snackbarMessage: errorShow,
                        snackbarDuration: 3000,
                        forceLogout: true
                    }
                });
                return false
            }

            yield put({ 
                type: SET_SNACKBAR, 
                payload: {
                    snackbarOpen: true,
                    snackbarVariant: "error",
                    snackbarMessage: errorShow,
                    snackbarDuration: 20000
                }
            });
            return false
        }
    }

      // if(response.data.hasOwnProperty("list") || response.data.hasOwnProperty("result")){
          
          if(callback) callback(response.data);

          // if(getEditRoute==action.type){
          //     return true
          // }

          //Truck Location
          if(getTruckLocation==action.type){
            let truck_location = response.data.list[0];
            truck_location.position = {
              lat: response.data.list[0].lat , 
              lng: response.data.list[0].lng,
            }
            truck_location.name = "Truck Location ( "+response.data.list[0].lat+","+response.data.list[0].lng+' )';
            yield put({ type: mapSetTruckLocation, payload:truck_location });
          }  else if(postConfirmRoute==action.type){ 
              // yield put({ type: SET_MAP_LOADING, payload: false });
          } else {

              let polylines = setPolylines(response.data.list[0],false);
              let hazardMarkers = setHazardMarkers_array(response.data.list[0],false);
              let circles = setCircles_array(response.data.list[0],false);
              let polygons = setPolygons_array(response.data.list[0],false);
              let dropPoints = setDropPoint_array(response.data.list[0],false);
              let subLegs = setSubLegs(response.data.list[0],false);
              // let bounds = setBounds_array(response.data.list);
            
              
              yield put({ type: mapSetMarkers,  payload:hazardMarkers } );
              yield put({ type: mapSetCircles, payload:circles });
              yield put({ type: mapSetPolygons, payload:polygons });
              yield put({ type: mapSetDropPoint,  payload:dropPoints } );
              yield put({ type: mapSetSubLegs,  payload:subLegs } );
              // yield put({ type: mapSetPolygons, payload:polygons });
              // yield put({ type: mapSetBounds, payload:bounds });

              yield put({ type: mapSetSuggestionPolylines, payload:polylines });
              yield put({ type: mapSetSuggestionRoutes, payload:response.data.list[0] });


              if(getEditRoute!=action.type){
                let bounds = setBoundsWaypoints(polylines)
                yield put({ type: mapSetBounds, payload:bounds });
              }
              
              // yield put({ type: SET_MAP_LOADING, payload: false });
          }

          //==========================

          // let legs = setLegs(response.routes.Legs,false);

          // let waypoints = []
          // legs.map((leg,index) => {
          //   waypoints.push({ position : { lat : leg.path[0].lat() , lng : leg.path[0].lng() } })
          // })
          
          // let last_leg_idx = legs.length - 1
          // let last_path_idx = legs[last_leg_idx].path.length - 1
      
          // waypoints.push(
          //   { position : 
          //     { 
          //         lat : legs[last_leg_idx].path[last_path_idx].lat() , 
          //         lng : legs[last_leg_idx].path[last_path_idx].lng() 
          //       } 
          //   })

      
          // // yield put({ type: mapSetCandidateRoutes, payload: {} });
          // // yield put({ type: mapSetCandidatePolylines, payload: {} });
      
          // yield put({ type: mapSetMasterLegs, payload: { legs : legs, waypoints : waypoints } });
          // let bounds = setBoundsWaypoints(response.routes.Legs)
        

          // yield put({ type: mapSetMasterPolylines, payload:waypoints });
          // yield put({ type: mapSetBounds, payload:bounds });


          //==========================
          // yield put({ type: mapSetBounds, payload:bounds });

          // yield put({ type: mapSetSuggestionRoutes, payload:response.data.list });
          // yield put({ type: mapSetSuggestionPolylines, payload:polylines });
          // yield put({ type: mapSetBounds, payload:bounds });

          //=============
          // let markers = setMarkers_array(response.data.list,false);
          // let circles = setCircles_array(response.data.list,false);
          // // let polygons = setPolygons(response.data.list,false);
          // let bounds = setBounds_array(response.data.list);
        
          // yield put({ type: mapSetMarkers,  payload:markers } );
          // yield put({ type: mapSetCircles, payload:circles });
          // // yield put({ type: mapSetPolygons, payload:polygons });
          // yield put({ type: mapSetBounds, payload:bounds });

          //==================
          // yield put({ 
          //     type: SET_SNACKBAR, 
          //     payload: {
          //         snackbarOpen: true,
          //         snackbarVariant: "success",
          //         snackbarMessage: "Manual reload data success",
          //         snackbarDuration: 2000
          //     }
          // });
          if(getTruckLocation!==action.type){
            // yield put({ type: SET_MAP_LOADING, payload: false });
          }
          // yield put({ type: SET_MAP_LOADING, payload: false });
          return true
      // }
      

      // yield put({ 
      //     type: SET_SNACKBAR, 
      //     payload: {
      //         snackbarOpen: true,
      //         snackbarVariant: "error",
      //         snackbarMessage: "API error",
      //         snackbarDuration: 20000
      //     }
      // });

      // return false
  } catch (error) {
    yield put({ type: SET_MAP_LOADING, payload: false });
      yield put({ 
          type: SET_SNACKBAR, 
          payload: {
              snackbarOpen: true,
              snackbarVariant: "error",
              snackbarMessage: error.toString(),
              snackbarDuration: 20000
          }
      });
  }
}
