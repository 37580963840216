import { handleActions,createAction } from 'redux-actions';
// ACTION CREATORS
export const searchRequest = createAction('SEARCH_REQUEST');
export const suggestResult = createAction('SUGGEST_RESULT');

const initialState = {
    search:'',
    suggest :[],
};

export default handleActions(
    { 
      [searchRequest](state, { payload }) {
        return { ...state, search: payload };
      },
      [suggestResult](state, { payload }) {
        return { ...state, suggest: payload };
      },
    },
    initialState
);