import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import classNames from "classnames";
import PanelDialog from "../Ui/PanelDialog";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import { propTypes, reduxForm, Form, Field } from "redux-form";
import { connect } from "react-redux";
import compose from "recompose/compose";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';

//Reducer
import { uiPanelDialogOpen } from "../../reducers/ui";
import { required, number } from "../../libs/validation";
import { apiCallRequest } from "../../reducers/api";
import {getWarningTypes} from "../../reducers/map/api";
import { formWarningType } from "../../reducers/ui/mapForm";

import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  formControl: {
    margin: theme.spacing.unit
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  button: {
    margin: theme.spacing.unit
  },
  titlePanel: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500])
  },
  center:{
    position: 'relative',
    left: '20px'
  }
});
const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const renderInputArea = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    multiline={true}
    rows={2}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children
}) => (
  <Select
    floatingLabelText={label}
    errorText={touched && error}
    {...input}
    onChange={(event, index, value) => input.onChange(value)}
    children={children}
  />
);
const renderCheckbox = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <Checkbox
    
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    checked={inputProps.value ? true : false}
  />
);
const renderCheckbox2 = ({ input, label }) =>
  <Checkbox
    label={label}
    onChange={input.onChange}
    //checked={input.value ? true : false}
    
  />


class WarningForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
    this.props.initialize({
      //logisticPointGroup: this.props.logistic.text || "",
    });
  }
  componentDidMount() {
    if(this.props.warningForm){

      // console.log('#componentDidMount warningForm',this.props.warningForm);
      //this.props.ADD_LOCATIONS(...logisticPoint);
      this.props.initialize({...this.props.warningForm});
      this.props.change({...this.props.warningForm});
    }
  }
  componentWillUnmount() {
  }
  componentWillReceiveProps(nextProps, props) {
    
  }
  handleSave = (form) => {
    // console.log('#handleSave form',form)
    this.props.onDialogOpen(false);
    let url = Configs[env].BACKEND_HOST + "/warningType";
    let method = 'POST';
    if (this.props.warningForm.id) {
      url = Configs[env].BACKEND_HOST + "/warningType/"+form.id;
      method = 'PUT';
    }
    let formData = form;
    this.props.API_REQUEST({
      url: url,
      method : method,
      form: {...formData,status : formData.status ? 1 : 0 },
      callback : this.handleComplete
    });
  }

  handleCancel = reset => {
    this.props.onDialogOpen(false);
    this.props.FORM_WARNING({});
  }
  handleComplete = () => {
    this.props.onDialogOpen(false);
    this.props.GET_WARNING_TYPES({
      method : "GET",
      url: Configs[env].BACKEND_HOST + "/warningType",
      filter : {}
    });
    this.props.onDialogOpen(false);
    this.props.FORM_WARNING({});
  }
  handleChangeStatus = (event) =>{
    // console.log('handleChangeStatus event',event.target);
    this.props.change("status",event.target.value);
  }
  renderMenuItem() {
    if (this.props.grouplist !== null) {
      if (
        Array.isArray(this.props.grouplist.result) &&
        this.props.grouplist.result.length > 0
      ) {
        return this.props.grouplist.result.forEach(data => {
          //debugger
          return (
            <MenuItem key={data.id} value={data.id}>
              {data.name}
            </MenuItem>
          );
        });
      } else return null;
    } else return null;
  }

  render() {
    const { handleSubmit, submitting , classes } = this.props;
    return (
      <div style={{ padding: "5px" }}>
        <Form
          autoComplete={"off"}
          autoCorrect={"off"}
          spellCheck={"off"}
          onSubmit={handleSubmit(this.handleSave.bind(this))}
        >
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Field
                name="code"
                component={renderInput}
                label="Code"
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="description"
                component={renderInputArea}
                label="Description"
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="voice"
                component={renderInputArea}
                label="Voice"
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12}>
                <Field 
                name="status" 
                component={renderCheckbox} 
                label="STATUS" 
                onChange={this.handleChangeStatus}
                /> STATUS
            </Grid>
            <Grid item xs={12}>
              <DialogActions>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  aria-label="Save"
                  //disabled={submitting}
                  disabled={this.props.isLoading}
                  type="submit"
                >{this.props.isLoading && <CircularProgress className={classes.center} size={25} thickness={2} />}
                  Save
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  variant="outlined"
                  color="secondary"
                  disabled={this.props.isLoading}
                  aria-label="Cancel"
                  onClick={this.handleCancel}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = function(state, ownProps) {

  return {
    dialogOpen: state.ui.dialogOpen,
    ///isLoading: state.api.fetching,
    isLoading: state.ui.isLoading,
    warningForm : state.mapForm.warningForm,
    type: ownProps.type
    // ...ownProps,
  };
};
const mapActionsToProps = {
  onDialogOpen: uiPanelDialogOpen,
  API_REQUEST: apiCallRequest,
  GET_WARNING_TYPES : getWarningTypes,
  FORM_WARNING : formWarningType,
};

const enhance = compose(
  reduxForm({
    form: "warningtypeForm"
    // validate
  }),
  connect(
    mapStateToProps,
    mapActionsToProps
  ),
  withStyles(styles)
);
export default enhance(WarningForm);
