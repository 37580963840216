import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { uiAlertOpen,uiAlertReset } from '../../reducers/ui/index'


function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AlertDialogSlide extends React.Component {
  // state = {
  //   open: false,
  // };

  // handleClickOpen = () => {
  //   this.setState({ open: true });
  //   setTimeout(()=>{
  //     this.handleClose();
  //   },this.props.timeout || 1000);
  // };

  // handleClose = () => {
  //   this.setState({ open: false });
  // };
  handleOk = () => {
    this.props.uiAlertReset();
  }
  handleCancel = () => {
    this.props.uiAlertReset();
  }
  render() {
    return (
      <div>
        <Dialog
          fullWidth={false}
          maxWidth={'md'}
          open={this.props.alert.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {this.props.alert.title || "Notification"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {this.props.alert.message}
            </DialogContentText>
          </DialogContent>
          {
            this.props.alert.type !== undefined
            && this.props.alert.type === "confirm" 
            && 
            <DialogActions>
              <Button onClick={this.handleCancel} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleOk} color="primary">
                Ok
              </Button>
            </DialogActions>
          }
          {
            this.props.alert.type !== undefined
            && this.props.alert.type ===  "warning"
            && 
            <DialogActions>
              <Button onClick={this.handleOk} color="primary">
                Ok
              </Button>
            </DialogActions>
          }
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = function(state,ownProps) {
  return {
    alert : state.ui.alert,
  }
}
const mapActionsToProps = {
  uiAlertOpen,
  uiAlertReset 
}

AlertDialogSlide.propTypes = {
  title: PropTypes.string,
  type : PropTypes.string,
  message : PropTypes.string,
  timeout : PropTypes.number,
};
const Alert = connect(mapStateToProps,mapActionsToProps)(AlertDialogSlide)

export default Alert;