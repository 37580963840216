import { createAction, handleActions } from 'redux-actions';

export const searchLocation = createAction("SEARCH_LOCATION");
export const searchUsers = createAction("SEARCH_USERS");
export const searchGroupCompany = createAction("SEARCH_GROUP_COMPANY");
export const searchCompany = createAction("SEARCH_COMPANY");
export const searchLevel = createAction("SEARCH_LEVEL");
export const searchProvider = createAction("SEARCH_PROVIDER");
export const searchProblem = createAction("SEARCH_PROBLEM");
export const searchAction = createAction("SEARCH_ACTION");

export const searchLogisticsRoute = createAction("SEARCH_LOGISTICS_ROUTE");
export const searchHazardPoint = createAction("SEARCH_HAZARD_POINTS");
export const searchManage = createAction("SEARCH_MANAGE");
export const searchOperation = createAction("SEARCH_OPERATION");
export const searchFrame = createAction("SEARCH_FRAME");
export const searchFrameTxt = createAction("SEARCH_FRAME_TXT");
export const searchOperationTxt = createAction("SEARCH_OPERATION_TXT");
export const searchHazardImpact = createAction("SEARCH_HAZARD_IMPACT");
export const searchHazardRoute = createAction("SEARCH_HAZARD_ROUTE");
export const searchHazardLogs = createAction("SEARCH_HAZARD_LOGS");

export const searchReset = createAction("SEARCH_RESET");

const initialState = {
    search_location:"",
    search_users:"",
    search_group_company:"",
    search_company:"",
    search_problem:"",
    search_provider:"",
    search_level:"",
    search_action:"",
    search_frame_txt:"",
    search_operation_txt:"",
    search_logistics_route : {},
    search_hazard_point : {},
    search_manage : {},
    search_operation : {},
    search_frame : {},
    search_hazard_impact : {},
    search_hazard_route : {},
    search_hazard_logs : {},
};

// REDUCERS
export default handleActions(
{
    [searchReset](state, { payload }) {
        return { ...state, 
            search_location:"",
            search_users:"",
            search_group_company:"",
            search_company:"",
            search_problem:"",
            search_provider:"",
            search_level:"",
            search_action:"",
            search_frame_txt:"",
            search_operation_txt:"",
            search_logistics_route : {},
            search_hazard_point : {},
            //search_manage : {},
            //search_operation : {},
            search_hazard_impact : {},
            search_hazard_route : {},
            search_hazard_logs : {},
        };
    },
    [searchLocation](state, { payload }) {
        return { ...state, search_location: payload };
    },
    [searchUsers](state, { payload }) {
        return { ...state, search_users: payload };
    },
    [searchLogisticsRoute](state, { payload }) {
        return { ...state, search_logistics_route: payload };
    },
    [searchHazardPoint](state, { payload }) {
        return { ...state, search_hazard_point: payload };
    },
    [searchHazardImpact](state, { payload }) {
        return { ...state, search_hazard_impact: payload };
    },
    [searchHazardRoute](state, { payload }) {
        return { ...state, search_hazard_route: payload };
    },
    [searchHazardLogs](state, { payload }) {
        return { ...state, search_hazard_logs: payload };
    },
    [searchGroupCompany](state, { payload }) {
        return { ...state, search_group_company: payload };
    },
    [searchCompany](state, { payload }) {
        return { ...state, search_company: payload };
    },
    [searchLevel](state, { payload }) {
        return { ...state, search_level: payload };
    },
    [searchProvider](state, { payload }) {
        return { ...state, search_provider: payload };
    },
    [searchProblem](state, { payload }) {
        return { ...state, search_problem: payload };
    },
    [searchAction](state, { payload }) {
        return { ...state, search_action: payload };
    },
    [searchManage](state, { payload }) {
        return { ...state, search_manage: payload };
    },
    [searchOperation](state, { payload }) {
        return { ...state, search_operation: payload };
    },
    [searchFrame](state, { payload }) {
        return { ...state, search_frame: payload };
    },
    [searchFrameTxt](state, { payload }) {
        return { ...state, search_frame_txt: payload };
    },
    [searchOperationTxt](state, { payload }) {
        return { ...state, search_operation_txt: payload };
    },
    
},initialState);