import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import green from "@material-ui/core/colors/green";
import { getLocationGroup,getLogisticPoint } from "../../reducers/map/api";
import {
  apiPointListRequest,
  apiPointListReset
} from "../../reducers/api";
import { selectLocationGroup } from "../../reducers/ui/selectList";
import { connect } from "react-redux";

import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  formControl: {
    margin: theme.spacing.unit
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  button: {
    margin: theme.spacing.unit
  },
  padding5px: {
    display: "flex",
    flexWrap: "wrap",
    padding: "5px",
    margin: "10px"
  },
  cssGreen: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  titlePanel: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500])
  },
  selectStyles: {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    })
  }
});

class LocationGroupSelect extends Component {
  state = {
    value:"",
  };
  componentWillReceiveProps(nextProps){
    if(nextProps.locationGroup.idSelect !== this.props.locationGroup.idSelect){
      let payload = { value : nextProps.locationGroup.value , text:nextProps.locationGroup.name};
      this.props.selectLocationGroup(payload);
    }
    else if(nextProps.location_group != this.props.location_group){
      let idSelect = this.props.locationGroup.idSelect
      nextProps.location_group.forEach((element,index) => {
        if(element.id === idSelect){
          let payload = { value : JSON.stringify(nextProps.location_group[index]) , text:element.name};
          this.props.selectLocationGroup(payload);
        }
      });
    }
  }
  handleChange = event => {
    let valueSelect = JSON.parse(event.target.value);
    let payload = { value : event.target.value , text:valueSelect.name};
    console.log('#handleChange payload',payload);
    this.props.selectLocationGroup(payload);
    
    //Data for create Marker Group

    
    this.props.GET_LOGISTIC_POINTS({
      url:Configs[env].BACKEND_HOST + "/locationCond",
      filter: {
        logisticsPointGroup: valueSelect.logisticsPointGroup,
        locationGroupId: valueSelect.id,
      }
    });
  };
  renderMenuItem() {
    if(this.props.location_group !== undefined){
      var menuItem = this.props.location_group;
      return menuItem.map(function(data, index) {
        return (
          <MenuItem key={index} value={JSON.stringify(data)}>
            {data.name}
          </MenuItem>
        );
      });
    }
    else 
      return null;
  }
  render() {
    return (
        <FormControl required fullWidth>
            <InputLabel shrink htmlFor="location-required">
            LOCATION GROUP
            </InputLabel>
            <Select
            testing="select-location-group"
            value={this.props.locationGroup.value}
            onChange={this.handleChange}
            styles={styles.selectStyles}
            name="location"
            fullWidth
            placeholder="Search Location Group"
            inputProps={{
                id: "location-required"
            }}
            >
            {this.renderMenuItem()}
            </Select>
        </FormControl>
    );
  }
}
const mapStateToProps = function(state) {
  return {
    locationGroupList: state.api.resultGroupList,
    locationGroup: state.selection.locationGroup,
    location_group: state.map.location_group,//Kong
  };
};
const mapActionsToProps = {
  selectLocationGroup,
  apiPointListRequest,
  apiPointListReset,
  GET_LOCATION_GROUP: getLocationGroup,//Kong
  GET_LOGISTIC_POINTS : getLogisticPoint,//Kong
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(LocationGroupSelect));
