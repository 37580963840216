import { takeLatest, call, put } from "redux-saga/effects";
import { authActionTypes } from "../reducers/auth";
import Cookies from 'js-cookie';

import moment from 'moment';

import Configs from "../config/config";
const env = process.env.NODE_ENV;

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(authActionTypes.authLoginRequest, loginWorkerSaga);
  yield takeLatest(authActionTypes.isAuthenticated, isAuthWorkerSaga);
  yield takeLatest(authActionTypes.authLogoutRequest, logoutWorkerSaga);
}

// function that makes the api request and returns a Promise for response
function fetchLogin(params) {

  let callback = params.payload.callback;
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache': "no-cache",
    'Authorization': Configs[env].AUTHORIZATION,
  };
  let inputParams = {
    list:[{...params.payload}]
  };


  return fetch(Configs[env].BACKEND_HOST + "/login", {
    method: "POST",
    body: JSON.stringify(inputParams),
    headers: headers,
    //credentials: "include"
  })
  .then(resp=> {
    // Here you get the data to modify as you please

    return resp.json()
  })
  .then(response =>{

    if(callback) callback(response);
    return {response};
  })
  .catch(error =>{ 
    // If there is any error you will catch them here
    return { error }
  });  
  /*
    .then(function(data) {

      if(callback) callback(data);
      // Here you get the data to modify as you please
      return data.json();
    })
    .catch(function(error) {

      // If there is any error you will catch them here
      return error;
    });*/
}
// function that makes the api request and returns a Promise for response
function fetchLogout(params) {

  let callback = params.payload.callback;
  let headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache': "no-cache",
    'Authorization': Configs[env].AUTHORIZATION,
    'token':localStorage.getItem('token'),
  };
  return fetch(Configs[env].BACKEND_HOST + "/logout", {
    method: "POST",
    body: JSON.stringify({}),
    headers: headers,
    //credentials: "include"
  })
  .then(resp=> {
    // Here you get the data to modify as you please

    return resp.json()
  })
  .then(response=>{

    if(callback) callback(response);
    return { response };
  })
  .catch(function(error) {
    // If there is any error you will catch them here
    return { error }
  }); 
}

function fetchIsAuthenticated(params) {
  return fetch(Configs[env].BACKEND_HOST + "/api/auth", {
    method: "GET",
    headers: { "Content-Type": "application/json", Cache: "no-cache" },
    credentials: "include"
  })
    .then(function(data) {
      // Here you get the data to modify as you please
      return data.json();
    })
    .catch(function(error) {
      // If there is any error you will catch them here
      return error;
    });
}

// worker saga: makes the api call when watcher saga sees the action
function* loginWorkerSaga(action) {
  try {
    const { response, error }   = yield call(fetchLogin, action);

    if(response) {

        // dispatch a response action
        let auth = true;
        let authResult = true;
        let profile = {
          email : response.email,
          firstName : response.firstName,
          lastName : response.lastName,
          id : response.id,
          company : response.company,
        };
        let user_authen = {
          stamp_datetime :  moment(moment().subtract(5, 'seconds')).format('YYYY/MM/DD HH:mm:ss')
        }
        // console.log("Timer : authSage (now-5)",moment(moment().subtract(5, 'seconds')).format('YYYY/MM/DD HH:mm:ss'))
        localStorage.setItem('user_authen', JSON.stringify(user_authen));

        let user_authen_localStorage = JSON.parse(localStorage.getItem('user_authen'));
        // console.log("Timer : authSage (localStorage)",user_authen_localStorage.stamp_datetime)



        if(response.errorList){
          auth = false;
          authResult = false;
          profile = {};
        }
        yield put({ type: authActionTypes.authLoginResponse, ...response,auth ,authResult,profile});
        // yield put({ type: authActionTypes.authLoginResponse, ...response,auth,authResult });

    } else {
        alert("Error : Please check the internet")
        yield put({ type: authActionTypes.authLoginError, error });
    }

  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: authActionTypes.authLoginError, error });
  }
}
function* logoutWorkerSaga(action) {
  try {
    const response = yield call(fetchLogout, action);

    // dispatch a response action
    let auth = false;
    let authResult = false;
    let profile = {};
    //clearLocalStorage();
    window.localStorage.clear();
    // window.location.href = "/login"
    yield put({ type: authActionTypes.authLogoutResponse, ...response,auth ,authResult,profile});
    
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: authActionTypes.authLogoutError, error });
  }
}
function* isAuthWorkerSaga(action) {
  try {
    const response = yield call(fetchIsAuthenticated, action);

    // dispatch a response action
    yield put({ type: authActionTypes.authLoginResponse, ...response });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: authActionTypes.authLoginError, error });
  }
}

function clearLocalStorage(){
    Cookies.remove('user_sid');
    window.localStorage.clear();
    //localStorage.setItem('id',null)
    //localStorage.setItem('email',null)
    //localStorage.setItem('firstName',null)
    //localStorage.setItem('lastName',null)
}