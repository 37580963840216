import React, {Component} from "react";
import Avatar from '@material-ui/core/Avatar';

const styles = {
    avatar: {
        margin: 5,
        width: 20,
        height: 20,
    },
    bigAvatar: {
        margin: 10,
        width: 60,
        height: 60,
    },
};


export default class TollRenderer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        }
    }


    render() {
        
        return (
            <div>
                {this.state.value==1 && (<Avatar alt="Toll" sizes={20} src="/img/toll_use.png" style={{ width: 20,height:20 }} className={styles.avatar} />)}
            </div>
        );
    }
};

