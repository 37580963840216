import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Marker } from "react-google-maps";

//Component 
import EditBtnRenderer from "../Ui/Renderer/EditBtnRenderer";
import DeleteBtnRenderer from "../Ui/Renderer/DeleteBtnRenderer";
import CategoryIconRenderer from "../HazardPoints/Renderer/CategoryIconRenderer";
//Reducer
import { apiGetCallRequest } from "../../reducers/api";
import { mapSetLogisticPoint, mapSelectedMarker,
  MAP_LOG,SET_HAZARD_PHOTO,ADD_HAZARD_VDO } from "../../reducers/map";
//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    margin: 0,
    //marginLeft: theme.spacing.unit,
    //marginRight: theme.spacing.unit,
    width: 200
  }
});
class Routes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pointList: this.props.hazard_route,
      url_endpoint: {
        response: false,
        endpoint: Configs[env].BACKEND_HOST
      },
      columnDefs: [{
          headerName: "Plan Arrival Time",
          field: "planArrivalTime",
          width: 70
        },{
          headerName: "Route",
          field: "routeCode",
          width: 60
        },{
          headerName: "Run Seq.",
          field: "runSeq",
          width: 50
        },{
          headerName: "ETA",
          field: "eta",
          width: 60
        },{
          headerName: "Part Usage Time",
          field: "partUsageTime",
          width: 80
        },{
          headerName: "Status",
          field: "status",
          width: 100
        }
      ],
      rowData: null,
      context: { componentParent: this },

      frameworkComponents: {
        categoryIconRender : CategoryIconRenderer,
      }
    };
  }
  componentDidMount() {
    this.autoSizeAll();
  }
  componentWillUnmount() {}
  componentDidUpdate(){
    this.autoSizeAll();
  }
  componentWillReceiveProps(nextProps) {
    this.focusRow(nextProps.marker_selecting)
  }
  focusRow = (marker_selecting) => {
    let gridApi = this.gridApi
    if(gridApi){
      gridApi.deselectAll()
      this.gridApi.forEachNode(function(node) {
        if (node.data.latitude === marker_selecting) {

          node.setSelected(true,true);
          gridApi.ensureIndexVisible(node.rowIndex, { position: 'top' }); 
        }
      });
    }
  }
  componentDidUpdate () {
    // this.autoSizeAll();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  }
  autoSizeAll = () => {
    var allColumnIds = [];
    if(this.gridColumnApi){

      this.gridColumnApi.getAllColumns().forEach(function(column,index) {
        if(index!=0)allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  }
  renderRow() {
    if (Array.isArray(this.props.hazard_route)) {
      return this.props.hazard_route;
    } else return [];
  }

  handleSelectionChanged = node => {
    
    try{
      var selectedRows = node.api.getSelectedRows();
      this.props.SELECT_MARKER(selectedRows[0].latitude);
      if(selectedRows[0].mediaUrl && selectedRows[0].mediaUrl.length>0){
          let video = null;
          let photo = [];
          selectedRows[0].mediaUrl.forEach(function(row){

              if(row.search(/mp4/i) > 0 || row.search(/jpg/i) > 0 || row.search(/jpeg/i) > 0){
                  let files = row.split('.');
                  if(files[files.length-1].toLowerCase() === 'mp4') video = row;
                  else photo.push(row);
              }
          });
          this.props.SET_HAZARD_PHOTO(photo);
          this.props.ADD_HAZARD_VDO(video);
      } else {
          this.props.SET_HAZARD_PHOTO([]);
          this.props.ADD_HAZARD_VDO(null);
      }
    } catch (e) {
      this.props.MAP_LOG({ error : e, detail : "Location.js - handleSelectionChanged"})
      
    }

  };
  render() {
    return (
      <div style={{ width: "100%", height: 'calc(100vh - 500px)'  }}>
        <div
          className="ag-theme-balham"
          style={{
            height: 'calc(100vh - 500px)' ,
            width: "100%",
            marginTop: "10px"
          }}
        >
          <AgGridReact
            enableSorting={true}
            singleClickEdit={true}
            enableColResize={true}
            suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            rowData={this.renderRow()}
            rowSelection="single"
            // onRowClicked={this.handleSelectionChanged}
            // onSelectionChanged={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady.bind(this)}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function(state, ownProps) {
  return {
    isLoading: state.api.fetching,
    logistic_point: state.map.logistic_point,
    hazard_route: state.map.hazard_route,
    textSearchLocation: state.search.search_location,
    marker_selecting: state.map.marker_selecting,
  };
};
const mapActionsToProps = {
  getRoutes: apiGetCallRequest,
  setLogisticPoint: mapSetLogisticPoint,
  onSelectedMarker: mapSelectedMarker,
  SELECT_MARKER: mapSelectedMarker,
  MAP_LOG:MAP_LOG,
  SET_HAZARD_PHOTO,
  ADD_HAZARD_VDO
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapActionsToProps
    //mapDispatchToProps
  ),
  withStyles(styles)
)(Routes);
