import { handleActions,createAction } from 'redux-actions';

export const getDataOperation = createAction('GET_DATA_OPERATION');
export const successDataOperation = createAction('SUCCESS_DATA_OPERATION');

const initialState = {
    loading: false,
    data:[],
    dataParser:[]
}
function parser(data){
    let dataValue = [];
    data.forEach(element => {
        let object = {}
        Object.keys(element).forEach((key)=>{
            if(key === "status")
                object[key] = element[key];
            else
                object[key] = element[key].value;
            
        })
        dataValue.push(object)
    });
    return dataValue
}
export default handleActions({

    [getDataOperation](state,{ payload }){
        return {...state, data : [],dataParser : [],loading:true, }
    },
    [successDataOperation](state,{payload}){
        return {...state, data : payload,dataParser:parser(payload), loading : false}
    },
},initialState
);
    
