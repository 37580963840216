import React, { Component } from "react";
import {
  Marker,
  InfoWindow
} from "react-google-maps";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import compose from 'recompose/compose';
import { MAP_SELECT_CANDIDATE,mapResetMap ,SELECT_MASTER_OR_CANDIDATE} from "../../../reducers/map";
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import {
} from "../../../reducers/map";


const styles = theme => ({
    div_hover: { 
        backgroundColor: "#C3DFFF",
        cursor: "pointer",
        fontSize: "small",
        padding:"5px",
        display: 'flex',
        flexDirection: 'row'
    },
    div_selecting: { 
        backgroundColor: "#89BFFE",
        cursor: "pointer",
        fontSize: "small",
        padding:"5px",
        display: 'flex',
        flexDirection: 'row'
    },
    normal :{
        cursor: "pointer",
        fontSize: "small",
        padding:"5px",
        display: 'flex',
        flexDirection: 'row'
    },
    avatar: {
        margin: 5,
        width: 15,
        height: 15,
    },

  })
class RoutesViewBoxes extends Component {
    constructor(props) {
        super(props);
        // var header_template = '<span class="text-danger" style="height:30px;">Some Value </span>';
        this.state = {
            buttonIsHovered: null,
            routesview : this.props.routesview
        }
    }
    componentWillUpdate(nextProps, nextState) {
    }

    componentDidMount() {
        if(this.props.routesview.length > 0){ 
            this.props.MAP_SELECT_CANDIDATE(this.props.routesview[0].id);
            this.props.SELECT_MASTER_OR_CANDIDATE(1)
        }
    }

    setButtonHovered = (param) => {
        this.setState({
            buttonIsHovered: param
        })
    }

    onRouteClick = id => {
        this.props.MAP_SELECT_CANDIDATE(id);
        this.props.SELECT_MASTER_OR_CANDIDATE(1)
    };

    render() {
    const { classes } = this.props;
    return (
        <div style={{ background: `white`, border: `1px solid #ccc`, padding: "0px", position: "absolute" ,left: "10px" , top: "55px"}}>
            {this.props.routesview.length > 0 && this.props.routesview.map((list, idx) => {
                let TotalDistance = (list.routes.TotalDistance / 1000).toFixed(2) + " KM";
                //TollUsage
                var seconds = parseInt(list.routes.TotalTime) //because moment js dont know to handle number in string format
                var hours = Math.floor(moment.duration(seconds,'minutes').asHours())!=0 ? Math.floor(moment.duration(seconds,'minutes').asHours()) + " Hour " : '';
                var mins = moment.duration(seconds,'minutes').minutes() + ' Mins' ;
                let TotalTime =  hours+' '+mins;

                let select_class = classes.normal
                if(this.state.buttonIsHovered === idx && this.props.candidate_selecting === list.id) select_class = classes.div_selecting
                else if(this.state.buttonIsHovered === idx) select_class = classes.div_hover
                else if(this.props.candidate_selecting === list.id) select_class = classes.div_selecting

                return(
                        <div className={select_class}
                            onMouseEnter={() => this.setButtonHovered(idx)} 
                            onMouseLeave={() => this.setButtonHovered(null)}
                            onClick={() => this.onRouteClick(list.id)}
                            >
                            <b>{idx+1})</b>&nbsp;{list.name} - {TotalDistance} / {TotalTime}&nbsp;
                            {list.routes.TollUsage === "1" && (<img alt="Toll" sizes={20} src="/img/toll_use.png" style={{ width: 15,height:15 }}/>)}
                        </div>
                )
            })
        }
        </div>
    );
    }
}

const mapStateToProps = state => {
  return {
    candidate_selecting : state.map.candidate_selecting,
  };
};

const mapActionsToProps = {
    SELECT_MASTER_OR_CANDIDATE : SELECT_MASTER_OR_CANDIDATE,
    MAP_SELECT_CANDIDATE
};

// export default connect(
//   mapStateToProps,
//   mapActionsToProps,
//   withStyles(styles)
// )(RoutesViewBoxes);


const enhance = compose(
    connect(mapStateToProps,mapActionsToProps),
    withStyles(styles)
  );
export default enhance(RoutesViewBoxes);