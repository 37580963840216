import { createAction, handleActions } from 'redux-actions';

export const settingUsers = createAction("SETTING_USERS");
export const settingGroupCompanies = createAction("SETTING_GROUP_COMP");
export const settingCompanies = createAction("SETTING_COMP");
export const settingUserLevel = createAction("SETTING_USER_LEVEL");
export const settingConfigs = createAction("SETTING_CONFIGS");
export const settingProvider = createAction("SETTING_PROVIDER");
export const settingProblem = createAction("SETTING_PROBLEM");
export const settingAction = createAction("SETTING_ACTION");

//Select
export const settingUserSelect = createAction("SETTING_USER_SELECT");
export const settingGroupSelect = createAction("SETTING_GROUP_SELECT");
export const settingCompanySelect = createAction("SETTING_COMPANY_SELECT");
export const settingLevelSelect = createAction("SETTING_LEVEL_SELECT");
export const settingProviderSelect = createAction("SETTING_PROVIDER_SELECT");
export const settingProblemSelect = createAction("SETTING_PROBLEM_SELECT");
export const settingActionSelect = createAction("SETTING_ACTION_SELECT");

//Dialog
export const settingOpenNewPass = createAction("SETTING_NEW_PASSWORD");
export const settingOpenLevel = createAction("SETTING_OPEN_LEVEL");
export const settingOpenGroup = createAction("SETTING_OPEN_GROUP");
export const settingOpenCompany = createAction("SETTING_OPEN_COMPANY");
export const settingOpenProvider = createAction("SETTING_OPEN_PROVIDER");
export const settingOpenProblem = createAction("SETTING_OPEN_PROBLEM");
export const settingOpenAction = createAction("SETTING_OPEN_ACTION");

const initialState = {
    setting_users : null,
    setting_companies : null,
    setting_groupcompanies : null,
    setting_userlevel : null,
    setting_configs : null,
    setting_provider : null,
    setting_problem : null,
    setting_action : null,

    user_selecting : null,//user_id
    level_selecting : null,
    group_selecting : null,
    company_selecting : null,
    provider_selecting : null,
    problem_selecting : null,
    action_selecting : null,

    newpass_dialog : false,
    level_dialog : false,
    group_dialog : null,
    company_dialog : null,
    provider_dialog : null,
    problem_dialog : null,
    action_dialog : null,
};

// REDUCERS
export default handleActions(
{
    [settingUsers](state, { payload }) {
        return { ...state, setting_users: payload };
    },
    [settingGroupCompanies](state, { payload }) {
        return { ...state, setting_groupcompanies: payload };
    },
    [settingCompanies](state, { payload }) {
        return { ...state, setting_companies: payload };
    },
    [settingUserLevel](state, { payload }) {
        return { ...state, setting_userlevel: payload };
    },
    [settingProvider](state, { payload }) {
        return { ...state, setting_provider: payload };
    },
    [settingProblem](state, { payload }) {
        return { ...state, setting_problem: payload };
    },
    [settingAction](state, { payload }) {
        return { ...state, setting_action: payload };
    },
    [settingUserSelect](state, { payload }) {
        return { ...state, user_selecting: payload };
    },
    [settingGroupSelect](state, { payload }) {
        return { ...state, group_selecting: payload };
    },
    [settingConfigs](state, { payload }) {
        return { ...state, setting_configs: payload };
    },
    [settingProblemSelect](state, { payload }) {
        return { ...state, problem_selecting: payload };
    },

    [settingOpenNewPass](state, { payload }) {
        return { ...state, newpass_dialog: payload };
    },
    [settingOpenLevel](state, { payload }) {
        return { ...state, level_dialog: payload };
    },
    [settingOpenProvider](state, { payload }) {
        return { ...state, provider_dialog: payload };
    },
    [settingOpenCompany](state, { payload }) {
        return { ...state, company_dialog: payload };
    },
    [settingOpenGroup](state, { payload }) {
        return { ...state, group_dialog: payload };
    },
    [settingOpenProblem](state, { payload }) {
        return { ...state, problem_dialog: payload };
    },
    [settingOpenAction](state, { payload }) {
        return { ...state, action_dialog: payload };
    },
    
},initialState);