import { handleActions , createAction  } from 'redux-actions';

//Distance Matrix Master
export const getSearchLocation = createAction('MAP_GET_SEARCH_LOCATION');
export const getDistanceMatrix = createAction('MAP_GET_DISTANCE_MATRIX');
export const getDistanceMatrixReport = createAction('MAP_GET_DISTANCE_MATRIX_REPORT');
export const getDistanceMatrixReportDetail = createAction('MAP_GET_DISTANCE_MATRIX_REPORT_DETAIL');
export const getSearchJob = createAction('MAP_GET_SEARCH_JOB');

//Location Master
export const getLogisticPoint = createAction('MAP_GET_LOGISTICPOINTS');
export const getLogisticDetail = createAction('MAP_GET_LOGISTICDETAIL');
export const getLocationGroup = createAction('MAP_GET_LOCATION_GROUP');
export const getLocationGroupDetail = createAction('MAP_GET_LOCATION_GROUPDETAIL');

//Hazard Points
export const getWarningTypes = createAction('MAP_GET_WARNINGT_TYPES');
export const getHazardPoints = createAction('MAP_GET_HAZARD_POINTS');
export const exportHazardPoints = createAction('MAP_EXPORT_HAZARD_POINTS');
export const exportHazardLogs = createAction('MAP_EXPORT_HAZARD_LOGS');
//Impact Hazard
export const getHistoryLogs = createAction('MAP_GET_HAZARD_LOGS');
export const GET_IMPACT_HAZARDS = createAction('GET_IMPACT_HAZARDS');
export const GET_IMPACT_ROUTES = createAction('GET_IMPACT_ROUTES');
export const GET_SELECTED_HAZARDS = createAction('GET_SELECTED_HAZARDS');
export const getRouteCode = createAction('MAP_GET_ROUTE_CODE');
export const getRunSeq = createAction('MAP_GET_RUN_SEQ');
//RouteMaster
export const MAP_GET_LOGISTIC_ROUTES = createAction('MAP_GET_LOGISTIC_ROUTES');
export const getCandidateRoutes = createAction('MAP_GET_CANDIDATE_ROUTES');
export const getMasterRoutes = createAction('MAP_GET_MASTER_ROUTES');
export const getMasterRouteDetail  = createAction('MAP_GET_MASTER_ROUTE_DETAIL');
//Route Suggestion
export const getSuggestionRoutes  = createAction('MAP_GET_SUGGESTION_ROUTES');
export const getTruckLocation  = createAction('MAP_GET_TRACK_LOCATION');
export const getEditRoute  = createAction('MAP_GET_EDIT_ROUTE');
export const postConfirmRoute  = createAction('MAP_POST_CONFIRM_ROUTE');

const initialState = {
    fetching: false,
    error: null,
    url : null,
};


// REDUCERS
export default handleActions(
    {
        [getSearchJob](state, { payload }) {
            return { ...state, 
                url : payload.url,
                path: payload.path,
                fetching: true, 
                error: null 
            };
        },
        [getDistanceMatrixReport](state, { payload }) {
            return { ...state, 
                url : payload.url,
                path: payload.path,
                fetching: true, 
                error: null 
            };
        },
        [getDistanceMatrixReportDetail](state, { payload }) {
            return { ...state, 
                url : payload.url,
                fetching: true, 
                error: null 
            };
        },
        [getDistanceMatrix](state, { payload }) {
            return { ...state, 
                url : payload.url,
                locations: payload.locations,
                fetching: true, 
                error: null 
            };
        },
        [getSearchLocation](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [getLocationGroup](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [getLocationGroupDetail](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [getLogisticPoint](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [getLogisticDetail](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [getWarningTypes](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [getCandidateRoutes](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [getMasterRoutes](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [getMasterRouteDetail](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        //RoteSuggestion
        [getSuggestionRoutes](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [getTruckLocation](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [getEditRoute](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [postConfirmRoute](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        //IMPACT HAZARD
        [getHazardPoints](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [GET_IMPACT_HAZARDS](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [GET_IMPACT_ROUTES](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [getHistoryLogs](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [getRouteCode](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [getRunSeq](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [exportHazardPoints](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
        [exportHazardLogs](state, { payload }) {
            return { ...state, 
                url : payload.url,
                filter : payload.filter,
                fetching: true, 
                error: null 
            };
        },
    },
    initialState
);
