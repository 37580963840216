import React, { Component } from "react";
import PropTypes from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import { setSidebarVisibility,uiSwitchForm } from "../../reducers/ui";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import uuidv4  from 'uuid/v4';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import SplitPane from 'react-split-pane';
import { Pane } from 'react-sortable-pane';
//Component
import BaseMap from "../Map/BaseMap";
import RouteTracking from "./RouteTracking";
//Reducer
import { setActiveMenuListItem } from '../../reducers/ui';
import { selectJobDashboardId } from "../../reducers/ui/selectList";
//import ManageForm from "./ManageForm";


const MIN_HEIGHT = 25;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  gridForm : {
    padding : '10px'
  },
  subheader: {
    width: '100%',
  },
});

class RouteSuggestion extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    };
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;
    const { jobDashboardId } = this.props.match.params;
    // console.log('#componentDidMount jobDashboardId',jobDashboardId);
    if(jobDashboardId)this.props.selectJobDashboardId(jobDashboardId);
    //this.props.setActiveMenuListItem({...this.props.activeMenuListItem,subLink:'/route_suggestion'});
    setSidebarVisibility(false);

  }
  autosizeHeaders(event) {
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        "#locationMap .ag-header-cell-label"
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach(cell => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }
  render() {
    const { match} = this.props

    return (
      <div style={{ display: 'flex', height:'calc(100vh - 50px)' }}>
        <div style={{ padding: "0px",display: 'flex', flexDirection: 'column', width: '100%' , height: 'calc(100vh - 40px)' }}   testing="div-route-suggession">
          <div style={{ width: '100%',flex: '1',display: 'flex', flexDirection: 'row'}}>
            <div style={{ width: '100%' ,marginTop: '0em'}}>
              <BaseMap isReset={this.props.isReset}  />
            </div>
            </div>
          <div style={{ width: '100',display: 'flex', flexDirection: 'row'}}>
            <div style={{ width: '100%' ,marginTop: '-0.6em'}}>
              <RouteTracking/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
RouteSuggestion.propTypes = {
  match: PropTypes.object.isRequired,
};


const mapStateToProps = state => ({
  formManage : state.ui.formManage,
  activeMenuListItem : state.ui.activeMenuListItem,
});

const mapDispatchToProps = dispatch => {
  return {
    setSidebarVisibility: status => dispatch(setSidebarVisibility(status)),
    setActiveMenuListItem: payload => dispatch(setActiveMenuListItem(payload)),
    selectJobDashboardId: payload => dispatch(selectJobDashboardId(payload)),
    dispatch
  };
};
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(withRouter(RouteSuggestion));

