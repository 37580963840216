import React, { Component } from 'react';
import { propTypes, reduxForm,Form, Field } from 'redux-form';
import { connect } from 'react-redux'
import moment from 'moment'
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import compose from 'recompose/compose';
import DialogActions from "@material-ui/core/DialogActions";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import CircularProgress from '@material-ui/core/CircularProgress';
//Lib
import {
  required,
  number,
} from '../../libs/validation'
//Conponent
//Reducer
import { uiPanelDialogOpen,uiSwitchForm } from '../../reducers/ui';
import { mapDrawingMode,mapChangeCircle,mapResetMap } from '../../reducers/map';
import { getMasterRouteDetail ,getMasterRoutes} from "../../reducers/map/api";
import { formMasterRoute } from '../../reducers/ui/mapForm';
import {
  apiCallRequest,
  apiCallSuccess,
  apiCallFail
} from '../../reducers/api';
//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  center:{
    position: 'relative',
    left: '20px'
  },
})



const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
  }) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);


class MasterRouteForm extends Component {
  constructor(props){
      super(props);
      this.state = {
        marker : {
          latitude: 0,
          longitude: 0
        }
      }
  }
  componentWillMount() {

    //debugger
    /*if (this.props.mode === "EDIT") {

      this.props.GET_MASTERROUTE_DETAIL({
        url: Configs[env].BACKEND_HOST + "/masterRouteCond",
        filter: {
          id : this.props.masterRouteForm.id
        }
      });
    } else {
      // mode ADD
      if (this.props.masterRouteForm) {
        this.props.initialize({...this.props.masterRouteForm});
      }
    }*/
    if (this.props.masterRouteForm) {
      this.props.initialize({...this.props.masterRouteForm});
      this.props.change("TotalDistance", this.formatDistance(this.props.modified_route.TotalDistance));
      this.props.change("TotalTime", this.formatDateTime(this.props.modified_route.TotalTime));
      
    }
  }
  componentDidMount() {
    // this.props.DRAWING_MODE(null);
    // this.props.RESET_MAP(true);
  }
  componentWillReceiveProps(nextProps, props) {


    if(nextProps.masterRouteForm  && nextProps.masterRouteForm !== this.props.masterRouteForm){

      //this.props.initialize({...nextProps.masterRouteForm});
      //this.props.change({...nextProps.masterRouteForm});
    }
    if(this.props.modified_route!=nextProps.modified_route){
      this.props.change("TotalDistance",this.formatDistance(nextProps.modified_route.TotalDistance));
      this.props.change("TotalTime",this.formatDateTime(nextProps.modified_route.TotalTime));
    }
  }

  formatDistance(value) {
      let km = value/1000
      km = km.toFixed(2) + " KM"
      return   km;
  }

  formatDateTime(value) {
        
      var seconds = parseInt(value) //because moment js dont know to handle number in string format
      var hours = Math.floor(moment.duration(seconds,'minutes').asHours())!=0 ? Math.floor(moment.duration(seconds,'minutes').asHours()) + ' Hour' : '';
      var mins = moment.duration(seconds,'minutes').minutes() + ' Mins ' ;
      return   hours+' '+mins;
  }
  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }
  handleSave = (form) =>{
    
    if (this.props.mode.toUpperCase() === "EDIT") {
      this.updateRoutes(form);
      //Update LogisticRoute Name
      /*this.props.API_REQUEST({
        url: Configs[env].BACKEND_HOST + "/logisticsRoute/"+this.props.logisticRouteForm.logisticsRouteId,
        form: { name : form.name},
        method : "PUT",
        callback: this.updateRoutes
      });*/
    } else {
      let formData = {
        logisticsRouteId : this.props.logisticRouteForm.logisticsRouteId,
        routes: this.props.modified_route,
        name : form.name
      };
      this.props.API_REQUEST({
        url: Configs[env].BACKEND_HOST + "/route",
        form: { ...formData},
        method : "POST",
        callback: this.handleReset
      });
    }
  }
  updateRoutes = (form) => {
    let formData = {
      logisticsRouteId : this.props.logisticRouteForm.logisticsRouteId,
      routes: this.props.modified_route,
      name : form.name
    };
    let id = this.props.masterRouteForm.id;
    this.props.API_REQUEST({
      url: Configs[env].BACKEND_HOST + "/route/"+id,
      form: {...formData },
      method : "PUT",
      callback: this.handleReset
    });
  }
  handleReset = () => {
    
    if(this.props.logisticRouteForm.logisticsRouteId){
      this.props.GET_MASTER_ROUTE({
        url:Configs[env].BACKEND_HOST + "/routeCond",
        filter: {
          logisticsRouteId: this.props.logisticRouteForm.logisticsRouteId,
        }
      });
    }
    this.props.reset();
    this.props.SWITCH_FORM({ page: "manage" });
    this.props.RESET_MAP(true);
    this.props.FORM_ROUTEMASTER({});
    
    
  }
  handleClick(event) {
    this.props.DIALOG_OPEN(true);
  }
  handleClickBack(event) {
    this.props.SWITCH_FORM({page:'manage',mode:"add"});
  }
  handleDrawingMode(mode,event) {

    this.setState({drawingMode: mode},this.setMode);
  }
  setMode(){
    this.props.DRAWING_MODE(this.state.drawingMode);
  }
  render() {
    const { handleSubmit,submitting,classes } = this.props;

    return (
      <div style={{ padding: "10px"}}>
        <Toolbar variant="dense">
          <Typography variant="h6" gutterBottom className={styles.titlePanel}>
          EDIT MASTER ROUTE
          </Typography>
        </Toolbar>
        <Card className={styles.card}>
          <CardContent>
            <Form 
                autoComplete={"off"}
                autoCorrect={"off"}
                spellCheck={"off"}
                onSubmit={handleSubmit(this.handleSave.bind(this))}
            >
                <Grid container spacing={15}>
                <Grid item xs={12}>
                    <Field
                    label="Name"
                    name="name"
                    component={renderInput}
                    className={styles.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    validate={[required]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                    disabled
                    name='priority'
                    component={renderInput}
                    label='Priority'
                    className={styles.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                    disabled
                    name='originName'
                    component={renderInput}
                    label='Origin'
                    className={styles.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                    disabled
                    name='destinationName'
                    component={renderInput}
                    label='Destination'
                    className={styles.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                    disabled
                    name='TotalTime'
                    component={renderInput}
                    label='Duration'
                    className={styles.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                    disabled
                    name='TotalDistance'
                    component={renderInput}
                    label='Distance'
                    className={styles.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    margin="dense"
                    />
                </Grid>
                <Grid item xs={12}>
                    <DialogActions>
                        <Button 
                            variant="contained" size="small" color="primary" aria-label="Save" className={styles.button}  disabled={this.props.isLoading}
                            type="submit">
                            {this.props.isLoading && <CircularProgress className={classes.center} size={25} thickness={2} />}
                    Save
                        </Button>
                        <Button variant="outlined" size="small"  color="secondary" aria-label="Add" className={classNames(styles.button, styles.cssGreen)}  disabled={this.props.isLoading}
                            onClick={this.handleClickBack.bind(this)}>
                            BACK
                        </Button>
                    </DialogActions>
                </Grid>
                </Grid>
            </Form>
          </CardContent>
        </Card>
      </div>
    );
  }
}
const mapStateToProps = function(state,ownProps) {
  return {
    isLoading: state.ui.isLoading,
    modified_route: state.map.modified_route,
    mode: state.ui.formManage.mode || ownProps.mode === "edit" ? "EDIT" : "ADD",
    masterRouteForm : state.mapForm.masterRouteForm,
    logisticRouteForm : state.mapForm.logisticRouteForm,
  }
}
const mapActionsToProps =  {
  SWITCH_FORM : uiSwitchForm,
  DRAWING_MODE : mapDrawingMode,
  GET_MASTERROUTE_DETAIL : getMasterRouteDetail,
  RESET_MAP: mapResetMap,
  FORM_ROUTEMASTER : formMasterRoute,
  API_REQUEST: apiCallRequest,
  GET_MASTER_ROUTE : getMasterRoutes,
};

const enhance = compose(
  reduxForm({
    form: 'masterRouteForm',
    // validate
  }),
  connect(mapStateToProps,mapActionsToProps),
  withStyles(styles)
);
export default enhance(MasterRouteForm);