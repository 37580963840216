import React, { Component } from "react";
import {
  Marker,
  InfoWindow
} from "react-google-maps";
import { connect } from "react-redux";
import {
  mapSelectedMarker,
  mapMoveMarkerGroup,
} from "../../reducers/map";

import { uiSwitchForm } from '../../reducers/ui';

class MarkerGroup extends Component {
  componentWillUpdate(nextProps, nextState) {}
  selectInfo = arg => {
    this.props.MARKER_SELECTING("g"+this.props.id);


    
  };

  onDragEnd = e => {

    this.props.position.lat = e.latLng.lat();
    this.props.position.lng = e.latLng.lng();
    this.props.MARKER_MOVE(this.props);
  };

  closeInfo = () => {
    this.props.MARKER_SELECTING(-1)
  }

  render() {
    const id = this.props.id;
    let selected = false;


    if (this.props.marker_selecting === "g"+id) {
      selected = true;
    }

    let icon = new window.google.maps.MarkerImage(
      "http://maps.google.com/mapfiles/ms/icons/green.png",
      null /* size is determined at runtime */,
      null /* origin is 0,0 */,
      null /* anchor is bottom center of the scaled image */,
      new window.google.maps.Size(43, 43)
    );

    return (
      <Marker 
        position={this.props.position}
        icon={icon}
        key={this.props.key}
        id={this.props.id}
        onClick={this.selectInfo}
        markerCilck={this.selectInfo}
        mapClearInfo={this.props.mapClearInfo}
        onDragEnd={this.onDragEnd}
        draggable={this.props.editable}
        zIndex={0}
        >
        {selected ? (
          <InfoWindow onCloseClick={this.closeInfo}>
            <div>
              <b>Logistics Point Group: </b><br />
              {this.props.info.logisticsPoint}
              <br />
              <b>Location Group: </b><br />
              {this.props.info.name}<br />
              {/* {!this.props.isGroup && 
                <div>
                  <button onClick={() => this.props.uiSwitchForm({page:"manage",mode:"edit",editID:this.props.id})}>
                    Edit
                  </button>
                </div>}
              <br /> */}
            </div>
          </InfoWindow>
        ) : null}
      </Marker>
    );
  }
}

const mapStateToProps = state => {
  return {
    marker_selecting: state.map.marker_selecting
  };
};

const mapActionsToProps = {
  MARKER_SELECTING : mapSelectedMarker,
  MARKER_MOVE : mapMoveMarkerGroup,
  uiSwitchForm : uiSwitchForm
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MarkerGroup);
