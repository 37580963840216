import React ,{Component}from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  PieChart,
  Pie,
  Cell,
  Text,
  ReferenceArea,
  LabelList,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
});

const valueAccessor = (entry,index,key) => {

	return entry[key]>0 ? entry[key] : '';
};

const CustomizedAxisTick = props => {
  // eslint-disable-next-line react/prop-types
  const { x, y, payload } = props;  
  let label = payload.value.substring(0,5)!='empty' ? payload.value : '';
  return (
    <text  x={x}  y={y} dy={10} dx={-30} fontSize={11} color={"#000000"}>{label}</text>
  );
};
const CustomizedYAxisTick = props => {
  const { x, y, payload } = props;  
  let label = payload.value;
  return (
    <text  x={x}  y={y}  dy={0} dx={-10} fontSize={11} tickLine color={"#000000"}>{label}</text>
  );
};
const CustomizedLabel = props => {
    const {x, y, fill, value} = props;
   	return (<text 
               x={x} 
               y={y} 
               dy={-4} 
               fontSize='10' 
               fill={fill}
               textAnchor="middle">{value > 0 ? value : ''}</text>
     );
};
class DeliveryChart extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        data : null,
        rangeX1 : 1,
        rangeX2 : 1,
        maxValue :0 ,
        maxBarSize : 50,
      };
    }
    componentWillReceiveProps(nextProps){
      let space =[]
      
      let data = [];
      let maxValue = 0;
      const timeFormat = 'HH:mm';
      const datetimeFormat = 'YYYY-MM-DD HH:mm';
      var now = moment(moment(),datetimeFormat);
      // var now = moment('2019-04-02 08:26',datetimeFormat);
      // var now = moment('09:31',timeFormat);

      space[0] = ""
      for(let s=1 ; s<12 ; s++){
        space[s] = `${space[s-1]} `
      }

      if(nextProps.dashboard_management !=this.props.dashboard_management){
          let barData = nextProps.dashboard_management.barGraph;
          console.log('#componentWillReceiveProps barData',barData);
          let betweenIndex = -1;
          barData.forEach((row, i) => {
            //console.log('#componentWillReceiveProps row',row);
            // if( i+1 == barData.length) return false
            /*let rangTime = row.label.split('-');
            let beforeTime = moment(rangTime[0],timeFormat);
            let afterTime = moment(rangTime[1],timeFormat);

            if(beforeTime.diff(afterTime, 'minutes') > 0){
              let nowHHmm = parseInt(moment(now).format("HHmm"))
              let beforeTimeHHmm = parseInt(moment(beforeTime).format("HHmm"))
              let afterTimeHHmm = parseInt(moment(afterTime).format("HHmm"))

              if(nowHHmm > beforeTimeHHmm )
                afterTime = moment(afterTime).add(1,'day')
              if(nowHHmm < afterTimeHHmm)
                beforeTime = moment(beforeTime).subtract(1,'day')
            }
            
            if (now.isBetween(moment(beforeTime).subtract(1,'minutes'), moment(afterTime).add(1,'minutes'))) {
              betweenIndex = i;

            } else {

            }*/
            
            let beforeTime = moment(row.from,datetimeFormat);
            let afterTime = moment(row.to,datetimeFormat);

            // if (now.isBetween(moment(beforeTime).subtract(1,'minutes'), moment(afterTime).add(1,'minutes')))

            // if (now.isBetween(moment(beforeTime), moment(afterTime))) {
            if (now.isBetween(moment(beforeTime).subtract(1,'minutes'), moment(afterTime).add(1,'minutes'))){  
              betweenIndex = i;
            }
            var sumValue = 0;
            for (var key in row.data) {
              if (row.data.hasOwnProperty(key)) {
                sumValue += parseInt(row.data[key]);
              }
            }
            if(sumValue>maxValue) maxValue = sumValue;
            let item = [];
              item['name'] = row.label+space[i];
              item['Completed'] = row.data.confirmEndJob; 
              item['On Time'] = row.data.onTime;
              item['ETA Delay'] = row.data.etaDelay;
              item['Delay'] = row.data.realDelay;
            data.push(item);
          });
          //Add = 12 record
          for(let i=barData.length ; i<12 ; i++){
            let item = [];
              item['name'] = 'empty'+i;
              item['Completed'] = 0; 
              item['On Time'] = 0;
              item['ETA Delay'] = 0;
              item['Delay'] = 0;
            data.push(item);
          }



          if(barData && betweenIndex!=-1 ){  //&& barData[betweenIndex+1]

            this.setState({ rangeX1 : barData[betweenIndex].label+space[betweenIndex]});
            if(betweenIndex == 11) {
              this.setState({ rangeX2 : null});

            } else if(betweenIndex+1 === barData.length)
              this.setState({ rangeX2 : 'empty' + barData.length});
            else 
              this.setState({ rangeX2 : barData[betweenIndex+1].label+space[(betweenIndex+1)]});
          } else {
            this.setState({ rangeX1 : 1});
            this.setState({ rangeX2 : 1});
          }



          
          //return data;
      }
      //return data;
      this.setState({maxValue: maxValue ,data:data});
    }
    render() {
        const fontBarChart = 16;
        return (
          <ResponsiveContainer>
          <BarChart  data={this.state.data}   margin={{top: 10, right: 10, left: 10, bottom: 10}}>
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="name"  tick={<CustomizedAxisTick />}  type="category" interval={0} tickLine={true}/>
              <YAxis stroke="black" tick={<CustomizedYAxisTick />}  type="number" domain={['dataMin', 'dataMax']}/>
              <Legend verticalAlign="top" align="right" styles={{margin:"-10px"}} height={24}/>
              {/*<Tooltip cursor={{ stroke: 'red', strokeWidth: 2 }} />*/}
              <Tooltip cursor={{fill:'transparent', stroke: 'transparent', strokeWidth: 0 }}/>
              <Bar isAnimationActive={false}
                dataKey="Completed" 
                stackId="a" 
                fill="#43A047" 
                stroke="#000000"
                strokeWidth={1}
                maxBarSize={this.state.maxBarSize} 
                  >
                  <LabelList position="middle" fontSize={fontBarChart}  valueAccessor={(entry,index) => valueAccessor(entry,index,'Completed')}/>
                </Bar>
              <Bar isAnimationActive={false}
                dataKey="On Time" 
                stackId="a" 
                fill="#8BFF8B"  
                stroke="#000000"
                strokeWidth={1}
                //label={<CustomizedLabel />}
                maxBarSize={this.state.maxBarSize}>
                  <LabelList position="middle" fontSize={fontBarChart}  valueAccessor={(entry,index) => valueAccessor(entry,index,'On Time')}/>
                </Bar> 
              <Bar isAnimationActive={false}
                dataKey="ETA Delay" 
                stackId="a" 
                fill="#FAEF0E"  
                stroke="#000000"
                strokeWidth={1}
                //label={<CustomizedLabel />}
                maxBarSize={this.state.maxBarSize}>
                <LabelList position="middle" fontSize={fontBarChart}  valueAccessor={(entry,index) => valueAccessor(entry,index,'ETA Delay')}/>
              </Bar> 
              <Bar isAnimationActive={false}
                dataKey="Delay" 
                stackId="a" 
                //fill="#EF9A9A"  
                fill="#FC4628"  
                stroke="#000000"
                trokeWidth={1}
                //label={<CustomizedLabel />}
                maxBarSize={this.state.maxBarSize}
                //label={{ position: 'center' ,fontSize:'10px'}}
                >
                  <LabelList position="middle" fontSize={fontBarChart}  valueAccessor={(entry,index) => valueAccessor(entry,index,'Delay')}/>
                </Bar> 
              <ReferenceArea
                x1={this.state.rangeX1}
                x2={this.state.rangeX2}
                // x1={"22:30-23:29"}
                // x2={"23:30-00:29"}
                y1={0}
                y2={this.state.maxValue}
                stroke='red'
                strokeWidth='1'
                strokeOpacity={1}
                fill='red'
                fillOpacity={0}
                // strokeDasharray="3 3" 
              />
              {/* <Legend /> */}
              
            </BarChart>
          </ResponsiveContainer>
        );
    }
};
const mapStateToProps = function(state, ownProps) {
  return {
    dashboard_management : state.dashboard.dashboard_management,
  };
};
const mapActionsToProps = {
};

export default compose(
    connect(
      mapStateToProps,
      mapActionsToProps
      //mapDispatchToProps
    ),
    withStyles(styles)
  )(DeliveryChart);
  