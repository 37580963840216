import { combineReducers } from 'redux'
import ui from './ui'
import { reducer as formReducer } from 'redux-form'
import literals from './literals';
import api from './api';
import auth from './auth';
import map from './map';
import websocket from './websocket';
import setting from './setting';
import search from './search';
import mapForm from './ui/mapForm';
import selectList from './ui/selectList';
import autoSuggest from './ui/autoSuggest';
import upload from './upload';
import dashboard from './dashboard';

const rootReducer = combineReducers({
  ui,
  literals,
  api,
  auth,
  websocket,
  map,
  mapForm,
  setting,
  search,
  form: formReducer,
  selection:selectList,
  autoSuggest,
  upload,
  dashboard
});
export default (state, action) => (
  action.type === 'AUTH_LOGOUT_REQUEST'
      ? rootReducer(undefined, action)
      : rootReducer(state, action)
)
