export const polygonColor = [
  "#2868FC",
  "#FF595E",
  "#00A8CC",
  "#FFD700",
  "#13D98D",
  "#880D1E",
  "#ABB3B5",
  "#E5B143",
  "#607D8B",
  "#FF7E67",
  "#8E8D8A",
  "#ACDBDF",
  "#08A045",
  "#11698E",
  "#5C7587",
  "#AB63B2",
  "#E5D4ED",
  "#0A143F",
  "#FFB800",
];
