import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Refresh,
  ViewList,
  PinDrop
} from '@material-ui/icons';
import moment from 'moment';
import { Link , Redirect ,withRouter } from 'react-router-dom';
//Reducer
import {
  apiGetCallRequest,
  apiGetCallSuccess,
  apiCallRequest
} from '../../reducers/api';
import { connect } from 'react-redux'
import { uiPanelDialogOpen,uiSwitchForm } from '../../reducers/ui';
import { 
  mapDrawingMode,
  mapResetMap,
  mapCenterChanged,
  mapZoomChanged,
  mapSetSuggestionRoutes,
  MAP_SELECT_SUGGESSION,
  MAP_EDIT_SUGGESSION,
  SET_DATA_FOR_EDIT_ROUTE,
  CLEAR_SELECTING } from '../../reducers/map';
import { 
  getSuggestionRoutes,
  getTruckLocation,
  getEditRoute,
  postConfirmRoute } from "../../reducers/map/api";

//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  titlePanel : {
    width: '100%',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  formControl: {
    margin: theme.spacing.unit
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    margin: 0,
    padding : 5,
  },
  card: {
    minWidth: 275,
  },
  card_active : {
    minWidth: 275,
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 10,
  },
  pos: {
    marginBottom: 12,
  },
  center:{
    position: 'relative',
    left: '20px'
  },
})

class RouteForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      route_editing : null,
      masterData : '',
      routesData : '',
      open : false,
      truck_location : null,
      allRouteResponse : null,
      isLoading : false
    };
  }
  componentDidMount() {
    setTimeout(() => this.handleLoadData(),1000)
    
  }
  componentWillUnmount() {
    
  }
  componentWillReceiveProps(nextProps){
    //getDataOperation({});
    if(this.props.suggestion_routes!=nextProps.suggestion_routes 
        || this.props.suggestion_selecting!=nextProps.suggestion_selecting
        || this.props.suggestion_editing!=nextProps.suggestion_editing){

      this.renderMasterData(nextProps.suggestion_routes);
      this.renderRouteMaster(nextProps);
    }
  }
  handleLoadData = () =>{
    this.setState({isLoading:true});
    this.props.RESET_MAP(true);
    if(this.props.jobDashboardId){
      this.props.mapSetSuggestionRoutes([]);
      
      this.props.getSuggestionRoutes({
        // url: "http://mapssh.leafte.ch/routeSuggestionSample",
        url:Configs[env].BACKEND_HOST + "/routeSuggestion/"+this.props.jobDashboardId,
        filter: {},
        callback : this.loadDataSuccess,
        // callback_error : this.updateTime_error,
      });
      this.props.getTruckLocation({
        url:Configs[env].BACKEND_HOST + "/truckLocation/"+this.props.jobDashboardId,
        filter: {},
        callback : this.setTruckLocation
      });
    }
  }
  setTruckLocation = (response) =>{
    
    let data = response.list[0];
    // console.log('#setTruckLocation data',data);
    if(data){
      this.setState({truck_location : data})
    }
  }
  loadDataSuccess = (response) => {
    this.props.MAP_SELECT_SUGGESSION(0);
    // console.log("Kai loadDataSuccess", response)
    this.setState({allRouteResponse : response,isLoading:false})

    // let dataForEdit = {
    //   jobDashboardId : this.props.jobDashboardId,
    //   routeName : response.list[0].routes[0].routeName,
    //   Legs : [],
    // }

    let dataForEditRoute = []

    response.list[0].routes.map((route,route_idx) => {
      dataForEditRoute[route_idx] = {
        jobDashboardId : this.props.jobDashboardId,
        routeName : response.list[0].routes[route_idx].routeName,
        Legs : []
      }
      route.Legs.map((Leg,idx)=>{        
        
        var decodePath = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(Leg.Route));
        var paths = [];
        decodePath.forEach(function(p) {
          paths.push({ lat: p.lat(), lng: p.lng() });
        });

        let waypoints = []
        waypoints.push(paths[0])

        if(Leg.hasOwnProperty("subLegs")){
          Leg.subLegs.forEach((subLeg, subLeg_id) => {
              var decodePath_sub = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(subLeg.subRoute));
              // console.log("Kai subLeg.subRoute2",subLeg.subRoute)
              var paths_sub = [];
              decodePath_sub.forEach(function(p) {
                paths_sub.push({ lat: p.lat(), lng: p.lng() });
              });

              waypoints.push(paths_sub[paths_sub.length-1])
          })
        } else {
          waypoints.push(paths[paths.length-1])
        }

        dataForEditRoute[route_idx].Legs.push({
          Seq: Leg.Seq,
          locationId: Leg.locationId,
          routeId: Leg.routeId,
          // Route: Leg.Route,
          waypoints : waypoints,
          isChangeRoute : false
        })
      })

    })

    // console.log("Kai dataForEditRoute", dataForEditRoute)
    this.props.SET_DATA_FOR_EDIT_ROUTE(dataForEditRoute)

    // SET_DATA_FOR_EDIT_ROUTE

    // response.list[0].routes[0].Legs.map((Leg,idx)=>{
    //   dataForEdit.Legs.push({
    //     Seq: Leg.Seq,
    //     locationId: Leg.locationId,
    //     routeId: Leg.routeId,
    //     // polyline : "u{`qA}_ngR}tMfoDs|KjnE",
    //     isChangeRoute : false
    //   })
    // })

    // dataForEdit.Legs[0].polyline = "u{`qA}_ngRow_@d`o@|cDq`c@"
    // dataForEdit.Legs[0].isChangeRoute = true
    // console.log("Kai dataForEdit", dataForEdit)
    

    // setTimeout(() => {
    //   this.props.getEditRoute({
    //     url: Configs[env].BACKEND_HOST + "/editRoute",
    //     objData : dataForEdit,
    //     method : "POST",
    //     callback: this.editR
    //   });
    // },1000)
  }

  editR = (response) =>{
    // console.log("Kai editR", response)
  }



  handleOpenConfirm = () =>{
    this.setState({ open: true });
  }
  handleConfirm = () =>{
    
    const route_id = this.props.suggestion_selecting
    // console.log("KAI handleConfirm",this.props.dataForEditRoute[route_id])
    //   this.props.API_REQUEST({
    //      url: Configs[env].BACKEND_HOST + "/confirmRoute",
    //      method : "POST",
    //      filter : {},
    //      callback: this.handleConfirmComplete
    //   });

    let post_data = JSON.parse(JSON.stringify(this.props.dataForEditRoute[route_id]));

    post_data.Legs.map((leg,idx) => {
        delete(post_data.Legs[idx].Seq)
        delete(post_data.Legs[idx].isChangeRoute)
        delete(post_data.Legs[idx].polyline)
        delete(post_data.Legs[idx].waypoints)
    })

    if(this.props.shiftSeq !== null && this.props.shiftSeq !== '')
        post_data.shiftNo = this.props.shiftSeq
    else
        post_data.shiftNo = null

    console.log("KAI post_data",post_data)

    this.props.postConfirmRoute({
        url: Configs[env].BACKEND_HOST + "/confirmRoute",
        objData : post_data,
        // route_id : this.props.route_id,
        method : "POST",
        callback: this.handleConfirmComplete
    });

    this.handleConfirmComplete()
  }
  handleConfirmComplete = () =>{
    this.setState({ open: false });
    this.props.history.push('/operation_dash')
  }
  handleCancel = () => {
      this.setState({ open: false });
  };
  handleClickRoute = (id) =>{
    this.props.MAP_SELECT_SUGGESSION(id);
    if(id!==this.props.suggestion_selecting)
      this.props.MAP_EDIT_SUGGESSION(-1);
  }
  handleCenterTruck = () =>{
    // console.log('#handleCenterTruck truck_location',this.state.truck_location);
    if(this.state.truck_location && this.state.truck_location.hasOwnProperty("position")){
      this.props.CLEAR_SELECTING()
      let position = JSON.parse(JSON.stringify(this.state.truck_location.position))
      this.props.mapCenterChanged(position);
    }
  }
  handleEditRoute = (event,id) =>{


    if(event.target.value=='route_editing' && id!=this.props.suggestion_editing)
        this.props.MAP_EDIT_SUGGESSION(id);
    else 
        this.props.MAP_EDIT_SUGGESSION(-1);
  }
  renderMenuItem(){
      
    if(this.props.grouplist !== undefined && this.props.grouplist !== null){      
          var menuItem = this.props.grouplist.result;
          return menuItem.map(function(data, index){
            return (
              <MenuItem key={index} value={data.id}>{data.name}</MenuItem>
            )
          });
    }
    else
        return null;
  }
  handleCenterMap = () =>{

  }
  renderMasterData = (suggestion_routes) =>{
    // console.log('#renderMasterData suggestion_routes',suggestion_routes);
    let self = this;
    let origin = 'FROM : '+(suggestion_routes.originLocationName ? suggestion_routes.originLocationName : "-");
    let destination = 'TO : '+(suggestion_routes.destinationLocationName ? suggestion_routes.destinationLocationName : "-");
    
    let component = (
              <Grid item xs={12}>
                <List component="nav">
                  <ListItem button onClick={self.handleCenterMap()}>
                    <ListItemIcon><PinDrop/></ListItemIcon>
                    <ListItemText inset primary={origin} />
                  </ListItem>
                  <ListItem button onClick={self.handleCenterMap()}>
                    <ListItemIcon><PinDrop/></ListItemIcon>
                    <ListItemText inset primary={destination} />
                  </ListItem>
                </List>
              </Grid>
      );
      this.setState({masterData:component})
  }
  
  renderRouteMaster = (props) =>{

    let component = (
      <div>
        {props.suggestion_routes.routes && props.suggestion_routes.routes.length>0 && 
          props.suggestion_routes.routes.map((route, index) => {

            return (<div key={'route_'+index}>{this.renderRouteCard(route,index,props)}</div>)
          })
        }
      </div>
    );
    this.setState({routesData:component})
  }
  renderRouteCard = (route,index,props) => {
    // let lastArrived = route.Legs[route.Legs.length-1].isArrived;
    let allIsCompleted = true
    // const map1 = array1.map(x => x * 2);

    route.Legs.map(Leg => {
      if(Leg.isCompleted === false){
        allIsCompleted = false
      }
    })

    return (
      <div style={{ marginTop: "5px"}}>
        <Card style={props.suggestion_selecting==index ? {border:'2px solid red'} : {}}  
            key={route.routeName} onClick={() => this.handleClickRoute(index)}>
          <CardContent>
            <Typography variant="subtitle1" className={styles.title} color={index!=0 ? "" : "secondary"} gutterBottom>
              Route {index+1} : {route.routeName} {index!=0 ? '' : '( Active )'}
            </Typography>
            <Typography component="p">
                Duration Time : {this.renderermins(parseInt(route.durationTime/60))}
            </Typography>
            <Typography component="p">
                {this.rendererETA(route.ETA,route.diffTime)}
            </Typography>
            <Typography component="p">
                {this.rendererDistance(route.durationDistance,route.diffDistance)}
            </Typography>
            <div style={{border:"1px solid #CCC",padding:"5px"}}>
                {this.rendererHazard(route.hazard)}
            </div>

          </CardContent>
          <CardActions>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={(event) => this.handleEditRoute(event,index)}
                    checked={props.suggestion_editing!=-1 && index==props.suggestion_editing ? 'checked' : ''}
                    value="route_editing"
                    color="secondary"
                    disabled={allIsCompleted}
                  />
                }label="Adjust"
              />
              <Button 
                  variant="contained" size="small"  color="primary" 
                  aria-label="Confirm" className={classNames(styles.button)} 
                  onClick={this.handleOpenConfirm}
                  disabled={allIsCompleted}>
                  CONFIRM
              </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
  renderermins = (mins) =>{
    let hour = parseInt(mins/60)>0 ? parseInt(mins/60)+' hour' : '';
    let min = (mins%60)+' mins';
    return  hour+' '+min;
  }
  rendererETA = (eta,diff) =>{
    let data = '-';
    if(eta!=0){
      let diffTxt = (diff>0 ? '+' :'') + parseInt(diff/60);
      data = moment(eta,'YYYY-MM-DD HH:mm:ss').format('HH:mm')+' '+( diff!=0 ? '( '+diffTxt+' mins )' : '' );
    }
    if(diff>0)
      return  (<Typography component="p" color="secondary">Estimate Time Arrival : {data}</Typography>);
    else 
      return  (<Typography component="p" color="primary">Estimate Time Arrival : {data}</Typography>);
  }
  rendererDistance = (distance,diff) =>{
    let data = '-';
    if(distance!=0){
      let diffTxt = (diff>0 ? '+' :'') + (diff/1000);
      data = (distance/1000)+' km. '+(diff!=0 ? '('+diffTxt+' km.)' : '');
    }
    if(diff>0)
      return  (<Typography component="p" color="secondary">Duration Distance : {data}</Typography>);
    else 
      return  (<Typography component="p" color="primary">Duration Distance : {data}</Typography>);
  }
  rendererHazard = (hazards) =>{
    if(hazards){
      return hazards.map((hazard, index) => {
          return <div><img width="20px" src={"/img/hz_"+hazard.category+".png"}/> {hazard.detail}</div>;
      });
    } else return '';
  }
  handleGotoDash = () =>{
    window.location.href = '/operation_dash'
  }
  render() {
    const { match , classes} = this.props
    const bull = <span className={styles.bullet}>•</span>;

    return (
      <div style={{ padding: "10px",overflowX : 'auto',height:'auto'}}>
      
          <List component="nav" className={styles.titlePanel}>
            <ListItem style={{paddingTop:"0px", paddingBottom:"0px"}}>
                <ListItemIcon>
                  <Tooltip title="Panto truck location" placement="top">
                  <img src='/img/truck_marker.png' height='30' onClick={this.handleCenterTruck} style={{ cursor: "pointer"}}/>
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary="ALTERNATIVE ROUTE "
                />
                <ListItemSecondaryAction>
                  <Link  to={'/operation_dash'} style={{ textDecoration: 'none'}} >
                    <Tooltip title="Operation Dasboard" placement="top">
                      <IconButton  variant="contained" size="small"  color="secondary" aria-label="Dashboard"    className={classNames(classes.button)} >
                          <ViewList  fontSize="small"/>
                      </IconButton>
                    </Tooltip>
                  </Link> 
                  <Tooltip title="Refresh" placement="top">
                    <IconButton variant="contained" size="small"  color="primary" aria-label="Refresh" disabled={this.state.isLoading} className={classNames(classes.button)} 
                        onClick={this.handleLoadData}>
                        <Refresh  fontSize="small"/>
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
          </List>
          {this.state.masterData}
          {this.state.routesData}
          
          <Dialog
              open={this.state.open}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
              >
              <DialogTitle id="alert-dialog-slide-title">
                  Set Route
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                  Confirm to set this route
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.handleConfirm.bind(this)} disabled={this.props.isLoading} color="primary">
                  {this.props.isLoading && <CircularProgress className={classes.center} size={25} thickness={2} />}
                  Confirm
                  </Button>
                  <Button onClick={this.handleCancel.bind(this)} disabled={this.props.isLoading} color="secondary">
                      Cancel
                  </Button>
              </DialogActions>
          </Dialog>
      </div>
    );
  }
}
RouteForm.propTypes = {
  match: PropTypes.object.isRequired,
};
const mapStateToProps = function(state) {
  return {
    isLoading: state.ui.isLoading,
    formManage : state.ui.formManage,
    suggestion_routes: state.map.suggestion_routes,
    suggestion_selecting: state.map.suggestion_selecting,
    suggestion_editing: state.map.suggestion_editing,
    jobDashboardId : state.selection.jobDashboardId,
    shiftSeq : state.selection.shiftSeq,
    dataForEditRoute: state.map.dataForEditRoute,
    shiftSeq : state.selection.shiftSeq
  }
}
const mapActionsToProps =  {
  DIALOG_OPEN : uiPanelDialogOpen,
  SWITCH_FORM : uiSwitchForm,
  getGroupList : apiGetCallRequest,
  successGroupList : apiGetCallSuccess,
  DRAWING_MODE : mapDrawingMode,
  RESET_MAP : mapResetMap,
  getSuggestionRoutes : getSuggestionRoutes,
  API_REQUEST: apiCallRequest,
  MAP_SELECT_SUGGESSION,
  MAP_EDIT_SUGGESSION,
  getTruckLocation,
  mapCenterChanged,
  mapSetSuggestionRoutes,
  mapZoomChanged,
  getEditRoute,
  SET_DATA_FOR_EDIT_ROUTE,
  postConfirmRoute,
  CLEAR_SELECTING
};

export default connect(mapStateToProps,mapActionsToProps)
(withStyles(styles)(withRouter(RouteForm)))