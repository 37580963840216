


import { select, call, put,takeEvery } from 'redux-saga/effects';
import Configs from "../config/config"
const env = process.env.NODE_ENV;
// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeEvery('SEARCH_REQUEST', workerSaga);
}

function fetchDataGet(params) {  

    let url = Configs[env].BACKEND_HOST+"/locationGroupPartial?name="+params.payload;
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache': "no-cache",
        'Authorization': Configs[env].AUTHORIZATION,
        'token':localStorage.getItem('token'),
    };


    return fetch(url, {
      method: "GET",
      headers: headers,
    }).then(function(data) {

        // Here you get the data to modify as you please
        return data.json();
    })
    .catch(function(error) {

        // If there is any error you will catch them here
        return error;
    });
}

function* workerSaga(action) {
    try {

        const response = yield call(fetchDataGet, action);

        yield put({ type: 'SUGGEST_RESULT', payload:response.list});
    } catch (error) {
        // dispatch a failure action to the store with the error
        yield put({ type: 'SUGGEST_RESULT', payload:[]});
    }
}