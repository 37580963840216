import { takeLatest, call, put,takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import Configs from "../config/config"
const env = process.env.NODE_ENV;
// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeEvery('UPLOAD_FILE_REQUEST', workerSaga);
}

// function that makes the api request and returns a Promise for response
function upload(params) {
  let callback = params.payload.callback;
  let files = params.payload.file;

  
  let url = Configs[env].BACKEND_HOST +"/uploadImage";

  const formData = new FormData();
  formData.append("files[]", files);
  // formData.append("files[]", files[1]);

  return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': "1234567890-12345-12345678",
        'token':localStorage.getItem('token'),
      },
    }).then(response => {
      const data = response.data;
      const fileURL = data.secure_url // You should store this URL for future references in your app

    })
    .catch(function(error) {
      // If there is any error you will catch them here
      return error
    });  
  // })

  // const uploaders = files.map(file => {
  //   // Initial FormData
  //   const formData = new FormData();

  //   let fileType = file.type;

  //   let url = Configs[env].BACKEND_HOST +"/uploadImage";
  //   if(fileType === "video/*"){
  //     //url = Configs[env].BACKEND_HOST+"/api/v1/uploadVideo";
  //     url = Configs[env].BACKEND_HOST +"/uploadVideo";
  //   }
    
  //   formData.append("files", file);
  //   return axios.post(url, formData, {
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       'Authorization': "1234567890-12345-12345678",
  //       'token':localStorage.getItem('token'),
  //     },
  //   }).then(response => {
  //     const data = response.data;
  //     const fileURL = data.secure_url // You should store this URL for future references in your app

  //   })
  // })

  // axios.all(uploaders).then(() => {
  //   return "ok"
  // });
}

// worker saga: makes the api call when watcher saga sees the action
function* workerSaga(action) {
  try {
    //debugger
    const response = yield call(upload, action);

    //const msg = response.message;
    // dispatch a success action to the store with the new dog
    yield put({ type: 'UPLOAD_FILE_SUCCESS', result:response });
  
  } catch (error) {
    // dispatch a failure action to the store with the error
    //yield put({ type: 'API_CALL_FAILURE', error });
  }
}