import React, {Component} from "react";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Avatar from '@material-ui/core/Avatar';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

// import EditIcon from '@material-ui/icons/Create';
//Component
import { uiSwitchForm } from '../../../reducers/ui';
//Reducer
import {
    apiCallRequest,
} from '../../../reducers/api';
import { getMasterRoutes } from "../../../reducers/map/api";
//Config
import Configs from "../../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
    avatar: {
        margin: 0,
        width: 16,
        height:16,
        textAlign : 'center'
    },
})

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class CategoryIconRenderer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open:false,
            name : this.props.data.name
        }
    }

    renderData  = () => {
        
    }
    render() {
        const { classes } = this.props;

        let path = "/img/_hz_"+this.props.data.category+".png";
        return  (<img alt={this.props.data.category} src={path} className={classes.avatar} />)
        
        /*return (
            <div>{this.renderData()}
                {this.props.data.category=='share' ? '🚫' : '' }
                {this.props.data.category=='warning' ? '❗' : '' }
                {this.props.data.category=='prohibit' ? '⚠️' : '' }
            </div>
        );*/
    }
};

const mapStateToProps = function(state,ownProps) {
    return {
        ...ownProps
    }
};

const mapActionsToProps =  {

};
const enhance = compose(
    connect(
        mapStateToProps,
        mapActionsToProps
    ),
    withStyles(styles)
);  

export default enhance(CategoryIconRenderer)
