import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { Marker } from "react-google-maps";

//Component 
import DateOnlyRenderer from "../Ui/Renderer/DateOnlyRenderer";
import ManageBtnRenderer from "./Renderer/ManageBtnRenderer";
import CategoryIconRenderer from "./Renderer/CategoryIconRenderer";

//Reducer
import { apiGetCallRequest } from "../../reducers/api";
import { 
    mapSetLogisticPoint, mapSelectedMarker,
    MAP_LOG,SET_HAZARD_PHOTO,ADD_HAZARD_VDO } from "../../reducers/map";
//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    margin: 0,
    //marginLeft: theme.spacing.unit,
    //marginRight: theme.spacing.unit,
    width: 200
  }
});
class Hazards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url_endpoint: {
        response: false,
        endpoint: Configs[env].BACKEND_HOST
      },
      columnDefs: [
        {
          cellRenderer: "categoryIconRender",
          width: 25,
          cellStyle: function(params) {
            return {
              textAlign:'center',
              paddingLeft: "0px",
              paddingRight: "0px",
            };
          }
        },{
          headerName: "LOCATION",
          field: "location",
          width: 160,
          cellStyle: function(params) {
            return {
              // textAlign:'center',
              paddingLeft: "3px",
              paddingRight: "3px",
            };
          }
        },{
          headerName: "DATE FROM",
          headerClass: "multiline",
          field: "dateFrom",
          width: 70,
          cellRenderer: "dateOnlyRenderer",
          cellStyle: function(params) {
            return {
              textAlign:'center',
              paddingLeft: "0px",
              paddingRight: "0px",
            };
          }
        },{
          headerName: "DATE TO",
          headerClass: "multiline",
          field: "dateTo",
          width: 70,
          cellRenderer: "dateOnlyRenderer",
          cellStyle: function(params) {
            return {
              textAlign:'center',
              paddingLeft: "0px",
              paddingRight: "0px",
            };
          }
        },{
          cellRenderer: "manageBtnRenderer",
          width: 50,
          cellStyle: function(params) {
            return {
              textAlign:'center',
              paddingLeft: "0px",
              paddingRight: "0px",
            };
          }
        }
      ],
      rowData: null,
      context: { componentParent: this },

      frameworkComponents: {
        categoryIconRender : CategoryIconRenderer,
        manageBtnRenderer: ManageBtnRenderer,
        dateOnlyRenderer: DateOnlyRenderer,
      }
    };
  }

  autosizeHeaders(event) {
    const MIN_HEIGHT = 38;

    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        "#jobDataGrid .ag-header-cell-label"
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach(cell => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }


  componentWillReceiveProps(nextProps) {
    
  }

  focusRow = (marker_selecting) => {
    let gridApi = this.gridApi
    if(gridApi){
      gridApi.deselectAll()
      this.gridApi.forEachNode(function(node) {
        if (node.data.id === marker_selecting) {

          node.setSelected(true,true);
          gridApi.ensureIndexVisible(node.rowIndex, { position: 'top' }); 

          // and ensureNodeVisible(event.node, { position: 'top' });
        }

      });


    }
  }

  componentDidUpdate () {
    this.focusRow(this.props.marker_selecting)
    // this.autoSizeAll();
  }
  //Grid
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autosizeHeaders(params) 
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  }
  autoSizeAll = () => {
    var allColumnIds = [];
    if(this.gridColumnApi){

      this.gridColumnApi.getAllColumns().forEach(function(column,index) {
        if(index!==0 && index!==2 && index!==3 && index!=4)allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  }

  renderData = () => {

    if (Array.isArray(this.props.hazard_points)) {
      let data = [];


        this.props.hazard_points.map((point, index)=>{
          data.push(point)
          // data[index].dateFrom = point.dateFrom.split(' ')[0]
          // data[index].dateTo = point.dateTo.split(' ')[0]

        }
      )
      
      return data;
    } else return [];
  }

  handleSelectionChanged = node => {
    try{
      var selectedRows = node.api.getSelectedRows();

      this.props.SELECT_MARKER(selectedRows[0].id);
      if(selectedRows[0].mediaUrl && selectedRows[0].mediaUrl.length>0){
          let video = null;
          let photo = [];
          selectedRows[0].mediaUrl.forEach(function(row){

              if(row.search(/mp4/i) > 0 || row.search(/jpg/i) > 0 || row.search(/jpeg/i) > 0){

                  let tokens = row.split('&');
                  let files = tokens[0].split(".")
                  if(files[files.length-1].toLowerCase() === 'mp4') video = row;
                  else photo.push(row);
              }
          });


          this.props.SET_HAZARD_PHOTO(photo);
          this.props.ADD_HAZARD_VDO(video);
      } else {
          this.props.SET_HAZARD_PHOTO([]);
          this.props.ADD_HAZARD_VDO(null);
      }
    } catch (e) {

      this.props.MAP_LOG({ error : e, detail : "Location.js - handleSelectionChanged"})
      
    }

    // this.testSelect(this.props.marker_selecting)
  }

  render() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div
          className="ag-theme-balham"
          style={{
            height: 'calc(100vh - 360px)' ,
            width: "99%",
            marginTop: "10px"
          }}
        >
          <AgGridReact
            enableSorting={true}
            singleClickEdit={true}
            suppressMovableColumns={true}
            enableColResize={true}
            columnDefs={this.state.columnDefs}
            rowData={this.renderData()}
            rowSelection="single"
            onGridReady={this.onGridReady}
            onRowClicked={this.handleSelectionChanged}
            onSelectionChanged={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            // cellContextMenu={this.handleCellContextMenu}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function(state, ownProps) {
  return {
    isLoading: state.api.fetching,
    hazard_points: state.map.hazard_points,
    marker_selecting : state.map.marker_selecting
  };
};
const mapActionsToProps = {
  SELECT_MARKER: mapSelectedMarker,
  MAP_LOG:MAP_LOG,
  SET_HAZARD_PHOTO,
  ADD_HAZARD_VDO
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapActionsToProps
    //mapDispatchToProps
  ),
  withStyles(styles)
)(Hazards);
