import React, { Component } from "react";
import {
  Marker,
  InfoWindow
} from "react-google-maps";
import { connect } from "react-redux";
import {
  mapSelectedMarker,
  mapMoveMarker,
} from "../../reducers/map";

import { uiSwitchForm } from '../../reducers/ui';

class ImMarker extends Component {
  componentWillUpdate(nextProps, nextState) {}
  selectInfo = arg => {
    this.props.MARKER_SELECTING(this.props.id);


    
  };

  onDragEnd = e => {

    this.props.position.lat = e.latLng.lat();
    this.props.position.lng = e.latLng.lng();
    this.props.MARKER_MOVE(this.props);
  };

  closeInfo = () => {
    this.props.MARKER_SELECTING(-1)
  }

  render() {
    const id = this.props.id;
    let selected = false;


    if (this.props.marker_selecting === id) {
      selected = true;
    }

    return (
      <Marker 
        position={this.props.position}
        icon={this.props.icon}
        key={this.props.key}
        id={this.props.id}
        onClick={this.selectInfo}
        markerCilck={this.selectInfo}
        mapClearInfo={this.props.mapClearInfo}
        onDragEnd={this.onDragEnd}
        draggable={this.props.editable}
        zIndex={this.props.zIndex}
        >
        {selected ? (
          <InfoWindow onCloseClick={this.closeInfo}>
            <div>
              <b>Information: </b><br />
              {this.props.info.logisticsPoint ? 'Logistics Point : '+this.props.info.logisticsPoint : ''}
              <br />
              <b>Name: </b><br />
              {this.props.info.name}<br />
              <b>Description: </b><br />
              {this.props.info.description ? this.props.info.description : ''}<br />
              <br />
              {/* {!this.props.isGroup && 
                <div>
                  <button onClick={() => this.props.uiSwitchForm({page:"manage",mode:"edit",editID:this.props.id})}>
                    Edit
                  </button>
                </div>}
              <br /> */}
            </div>
          </InfoWindow>
        ) : null}
      </Marker>
    );
  }
}

const mapStateToProps = state => {
  return {
    marker_selecting: state.map.marker_selecting
  };
};

const mapActionsToProps = {
  MARKER_SELECTING : mapSelectedMarker,
  MARKER_MOVE : mapMoveMarker,
  uiSwitchForm : uiSwitchForm
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ImMarker);
