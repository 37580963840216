import React, { Component } from "react";
import { render } from "react-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline
} from "react-google-maps";
import { compose, withProps, withStateHandlers } from "recompose";
import { connect } from "react-redux";
import ImPolyline from "../ImPolyline";
import ImMarker from "../ImMarker";
import WpPolyline from "./WpPolyline"
import WpMarker from "./WpMarker"


class Waypoints extends Component {
  render() {
    let legInfo = "";
    let totalDistance = 0;
    let totalETA = 0;
    let infoDistance = 0;
    let infoETA;
    return (
      <React.Fragment>
        {this.props.legs.map((leg, index) => {
          // let decodedPath = decodePolyline(decodeURIComponent(leg.Route));
          return (
            <div>
              <WpPolyline path={leg.path} key={index} id={index} />
            </div>
          );
        })}

        {this.props.waypoints.map((waypoint, index) => {
          let isFirst = false;
          let isLast = false;

          if (index === this.props.waypoints.length - 1) {
            isLast = true;
          }
          if (index === 0) {
            isFirst = true;
          }

          if (this.props.legs.length > 0 && index > 0) {
            try {
              totalDistance =
                totalDistance + Number(this.props.modified_route.Legs[index - 1].Distance);
              totalETA = totalETA + Number(this.props.modified_route.Legs[index - 1].ETA);

              infoDistance = (totalDistance / 1000).toFixed(2) + " KM";
              infoETA = totalETA + " min";
            } catch (e) {
              infoDistance = (totalDistance / 1000).toFixed(2) + " KM";
              infoETA = totalETA + " min";
            }
          } else {
            infoDistance = "0 KM";
            infoETA = "0 min";
          }

          return (
            <div>
              <WpMarker
                key={index}
                id={index}
                isFirst={isFirst}
                isLast={isLast}
                defaultDraggable={isFirst || isLast || this.props.map_loading ? false : true}
                infoDistance={infoDistance}
                infoETA={infoETA}
                position={waypoint.position}
                isGroup={waypoint.isGroup}
                description={waypoint.description}
              />
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    markers: state.map.markers,
    legs: state.map.legs,
    modified_route: state.map.modified_route,
    map_loading : state.map.map_loading
    // legs: state.legs,
    // waypoints: state.waypoints
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Waypoints);
