import { createAction, handleActions } from "redux-actions";

// ACTION TYPES
export const authActionTypes = {
  isAuthenticated: "AUTH_IS_AUTHENTICATED",
  authLoginRequest: "AUTH_LOGIN_REQUEST",
  authLoginResponse: "AUTH_LOGIN_RESPONSE",
  authLoginError: "AUTH_LOGIN_ERROR",
  authLogoutRequest:"AUTH_LOGOUT_REQUEST",
  authLogoutResponse: "AUTH_OUT_RESPONSE",

};

// ACTION CREATORS
export const isAuthenticated = createAction(authActionTypes.isAuthenticated);
export const authLoginRequest = createAction(authActionTypes.authLoginRequest);
export const authLoginResponse = createAction(
  authActionTypes.authLoginResponse
);
export const authLoginError = createAction(authActionTypes.authLoginError);
export const authLogoutRequest = createAction(authActionTypes.authLogoutRequest);
export const authLogoutResponse = createAction(authActionTypes.authLogoutResponse);
export const forceLogout = createAction("forceLogout");

const initialState = {
  force_logout: false,
  authResult : false,
  fetching: false,
  auth: null,
  error: null,
  // privilege : [],
  token : null,
  profile : {},
  privilege : {}
};

// REDUCERS
export default handleActions(
  {
    [forceLogout](state, { payload }) {
      window.localStorage.clear();
      return { ...state, force_logout: payload, auth : null , authResult : false };
    },
    [isAuthenticated](state, { payload }) {
      return { ...state, fetching: true, error: null };
    },
    [authLoginRequest](state, { payload }) {
      return { ...state, fetching: true, error: null };
    },
    [authLoginResponse](state, auth) {


      if(auth.hasOwnProperty("privilege")) {
        let privilege = {
          login : auth.privilege.find(o => o.screen === 'login'),
          operationDash : auth.privilege.find(o => o.screen === 'operationDash'),
          managementDash : auth.privilege.find(o => o.screen === 'managementDash'),
          routeSuggestion : auth.privilege.find(o => o.screen === 'routeSuggestion'),
          inputHazard : auth.privilege.find(o => o.screen === 'inputHazard'),
          impactHazard : auth.privilege.find(o => o.screen === 'impactHazard'),
          locationMaster : auth.privilege.find(o => o.screen === 'locationMaster'),
          routeMaster : auth.privilege.find(o => o.screen === 'routeMaster'),
          configuration : auth.privilege.find(o => o.screen === 'configuration'),
          sequenceDash : auth.privilege.find(o => o.screen === 'sequenceDash'),
        }
        // privilege.locationMaster.controllable = false
        localStorage.setItem('privilege', JSON.stringify(privilege));
      }
      return { 
        // ...state,
        ...auth, 
        // privilege : privilege,
        fetching: false ,
        force_logout: false};
    },
    [authLoginError](state, { error }) {
      return { ...state, fetching: false, auth: null, error: error };
    },
    [authLogoutRequest](state, { payload }) {
      return { ...state, fetching: true, error: null };
    },
    [authLogoutResponse](state, auth) {

      return { ...auth, fetching: false };
    },
  },
  initialState
);

// SELECTORS
export const getAuthResponse = state => state.auth;
