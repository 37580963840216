import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import EditBtnRenderer from "../Ui/Renderer/EditBtnRenderer";
import DeleteBtnRenderer from "../Ui/Renderer/DeleteBtnRenderer";
import ManageBtnRenderer from "../Ui/Renderer/ManageBtnRenderer";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { 
  mapSetLogisticPoint, 
  mapSelectedMarker ,
  mapSetMarkers,
  MAP_LOG
} from "../../reducers/map";
import Configs from "../../config/config";

const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    margin: 0,
    //marginLeft: theme.spacing.unit,
    //marginRight: theme.spacing.unit,
    width: 200
  }
});
class Locations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marker_selecting : this.props.marker_selecting,
      pointList: this.props.logistic_point,
      url_endpoint: {
        response: false,
        endpoint: Configs[env].BACKEND_HOST
      },
      columnDefs: [{
            cellRenderer: "manageBtnRenderer",
            width: 50,
            cellStyle: function(params) {
              return {
                paddingLeft: "0px",
                paddingRight: "0px",
                align: "center"
              };
          },
        },{
          headerName: "",
          //field: "logisticsPoint",
          width: 30,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          autoHeight: true,
          headerClass: 'location-checkbox-header',
          cellStyle: function(params) {
              return {
                paddingLeft: "5px",
                paddingRight: "0px",
                align: "center",
                textAlign: "center"
              };
          },
        },{
          headerName: "LOGISTICS POINT",
          field: "logisticsPoint",
          headerClass: "multiline",
          width: 100,
          autoHeight: true
        },{
          headerName: "NAME",
          field: "name",
          width: 170,
          autoHeight: true
        }],
      headerHeight: 80,
      rowData: null,
      context: { componentParent: this },
      suppressRowClickSelection: true,
      rowSelection: "multiple",
      frameworkComponents: {
        manageBtnRenderer: ManageBtnRenderer,
      }
    };
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }
  componentDidUpdate () {
      this.autoSizeAll();
  }
  componentWillReceiveProps(nextProps) {
    if(this.props.marker_selecting!=nextProps.marker_selecting)
      this.focusRow(nextProps.marker_selecting)
  }

  renderData() {
    if (Array.isArray(this.props.logistic_point)) {
      //this.props.setLogisticPoint(this.props.logistic_point);
      //filter text in location point
      if (this.props.textSearchLocation === "")
        return this.props.logistic_point;
      else {
        let result = [];
        let findText = this.props.textSearchLocation;
        this.props.logistic_point.forEach((point, i) => {
            if (
              point.name.toUpperCase().search(findText.toUpperCase()) > -1 ||
              point.logisticsPoint .toUpperCase().search(findText.toUpperCase()) > -1
            )
              result.push(point);
        });
        
        return result;
      }
    } else return [];
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  }
  autoSizeAll = () => {
    var allColumnIds = [];
    if(this.gridColumnApi){

      this.gridColumnApi.getAllColumns().forEach(function(column,index) {
        if(index > 1)allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  }

  autosizeHeaders(event) {
    const MIN_HEIGHT = 38;

    this.gridApi = event.api;
    this.gridColumnApi = event.columnApi;
    if (event.finished !== false) {
      event.api.setHeaderHeight(MIN_HEIGHT);
      const headerCells = document.querySelectorAll(
        "#jobDataGrid .ag-header-cell-label"
      );

      let minHeight = MIN_HEIGHT;
      headerCells.forEach(cell => {
        minHeight = Math.max(minHeight, cell.scrollHeight);
      });
      event.api.setHeaderHeight(minHeight);
    }
  }

  handleSelectionChanged = node => {
    try{
      var selectedRows = node.api.getSelectedRows();
      var selectedNodes = node.api.getSelectedNodes()

      
      this.props.SELECT_MARKER(selectedRows[0].id);
    } catch (e) {

      this.props.MAP_LOG({ error : e, detail : "Location.js - handleSelectionChanged"})
    }

    // this.focusRow(this.props.marker_selecting)
  }
  getSelections = () =>{
      let nodeSelected = [];
      this.gridApi.forEachNode(function(node) {
        if(node.selected) nodeSelected.push(node.data.id);
      });
      return nodeSelected;
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autosizeHeaders(params)
  }

  focusRow = (marker_selecting) => {
    let gridApi = this.gridApi
    if(gridApi){
      gridApi.deselectAll()
      this.gridApi.forEachNode(function(node) {
        if (node.data.id === marker_selecting) {

          node.setSelected(true,true);
          gridApi.ensureIndexVisible(node.rowIndex, { position: 'top' }); 

          // and ensureNodeVisible(event.node, { position: 'top' });
        }
      });
    }
  }

  render() {
    
    return (
      <div style={{ width: "100%", height: "200px" }}>
        <div
          className="ag-theme-balham"
          style={{
            height: 'calc(100vh - 390px)',
            width: "100%",
            marginTop: "10px"
          }}
        >
          <AgGridReact
            ref={(node) => { this.grid = node; }}
            enableSorting={true}
            singleClickEdit={true}
            suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            enableColResize={true}
            rowData={this.renderData()}
            rowSelection="multiple"
            onRowClicked={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady.bind(this)}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function(state, ownProps) {
  return {
    isLoading: state.api.fetching,
    pointLocationList : state.api.resultPointList|| [],
    logistic_point: state.map.logistic_point,
    marker_selecting: state.map.marker_selecting,
    //pointLocationList: state.api.mapResult.get("POINT_LIST") || [],
    textSearchLocation: state.search.search_location
  };
};
const mapActionsToProps = {
  SELECT_MARKER: mapSelectedMarker,
  MAP_LOG:MAP_LOG
};

export default compose(
  connect(
    mapStateToProps,
    mapActionsToProps
    //mapDispatchToProps
  ),
  withStyles(styles)
)(Locations);
