import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


import {
  required,
  maxLength,
  minLength,
  number,
  minValue,
  email
} from '../libs/validation';

import { getLiterals } from '../reducers/literals';
import { getAuthResponse } from '../reducers/auth';
import { apiCallRequest } from '../reducers/api';
import { Redirect } from 'react-router-dom';
import {
  authLoginRequest,
  authLoginResponse,
  authLoginError,
  forceLogout
} from '../reducers/auth';

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from './Ui/Snackbar';

import Configs from "../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    //background: 'url(https://source.unsplash.com/random/1600x900)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    minWidth: 300,
    marginTop: '6em'
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    backgroundColor: theme.palette.secondary.main
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500]
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    marginTop: '1em'
  },
  actions: {
    padding: '0 1em 1em 1em'
  },
  margin: {
    margin: theme.spacing.unit
  },
  center:{
    position: 'relative',
    left: '20px'
  }
});

// see https://redux-form.com/7.4.2/examples/material-ui//
const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

class Login extends Component {
  state = {
      snackbarOpen: false,
      snackbarOpen_success: false,
      response: false,
      isLogout : false,
      endpoint: "http://mapssh.leafte.ch:8000",
      forgot_email : '',
      forgotPassword_error : false,
      forgotPassword_success : false,
      forgotPassword_msg : ""
  };

  componentWillUnmount(){
  }

  componentWillReceiveProps(nextProps) {}

  handleSnackbarClose = (event, reason) => {
    this.setState({
      snackbarOpen: false,
      forceLogout: false,
    });

    this.setState({
      forgotPassword_error : false,
      forgotPassword_success: false,
      snackbarOpen_success: false
    });
  };

  login = auth => {
    let self = this;
    
    this.props.userLogin({
      ...auth,callback : this.setLogin
    });
  };
  setLogin = (response) =>{

      this.setState({ snackbarOpen: true });
  }
  //Forgot Passowrd
  handleForgotEmail = (e) =>{
    this.setState({
        forgot_email: e.target.value
    });
  }
  handleOpenForgot = () => {
    this.setState({ open: true });
  };

  handleCloseForgot = () => {
    this.setState({ open: false });
  };
  handleSendForgot = () => {
    if(this.state.forgot_email==""){
      this.setState({
        snackbarOpen: true,
        forgotPassword_error : true,
        forgotPassword_msg : "Email account is required"
      })
    } else {

      this.props.apiCallRequest({
        url: Configs[env].BACKEND_HOST + "/forgotPassword",
        method : 'POST',
        callback : this.sendComplete,
        callback_error : this.sendError,
        form : {
          email : this.state.forgot_email
        }
      });
    }
  }
  sendComplete = (data) =>{
    this.setState({
      snackbarOpen_success: true,
      forgotPassword_success : true,
      forgotPassword_msg : "Send reset password link email is completed"
    })
  }
  sendError = (data) =>{
   
    this.setState({
      snackbarOpen: true,
      forgotPassword_error : true,
      forgotPassword_msg : "This email does not exist"
    })
  }
  render() {
    const { classes, handleSubmit, isLoading, auth } = this.props;

    // if (auth && auth.authResult && !this.props.force_logout) {
    if (auth && auth.authResult) {
      localStorage.setItem('token', auth.token);
      //document.cookie = "token=" + auth.token;
      localStorage.setItem('authResult', auth.authResult);
      localStorage.setItem('auth', auth.auth);

      localStorage.setItem('email', auth.profile.email);
      localStorage.setItem('id', auth.profile.id);
      localStorage.setItem('firstName', auth.profile.firstName);
      localStorage.setItem('lastName', auth.profile.lastName);
      localStorage.setItem('company', auth.profile.company);
      return <Redirect to='/operation_dash' />;
    }

    let errorMessage = ""
    if(auth.hasOwnProperty("errorList"))
    if(auth.errorList.length > 0){
      errorMessage = auth.errorList[0].errorMessage
    } 

    if(this.state.forgotPassword_success){
      errorMessage = this.state.forgotPassword_msg

    }
    if(this.state.forgotPassword_error){
      errorMessage = this.state.forgotPassword_msg
    }

    return (
      
      <div className={classes.main}>
        {((!isLoading &&
          auth !== null &&
          auth.authResult === false) || this.state.forgotPassword_error) && (
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={this.state.snackbarOpen && !this.state.snackbarOpen_success}
              onClose={this.handleSnackbarClose}
              autoHideDuration={3000}
            >
              <SnackbarContentWrapper
                variant='error'
                className={classes.margin}
                message={errorMessage}
                onClose={this.handleSnackbarClose}
              />
            </Snackbar>
          )}
        {((!isLoading &&
          auth !== null &&
          auth.authResult === true) || this.state.forgotPassword_success) && (
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              open={this.state.snackbarOpen || this.state.snackbarOpen_success}
              onClose={this.handleSnackbarClose}
              autoHideDuration={3000}
            >
              <SnackbarContentWrapper
                variant='success'
                className={classes.margin}
                message={errorMessage}
                onClose={this.handleSnackbarClose}
              />
            </Snackbar>
          )}
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          <form onSubmit={handleSubmit(this.login)}>
            <div className={classes.hint}>DIGITAL MAP</div>
            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  name='email'
                  component={renderInput}
                  label='email'
                  disabled={isLoading}
                  validate={[required]}
                />
              </div>
              <div className={classes.input}>
                <Field
                  name='password'
                  component={renderInput}
                  label='password'
                  type='password'
                  disabled={isLoading}
                  validate={[required]}
                />
              </div>
            </div>
            <CardActions className={classes.actions}>
              <Button
                variant='contained'
                type='submit'
                color='primary'
                disabled={isLoading}
                className={classes.button}
                fullWidth
              >
                {isLoading && <CircularProgress className={classes.center} size={25} thickness={2} />}
                login
              </Button>
            </CardActions>
          </form>
          <div style={{ margin: '10px'}}>
            <Typography variant="subtitle2" align="center" color="primary" onClick={this.handleOpenForgot}>
              Forgot Password
            </Typography>
          </div>
        </Card>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">FORGOT PASSWORD</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter your email address , and we will send you a password reset email.
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              name="forgot_email"
              label="Email Address"
              type="email"
              onChange={this.handleForgotEmail}
              fullWidth
            /> 
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={this.handleCloseForgot} >
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={this.handleSendForgot}>
              Reset
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Login.propTypes = {
  ...propTypes,
  authProvider: PropTypes.func,
  classes: PropTypes.object,
  previousRoute: PropTypes.string
  //translate: PropTypes.func.isRequired,
  //userLogin: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoading: state.auth.fetching,
  literals: getLiterals(state),
  auth: getAuthResponse(state),
  force_logout: state.auth.force_logout,
});

const mapDispatchToProps = dispatch => {
  return {
    userLogin: auth => dispatch(authLoginRequest(auth)),
    apiCallRequest: payload => dispatch(apiCallRequest(payload)),
    forceLogout: payload => dispatch(forceLogout(payload)),
    dispatch,
    // apiCallRequest,
  };
};

const enhance = compose(
  //translate,
  reduxForm({
    form: 'signIn'
    /* validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.email) {
                errors.email = 'Username Error'
            }
            if (!values.password) {
                errors.password = 'Password Error'
            }
            return errors;
        },*/
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
);

export default enhance(Login);
