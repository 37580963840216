import React, {Component} from "react";

class DetailExRenderer extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            value: props.value,
            // title : 'Test',
            // isYard: props.data.isYard.value
        }
    }


    // noinspection JSUnusedGlobalSymbols
    refresh(params) {
        // if(params.value !== this.state.value) {
        //     this.setState({
        //         value: params.value.toFixed(2),
        //         isYard: params.data.isYard
        //     })
        // }
        // return true;
    }
    openDateTimeDialog = (event) => {



        // let partUsageTime_editable = this.props.data.partUsageTime.editable
        // if(this.props.colDef.field === "partUsageTime.value" && partUsageTime_editable === 0) 
        //     return false
        // if(this.props.colDef.field === "ETA.value" && this.props.data.ETA.editable === 0) 
        //     return false
        
        // if(this.props.colDef.field=="ETA.value" || this.props.colDef.field=="partUsageTime.value" ){

            // this.props.dashboardOpenTimeDialog(true);
            // this.props.formOperation({...this.props.data,colDef : this.props.colDef});
        // }
    }
    render() {

        let editDetail = this.props.data.editDetail
        let comma = ""
        if(this.props.value) comma = " "
        return (
            <div>
                {this.props.value}
                {comma}{editDetail}
            </div>
        );
    }
};
const mapStateToProps = function(state,ownProps) {
    return {
        ...ownProps,
        // dashboard_time_dialog : state.dashboard.dashboard_time_dialog
    }
};

const mapActionsToProps =  {
    // dashboardOpenTimeDialog,
    // formOperation
};
// const enhance = compose(
//     connect(
//         mapStateToProps,
//         mapActionsToProps
//     ),
//     withStyles(styles)
// );  

// export default enhance(DetailExRenderer)
export default DetailExRenderer
