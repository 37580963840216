import React, { Component } from "react";
import { render } from "react-dom";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  Polyline
} from "react-google-maps";
import { compose, withProps, withStateHandlers } from "recompose";
import { connect } from "react-redux";
import RsWaypointMarker from "./RsWaypointMarker"

import uuidv4  from 'uuid/v4';


class RsWaypoints extends Component {
  render() {
    let legInfo = "";
    let totalDistance = 0;
    let totalETA = 0;
    let infoDistance = 0;
    let infoETA;

    return(
        <div>
            {this.props.subLegs.map((leg,idx) => {
                    if(this.props.suggestion_editing === leg.route_idx)
                    return (
                        <RsWaypointMarker
                            {...leg}
                            key={uuidv4()}
                            id={leg.subleg_idx}
                            // isFirst={isFirst}
                            // isLast={isLast}
                            defaultDraggable={true}
                            infoDistance={infoDistance}
                            infoETA={infoETA}
                            position={leg.position}
                            // isGroup={waypoint.isGroup}
                            // description={waypoint.description}
                        />
                    )
                })
            }
        </div>
    )

    // return(
    //     <div>
    //         <Marker
    //         position={{lat: 13.63333, lng: 100.590460}}
    //         />
    //     </div>
    // )
    
    // return (
    //   <React.Fragment>
    //     {this.props.suggestion_routes.routes.map((route,route_idx) => {
    //         console.log("kai",route)

    //         route.Legs.map((Leg,idx) => {
    //             if(Leg.hasOwnProperty("subLegs")){
    //                 Leg.subLegs.forEach((subLeg, subLeg_id) => {
    //                     var decodePath_sub = window.google.maps.geometry.encoding.decodePath(decodeURIComponent(subLeg.subRoute));
    //                     // console.log("Kai subLeg.subRoute2",subLeg.subRoute)
    //                     var paths_sub = [];
    //                     decodePath_sub.forEach(function(p) {
    //                         paths_sub.push({ lat: p.lat(), lng: p.lng() });
    //                     });
    //                     console.log("Kai paths_sub[0]",paths_sub[0])
    //                     return (
                            
    //                         <div>
    //                         <RsWaypointMarker
    //                             key={uuidv4()}
    //                             id={subLeg_id}
    //                             // isFirst={isFirst}
    //                             // isLast={isLast}
    //                             // defaultDraggable={isFirst || isLast || this.props.map_loading ? false : true}
    //                             infoDistance={infoDistance}
    //                             infoETA={infoETA}
    //                             position={paths_sub[0]}
    //                             // isGroup={waypoint.isGroup}
    //                             // description={waypoint.description}
    //                         />
    //                         </div>
    //                     );
    //                 })

    //             }
    //       })
    //     })}
    //   </React.Fragment>
    // );
  }
}

const mapStateToProps = state => {
  return {
    subLegs: state.map.subLegs,
    suggestion_routes: state.map.suggestion_routes,
    markers: state.map.markers,
    legs: state.map.legs,
    modified_route: state.map.modified_route,
    map_loading : state.map.map_loading,
    suggestion_editing : state.map.suggestion_editing
    // legs: state.legs,
    // waypoints: state.waypoints
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RsWaypoints);
