import React,{Component} from 'react';
import { connect } from "react-redux";
import moment from "moment";
import debounce from 'lodash/debounce'
import { propTypes, reduxForm,Form, Field,formValueSelector,reset } from 'redux-form';
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from "@material-ui/core/Typography";
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import UpdateIcon from '@material-ui/icons/Update';
import SyncIcon from '@material-ui/icons/Sync';
import SearchIcon from "@material-ui/icons/Search";
import ScheduleIcon from '@material-ui/icons/Schedule';
import InputLabel from "@material-ui/core/InputLabel";
import TimerMachine from 'react-timer-machine'
import Slide from '@material-ui/core/Slide';
import momentDurationFormatSetup from "moment-duration-format";
import SplitPane from 'react-split-pane';
import { Pane } from 'react-sortable-pane';
//Component
import FrameGrid from './FrameGrid';
import SubFrameGrid from './SubFrameGrid';
import PlantSelect from './PlantSelect';
import RouteSelect from './RouteSelect';
import ETASelect from './ETASelect';
//Reducer
import { getDataOperation } from '../../reducers/dashboard/operation';
import { getFrame,exportFrame } from '../../reducers/dashboard/api';
import { setSidebarVisibility,uiSwitchForm,SET_SNACKBAR,STATUS_LOADING } from "../../reducers/ui";
import { searchFrame,searchFrameTxt } from "../../reducers/search";
import { dashboardSelected,dashboardSubFrame } from '../../reducers/dashboard'
//Lib
import { required , betweenRouteDate} from "../../libs/validation";

import io from "socket.io-client"

//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  card: {
    margin: theme.spacing.unit,
  },
  media: {
    height: 140,
  },
  margin: {
    margin: theme.spacing.unit,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: 200
  },
  txtBlack : {
    color : "#000000",
    fontSize : 12,
    justifyContent: 'center', 
    alignItems: 'center',
    textAlign: 'right',
  }
});

momentDurationFormatSetup(moment);

//let endpoint = "http://mapssh.leafte.ch:8000";
let endpoint = "http://map.leafte.ch";
let socket

const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
  />
);

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      etaSelect:'',
      plantSelect : '',
      routeSelect : '',
      last_updated : '',
      operationUpdate : null,
      blinkColor : 0,
      started : true,
      timeStart : 180,
      fetching : false,
      manualReload: false,
      isLoading : false,
      routeDateFrom : new Date(),
      routeDateTo : new Date(),
      isDisableDownload : false,
    };
    this.onFilterTextBoxChanged = debounce(this.onFilterTextBoxChanged, 500);
  }
  componentDidMount() {
    var defaultValue = {
      routeDateFrom:moment().format("YYYY-MM-DD"),
      routeDateTo:moment().format("YYYY-MM-DD"),
    };
    console.log('#componentDidMount search_frame',this.props.search_frame);
    if (this.props.search_frame.routeDateFrom || this.props.search_frame.routeDateTo ) {
      defaultValue = this.props.search_frame;
    }
    this.props.initialize({...defaultValue});
    this.props.searchFrame({...defaultValue});

    var intervalId = setInterval(this.timer, 1000);

    const { setSidebarVisibility } = this.props;
    setSidebarVisibility(false);

    let search = this.props.search_frame;
    this.getData(search);
    
    // Configs[env].BACKEND_HOST
    // endpoint
    socket = io.connect(Configs[env].SOCKETIO_URL)

    socket.on("digitalMapEvent", (data) => {
      console.info("Realtime Updated by socket.io msg: ",data)
      if(data === "update") {
        this.setState({ operationUpdate: new Date().toISOString() })
      }
    });
    //this.props.initialize({...this.props.search_frame});
    
  }
  componentDidUpdate(prevProps, prevState) {
    // console.log('#componentDidUpdate search_frame',this.props.search_frame);
    if(prevState.operationUpdate !== this.state.operationUpdate){
      //this.getData(this.props.search_frame);
    }    
  }
  componentWillUnmount() {
    clearInterval(this.state.blinkColor);
    socket.disconnect()
  }
  componentWillReceiveProps(nextProps){
    //console.log('#componentWillReceiveProps search_frame',nextProps.search_frame);
    if(nextProps.search_frame!==this.props.search_frame){
      this.getData(nextProps.search_frame);
    }
  }

  timer = () => {
    this.setState({blinkColor:this.state.blinkColor!=1 ? 1 : 0})
  }
  manualReload(search_frame){
    this.getData(search_frame);
    this.setState({manualReload : true})
  }
  handleInputChange = e => {
    //console.log('#handleInputChange e',e.target.value);
    this.onFilterTextBoxChanged(e.target.value);
  }
  onFilterTextBoxChanged = (value) => {
    console.log('#onFilterTextBoxChanged searchTxt',value);
    this.props.searchFrameTxt(value);
    this.props.dashboardSelected(null); 
    this.props.dashboardSubFrame([]); 
    //this.props.filterResult({key:'LOCATION_GROUP_LIST',filter:event.target.value});
    //this.state.api.setQuickFilter(document.getElementById('location-search').value);
  }
  changeDateForm = (event) =>{
    //console.log('#changeDateForm',event.target.value);
    this.setState({routeDateFrom:event.target.value});
    this.props.searchFrame({...this.props.search_frame,routeDateFrom:event.target.value});
  }  
  changeDateTo = (event) =>{
    //console.log('#changeDateTo',event.target.value);
    this.setState({routeDateTo:event.target.value});
    this.props.searchFrame({...this.props.search_frame,routeDateTo:event.target.value});
  }
  handleTimerOnComplete = (search_frame) =>{
    this.getData(this.props.search_frame)
  }
  getData = (search) =>{
    console.log('#getData search',search);
    this.setState({fetching : true,started : false,isLoading : true })
    if(search.routeDateFrom && search.routeDateTo)
      this.setState({isDisableDownload:false})
    else this.setState({isDisableDownload:true});
    //const selector = formValueSelector('frameForm')
    
    this.props.STATUS_LOADING(true);
    let filter = search;
    this.props.getFrame({
      url: Configs[env].BACKEND_HOST + "/frameDashboard",
      //url: endpoint + "/dashboard/frame",
      method : "GET",
      filter: {...filter },
      callback : this.updateTime,
      callback_error : this.updateTime_error,
    });
  }
  updateTime = () => {
    //console.log('#updateTime.....');
    this.setState({ last_updated: moment().format('YYYY/MM/DD HH:mm:ss') });
    this.setState({started : true,fetching : false,isLoading : false })
    this.props.STATUS_LOADING(false);
    if(this.state.manualReload) {
        this.props.SET_SNACKBAR({ 
          snackbarOpen: true,
          snackbarVariant: "success",
          snackbarMessage: "Manual reload data success",
          snackbarDuration: 2000,
          forceLogout: false
        })
    }
    this.setState({manualReload : false})
  }

  updateTime_error = (error) => {
    //console.log('#updateTime_error.....');
    this.props.STATUS_LOADING(false);
    this.setState({started : true,fetching : false,isLoading : false,manualReload : false })
  }
  handleSearch = (form) => {

  }
  handleClear = () =>{
    this.props.searchFrame({});
    this.props.initialize({});
    this.props.change({});
    this.props.searchFrameTxt("");
  }
  handleExport = () => {
    let filter = this.props.search_frame;
    filter.routeDateFrom = moment(filter.routeDateFrom,'YYYY-MM-DD').format('YYYY/MM/DD')
    filter.routeDateTo = moment(filter.routeDateTo,'YYYY-MM-DD').format('YYYY/MM/DD')

    this.props.exportFrame({
      url: Configs[env].BACKEND_HOST + "/frameDashboard/report",
      filter: filter
    });
  };
  render (){
    const { handleSubmit, submitting,classes,invalid,error } = this.props;
    //console.log('#renderinvalid',invalid);
    return (
        <div style={{ padding: "10px",display: 'flex', flexDirection: 'column', height:'calc(100vh - 80px)' }}  testing="div-operation-dashboard">
          <div>
            <Form
                testing="form-add_edit-logistics-point"
                autoComplete={"off"}
                autoCorrect={"off"}
                spellCheck={"off"}
                onSubmit={handleSubmit(this.handleSearch.bind(this))}
                
              >
              <Grid container spacing={16}>
                  <Grid container item xs={4}>
                    <Grid container spacing={16} item xs={12}>
                      <Grid item xs={6}><PlantSelect/></Grid>
                      <Grid item xs={6}><RouteSelect/></Grid>
                    </Grid>
                    <Grid item xs={8}>
                      <FormControl required fullWidth noValidate autoComplete="off">
                        <TextField
                          id="framedash-search"
                          label="SEARCH"
                          type="search"
                          InputLabelProps={{
                            shrink: true
                          }}
                          //value={this.props.textSearchFrame} 
                          margin="dense"
                          className={classes.textField}
                          onChange={this.handleInputChange}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment variant="filled" position="end">
                                <IconButton>
                                  <SearchIcon />
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <div style={{ width: '300px' , textAlign:'center',display: 'flex', flexDirection: 'row',cursor: 'pointer'}}>
                    <Button disabled={this.props.isLoading}  variant="contained" size="small" color="primary" size="small" className={styles.margin} 
                          onClick={() => this.manualReload(this.props.search_frame)}>
                      Update</Button>
                      <Button  size="medium" className={styles.margin} 
                          disabled={this.props.isLoading} onClick={() => this.manualReload(this.props.search_frame)}>
                        {this.state.last_updated && moment(this.state.last_updated).format("YYYY/MM/DD HH:mm:ss")} 
                        &nbsp;(<TimerMachine
                          timeStart={this.state.timeStart * 1000} // start at 10 seconds
                          timeEnd={0} // end at 20 seconds
                          started={this.state.started}
                          paused={false}
                          countdown={true} // use as stopwatch
                          interval={1000} // tick every 1 second
                          formatTimer={(time, ms) =>
                            moment.duration(ms, "milliseconds").format("h:mm:ss")
                          }
                          // onStart={time =>
                          //   console.info(`Timer started: ${JSON.stringify(time)}`)
                          // }
                          // onStop={time =>
                          //   console.info(`Timer stopped: ${JSON.stringify(time)}`)
                          // }
                          // onTick={time =>
                          //   console.info(`Timer ticked: ${JSON.stringify(time)}`)
                          // }
                          // onPause={time =>
                          //   console.info(`Timer paused: ${JSON.stringify(time)}`)
                          // }
                          // onResume={time =>
                          //   console.info(`Timer resumed: ${JSON.stringify(time)}`)
                          // }
                          onComplete={time => {
                            this.handleTimerOnComplete(this.props.search_frame)
                            }
                          }
                      />)
                    </Button>
                  </div>
                  </Grid>
                  <Grid container spacing={16} item xs={3}>
                      <Grid item xs={5}>
                        <InputLabel shrink htmlFor="start-required" className={classes.txtBlack}>
                          Route Date From : 
                        </InputLabel>
                      </Grid>
                      <Grid item xs={7}>
                        <Field
                          name="routeDateFrom"
                          type="date"
                          component={renderInput}
                          className={styles.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          onChange={this.changeDateForm}
                          margin="dense"
                          validate={[required,betweenRouteDate]}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <InputLabel shrink htmlFor="end-required" className={classes.txtBlack}>
                          Route Date To : 
                        </InputLabel>
                      </Grid>
                      <Grid item xs={7}>
                        <Field
                          name="routeDateTo"
                          type="date"
                          component={renderInput}
                          className={styles.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          onChange={this.changeDateTo}
                          margin="dense"
                          validate={[required,betweenRouteDate]}
                        />
                      </Grid>
                  </Grid>
                  <Grid item container spacing={16} xs={2} style={{textAlign: 'center'}}>
                    <Grid item xs={12}>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={this.handleClear}
                        className={styles.button}>
                        CLEAR
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button 
                        variant="contained" 
                        color="secondary"  
                        disabled={invalid || this.state.isDisableDownload}
                        onClick={this.handleExport}
                        className={styles.button}>
                        DOWNLOAD
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </div>
            <div style={{ flex: '1' ,display: 'flex', flexDirection: 'column' ,marginTop: '1em'}}>
              {/* <Grid item xs={12}> */}
                <Card className={styles.card} style={{height : "100%" }}>
                  <CardContent style={{height : "100%" }}>
                    {/*<Grid container spacing={16} style={{height : "100%" }}>
                      <Grid item xs={this.props.dashboard_subframe_open ? 8 : 12} style={{height : "100%" }}>
                        <FrameGrid blinkColor={this.state.blinkColor}/>
                      </Grid>
                      <Slide direction="left" in={this.props.dashboard_subframe_open} mountOnEnter unmountOnExit>
                        <Grid item xs={4} style={{height : "100%" }}>
                          <SubFrameGrid blinkColor={this.state.blinkColor}/>
                        </Grid>
                      </Slide>
                      
                      </Grid>*/}
                    <Grid container spacing={16} style={{height : "100%" }}> 
                        <Grid item xs={8} style={{height : "100%" }}>
                          <FrameGrid blinkColor={this.state.blinkColor}/>
                        </Grid>
                        <Grid item xs={4} style={{height : "100%" }}>
                          <SubFrameGrid blinkColor={this.state.blinkColor}/>
                        </Grid>  
                    </Grid>
                    {/*<SplitPane split="vertical" minSize={50} style={{height : "100%",width:"100%" }}>
                        
                        <div style={{height : "100%",width:"calc(100vh-300px)" }}>
                              <FrameGrid blinkColor={this.state.blinkColor}></FrameGrid>
                        </div>
                        <div style={{height : "100%",width:"300px" }}>
                              <SubFrameGrid blinkColor={this.state.blinkColor} ></SubFrameGrid>
                        </div>
                    </SplitPane>*/}
                  </CardContent>
                </Card>
            {/* </Grid> */}
          </div>
      </div>
      );
    };
  }
const mapStateToProps = state => ({
  search_frame : state.search.search_frame,
  textSearchFrame : state.search.search_frame_txt,
  isLoading : state.ui.isLoading,
  dashboard_subframe_open : state.dashboard.dashboard_subframe_open,
});
const mapDispatchToProps = dispatch => {
  return {
    setSidebarVisibility: status => dispatch(setSidebarVisibility(status)),
    getFrame : payload => dispatch(getFrame(payload)),
    searchFrame : payload => dispatch(searchFrame(payload)),
    searchFrameTxt : payload => dispatch(searchFrameTxt(payload)),
    exportFrame : payload => dispatch(exportFrame(payload)),
    dashboardSubFrame : payload => dispatch(dashboardSubFrame(payload)),
    dashboardSelected : payload => dispatch(dashboardSelected(payload)),
    SET_SNACKBAR: payload => dispatch(SET_SNACKBAR(payload)),
    STATUS_LOADING: payload => dispatch(STATUS_LOADING(payload)),
    dispatch
  };
};
const enhance = compose(
  reduxForm({
    form: "frameForm",
    // validate
    initialValues: { min: '1', max: '10' }
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
);

export default enhance(Main);