import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withStyles } from '@material-ui/core/styles';
import socketIOClient from 'socket.io-client';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from "@material-ui/core/DialogActions";
import Typography from '@material-ui/core/Typography';

//Component
import MasterRouteBtnRenderer from "./Renderer/MasterRouteBtnRenderer";
import SecToTimeRenderer from "../Ui/Renderer/SecToTimeRenderer";
import DistanceRenderer from "../Ui/Renderer/DistanceRenderer";
import TollRenderer from "../Ui/Renderer/TollRenderer";
//Reducer
import {
  apiCallRequest,
  apiCallSuccess,
  apiCallFail
} from '../../reducers/api';
import { getMasterRoutes } from "../../reducers/map/api";
import { MAP_SELECT_CANDIDATE,mapResetMap ,SELECT_MASTER_OR_CANDIDATE} from "../../reducers/map";

//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    margin:0,
    //marginLeft: theme.spacing.unit,
    //marginRight: theme.spacing.unit,
    width: 200,
  },
});
class MasterRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isToll : false,
      columnDefs: [
        { 
          headerName: "", 
          field: "id" ,
          cellRenderer: "masterBtnRenderer",
          width: 65,
          autoHeight: true
        },{ 
          width:100,
          headerName: "ROUTE", 
          field: "name" ,
          rowDrag: true,
        },{ 
          headerName: "DISTANCE", 
          field: "routes.TotalDistance" ,
          cellRenderer : "distanceRenderer",
          width: 75,
          autoHeight: true
        },
        { 
          headerName: "DURATION", 
          field: "routes.TotalTime" ,
          cellRenderer : "secToTimeRenderer",
          width: 100,
          autoHeight: true
        },
        { 
          headerName: "TOLL", 
          field: "routes.TollUsage" ,
          width: 50,
          autoHeight: true,
          cellRenderer: "tollRenderer",
        }
      ],
      rowData: null,
      context: { componentParent: this },
      suppressRowClickSelection: true,
      rowSelection: "multiple",
      frameworkComponents: {
        tollRenderer : TollRenderer,
        masterBtnRenderer: MasterRouteBtnRenderer,
        secToTimeRenderer : SecToTimeRenderer,
        distanceRenderer : DistanceRenderer,
      },
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading...</span>',
    };
  }
  componentDidMount() {
    this.handleLoadData();
  }
  componentWillUnmount() {
    
  }
  componentDidUpdate () {
    this.autoSizeAll();
  }
  //Grid
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  }
  autoSizeAll = () => {
    // var allColumnIds = [];
    // if(this.gridColumnApi){

    //   this.gridColumnApi.getAllColumns().forEach(function(column,index) {
    //     if(index!=0)allColumnIds.push(column.colId);
    //   });
    //   this.gridColumnApi.autoSizeColumns(allColumnIds);
    // }
  }
  handleLoadData = () =>{
    if(this.gridApi) this.gridApi.showLoadingOverlay();

    this.props.RESET_MAP(true);
    if(this.props.logisticRouteForm.logisticsRouteId){
      this.props.GET_MASTER_ROUTE({
        url:Configs[env].BACKEND_HOST + "/routeCond",
        filter: {
          logisticsRouteId: this.props.logisticRouteForm.logisticsRouteId,
        },
        callback : this.loadingComplete,
        callback_error : this.loadingComplete
      });
    }
    
  }
  loadingComplete = () =>{
    if(this.gridApi)this.gridApi.hideOverlay();
  }
  componentWillReceiveProps(nextProps) {
    this.focusRow(nextProps.candidate_selecting)
  }

  focusRow = (candidate_selecting) => {
    let gridApi = this.gridApi
    if(gridApi){
      gridApi.deselectAll()
      this.gridApi.forEachNode(function(node) {
        if (node.data.id === candidate_selecting) {

          node.setSelected(true,true);
          gridApi.ensureIndexVisible(node.rowIndex, { position: 'top' }); 

          // and ensureNodeVisible(event.node, { position: 'top' });
        }

      });


    }
  }
  renderData = () => {
    if (Array.isArray(this.props.master_routes)) {

      return this.props.master_routes;
    } else return [];
  }
  handleSelectionChanged = node => {
    try{
      var selectedRows = node.api.getSelectedRows();

      this.props.MAP_SELECT_CANDIDATE(selectedRows[0].id);
      this.props.SELECT_MASTER_OR_CANDIDATE(1)
    } catch (e){

    }
  };
  onRowDragEnter = (e) => {

  }
  onRowDragEnd = (e) => {

    let gridApi = this.gridApi
    if(gridApi)gridApi.showLoadingOverlay();

    let self = this;
    gridApi.deselectAll()
    this.gridApi.forEachNode(function(node) {

      self.updatePriority(node.data.id,(node.childIndex+1));
    });
  }
  updatePriority = (id,priority) => {
    this.props.API_REQUEST({
      url: Configs[env].BACKEND_HOST + "/routePriority/"+id,
      form: { priority : priority},
      method : "PUT",
      callback : this.handleLoadData
    });
  }
  onRowDragMove = (e) => {

  }
  onRowDragLeave = (e) => {

  }
  render() {
    return (
      <div style={{ width: "100%", height: "180px",padding:'5px' }}>
        <DialogActions>
            <Typography variant="subheading">MASTER ROUTES : </Typography>
              {/* <Button 
                  variant="contained" size="small"  color="primary" 
                  aria-label="RELOAD" className={classNames(styles.button)} 
                  onClick={this.handleLoadData}>
                  RELOAD
              </Button> */}
          </DialogActions>
        <div
          className="ag-theme-balham"
          style={{
            height: "150px",
            width: "100%",
          }}>
          
          <AgGridReact
            singleClickEdit={true}
            // suppressMovableColumns={true}
            columnDefs={this.state.columnDefs}
            rowDragManaged={true}
            animateRows={true}
            enableSorting={true}
            enableFilter={true}
            enableColResize={true}
            // suppressRowDrag={true}
            rowData={this.renderData()}
            rowSelection="single"
            overlayLoadingTemplate={this.state.overlayLoadingTemplate}
            onRowClicked={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
            onGridReady={this.onGridReady}
            onRowDragEnter={this.onRowDragEnter}
            onRowDragEnd={this.onRowDragEnd}
            onRowDragMove={this.onRowDragMove}
            onRowDragLeave={this.onRowDragLeave}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function(state,ownProps) {
  return {
    isLoading : state.api.fetching,
    master_routes: state.map.master_routes,
    logisticRouteForm : state.mapForm.logisticRouteForm,
    candidate_selecting : state.map.candidate_selecting,
  }  
};
const mapActionsToProps =  {
  success : apiCallSuccess,
  API_REQUEST: apiCallRequest,
  RESET_MAP: mapResetMap,
  GET_MASTER_ROUTE : getMasterRoutes,
  SELECT_MASTER_OR_CANDIDATE : SELECT_MASTER_OR_CANDIDATE,
  MAP_SELECT_CANDIDATE
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapActionsToProps,
    //mapDispatchToProps
  ),
  withStyles(styles)
)(MasterRoutes);
