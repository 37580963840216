import React, { Component } from "react";
import { render } from "react-dom";
import {
  Polygon,
  InfoWindow
} from "react-google-maps";
import { connect } from "react-redux";
import {
  mapSelectedPolygon,
  mapChangePolygon
} from "../../reducers/map";

class Impolygon extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.addPolygonEvents();
  }
  componentDidUpdate() {
    this.addPolygonEvents();
  }
  addPolygonEvents(){
    let _this = this;
    window.google.maps.event.addListener(_this.polygon.getPath(), 
      'set_at', 
      position => _this.onChange(position)
    );
    window.google.maps.event.addListener(_this.polygon.getPath(), 
      'insert_at', 
      position => _this.onChange(position)
    );
    window.google.maps.event.addListener(_this.polygon.getPath(), 
      'remove_at', 
      position => _this.onChange(position)
    );
  }
  onChange = position => {
    try {
      let obj = { ...this.props, path: this.polygon.getPath()  };
      this.props.POLYGON_CHANGE(obj);
    } catch(e) {
    }
  };  
  selectInfo = arg => { 
     this.props.POLYGON_SELECTING(this.props.id);


  };

  toggleEdit = e => {
    this.props.POLYGON_TOGGLE_EDIT(this.props.id);

  };

  render() {
    const id = this.props.id;
    let selected = false;



    if (this.props.polygon_selecting === id) {
      selected = true;
    }

    return (
      <Polygon
        key={this.props.id}
        id={this.props.id}
        path={this.props.path}
        editable={this.props.editable}
        color="blue"
        radius={this.props.radius}
        //onClick={this.toggleEdit}
        onClick={this.selectInfo}
        ref={input => (this.polygon = input)}
      >
        {selected ? (
          <InfoWindow>
            <div>
              {this.props.info.logisticsPoint ? 'Logistic Point : '+this.props.info.logisticsPoint : ''}
              <br />
              Name: <br />
              {this.props.info.name}
              Description: <br />
              {this.props.info.description}
              <br />
              {this.props.isGroup}
            </div>
          </InfoWindow>
        ) : null}
      </Polygon>
    );
  }
}

const mapStateToProps = state => {
  return {
    polygon_selecting: state.map.polygon_selecting
  };
};

const mapActionsToProps = {
  POLYGON_SELECTING : mapSelectedPolygon ,
  POLYGON_CHANGE : mapChangePolygon ,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(Impolygon);
