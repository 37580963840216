import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { connect } from "react-redux";
import compose from "recompose/compose";
import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from "@material-ui/core/DialogActions";
import Typography from '@material-ui/core/Typography';

//Component
//Reducer
import {
  apiCallRequest,
  apiCallSuccess,
  apiCallFail
} from '../../reducers/api';
import { getSuggestionRoutes ,getTruckLocation } from "../../reducers/map/api";
import { MAP_SELECT_CANDIDATE,mapResetMap,SET_MARKER_ORIGIN_DESC,MAP_ROW_SUGGESSION } from "../../reducers/map";

//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '10px',
  },
  textField: {
    margin:0,
    //marginLeft: theme.spacing.unit,
    //marginRight: theme.spacing.unit,
    width: 200,
  },
});
class RouteTracking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isToll : false,
      columnDefs : [],
      jsonData : '',
      rowData : [],
      defaultColumn: [
        { 
          headerName: "TRUCK LICENSE", 
          field : 'truckLicense',
          width: 90,
          rowSpan: function(params) {return 2;}
        },{ 
          headerName: "ROUTE", 
          field : 'routeCode',
          width: 80,
          rowSpan: function(params) {return 2;}
        },{ 
          headerName: "RUN SEQ", 
          field : 'runSeq',
          width: 50,
          rowSpan: function(params) {return 2;}
        },{ 
          headerName: "JOB", 
          field : 'jobNo',
          width: 50,
          rowSpan: function(params) {return 2;}
        }
      ],
      context: { componentParent: this },
    };
  }
  componentDidMount() {
    setTimeout(() => this.handleLoadData(),3000)
  }
  componentWillUnmount() {
    
  }
  componentWillReceiveProps(nextProps){
    //getDataOperation({});
    if(this.props.suggestion_routes!=nextProps.suggestion_routes 
        || this.props.suggestion_selecting!=nextProps.suggestion_selecting){
      //this.renderData(nextProps.suggestion_routes,nextProps.suggestion_selecting);
      
      //let data1 = this.renderData(nextProps.suggestion_routes,0);
      //let data2 = this.renderData(nextProps.suggestion_routes,1);
      let rowData = [];
      let _this = this;
      nextProps.suggestion_routes.routes.forEach(function(item,index) {
        let data = _this.renderData(nextProps.suggestion_routes,item,index)
        data.forEach(function(itemD,indexD) {
          // console.log('#componentWillReceiveProps itemD',itemD);
          rowData.push(itemD);
        });
      });
      // console.log('#componentWillReceiveProps rowData',rowData);
      //this.props.MAP_ROW_SUGGESSION(data1.concat(data2));
      this.setState({rowData:rowData});
      this.focusRow(nextProps.suggestion_selecting!=0 ? nextProps.suggestion_selecting+2 : 0);
    }
  }
  handleLoadData = () =>{
    this.props.RESET_MAP(true);
    if(this.props.jobDashboardId){
      this.props.getSuggestionRoutes({
        //url: "http://mapssh.leafte.ch/routeSuggestionSample",
        url:Configs[env].BACKEND_HOST + "/routeSuggestion/"+this.props.jobDashboardId,
        filter: {},
        callback : this.loadDataSuccess,
        // callback_error : this.updateTime_error,
      });

      this.props.getTruckLocation({
        url:Configs[env].BACKEND_HOST + "/truckLocation/"+this.props.jobDashboardId,
        filter: {},
      });
    }
  }

  loadDataSuccess = (data) => {

  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAll();
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  }
  autoSizeAll = () => {
    var allColumnIds = [];
    if(this.gridColumnApi){

      this.gridColumnApi.getAllColumns().forEach(function(column,index) {
        if(index!=0)allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  }
  focusRow = (selecting) => {
    let gridApi = this.gridApi
    if(gridApi){
      gridApi.deselectAll()
      this.gridApi.forEachNode(function(node,index) {

        if (node.rowIndex === selecting) {

          node.setSelected(true,true);
          gridApi.ensureIndexVisible(node.rowIndex, { position: 'top' }); 
        }

      });
    }
    gridApi.deselectAll()
  }
  renderData = (suggestion_routes,item,index) => {
    let self = this;
    //console.log('#renderData suggestion_routes',suggestion_routes);
    if (item) {
      // console.log('#renderData item',item);
      let dropsHeader = JSON.parse(JSON.stringify(this.state.defaultColumn));
      let dropCount = 1;
      let routes = [];
      var data = [];data[0] = []; data[1] = [];

      //Set first row detail
      for (var key in suggestion_routes) {
        if (suggestion_routes.hasOwnProperty(key) && !Array.isArray(suggestion_routes[key])) {
          data[0][key] = suggestion_routes[key];
          data[1][key] = '';
        }
      }
      for(let leg of item.Legs){
        dropsHeader.push({
          headerName: dropCount+' : '+leg.locationName,
          children: [
              {
                headerName: 'IN',
                field:'in'+dropCount, 
                width: 70 ,
                cellStyle: function(params) {

                  return {color:'black',textAlign:'center',backgroundColor: leg.isCompleted ? "#1976D2" : "transparent"};
                },
              },{
                headerName: 'OUT', 
                field:'out'+dropCount,
                width: 70,
                cellStyle: function(params) {

                  return {color:'black',textAlign:'center',backgroundColor: leg.isCompleted ? "#1976D2" : "transparent"};
                },
              },
          ]
        });
        
        data[0]['in'+dropCount] = leg.planArrivalTime !="" ? moment(leg.planArrivalTime,'YYYY-MM-DD HH:mm:ss').format('HH:mm') : "-";
        data[0]['out'+dropCount] = leg.planDepartureTime !="" ? moment(leg.planDepartureTime,'YYYY-MM-DD HH:mm:ss').format('HH:mm') : "-";
        data[0]['complete'+dropCount] = leg.isCompleted;

        data[1]['in'+dropCount] = leg.actualArrivalTime !="" ? moment(leg.actualArrivalTime,'YYYY-MM-DD HH:mm:ss').format('HH:mm') : "-";
        data[1]['out'+dropCount] = leg.actualDepartureTime !="" ? moment(leg.actualDepartureTime,'YYYY-MM-DD HH:mm:ss').format('HH:mm') : "-";
        data[1]['completed'+dropCount] = leg.isCompleted;
        
        // console.log('#renderData data',data);
        dropCount++;
      }

      routes.push(data[0]);
      routes.push(data[1]);
      // console.log('#renderData routes',routes);
      this.updateCoulumns(dropsHeader);
      //this.props.MAP_ROW_SUGGESSION(routes);
      //this.setState({jsonData : routes,columnDefs:dropsHeader});
      //return this.props.suggestion_routes.routes;
      return routes;
    } 
  }
  handleSelectionChanged = node => {
    var selectedRows = node.api.getSelectedRows();

    this.props.MAP_SELECT_CANDIDATE(selectedRows[0].id);
  };
  updateRouteData = (data) =>{

    this.setState({jsonData:data});
  }
  updateCoulumns = (data) =>{
    this.setState({columnDefs:data});
  }
  renderRow = () =>{
    if (Array.isArray(this.props.suggestion_rowdata)) {

      return this.props.suggestion_rowdata;
    } else return [];
  }
  render() {
    return (
      <div style={{ width: "100%", height: "140px" }}>
        <div
          className="ag-theme-balham"
          style={{
            height: "120px",
            width: "100%",
            marginTop: "10px"
          }}>
          <AgGridReact
            enableSorting={true}
            suppressMovableColumns={true}
            suppressRowTransform={true}
            columnDefs={this.state.columnDefs}
            rowData={this.state.rowData}
            enableColResize={true}
            //rowDataUpdated={this.updateRowData}
            onGridReady={this.onGridReady.bind(this)}
            //onSelectionChanged={this.handleSelectionChanged}
            frameworkComponents={this.state.frameworkComponents}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = function(state,ownProps) {
  return {
    isLoading : state.api.fetching,
    suggestion_routes: state.map.suggestion_routes,
    suggestion_rowdata: state.map.suggestion_rowdata,
    suggestion_selecting: state.map.suggestion_selecting,
    jobDashboardId : state.selection.jobDashboardId
  }  
};
const mapActionsToProps =  {
  getRequest : apiCallRequest,
  success : apiCallSuccess,
  RESET_MAP: mapResetMap,
  getSuggestionRoutes ,
  getTruckLocation,
  SET_MARKER_ORIGIN_DESC,
  MAP_SELECT_CANDIDATE,
  MAP_ROW_SUGGESSION
};

export default compose(
  connect(
    mapStateToProps,
    mapActionsToProps,
    //mapDispatchToProps
  ),
  withStyles(styles)
)(RouteTracking);
