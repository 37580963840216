import React, {Component} from "react";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { uiSwitchForm } from '../../../reducers/ui';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/DeleteForever';
// import EditIcon from '@material-ui/icons/Create';
import { apiCallRequest } from '../../../reducers/api';
import { searchOperation } from "../../../reducers/search";
import { getOperation } from "../../../reducers/dashboard/api";
//Config
import Configs from "../../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
    gridButton: {
        padding: '0px 0px',
        minWidth: '50px',
        fontSize: '0.8em',
        minHeight: '20px'
    },
    iconStyle :{
        padding:0,
    }
})
function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class ManageBtnRenderer extends Component {
    constructor(props) {
        super(props);

        this.invokeParentMethodDelete = this.invokeParentMethodDelete.bind(this);
        this.state = {
            open:false,
            name : this.props.data.jobDashboardId.value,
            shiftSeq : this.props.data.shiftSeq.value
        }
    }
    handleConfirm = () =>{
        var id = this.props.data.jobDashboardId.value;
        console.log('#handleConfirm Delete this.props.data',this.props.data);
        let filter = {};
        if(this.props.data.planAccessSeq && this.props.data.planAccessSeq!=""){
            filter.planAccessSeq = this.props.data.planAccessSeq;
        }
        if(this.props.data.shiftSeq.value && this.props.data.shiftSeq.value!=""){
            filter.shiftSeq = this.props.data.shiftSeq.value.toString();
        }
        console.log('#handleConfirm Delete filter',filter);
        this.props.apiCallRequest({
            url: Configs[env].BACKEND_HOST + "/operationDashboard/"+id,
            method : 'DELETE',
            //listData: [filter],
            objData : filter,
            callback : this.handleComplete
        });
        this.handleCancel();
          
    }
    handleComplete = ()=>{
        let filter = this.props.search_operation;
        //if(this.props.search_operation.plant) filter.plant = this.props.search_operation.plant.join(',')
        //if(this.props.search_operation.yard) filter.yard = this.props.search_operation.yard.join(',');
        if(filter.etaType=='all') delete(filter.etaType);
        this.props.getOperation({
            url: Configs[env].BACKEND_HOST + "/operationDashboard",
            method : "GET",
            filter: filter,
            callback : this.handleCancel
        });
    }
    handleCancel = () => {
        this.setState({ open: false });
    };
    invokeParentMethodDelete() {

        let privilege = JSON.parse(localStorage.getItem('privilege'));
        let controllable = privilege && privilege.hasOwnProperty("configuration") ? privilege.configuration.controllable : false
        // let toDisable = !controllable

        if(controllable)
            this.setState({open : true});
    }
    render() {
        const { classes } = this.props;
        let privilege = JSON.parse(localStorage.getItem('privilege'));
        return (
            <span>
                <IconButton
                    className={classes.iconStyle}
                    onClick={this.invokeParentMethodDelete}
                    disabled={privilege && privilege.hasOwnProperty("configuration") && this.state.shiftSeq=="" ? !privilege.configuration.controllable : true}  
                >
                    <DeleteIcon/>
                </IconButton>
                <Dialog
                    open={this.state.open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    >
                    <DialogTitle id="alert-dialog-slide-title">
                        Delete Job
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                        Confirm to delete { this.state.name.substring(0,this.state.name.length-4) }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirm.bind(this)} color="primary">
                            Confirm
                        </Button>
                        <Button onClick={this.handleCancel.bind(this)} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        );
    }
};

const mapStateToProps = function(state,ownProps) {
    return {
        ...ownProps,
        search_operation : state.search.search_operation
    }
};

const mapActionsToProps =  {
    getOperation,
    searchOperation,
    apiCallRequest
};
const enhance = compose(
    connect(
        mapStateToProps,
        mapActionsToProps
    ),
    withStyles(styles)
);  

export default enhance(ManageBtnRenderer)
