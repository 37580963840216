import React, { Component } from "react";
import {
  Marker,
  InfoWindow
} from "react-google-maps";
import { connect } from "react-redux";
import {
  mapMoveMarker,
  mapSetMarkerPlace
} from "../../reducers/map";

import { uiSwitchForm } from '../../reducers/ui';

class CarMarker extends Component {
  componentWillUpdate(nextProps, nextState) {}

  selectInfo = arg => {
    // console.log('#selectInfo',this.props.id);
    // this.props.mapSelectedMarkerSearch(this.props.id);
  };
  closeInfo = () => {
    this.props.mapSelectedMarkerSearch("")
  }
  render() {
    const id = this.props.id;

    return (
      <Marker 
        {...this.props}
        position={this.props.position}
        icon={this.props.icon}
        key={this.props.key}
        id={this.props.id}
        title={this.props.name || ''}
        zIndex={this.props.zIndex}
        onClick={this.selectInfo}
        markerCilck={this.selectInfo}
        >
      </Marker>
    );
  }
}

const mapStateToProps = state => {
  return {

  };
};

const mapActionsToProps = {
  mapSetMarkerPlace,
  MARKER_MOVE : mapMoveMarker,
  uiSwitchForm : uiSwitchForm
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(CarMarker);
