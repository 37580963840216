import React, {Component} from "react";
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    },
  });

export default class DateTimeEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        }
    }

    formatDateTime(value) {
        
        return moment(value).format('HH:mm')
    }


    render() {
        //const { classes } = props;

        return (
          <form className={styles.container} noValidate>
            <TextField
              id="datetime-local"
              label="Next appointment"
              type="datetime-local"
              defaultValue="2017-05-24T10:30"
              className={styles.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
        );
    }
};