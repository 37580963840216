import React, { Component } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import classNames from "classnames";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import { propTypes, reduxForm, Form, Field } from "redux-form";
import { connect } from "react-redux";
import compose from "recompose/compose";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import PlaceIcon from "@material-ui/icons/Place";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from '@material-ui/core/CircularProgress';

//Component
import Loading from '../Ui/Loading';
import Locations from "./Locations";
import PanelDialog from "../Ui/PanelDialog";  
import LogisticPointSelect from './LogisticPointSelect';
import DeleteDialog from '../Ui/DeleteDialog';

//Reducer
import { uiPanelDialogOpen ,STATUS_LOADING} from "../../reducers/ui";
import { required, number , maxLength100} from "../../libs/validation";
import { apiCallRequest,apiGetCallRequest } from "../../reducers/api";
import { uiSwitchForm } from "../../reducers/ui";
import { addGroupLocation } from "../../reducers/ui/mapForm";
import { getLocationGroup,getLogisticPoint } from "../../reducers/map/api";
import { selectLocationGroup,selectLogisticPoint } from '../../reducers/ui/selectList';
import {
  mapDrawingMode,
  mapAreaType,
  mapResetMap,
  mapSetMarkers,
  mapCenterChanged
} from "../../reducers/map";
//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  formControl: {
    margin: theme.spacing.unit
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  button: {
    margin: theme.spacing.unit
  },
  titlePanel: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500])
  },
  center:{
    position: 'relative',
    left: '20px'
  }
});
const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const renderInputArea = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    multiline={true}
    rows={2}
    {...inputProps}
    {...props}
    fullWidth
  />
);

class LocationGroupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteDialog : false,
      deleteDetail : '',
      isRoute : false,
      name : '',
      logisticsPointGroup : ''
    };
  }

  componentWillMount() {

    this.props.initialize({...this.props.groupForm});
  }
  componentDidMount() {
    //this.props.RESET_MAP(true);
    /*this.props.GET_LOCATION_GROUP({
      url: Configs[env].BACKEND_HOST + "/location/group_list",
      filter: {
        logisticsPointGroup: this.props.groupForm.logisticsPointGroup
      }
    });*/
    if (this.props.type.toLowerCase() === "edit" && this.props.groupForm) {
      let markers = this.setMarker(this.props.groupForm,true);
      this.props.SET_MARKERS(markers);
      this.props.CENTER_CHANGE(markers[0].position);
    }
  }
  componentWillUnmount() {
    
  }
  componentWillReceiveProps(nextProps) {

    //SET MARKER 
    if (nextProps.markerList && nextProps.markerList.length>0) {
      this.props.change("latitude", nextProps.markerList[0].position.lat);
      this.props.change("longitude", nextProps.markerList[0].position.lng);
    }
  }
  setMarker = (point,editable) =>{
    let markerList = [];

      //Marker
    let marker = {
      id : point.id,
      position : {lat: point.latitude , lng: point.longitude},
      info : {
        name : point.name,
        logisticsPoint : point.logisticsPointGroup ,
      },
      isGroup : true,
      editable : editable
    };
    markerList.push(marker);
    return markerList;
  }
  handleSave = (form) => {


    let formData = {};
    let url = "";
    let method = "POST";
    if (this.props.type.toLowerCase() === "edit") {
      url = Configs[env].BACKEND_HOST + "/locationGroup/"+form.id;
      method = "PUT";
      formData = { ...form, 
        //id : form.id,
        logisticsPointGroup : form.logisticsPointGroup.trim(),
        latitude: parseFloat(this.props.markerList[0].position.lat),
        longitude: parseFloat(this.props.markerList[0].position.lng)
      };
    } else {
      url = Configs[env].BACKEND_HOST + "/locationGroup"
      formData = { ...form,
        logisticsPointGroup : form.logisticsPointGroup.trim(),
        latitude: parseFloat(this.props.markerList[0].position.lat),
        longitude: parseFloat(this.props.markerList[0].position.lng),
      };
      this.setState({name:form.name,logisticsPointGroup : form.logisticsPointGroup.trim()})
    }
    delete(formData.id);

    this.props.API_REQUEST({
      url: url,
      form: formData,
      method : method,
      callback: this.handleSaveComplete
    });
  }
  
  handleOpenDelete = () =>{
    this.setState({deleteDialog:true});
  }
  handleComfirmDelete = () => {
    this.props.API_REQUEST({
      url: Configs[env].BACKEND_HOST + "/locationGroup/"+this.props.groupForm.id,
      method : "DELETE",
      callback_error: this.handleBeforeDelete,
      callback: this.handleDeleteComplete
    });
  }
  handleComfirmDeleteRoute = () => {
    this.props.API_REQUEST({
      url: Configs[env].BACKEND_HOST + "/locationGroup/"+this.props.groupForm.id+'?confirmed=1',
      method : "DELETE",
      callback: this.handleDeleteComplete
    });
  }
  handleBeforeDelete = (response) => {
    //console.log('#handleBeforeDelete response',response)
    if(response.errorCode === 1104 || response.errorCode === 1105){
      this.props.STATUS_LOADING(false);
      this.setState({deleteDialog:true,deleteDetail : response.errorMessage , isRoute:true});
    } else {
      this.handleDeleteComplete();   
    }
  }
  handleDeleteComplete = () => {   
    //console.log('#handleDeleteComplete logisticPoint',this.props.logisticPoint.value)
    let payload = { value : '' , text:''};
    this.props.selectLocationGroup(payload);
    this.handleReset();
  }
  handleSaveComplete = (response) => {
    if (this.props.type.toLowerCase() != "edit") {
      console.log('#handleSaveComplete response',response)
      let payload = { value : JSON.stringify({id:response.id,name:this.state.name,logisticsPointGroup:this.state.logisticsPointGroup}) , text:this.state.name};
      console.log('#handleSaveComplete payload',payload);
      this.props.selectLocationGroup(payload);
    }
    
    this.props.reset();
    this.props.SWITCH_FORM({ page: "list" });
    this.props.RESET_MAP(true);
  }
  handleReset = () => {
    //console.log('#handleReset logisticPoint',this.props.logisticPoint.value)
    this.props.reset();
    this.props.SWITCH_FORM({ page: "list" });
    this.props.RESET_MAP(true);
  }
  /*handleCancel = (reset) => {
    //this.props.OPEN_DIALOG(false);
    this.props.SET_GROUP_LOCATION({});
    this.props.SWITCH_FORM({page:'list'});
  }*/
  handleComfirmCancel = () => {
    this.setState({deleteDialog:false,deleteDetail : '' , isRoute:false});
  }
  handleDrawingMode(mode, event) {

    //this.props.onResetMap(false);
    if (mode != "marker")  this.props.CHANGE_AREATYPE(mode);
    this.props.DRAWING_MODE(mode);
  }
  renderMenuItem() {
    if (this.props.grouplist !== null) {
      if (
        Array.isArray(this.props.grouplist.result) &&
        this.props.grouplist.result.length > 0
      ) {
        return this.props.grouplist.result.forEach(data => {
          //debugger
          return (
            <MenuItem key={data.id} value={data.id}>
              {data.name}
            </MenuItem>
          );
        });
      } else return null;
    } else return null;
  }
  
  render() {
    const { handleSubmit, submitting,classes } = this.props;
    return (
      <div style={{ padding: "10px" }}>
      <Toolbar variant="dense">
        <Typography variant="h6" gutterBottom className={styles.titlePanel}>
          {this.props.mode} LOCATION GROUP
        </Typography>
      </Toolbar>
      <Card className={styles.card}>
          <CardContent>
            <Form
              testing="form-add_edit-location-group"
              autoComplete={"off"}
              autoCorrect={"off"}
              spellCheck={"off"}
              onSubmit={handleSubmit(this.handleSave.bind(this))}
            >
             <Grid container spacing={16}>
                <Grid item xs={12}>
                  
                  <LogisticPointSelect/>
                </Grid>
                <Grid item xs={12}> 
                  <Field
                    testing="field-location-group"
                    name="name"
                    component={renderInput}
                    label="Location Group"
                    validate={[required,maxLength100]}
                    disabled={this.props.isLoading}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    color={this.props.drawingMode=="marker" ? "secondary" : "primary"}
                    aria-label="Create Marker"
                    className={styles.button}
                    onClick={this.handleDrawingMode.bind(this, "marker")}
                  >
                    <PlaceIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    testing="field-lat"
                    disabled
                    name="latitude"
                    label="Latitude"
                    component={renderInput}
                    className={styles.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="dense"
                    validate={[required, number]}
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                  <Field
                    testing="field-lng"
                    disabled
                    name="longitude"
                    label="Longitude"
                    component={renderInput}
                    className={styles.textField}
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="dense"
                    validate={[required, number]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DialogActions>
                    <Button
                      testing="button-save-location-group"
                      variant="contained"
                      size="small"
                      color="primary"
                      aria-label="Save"
                      //disabled={submitting}
                      disabled={this.props.isLoading}
                      type="submit"
                    >
                    {this.props.isLoading && <CircularProgress className={classes.center} size={25} thickness={2} />}
                      Save
                    </Button>
                    {this.props.groupForm.id && 
                      (<Button
                        variant="contained"
                        size="small"
                        variant="contained"
                        color="secondary"
                        aria-label="Delete"
                        disabled={this.props.isLoading}
                        onClick={this.handleOpenDelete}
                      >
                      {this.props.isLoading && <CircularProgress className={classes.center} size={25} thickness={2} />}
                        Delete
                      </Button>)
                    }
                    <Button
                      variant="contained"
                      size="small"
                      variant="outlined"
                      color="secondary"
                      aria-label="Cancel"
                      disabled={this.props.isLoading}
                      onClick={this.handleReset}
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          </CardContent>
        </Card>
        {this.state.deleteDialog && 
          (<DeleteDialog
            title="Location Group"
            detail={this.state.isRoute ? this.state.deleteDetail : this.props.form.name}
            handleComfirmDelete={this.state.isRoute ? this.handleComfirmDeleteRoute : this.handleComfirmDelete}
            handleComfirmCancel={this.handleComfirmCancel}
          />)
        }
      </div>
    );
  }
}

const mapStateToProps = function(state, ownProps) {

  return {
    mode: state.ui.formManage.mode || ownProps.mode === "edit" ? "EDIT" : "ADD",
    dialogOpen: state.ui.dialogOpen,
    //isLoading: state.api.fetching,
    isLoading: state.ui.isLoading,
    logistic: ownProps.logistic,
    location: ownProps.location,
    groupForm: state.mapForm.groupForm,
    grouplist: state.api.result,
    drawingMode : state.map.drawingMode,
    markerList : state.map.markerList,
    type: ownProps.type,
    logisticPoint : state.selection.logisticPoint,
    locationGroup: state.selection.locationGroup,
    // ...ownProps,
  };
};
const mapActionsToProps = {
  OPEN_DIALOG: uiPanelDialogOpen,
  API_REQUEST: apiCallRequest,
  DRAWING_MODE : mapDrawingMode,
  CHANGE_AREATYPE: mapAreaType,
  GET_LOGISTIC_POINTS : getLogisticPoint,
  GET_LOCATION_GROUP: getLocationGroup,
  SWITCH_FORM: uiSwitchForm,
  SET_GROUP_LOCATION : addGroupLocation,
  RESET_MAP: mapResetMap,
  CENTER_CHANGE : mapCenterChanged,
  SET_MARKERS : mapSetMarkers,
  STATUS_LOADING,
  selectLocationGroup,
  selectLogisticPoint
};

const enhance = compose(
  //translate,
  reduxForm({
    form: "groupForm"
    // validate
  }),
  connect(
    mapStateToProps,
    mapActionsToProps
  ),
  withStyles(styles)
);
export default enhance(LocationGroupForm);
/*
                <Grid item xs={12}> 
                  <Field
                    name="description"
                    component={renderInputArea}
                    label="Details"
                    disabled={this.props.isLoading}
                  />
                </Grid>*/