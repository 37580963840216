import { createAction, handleActions } from 'redux-actions';
import { create } from 'jss';

// ACTION CREATORS
export const apiCallRequest = createAction('API_CALL_REQUEST');
export const apiCallSuccess = createAction('API_CALL_SUCCESS');
export const apiCallFailure = createAction('API_CALL_FAILURE');

export const apiGetCallRequest = createAction("API_GET_CALL_REQUEST");
export const apiGetCallSuccess = createAction("API_GET_CALL_SUCCESS");
export const apiGetCallFailure = createAction("API_GET_CALL_FAILURE");

export const resetAPIResult = createAction("API_RESET_API_RESULT");

//** renew state */
export const apiPointListRequest = createAction("API_POINT_LIST_REQUEST");
export const apiPointListSuccess = createAction("API_POINT_LIST_SUCCESS");
export const apiPointListFailure = createAction("API_POINT_LIST_FAILURE");
export const apiPointListReset = createAction("API_POINT_LIST_RESET");

export const apiGroupListRequest = createAction("API_GROUP_LIST_REQUEST");
export const apiGroupListSuccess = createAction("API_GROUP_LIST_SUCCESS");
export const apiGroupListFailure = createAction("API_GROUP_LIST_FAILURE");
export const apiGroupListReset = createAction("API_GROUP_LIST_RESET");


const initialState = {
  fetching: false,
  //dog: null,
  error: null,
  result:null,
  mapResult : new Map(),//GROUP LIST,POINT LIST
  
  //** renew state */
  fetchingPointList : false,
  resultPointList : [],
  fetchingGroupList : false,
  resultGroupList : [],
};

// REDUCERS
export default handleActions(
  {
    [apiCallRequest](state, { payload }) {

      return { ...state, fetching: true, error: null};
    },
    // [apiCallSuccess](state, {dog}) {
    //   return { ...state, fetching: false, dog: dog  };
    // },
    [apiCallSuccess](state, {result}) {
      return { ...state, fetching: false, result: result};
    },
    // [apiCallFailure](state, { error }) {
    //   return { ...state, fetching: false, dog: null, error: error };
    // }
    [apiCallFailure](state, { error }) {
      return { ...state, fetching: false, result: null, error: error };
    },

    [apiGetCallRequest](state, { payload }) {
      return { ...state, fetching: true, error: null};
    },
    [apiGetCallSuccess](state, {result,label}) {
      state.mapResult.set(label,result);
      return Object.assign({ ...state, fetching: false, result: result });
    },
    [apiGetCallFailure](state, { error }) {
      return { ...state, fetching: false, result: null, error: error };
    },
    [resetAPIResult](state,{payload})
    {
      state.mapResult.set(payload,[]);
      return Object.assign({ ...state, fetching: false });
    },

    /** renew actions point list*/
    [apiPointListRequest](state, { payload }){
      return { ...state, fetchingPointList : true, error : null};
    },
    [apiPointListSuccess](state,{ result }){
      return { ...state, fetchingPointList : false, resultPointList:result };
    },
    [apiPointListFailure](state,{ error }){
      return { ...state ,fetchingPointList : false, error: error};
    },
    [apiPointListReset](state){
      return { ...state ,fetchingPointList : false, resultPointList:[]};
    },

    /** renew action group list */
    [apiGroupListRequest](state, { payload }){
      return { ...state, fetchingGroupList : true, error : null};
    },
    [apiGroupListSuccess](state,{ result }){
      return { ...state, fetchingGroupList : false, resultGroupList:result };
    },
    [apiGroupListFailure](state,{ error }){
      return { ...state ,fetchingGroupList : false, error: error};
    },
    [apiGroupListReset](state){
      return { ...state ,fetchingGroupList : false, resultGroupList: []};
    }
    

  },
  initialState
);

// SELECTORS

