import { select,takeLatest, call, put } from "redux-saga/effects";
import { 
  getWarningTypes,
  getHazardPoints,
  getHistoryLogs } from "../reducers/map/api";
import { 
  mapSetWariningTypes,
  mapSetHazardPoints,
  mapSetMarkers,
  mapSetCircles,
  mapSetPolygons ,
  mapCenterChanged,
  mapSetBounds,
  mapSetHazardHistoryLogs,
  SET_MAP_LOADING
} from "../reducers/map";

import { SET_SNACKBAR } from "../reducers/ui";

import axios from 'axios';
import { formHazardPoint } from "../reducers/ui/mapForm";
import Configs from "../config/config";
const env = process.env.NODE_ENV;

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {

  yield takeLatest(getWarningTypes, warningTypesWorkerSaga);
  yield takeLatest(getHazardPoints, hazardPointsWorkerSaga);
  yield takeLatest(formHazardPoint, hazardPointDetailWorkerSaga);
  //Impact Hazard
  // yield takeLatest(getHistoryLogs, hazardHistoryLogWorkerSaga);
}

function fetchDataGet_NEW(params) {  

  //http://mapssh.leafte.ch/managementTest
  let url = params.payload.url+'?'+paramsUrl(params.payload.filter);
  let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache': "no-cache",
      'Authorization': Configs[env].AUTHORIZATION,
      'token':localStorage.getItem('token'),
  };

  return axios
  .get(url , { headers: headers , timeout: 60000})
  .then(resp => {
      return resp
  })
  .catch(error => {      
      return { catchError : error};
  });
}

function paramsUrl(data) {
  return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
}

// worker saga: makes the api call when watcher saga sees the action
function* warningTypesWorkerSaga(action) {
  try {
      const resp = yield call(fetchDataGet_NEW, action);
      const response = yield checkError(resp,action)
      // const response = yield call(fetchDataGet, action);
      if(response !== false) {
        yield put({ type: mapSetWariningTypes, payload:response.data.list });
      }
  } catch (error) {
      yield put({ 
        type: SET_SNACKBAR, 
        payload: {
            snackbarOpen: true,
            snackbarVariant: "error",
            snackbarMessage: error.toString(),
            snackbarDuration: 20000
        }
      });
  }
}
// worker saga: makes the api call when watcher saga sees the action
function* hazardPointsWorkerSaga(action) {
  try {
    yield put({ type: SET_MAP_LOADING, payload: true });
    const state = yield select();


    if(state.ui.formManage.page=='list'){
      // const response = yield call(fetchDataGet, action);

      const resp = yield call(fetchDataGet_NEW, action);
      const response = yield checkError(resp,action)

        if(response !== false) {
          // dispatch a response action
          let markers = setMarkers(response.data.list,false);
          let circles = setCircles(response.data.list,false);
          let polygons = setPolygons(response.data.list,false);


          let bounds = setBounds(response.data.list);

          //yield put({ type: mapSetHazardPoints, data });
        
          yield put({ type: mapSetMarkers,  payload:markers } );
          yield put({ type: mapSetCircles, payload:circles });
          yield put({ type: mapSetPolygons, payload:polygons });
          yield put({ type: mapSetHazardPoints, payload:response.data.list });
          yield put({ type: mapSetBounds, payload:bounds });
      }

      // if(response !== null) {

      //     // dispatch a response action
      //     let markers = setMarkers(response.list,false);
      //     let circles = setCircles(response.list,false);
      //     let polygons = setPolygons(response.list,false);


      //     let bounds = setBounds(response.list);

      //     //yield put({ type: mapSetHazardPoints, data });
        
      //     yield put({ type: mapSetMarkers,  payload:markers } );
      //     yield put({ type: mapSetCircles, payload:circles });
      //     yield put({ type: mapSetPolygons, payload:polygons });
      //     yield put({ type: mapSetHazardPoints, payload:response.list });
      //     yield put({ type: mapSetBounds, payload:bounds });
      // }
      yield put({ type: SET_MAP_LOADING, payload: false });
    }
  } catch (error) {
    yield put({ 
      type: SET_SNACKBAR, 
      payload: {
          snackbarOpen: true,
          snackbarVariant: "error",
          snackbarMessage: error.toString(),
          snackbarDuration: 20000
      }
    });
    yield put({ type: SET_MAP_LOADING, payload: false });
  }
}
// worker saga: makes the api call when watcher saga sees the action
function* hazardPointDetailWorkerSaga(action) {
  try {
    yield put({ type: SET_MAP_LOADING, payload: true });
    const state = yield select();
    let response = [];
    let hazardForm = state.mapForm.hazardForm;
    response.push(hazardForm);
    //const response = yield call(fetchDataGet, action);


    // dispatch a response action
    
    //response.editble = true;
    let markers = setMarkers(response,true);
    let circles = setCircles(response,true);
    let polygons = setPolygons(response,true);
    let bounds = setBounds(response);



    //yield put({ type: mapSetLogisticPoint, response.list });
    yield put({ type: mapSetMarkers,  payload:markers } );
    yield put({ type: mapSetCircles, payload:circles });
    yield put({ type: mapSetPolygons, payload:polygons });
    yield put({ type: mapSetHazardPoints, payload:response });
    //add to location form
    yield put({ type: mapSetBounds, payload:bounds });
    yield put({ type: SET_MAP_LOADING, payload: false });

  } catch (error) {
    yield put({ type: SET_MAP_LOADING, payload: false });
    // dispatch a failure action to the store with the error
    //yield put({ type: mapAction.authLoginError, error });


  }
}

function setMarkers(data,editable){
  let markerList = [];
  let marker ={};

  if(data){
      data.forEach((point, i) => {
        //Marker
        marker = {
          id : point.id,
          position : {lat: point.latitude , lng: point.longitude},
          info : {
            name : point.location,
            description : point.detail,
            logisticsPoint : point.category ,
          },
          isGroup : false,
          editable : editable
        };
        //markerList.push(marker);
        markerList.push(marker);
      });
  }
  return markerList;
}
function setCircles(data,editable){
  let circleList = [];
  let circle = {};
  if(data){
      data.forEach((point, i) => {
        //Circle
        if(point.type=='circle'){

          circle = {
            id : point.id,
            center : {lat: point.circleLatitude , lng: point.circleLongitude},
            radius : point.radius,
            info : {
              name : point.location,
              description : point.detail,
              logisticsPoint : point.category ,
            },
            isGroup : false,
            editable : editable
          };
          circleList.push(circle);
        }
      });
  }

  return circleList;
}
function setPolygons(data,editable){
  let polygonList = [];
  let polygon = {};
  if(data){
      data.forEach((point, i) => {
        if(point.type=='polygon'){
          var decodePath = window.google.maps.geometry.encoding.decodePath(point.polygon);
          var paths = [];
          decodePath.forEach(function(p) {
            paths.push({ lat: p.lat(), lng: p.lng() });
          });
          polygon = {
            id : point.id,
            path : decodePath,
            info : {
              name : point.location,
              description : point.detail,
              logisticsPoint : point.category ,
            },
            isGroup : false,
            editable : editable
          };
          //polygonList.push(polygon);
          polygonList.push(polygon);
        }
      });
      return polygonList;
  }
}
function setBounds(data){
  let bounds = [];
  if(data){
      data.forEach((point, i) => {
          bounds.push({lat: point.latitude , lng: point.longitude});
          if(point.type=='circle'){
              bounds.push({lat: point.circleLatitude , lng: point.circleLongitude});
          } else if(point.type=='polygon'){
              var decodePath = window.google.maps.geometry.encoding.decodePath(point.polygon);
              decodePath.forEach(function(p) {
                bounds.push({ lat: p.lat(), lng: p.lng() });
              });
          }
      });
  }
  return bounds;
}



//===============================
function* checkError(response,action) {
  let params = action
  let callback = params.payload.callback;
  let callback_error = params.payload.callback_error;

  if(response.hasOwnProperty("catchError")){
      if(callback_error) callback_error(response.catchError.toString());
      yield put({ 
          type: SET_SNACKBAR, 
          payload: {
              snackbarOpen: true,
              snackbarVariant: "error",
              snackbarMessage: response.catchError.toString(),
              snackbarDuration: 20000
          }
      });
      return false
  } 

  if(parseInt(response.status) < 200 ||  parseInt(response.status) > 200 ){
      if(callback_error) callback_error("HTTP ERROR : status code = " + response.status);
      yield put({ 
          type: SET_SNACKBAR, 
          payload: {
              snackbarOpen: true,
              snackbarVariant: "error",
              snackbarMessage: "HTTP ERROR : status code = " + response.status,
              snackbarDuration: 20000
          }
      });
      return false
  }
  if(response.data.hasOwnProperty("errorList")) {
      if(response.data.errorList.length > 0){
          if(callback_error) callback_error(errorShow)

          let errorShow = ""
          errorShow = response.data.errorList[0].errorMessage
          console.info("errorDetail",response.data.errorList[0].errorDetail)
          
          if(parseInt(response.data.errorList[0].errorCode) === 2101){
            yield put({ 
              type: SET_SNACKBAR, 
                payload: {
                    snackbarOpen: true,
                    snackbarVariant: "error",
                    snackbarMessage: errorShow,
                    snackbarDuration: 3000
                }
            });
            window.location.href = "/operation_dash";
            return false
          }
          
          if(parseInt(response.data.errorList[0].errorCode) === 2102 ||
              parseInt(response.data.errorList[0].errorCode) === 2103 ){
              yield put({ 
                  type: SET_SNACKBAR, 
                  payload: {
                      snackbarOpen: true,
                      snackbarVariant: "error",
                      snackbarMessage: errorShow,
                      snackbarDuration: 3000,
                      forceLogout: true
                  }
              });
              return false
          }

          yield put({ 
              type: SET_SNACKBAR, 
              payload: {
                  snackbarOpen: true,
                  snackbarVariant: "error",
                  snackbarMessage: errorShow,
                  snackbarDuration: 20000
              }
          });
          return false
      }
  }


  if(response.data.hasOwnProperty("list") || response.data.hasOwnProperty("result")){
      
      if(callback) callback(response.data);
      // yield put({ 
      //     type: SET_SNACKBAR, 
      //     payload: {
      //         snackbarOpen: true,
      //         snackbarVariant: "success",
      //         snackbarMessage: "Manual reload data success",
      //         snackbarDuration: 2000
      //     }
      // });

      return response
  }
  

  yield put({ 
      type: SET_SNACKBAR, 
      payload: {
          snackbarOpen: true,
          snackbarVariant: "error",
          snackbarMessage: "API error",
          snackbarDuration: 20000
      }
  });

  return false
}


