import React, {Component} from "react";
import moment from 'moment';

export default class AmountRenderer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        }
    }
    formatColor = (value) => {
        //return '<span style="background-color: red;">' + value + '</span>';
    }
    render() {
        return (
            <span >{this.state.value}</span>
        );
    }
};

