import React, {Component} from "react";
import Avatar from '@material-ui/core/Avatar';
import Grid from "@material-ui/core/Grid";
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';

const styles = {
    avatar: {
        margin: 5,
        width: 20,
        height: 20,
    },
    bigAvatar: {
        margin: 10,
        width: 60,
        height: 60,
    },
};


class RouteRenderer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value
        }
    }

    handleOpenRoute = () => {
        window.location.href = '/route_suggestion/'+this.props.data.jobDashboardId.value
    }
    // render() {
    //     const { classes } = this.props;
        
    //     return (
    //         <div onClick={this.handleOpenRoute}>{this.state.value}</div>
    //     );
    // }

    render() {
        const { classes } = this.props;
        let canEdit = false
        let bgColor = '';
        // console.log("KAI",this.props.data)

        if(this.props.data.routeCode.editable === 1) canEdit = true

        return (
            <div class="something" 
                //onClick={canEdit ? this.handleOpenRoute: null} 
                styles={{
                    width:'100%',
                    height:'100%',
                    backgroundColor:bgColor,
                }}>
                {canEdit && <div class="arrow-right"></div>}
                {/*<span>{this.state.value}</span>*/}
                {canEdit ?
                <Link 
                    style={{ textDecoration: 'none',color:'black'}} 
                    to={'/route_suggestion/'+this.props.data.jobDashboardId.value}>
                    {this.state.value}
                </Link> : this.state.value
                }
            </div>
        );
    }
};

const mapStateToProps = function(state,ownProps) {
    return {
        ...ownProps,
        activeMenuListItem : state.ui.activeMenuListItem,
    }
};

const mapActionsToProps =  {
    //setActiveMenuListItem
};
const enhance = compose(
    connect(
        mapStateToProps,
        mapActionsToProps
    ),
    withStyles(styles)
);  

export default enhance(RouteRenderer)