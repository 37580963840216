import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { connect } from "react-redux";
import compose from "recompose/compose";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
//Component
import WarningBtnRenderer from "./Renderer/WarningBtnRenderer";
import PanelDialog from "../Ui/PanelDialog";
import WarningForm from "./WarningForm";
//Reducer
import { uiPanelDialogOpen, uiSwitchForm } from "../../reducers/ui";
import { getWarningTypes} from "../../reducers/map/api";
import { formWarningType } from "../../reducers/ui/mapForm";

import Configs from "../../config/config";
const env = process.env.NODE_ENV;
const ComponentName = "WarningTypes";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  textField: {
    margin: 0,
    //marginLeft: theme.spacing.unit,
    //marginRight: theme.spacing.unit,
    width: 200
  }
});
class WarningTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url_endpoint: {
        response: false,
        endpoint: Configs[env].BACKEND_HOST
      },
      columnDefs: [{
          headerName: "CODE",
          field: "code",
          width: 60
        },{
          headerName: "DESCRIPTION",
          field: "description",
          width: 130
        },{
          headerName: "VOICE",
          field: "voice",
          width: 130
        },{
          headerName: "STATUS",
          field: "status",
          width: 80,
          cellRenderer :"statusRenderer"
        },
        {
          cellRenderer: "warningBtnRenderer",
          width: 70
        }
      ],
      rowData: null,
      context: { componentParent: this },

      frameworkComponents: {
        warningBtnRenderer: WarningBtnRenderer,
        statusRenderer: this.statusRenderer,
      }
    };
  }
  componentDidMount() {

  }
  componentWillMount() {

    this.props.GET_WARNING_TYPES({
      url: Configs[env].BACKEND_HOST + "/warningType",
      filter: {
        //status : true
      }
    });
  }
  componentWillUnmount() {}
  componentDidUpdate () {
    // this.autoSizeAll();
  }
  
  //Grid
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
  }
  sizeToFit = () => {
    this.gridApi.sizeColumnsToFit();
  }
  autoSizeAll = () => {
    var allColumnIds = [];
    if(this.gridColumnApi){

      this.gridColumnApi.getAllColumns().forEach(function(column,index) {
        if(index!=0)allColumnIds.push(column.colId);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds);
    }
  }


  renderRow() {
    if (Array.isArray(this.props.warning_types)) {

        //filter text in location point
        return this.props.warning_types;
    } else return [];
  }
  statusRenderer(params) {

    if (params.value == 1)
        return 'Enable';
    else
        return 'Disable';
  }
  dialogFormWarningType = () => {
    return (
      <WarningForm
        type={this.state.mode}
        logistic={{
          value: this.state.logistic,
          text: this.state.logisticText
        }}
        location={{
          detail: this.state.locationDetail,
          value: this.state.location,
          text: this.state.locationText
        }}
      />
    );
  }
  handleBack = event =>{

      this.props.SWITCH_FORM({...this.props.formManage, page: "manage" });
  }
  handleAdd = event => {
    //this.setState({ mode: "ADD" });
    this.props.SWITCH_FORM({...this.props.formManage, mode : "add" });
    this.props.FORM_WARNING({});
    this.props.DIALOG_OPEN(true);
  }
  handleEdit = event => {
    this.setState({ mode: "EDIT" });
    this.props.DIALOG_OPEN(true);
  }
  render() {
    return (
      <div style={{ height: "400px",padding:"10px" }}>
        <Toolbar variant="dense">
          <Typography variant="h6" gutterBottom className={styles.titlePanel}>
            {this.props.mode} WARNING TYPE
          </Typography>
        </Toolbar>

        <PanelDialog
          open={this.props.dialogOpen || (this.props.warningForm && this.props.warningForm.id)}
          title={this.props.formManage.mode.toUpperCase() + " WARNING TYPES"}
          form={this.dialogFormWarningType}
        />

        <Card className={styles.card}>
          <CardContent>
            <Grid container spacing={8}>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  aria-label="Add"
                  className={classNames(styles.button, styles.cssGreen)}
                  onClick={this.handleAdd}
                >
                  ADD WARNING TYPE
                </Button>
              </Grid>
            </Grid>
            <div
              className="ag-theme-balham"
              style={{
                height: "550px",
                width: "100%",
                marginTop: "10px"
              }}
            >
              <AgGridReact
                    enableSorting={true}
                    enableColResize={true}
                    singleClickEdit={true}
                    suppressMovableColumns={true}
                    columnDefs={this.state.columnDefs}
                    rowData={this.renderRow()}
                    rowSelection="single"
                    onGridReady={this.onGridReady}
                    //onSelectionChanged={this.handleSelectionChanged}
                    frameworkComponents={this.state.frameworkComponents}
                  />
            </div>
            <Button
                variant="outlined"
                size="small"
                color="secondary"
                aria-label="Back"
                className={classNames(styles.button, styles.cssGreen)}
                onClick={this.handleBack}>
                BACK
            </Button>
          </CardContent>
        </Card>
        
      </div>
    );
  }
}
const mapStateToProps = function(state, ownProps) {
  return {
    isLoading: state.api.fetching,
    dialogOpen: state.ui.dialogOpen,
    warning_types: state.map.warning_types,
    formManage : state.ui.formManage,
    warningForm : state.mapForm.warningForm,
    FORM_WARNING : formWarningType,
  };
};
const mapActionsToProps = {
  DIALOG_OPEN: uiPanelDialogOpen,
  SWITCH_FORM: uiSwitchForm,
  GET_WARNING_TYPES : getWarningTypes
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapActionsToProps
    //mapDispatchToProps
  ),
  withStyles(styles)
)(WarningTypes);
