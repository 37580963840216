import React, { Component } from "react";
import { render } from "react-dom";
import { connect } from "react-redux";
import ImMarker from "./ImMarker";
import ImCircle from "./ImCircle";
import ImPolygon from "./ImPolygon";
import MarkerGroup from "./MarkerGroup";
import MarkerGroupIcon from "./MarkerGroupIcon";
import MarkerLocation from "./MarkerLocation";

class LocationMaster extends Component {
  render() {
    return (
        <div>
            {this.props.markerList.length>0 && this.props.markerList.map((marker, index) => {
                 if (marker.isGroup)
                     return (
                         <MarkerGroupIcon
                             key={marker.id}
                             id={marker.id}
                             position={marker.position}
                             isGroup={marker.isGroup}
                             info={marker.info}
                             editable={marker.editable}
                     />
                     );
                return (
                    <MarkerLocation
                        key={marker.id}
                        id={marker.id}
                        position={marker.position}
                        info={marker.info}
                        editable={marker.editable}
                        isGroup={marker.isGroup}
                        />
                );
            })}
            {this.props.circleList && this.props.circleList.length>0 && this.props.circleList.map((circle, index) => {
                return (
                    <ImCircle
                    key={circle.id}
                    id={circle.id}
                    center={circle.center}
                    color="blue"
                    info={circle.info}
                    radius={circle.radius}
                    editable={circle.editable}
                    />
                );
            })}
            {this.props.polygonList && this.props.polygonList.length>0 && this.props.polygonList.map((polygon, index) => {
                return (
                    <ImPolygon
                    key={polygon.id}
                    id={polygon.id}
                    path={polygon.path}
                    editable={polygon.editable}
                    info={polygon.info}
                    />
                );
            })}
            {this.props.markerGroup &&  (
                    <MarkerGroup
                    key={this.props.markerGroup.id}
                    id={this.props.markerGroup.id}
                    position={this.props.markerGroup.position}
                    info={this.props.markerGroup.info}
                    editable={this.props.markerGroup.editable}
                    isGroup={true}
                    />
            )}
            
        </div>
    )
  }
}
const mapStateToProps = function(state) {
    return {
        markerGroup: state.map.markerGroup,
        markerList: state.map.markerList,
        circleList: state.map.circleList,
        polygonList: state.map.polygonList,
    };
  };
  
  const mapActionsToProps = {
  };
  
  export default connect(
    mapStateToProps,
    mapActionsToProps
  )(LocationMaster);
  