import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";

import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
  propTypes,
  reduxForm,
  Form,
  Field,
  formValueSelector
} from "redux-form";
//Component
import Users from "./Users";
import ManageForm from "./ManageForm";

//Reducer
import { searchUsers } from "../../reducers/search";
import { getUsers } from "../../reducers/setting/api";
import { setSidebarVisibility,uiSwitchForm } from "../../reducers/ui";
import { formUser } from "../../reducers/ui/mapForm";
//Config
import Configs from "../../config/config";
const env = process.env.NODE_ENV;
const MIN_HEIGHT = 25;

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  scrollHeight:{

  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  gridForm : {
    padding : '0px'
  },
  subheader: {
    width: '100%',
  },
});
const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    format="YYYY/MM/DD" 
    {...inputProps}
    {...props}
  />
);

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }
  componentDidMount() {
    const { setSidebarVisibility } = this.props;

    setSidebarVisibility(false);
    this.getUsers();
  }
  getUsers = () => {
    this.props.getUsers({
      url: Configs[env].BACKEND_HOST + "/user",
      filter : {
      }
    });
  }
  renderManageForm(){
    switch(this.props.formManage.page){
      case 'manage' : 
        return (<ManageForm/>);
      default : 
        return this.renderSearchForm();
    }
  }
  methodFromParent(cell) {
    alert("Parent Component Method from " + cell + "!");
  }
  onFilterTextBoxChanged = event => {

    this.props.searchUsers(event.target.value);
    //this.props.filterResult({key:'LOCATION_GROUP_LIST',filter:event.target.value});
    //this.state.api.setQuickFilter(document.getElementById('location-search').value);
  };
  handleAdd = (event) => {
    this.props.formUser({
    });
    this.props.uiSwitchForm({page:'manage',mode:"add"});
  }
  renderSearchForm = () =>{
    

    return (
      <Grid container spacing={16}>
        <Grid item xs={4}>
          <FormControl required fullWidth noValidate autoComplete="on">
            <TextField
              id="location-search"
              label="SEARCH USER"
              type="search"
              InputLabelProps={{
                shrink: true
              }}
              margin="dense"
              className={styles.textField}
              onChange={this.onFilterTextBoxChanged.bind(this)}
              InputProps={{
                endAdornment: (
                  <InputAdornment variant="filled" position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={8}>
          <DialogActions>
            <Button
              testing="button-save-logistics-point"
              variant="contained"
              size="small"
              color="primary"
              aria-label="Save"
              className={styles.button}
              type="submit"
              onClick={this.handleAdd}
            >
              ADD USER
            </Button>
          </DialogActions>
        </Grid>
        <Grid item xs={12}>
          <Users/>
        </Grid>
        
      </Grid>
    );
  }
  render() {

    return (
      //height: 'calc(100vh-60px)' 
      <div style={{ padding:'10px',display: 'flex', height: 'calc(100vh-60px)' }}>
        <div
          id="settingUser"
          style={{
            boxSizing: "border-box",
            flex : 1,
            width: "100%",
            height: "90%"
          }}
          className="ag-theme-balham"
        >
          <React.Fragment> 
            {this.renderManageForm()}
          </React.Fragment>  
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  isReset : state.map.isReset,
  formManage : state.ui.formManage,
});

const mapDispatchToProps = dispatch => {
  return {
    
    setSidebarVisibility: status => dispatch(setSidebarVisibility(status)),
    getUsers: payload => dispatch(getUsers(payload)),
    searchUsers: payload => dispatch(searchUsers(payload)),
    formUser: payload => dispatch(formUser(payload)),
    uiSwitchForm: payload => dispatch(uiSwitchForm(payload)),
    dispatch
  };
};


export default compose(
  connect(
    mapStateToProps,
    //mapActionsToProps
    mapDispatchToProps
  ),
  withStyles(styles)
)(User);
