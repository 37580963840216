import { createAction, handleActions } from 'redux-actions';


export const getManagement = createAction("API_GET_MANAGEMENT");
export const getOperation = createAction("API_GET_OPERATION");
export const getFrame = createAction("API_GET_FRAME");
export const getRecovery = createAction("API_GET_RECOVERY");
export const exportOperation = createAction("API_EXPORT_OPERATION");
export const exportFrame = createAction("API_EXPORT_FRAME");

const initialState = {
    fetching: false,
};

// REDUCERS
export default handleActions(
{
    [getManagement](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    [getOperation](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    [getFrame](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    [getRecovery](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    [exportOperation](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    [exportFrame](state, { payload }) {
        return { ...state, 
            url : payload.url,
            filter : payload.filter,
            fetching: true, 
            error: null 
        };
    },
    
},initialState);