import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import green from "@material-ui/core/colors/green";
//import AutoSelect from 'react-select';
import { connect } from "react-redux";
import { searchOperation } from "../../reducers/search";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: "10px"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    //width: 200
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  button: {
    margin: theme.spacing.unit
  },
  padding5px: {
    display: "flex",
    flexWrap: "wrap",
    padding: "5px",
    margin: "10px"
  },
  cssGreen: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  titlePanel: {
    backgroundColor: green[500],
    color: theme.palette.getContrastText(green[500])
  },
  selectStyles: {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit"
      }
    })
  },
  txtBlack : {
    color : "#000000",
  }
});

class YardSelect extends Component {
  state = {
    value : []
  };
  renderMenuItem = () => {
    let self = this;
    if(this.props.dashboard_yard){
      var menuItem = this.props.dashboard_yard;
      return menuItem.map(function(value, index) {


        return (
          <MenuItem key={value} value={value}>
            {/*<Checkbox checked={self.state.value.indexOf(value) > -1} />*/}
            <Checkbox checked={self.props.search_operation.yard && self.props.search_operation.yard.indexOf(value) > -1 ? 'checked' : ''} />
            <ListItemText primary={value} />
          </MenuItem>
        );
      });
    }
    else 
      return null;
  }
  handleChange = (event) =>{
    this.setState({ value: event.target.value });
    this.props.searchOperation({...this.props.search_operation,yard:event.target.value });
  }
  render() {
    const { classes } = this.props;
    return (
        <FormControl required fullWidth className={classes.textField}>
            <InputLabel shrink htmlFor="yard-required" className={classes.txtBlack}>
            YARD
            </InputLabel>
            <Select
              multiple
              testing="select-yard"
              name="yard"
              value={this.props.search_operation.yard ? this.props.search_operation.yard : []}
              onChange={this.handleChange}
              renderValue={selected => selected.join(', ')}
              inputProps={{
                  id: "yard-required"
              }}
            >
              {this.renderMenuItem()}
            </Select>
        </FormControl>
    );
  }
}
const mapStateToProps = function(state) {
  return {
    dashboard_yard : state.dashboard.dashboard_yard,
    search_operation : state.search.search_operation,
  };
};
const mapActionsToProps = {
  searchOperation
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(YardSelect));
