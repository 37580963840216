import React, { Component } from "react";
import { render } from "react-dom";
import { connect } from "react-redux";
import ImMarker from "./ImMarker";
import ImCircle from "./ImCircle";
import RsPolyline from "./RouteSuggestion/RsPolyline";
import MarkerGroup from "./MarkerGroup";
import MarkerLocation from "./MarkerLocation";
import TruckMarker from "./RouteSuggestion/TruckMarker";
import DropMarker from "./RouteSuggestion/DropMarker"
import RsWaypoints from "./RouteSuggestion/RsWaypoints"


import uuidv4  from 'uuid/v4';
import {
    Marker,
    InfoWindow,
    Circle,
    Polygon
  } from "react-google-maps";

import { 
SET_MAP_LOADING,

} from "../../reducers/map";


import RsHazardMarker from "./RouteSuggestion/RsHazardMarker";

class RouteSuggestMap extends Component {
    componentWillUpdate(nextProps, nextState) {
        // if (nextState.open == true && this.state.open == false) {
            this.props.SET_MAP_LOADING(true)
        // }
      }

    // componentDidUpdate(prevProps, prevState) {
    //     // if (prevProps.data !== this.props.data) {
    //     //     console.log("KAI map updated")
    //         this.props.SET_MAP_LOADING(false)
    //     // }
        
    // }

    render() {

      let size = new window.google.maps.Size(22, 36);
  
      let positionA =  { lat: 14.1643, lng: 100.581 }
      let positionB =  { lat: 14.1669, lng: 100.6495 }
  
      let icon_urlA = "http://www.google.com/mapfiles/dd-start.png";
      let icon_urlB = "http://www.google.com/mapfiles/dd-end.png";
  
      
      
  
      return (
          // https://developers.google.com/maps/documentation/javascript/reference/polyline#PolylineOptions
          // visible
          // defaultVisible
  
          <React.Fragment key={uuidv4()}>
              {this.props.suggestion_routes.hasOwnProperty("routes") && <RsWaypoints/>}
              

              {this.props.show_master_or_candidate !=2 && 
              this.props.suggestionPolylineList && 
              this.props.suggestionPolylineList.length>0 && 
              this.props.suggestionPolylineList.map((polyline, index) => {
                  return (
                      <RsPolyline
                      {...polyline}
                      key={polyline.index}
                      id={polyline.id}
                      path={polyline.path}
                      editable={polyline.editable}
                    //   info={polyline.info}
                      />
                  );
              })}

 
            {this.props.markerList.length>0 && this.props.markerList.map((marker, index) => {
                return (
                    <RsHazardMarker
                        {...marker}
                        key={marker.id}
                        id={marker.id}
                        position={marker.position}
                        info={marker.info}
                        editable={marker.editable}
                        isGroup={marker.isGroup}
                        category={marker.category}
                        />
                );
            })}
            {this.props.truck_location && 
                 (
                    <TruckMarker
                        key="truck_id"
                        id="truck_id"
                        name={this.props.truck_location.name}
                        position={this.props.truck_location.position}
                        />
            )}
            {this.props.circleList && this.props.circleList.length>0 && this.props.circleList.map((circle, index) => {
                return (
                    <Circle
                    key={circle.id}
                    id={circle.id}
                    center={circle.center}
                    color="blue"
                    radius={circle.radius}
                    options={{
                        zIndex: 10,
                        strokeColor: 'red',
                        fillColor: 'red',
                        strokeOpacity: 1,
                        strokeWeight: 2,
                        fillOpacity: 0.3
                    }}
                    />
                );
            })}
            {this.props.polygonList && this.props.polygonList.length>0 && this.props.polygonList.map((polygon, index) => {
                return (
                    <Polygon
                    options={{
                        zIndex: 10,
                        strokeColor: 'red',
                        fillColor: 'red',
                        strokeOpacity: 1,
                        strokeWeight: 2,
                        fillOpacity: 0.3
                    }}
                    key={polygon.id}
                    id={polygon.id}
                    path={polygon.path}
                    />
                );
            })}


            {this.props.dropPointList.map((droppoint, index) => {
                    let isFirst = false;
                    let isLast = false;
                    

                    // if (index === this.props.droppoints.length - 1) {
                    //     isLast = true;
                    // }
                    // if (index === 0) {
                    //     isFirst = true;
                    // }

                    // if (this.props.legs.length > 0 && index > 0) {
                    //     try {
                    //     totalDistance =
                    //         totalDistance + Number(this.props.modified_route.Legs[index - 1].Distance);
                    //     totalETA = totalETA + Number(this.props.modified_route.Legs[index - 1].ETA);

                    //     infoDistance = (totalDistance / 1000).toFixed(2) + " KM";
                    //     infoETA = totalETA + " min";
                    //     } catch (e) {
                    //     infoDistance = (totalDistance / 1000).toFixed(2) + " KM";
                    //     infoETA = totalETA + " min";
                    //     }
                    // } else {
                    //     infoDistance = "0 KM";
                    //     infoETA = "0 min";
                    // }

                    return (
                        <div>
                            <DropMarker
                                {...droppoint}
                                key={index}
                                id={index}

                                isFirst={isFirst}
                                isLast={isLast}
                                defaultDraggable={isFirst || isLast || this.props.map_loading ? false : true}
                                // infoDistance={infoDistance}
                                // infoETA={infoETA}
                                position={droppoint.position}
                                isGroup={droppoint.isGroup}
                                description={droppoint.description}
                            />
                        </div>
                    );
                    })}



              
              {/* <div>
                  <Marker
                      position={this.props.marker_origin}
                      icon={{
                          url: "http://www.google.com/mapfiles/dd-start.png",
                          labelOrigin: new window.google.maps.Point(19, 14),
                          scaledSize: new window.google.maps.Size(22, 36),
                          size: new window.google.maps.Size(22, 36)
                      }}
                  />
                  <Marker
                      position={this.props.marker_desc}
                      icon={{ 
                          url: "http://www.google.com/mapfiles/dd-end.png",
                          labelOrigin: new window.google.maps.Point(19, 14),
                          scaledSize: new window.google.maps.Size(22, 36),
                          size: new window.google.maps.Size(22, 36)
                      }}
                  />
              </div> */}
          </React.Fragment>
      )
    }
  }
const mapStateToProps = function(state) {
    return {
        markerGroup: state.map.markerGroup,
        markerList: state.map.markerList,
        dropPointList: state.map.dropPointList,
        circleList: state.map.circleList,
        polygonList: state.map.polygonList,
        suggestionPolylineList: state.map.suggestionPolylineList,
        truck_location : state.map.truck_location,
        suggestion_routes: state.map.suggestion_routes,
    };
  };
  
  const mapActionsToProps = {
    SET_MAP_LOADING,
  };
  
  export default connect(
    mapStateToProps,
    mapActionsToProps
  )(RouteSuggestMap);
  
