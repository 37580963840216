import { select,takeLatest, call, put } from "redux-saga/effects";
import {
  getDistanceMatrix,
  getDistanceMatrixReportDetail,
  getDistanceMatrixReport,
  getSearchJob,
} from "../reducers/map/api";
import {
  mapSetDistanceMatrix,
  mapSetReportDetail,
  SET_MAP_LOADING,
  mapSetSearchJob,
} from "../reducers/map";

import { SET_SNACKBAR } from "../reducers/ui";
import axios from 'axios';
import Configs from "../config/config";
const env = process.env.NODE_ENV;

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {

  yield takeLatest(getDistanceMatrix, distanceMatrixWorkerSaga);
  yield takeLatest(getDistanceMatrixReport, DownloadReportDistanceMatrixSaga);
  yield takeLatest(getDistanceMatrixReportDetail, reportDistanceMatrixSaga);
  yield takeLatest(getSearchJob, searchJobWorkerSaga);
}


function fetchData(params) {
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Cache: "no-cache",
    Authorization: Configs[env].AUTHORIZATION,
    token: localStorage.getItem("token"),
  };

  return axios
    .get(params.payload.url, { headers: headers, timeout: 20000 })
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      return { catchError: error };
    });
}

function* searchJobWorkerSaga(action) {
  try {
    // search_job?key=abd
    const queryString = new URLSearchParams({
      key: action.payload.key,
    }).toString();
    let url = action.payload.url + "?" + queryString;
    action.payload.url = url;

    const resp = yield call(fetchData, action);
    const response = yield checkError(resp, action);

    if (response !== false) {
      yield put({
        type: mapSetSearchJob,
        payload: response.data.list,
      });
    }
  } catch (error) {
    yield put({ type: SET_MAP_LOADING, payload: false });
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: "error",
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
  }
}

function* distanceMatrixWorkerSaga(action) {
  try {
    // Missing input
    if (!action.payload.locations.every((item) => item !== null)) {
      yield put({ type: mapSetDistanceMatrix, payload: [] });
      return;
    }

    const idsArray = action.payload.locations.map((item) => item.id);
    const queryString = new URLSearchParams({
      locations: idsArray,
    }).toString();
    let url = action.payload.url + "?" + queryString;

    action.payload.url = url;

    yield put({ type: SET_MAP_LOADING, payload: true });
    const resp = yield call(fetchData, action);
    const response = yield checkError(resp, action);

    if (response !== false) {
      yield put({ type: mapSetDistanceMatrix, payload: response.data.list });
      if (response.data.list.some((item) => item.distance < 0)) {
        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: "warning",
            snackbarMessage:
              "Some distance matrix route are not available! Please select new location",
            snackbarDuration: 5000,
          },
        });
      }
    }

    yield put({ type: SET_MAP_LOADING, payload: false });
  } catch (error) {
    yield put({ type: SET_MAP_LOADING, payload: false });
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: "error",
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
  }
}

function* reportDistanceMatrixSaga(action) {
  try {
    const resp = yield call(fetchData, action);
    const response = yield checkError(resp, action);

    if (response !== false) {
      yield put({ type: mapSetReportDetail, payload: response.data.list[0] });
    }
  } catch (error) {
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: "error",
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
  }
}

function* DownloadReportDistanceMatrixSaga(action) {
  const queryString = new URLSearchParams({
    path: action.payload.path,
  }).toString();
  let url = action.payload.url + "?" + queryString;
  action.payload.url = url;

  try {
    const resp = yield call(fetchData, action);
    if (resp.status != 200) {
      yield put({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarVariant: "error",
          snackbarMessage: `API returned status ${resp.status}`,
          snackbarDuration: 20000,
        },
      });
      action.payload.callback_error();
      return;
    }
    action.payload.callback(resp.data);
  } catch (error) {
    action.payload.callback_error();
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: "error",
        snackbarMessage: error.toString(),
        snackbarDuration: 20000,
      },
    });
  }
}


//===============================
function* checkError(response, action) {
  let params = action;
  let callback = params.payload.callback;
  let callback_error = params.payload.callback_error;

  if (response.hasOwnProperty("catchError")) {
    if (callback_error) callback_error(response.catchError.toString());
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: "error",
        snackbarMessage: response.catchError.toString(),
        snackbarDuration: 20000,
      },
    });
    return false;
  }

  if (parseInt(response.status) < 200 || parseInt(response.status) > 200) {
    if (callback_error)
      callback_error("HTTP ERROR : status code = " + response.status);
    yield put({
      type: SET_SNACKBAR,
      payload: {
        snackbarOpen: true,
        snackbarVariant: "error",
        snackbarMessage: "HTTP ERROR : status code = " + response.status,
        snackbarDuration: 20000,
      },
    });
    return false;
  }
  if (response.data.hasOwnProperty("errorList")) {
    if (response.data.errorList.length > 0) {
      if (callback_error) callback_error(errorShow);

      let errorShow = "";
      errorShow = response.data.errorList[0].errorMessage;
      console.info("errorDetail", response.data.errorList[0].errorDetail);

      if (parseInt(response.data.errorList[0].errorCode) === 2101) {
        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: "error",
            snackbarMessage: errorShow,
            snackbarDuration: 3000,
          },
        });
        window.location.href = "/operation_dash";
        return false;
      }

      if (
        parseInt(response.data.errorList[0].errorCode) === 2102 ||
        parseInt(response.data.errorList[0].errorCode) === 2103
      ) {
        yield put({
          type: SET_SNACKBAR,
          payload: {
            snackbarOpen: true,
            snackbarVariant: "error",
            snackbarMessage: errorShow,
            snackbarDuration: 3000,
            forceLogout: true,
          },
        });
        return false;
      }

      yield put({
        type: SET_SNACKBAR,
        payload: {
          snackbarOpen: true,
          snackbarVariant: "error",
          snackbarMessage: errorShow,
          snackbarDuration: 20000,
        },
      });
      return false;
    }
  }

  if (
    response.data.hasOwnProperty("list") ||
    response.data.hasOwnProperty("result")
  ) {
    if (callback) callback(response.data);

    return response;
  }

  yield put({
    type: SET_SNACKBAR,
    payload: {
      snackbarOpen: true,
      snackbarVariant: "error",
      snackbarMessage: "API error",
      snackbarDuration: 20000,
    },
  });

  return false;
}
